import {Center, ScrollView, Text, View} from "native-base";
import React from 'react';
import {useWindowDimensions} from 'react-native';
import {Configure, InstantSearch, Index, connectStateResults} from 'react-instantsearch-native';
import algoliasearch from 'algoliasearch';
import SearchBox from "./SearchBox";
import InfiniteHits from "./InfiniteHits";
import {useSelector} from "react-redux";

const algoliaClient = algoliasearch(
    'MEFT3ZROYW',
    '136ad666414cfe715d1714d63e8a4ad6'
);

const searchClient = {
    ...algoliaClient,
    search(requests) {
        if (requests.every(({params}) => !params.query || params.query.length < 3)) {
            return Promise.resolve({
                results: requests.map(() => ({
                    hits: [],
                    nbHits: 0,
                    nbPages: 0,
                    page: 0,
                    processingTimeMS: 0,
                })),
            });
        }
        return algoliaClient.search(requests);
    },
};

const AllResults = connectStateResults(({allSearchResults, children, searchState}) => {
    const hasResults =
        allSearchResults &&
        Object.values(allSearchResults).some(results => results.nbHits > 0);
    return !hasResults && searchState.query && searchState.query.length > 2 ? (
        <Center py={4}>
            <Text>Sorry, we found no results matching your search.</Text>
            <Index indexName="live_speakers"/>
            <Index indexName="live_exhibitors"/>
            <Index indexName="live_sessions"/>
        </Center>
    ) : (
        children
    );
});

function SearchBarAlgolia() {
    const {height, width} = useWindowDimensions();
    const {congress} = useSelector(state => state.global);

    return (
        <InstantSearch
            searchClient={searchClient}
            indexName="live_speakers"
        >
            <Configure
                hitsPerPage={5}
                filters={'congress_id:' + congress.id}
            />
            <View style={{position: 'absolute', top: 0, zIndex: 20, width: '100%'}}>
                <SearchBox/>
                <ScrollView
                    style={{maxHeight: (height - (height * 0.4))}}
                    shadow={9}
                >
                    <AllResults>
                        <Index indexName="live_speakers">
                            <InfiniteHits attribute={'fullname'} haveAvatar entity='speaker'/>
                        </Index>
                        <Index indexName="live_exhibitors">
                            <InfiniteHits attribute={'company_name'} haveAvatar entity='company'/>
                        </Index>
                        <Index indexName="live_sessions">
                            <InfiniteHits attribute={'full_title'} haveAvatar entity='session'/>
                        </Index>
                    </AllResults>
                </ScrollView>
            </View>
        </InstantSearch>
    );
}

export default SearchBarAlgolia;

