import * as React from "react";
import {Button, Center, Flex, useTheme, View} from "native-base";
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';

export default function LayoutLivePanelMobile({navigation, ...props}) {
    const {colors} = useTheme();

    function goToChat() {
        return navigation.navigate('LiveChatMobileScreen');
    }

    function goToInfo() {
        return navigation.navigate('LiveInfoMobileScreen');
    }

    return(
            <Flex flex={1} bg={colors['background'][50]}>
                <View flex={7}>
                    {props.children}
                </View>
                <Flex direction='row' justify={'space-around'} flex={1}>
                    <Button
                        variant={'unstyled'}
                        borderTopWidth={props.activePage === 'Chat' ? 1 : 0}
                        borderColor={colors['action'][50]}
                        flex={1}
                        borderRadius={0}
                        onPress={goToChat}
                        p={0}
                    >
                        <MaterialCommunityIcons name="chat" size={20} color='#5766D6' />
                    </Button>
                    <Button
                        variant={'unstyled'}
                        borderTopWidth={props.activePage === 'Info' ? 1 : 0}
                        borderColor={colors['action'][50]}
                        flex={1}
                        borderRadius={0}
                        onPress={goToInfo}
                        p={0}
                    >
                        <MaterialIcons name="info" size={20} color='#5766D6' />
                    </Button>
                </Flex>
            </Flex>
    );
}
