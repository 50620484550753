import {
    Box,
    Button,
    Center,
    Text,
    Input,
    useTheme,
    Heading,
    Flex,
    FormControl,
    ScrollView, Image, Spacer, View,
} from "native-base";
import * as React from "react";
import {useForm, Controller} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {login} from "../actions/auth";
import {Link, useIsFocused} from "@react-navigation/native";
import {useTranslation} from "react-i18next";
import TermsOfUse from "../components/TermsOfUse";
import {Animated, KeyboardAvoidingView, Linking, Platform, Dimensions} from "react-native";
import * as Device from "expo-device";
import {useEffect, useRef, useState} from "react";
import {clearMessage} from "../actions/message";
import * as Localization from "expo-localization";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import SupportButton from "../components/SupportButton";
import Config from "../config.json";

import playstoreImg from "../../assets/download_google_play.png";
import appstoreImg from "../../assets/download_app_store.png";
import AsyncStorage from "@react-native-async-storage/async-storage";

const logoImageSVG = require('../../assets/login_logo_white.svg');
const logoImagePNG = require('../../assets/login_logo_white.png');

const logoImageSVGAop = require('../../assets_aop/login_logo_white.svg');
const logoImagePNGAop = require('../../assets_aop/login_logo_white.png');

export default function LoginScreen({navigation}) {
    const {control, handleSubmit, formState: {errors}} = useForm();
    const {user} = useSelector(state => state.auth);
    const {message} = useSelector(state => state.message);
    const {congress} = useSelector(state => state.global);
    const dispatch = useDispatch();
    const {colors} = useTheme();
    const {t, i18n} = useTranslation();
    const isFocused = useIsFocused();
    const [isLoading, setIsLoading] = useState(false);
    const [deviceType, setDeviceType] = useState();
    const [shadowOp, setShadowOp] = useState(0);
    const [translateBoxX, setTranslateBoxX] = useState(0);


    // Valeur du padding (Native base values) du titre&layout sert à la conversion de ces valeurs en pixel pour l'animation
    const paddingDesktop = Platform.OS == "web" && Dimensions.get("window").width < 1300  ? 5:  10;
    const paddingMobile = 5;

    // Références des animations : 1 variable = 1 valeur/style modifié
    const fadeFormAnim = useRef(new Animated.Value(0)).current;
    const fadeImageAnim = useRef(new Animated.Value(0)).current;
    const fadeTextAnim = useRef(new Animated.Value(0)).current;
    const borderAnim = useRef(new Animated.Value(0)).current;
    const translationAnim = useRef(new Animated.Value(paddingDesktop * 4)).current;
    const heightBoxAnim = useRef(new Animated.Value(200)).current;


    useEffect(() => {
        dispatch(clearMessage());
        let deviceLocale = Localization.locale.split('-')[0];
        //Désolé pour ces conditions horrible
        if (Config.type == 'imcas' && (deviceLocale !== 'es' && deviceLocale !== 'pt')) {
            deviceLocale = 'en';
        }
        if(Config.type == 'aop'){
            deviceLocale = 'fr';
        }
        i18n.changeLanguage(deviceLocale);
    }, [isFocused])


    useEffect(
        () => {
            async function setLayout() {
                const type = await getDeviceType();
                setDeviceType(type);
            }
            setLayout();
            Animated.sequence([
                Animated.timing(translationAnim, {
                    toValue: 0,
                    delay: 200,
                    duration: 200,
                    useNativeDriver: true
                }),
                Animated.timing(fadeImageAnim, {
                    toValue: 1,
                    delay: 300,
                    duration: 400,
                    useNativeDriver: true
                }),
                Animated.timing(fadeTextAnim, {
                    toValue: 1,
                    delay: 300,
                    duration: 500,
                    useNativeDriver: true
                }),
                Animated.timing(heightBoxAnim, {
                    toValue: Platform.OS == "web" && Dimensions.get("window").width < 1300 ? 555 : 1000,
                    duration: 750,
                    delay: 500,
                    useNativeDriver: true
                }),
                Animated.timing(fadeFormAnim, {
                    toValue: 1,
                    duration: 500,
                    useNativeDriver: true
                }),
                Animated.timing(borderAnim, {
                    toValue: 1,
                    duration: 500,
                    useNativeDriver: true
                })
            ]).start();

        }, []);

    async function getDeviceType() {
        return await Device.getDeviceTypeAsync();
    }

    const onSubmit = async data => {
        setIsLoading(true);

        let deviceTypeClean = '';


        if (deviceType == 1) {
            deviceTypeClean = 'phone';
        } else if (deviceType == 2) {
            deviceTypeClean = 'tablet';
        } else if (deviceType == 3) {
            deviceTypeClean = 'desktop';
        } else {
            deviceTypeClean = 'other';
        }
        deviceTypeClean = deviceTypeClean + '-' + Platform.OS;
        dispatch(login(data, deviceTypeClean)).then(() => {
                setIsLoading(false);
            }, () => {
                //alert('not logged')
                setIsLoading(false);
            }
        )
    };

    const setHeightBoxShadow = () => {
        if(Platform.OS == "web" && Dimensions.get("window").width < 1300 ){
            return 570;
        }
        else if(Platform.OS === "web" && deviceType === 3){
            return 770;
        }
        else return 700
    }

    function handlePressOnRegister() {
        if (Platform.OS == 'web') {
            return window.open(congress.registration_url, "_blank")
        } else {
            return Linking.openURL(congress.registration_url)
        }
    }


    function getLogo(){
        if(Config.type === 'aop'){
            return Platform.OS === "web" ? logoImageSVGAop : logoImagePNGAop;
        }
        else{
            return Platform.OS === "web" ? logoImageSVG : logoImagePNG;
        }
    }

    return (
        <>
            <Center
                flex={1}
                bg={colors['background'][50]}
                position={"relative"}
                height={Dimensions.get("window").height}

                overflow={"hidden"}
            >

                {/*Layout - animation "Welcome To" translate to top*/}
                <Animated.View
                    style={{
                        width: Platform.OS == "web" && Dimensions.get("window").width < 1300 ? "45%" : "100%",
                        /*maxHeight: heightBoxAnim,*/
                        borderRadius: 5,
                        transform: [{translateY: translationAnim}],
                    }}
                    showsVerticalScrollIndicator={false}
                >
                    {/*Start Box Shadow - animation opacity to 1*/}
                    <Animated.View style={{
                        position: "absolute",
                        height: setHeightBoxShadow(),
                        width: "100%",
                        maxWidth: 700,
                        top: Platform.OS === "web" && Dimensions.get("window").width < 1300 ? -5 : 0,
                        left: "50%",
                        transform: [{translateX: Platform.OS === "web" ? "-50%" : 0}],
                        shadowColor: "#5766D6",
                        shadowOffset: {width: 0, height: 0},
                        shadowOpacity: Dimensions.get("window").width > 1000 ? shadowOp : 0,
                        shadowRadius: 20,
                        opacity: borderAnim,
                    }}></Animated.View>
                    {/*End Box Shadow*/}
                    <KeyboardAvoidingView
                        behavior={Platform.OS === "ios" ? "padding" : "position"}
                    >
                    <ScrollView width={'100%'}
                                py={paddingDesktop}
                                pb={2}
                                px={{base: paddingMobile, md: paddingDesktop}}
                                mx={"auto"}
                                maxW={700}
                                position={"relative"}
                                onLayout={(event) => {
                                    const {x} = event.nativeEvent.layout;
                                    const p = deviceType == 1 ? paddingMobile * 4 : paddingDesktop * 4;
                                    setShadowOp(0.5);
                                }}
                                showsHorizontalScrollIndicator={false}

                    >


                            <Center>
                                <Heading
                                    size={Platform.OS == "web" && Dimensions.get("window").width < 1300 ? "md" : "lg"}
                                    fontWeight={"light"}>{t('Welcome_to')}</Heading>
                                {/*Image LOGO - animation opacity to 1 */}
                                <Animated.Image source={getLogo()}
                                                style={{
                                                    resizeMode: "contain",
                                                    width: deviceType == 1 || (Platform.OS == "web" && Dimensions.get("window").width < 1300) ? 310 : 420,
                                                    height: deviceType == 1 || (Platform.OS == "web" && Dimensions.get("window").width < 1300) ? 110 :110,
                                                    marginVertical: -24,
                                                    opacity: fadeImageAnim
                                                }}
                                                alt="Alternate Text"
                                />

                                {/*Baseline- animation opacity to 1 */}
                                <Animated.Text
                                    style={{
                                        fontSize: deviceType == 1 || (Platform.OS == "web" && Dimensions.get("window").width < 1300) ? 11 : 14,
                                        marginTop: deviceType == 1 || (Platform.OS == "web" && Dimensions.get("window").width < 1300) ? 0 : 8,
                                        opacity: fadeTextAnim,
                                        color: "#1F2357",
                                        fontFamily: "Poppins_400Regular",
                                        textAlign: 'center'
                                    }}>
                                    {t("Platform_designed_to_reinvent_your_congress_experience")}
                                </Animated.Text>
                            </Center>

                            {/*Animation From Part 1 - opacity to 1*/}
                            <Animated.View style={{opacity: fadeFormAnim}}>
                                <Box mb={Platform.OS == "web" && Dimensions.get("window").width < 1300 ? 6 : 10}/>

                                <Heading
                                    fontSize={Platform.OS == "web" && Dimensions.get("window").width < 1300 ? "18px" : "24px"}
                                    fontWeight={"light"}>{t('Sign_In')}</Heading>
                                <Text
                                    fontSize={Platform.OS == "web" && Dimensions.get("window").width < 1300 ? "sm" : "md"}
                                    opacity={0.5}>{t('Login_introduction')}</Text>

                                <Box mb={3}/>

                                {message !== '' && (
                                    <Text bold color={'red.400'}>
                                        {message}
                                    </Text>
                                )}

                                <Controller
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({field: {onChange, onBlur, value}}) => (
                                        <FormControl isInvalid={errors.username} my={3}>
                                            <Input
                                                onBlur={onBlur}
                                                onChangeText={onChange}
                                                value={value}
                                                style={Platform.OS == "web" && Dimensions.get("window").width < 1300 ? {fontSize: "12px"} : null}
                                                w='100%'
                                                placeholder={'Email / Username'}
                                                mb={1}
                                            />
                                            {errors.username &&
                                                <FormControl.ErrorMessage>This field is
                                                    required </FormControl.ErrorMessage>}
                                        </FormControl>
                                    )}
                                    name="username"
                                    defaultValue=""
                                />

                                <Controller
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({field: {onChange, onBlur, value}}) => (
                                        <FormControl isInvalid={errors.password}>
                                            <Input
                                                onBlur={onBlur}
                                                onChangeText={onChange}
                                                value={value}
                                                w='100%'
                                                style={Platform.OS == "web" && Dimensions.get("window").width < 1300 ? {fontSize: "12px"} : null}
                                                type={'password'}
                                                placeholder={'Password'}
                                            />
                                            {errors.password &&
                                                <FormControl.ErrorMessage>This field is
                                                    required </FormControl.ErrorMessage>}
                                        </FormControl>
                                    )}
                                    name="password"
                                    defaultValue=""
                                />

                                <Button
                                    onPress={handleSubmit(onSubmit)}
                                    isLoading={isLoading}
                                    my={6}
                                    mb={4}
                                    w={'100%'}
                                    fontSize={Platform.OS == "web" && Dimensions.get("window").width < 1300 ? "xs" : "md"}
                                >{t('Get_started')}</Button>
                            </Animated.View>

                        {/*Animation From Part 2 - opacity to 1*/}
                        <Animated.View style={{opacity: fadeFormAnim}}>
                            <Link to={{screen: 'ResetPassword'}} fontWeight={"light"}>
                                <Text
                                    fontSize={Platform.OS == "web" && Dimensions.get("window").width < 1300 ? "sm" : "md"}
                                >{t('Forgot_your_password')}</Text>
                            </Link>

                            {/*<Center my={Platform.OS == "web" && Dimensions.get("window").width < 1300 ? 2 : 5}>*/}
                            {/*    <Link to={{screen: 'WelcomePack'}}>*/}
                            {/*        <MaterialCommunityIcons name="package-variant"*/}
                            {/*                                size={Platform.OS == "web" && Dimensions.get("window").width < 1300 ? 18 : 26}*/}
                            {/*                                color="white"/>*/}
                            {/*        <Text*/}
                            {/*            fontSize={Platform.OS == "web" && Dimensions.get("window").width < 1300 ? "md" : "lg"}> {t("Open_the_welcome_pack")}</Text>*/}
                            {/*    </Link>*/}
                            {/*</Center>*/}

{/*                         Because of Apple Store rules we had to remove the "register here" link
                            <Button
                                variant={"unstyled"}
                                onPress={handlePressOnRegister}
                                backgroundColor={colors['backgroundLight'][50]}
                                width={"100%"}
                                p={1}
                                rounded={0}
                                my={4}
                                mx={{base:'auto',md:0}}
                            >
                                <Flex direction={"row"} flexWrap={'wrap'} justifyContent={'center'}>
                                    <Text
                                        fontSize={Platform.OS == "web" && Dimensions.get("window").width < 1300 ? "sm" : "md"}>{t("Not_registered_yet")} </Text>
                                    <Text
                                        fontSize={Platform.OS == "web" && Dimensions.get("window").width < 1300 ? "sm" : "md"}
                                        fontWeight={"bold"}> {t("Register_here")}</Text>
                                </Flex>
                            </Button>*/}

                            {
                                Platform.OS === "web" && deviceType === 3 ?
                                    <View>
                                        <Flex fontSize={'xs'} direction={'row'} justify={'space-between'}
                                              my={Platform.OS == "web" && Dimensions.get("window").width < 1300 ? 2 : 4}>
                                            <a href={Config.type === 'aop' ? 'https://play.google.com/store/apps/details?id=com.aop.live' : 'https://play.google.com/store/apps/details?id=com.imcas.live'}
                                               target={'_blank'}
                                               style={{flex: 5}}
                                            >
                                                <Image
                                                    source={playstoreImg}
                                                    alt={'Playstore'}
                                                    style={{
                                                        width: '100%',
                                                        height: Platform.OS == "web" && Dimensions.get("window").width < 1300 ? "2rem" : '2.5rem'
                                                    }}
                                                    resizeMode={'contain'}
                                                />
                                            </a>
                                            <Spacer flex={1}/>
                                            <a href={Config.type === 'aop' ? 'https://apps.apple.com/us/app/aop-live/id1602083334' : 'https://apps.apple.com/us/app/imcas-live/id1590873790'}
                                               target={'_blank'}
                                               style={{flex: 5}}
                                            >
                                                <Image
                                                    source={appstoreImg}
                                                    alt={'App Store'}
                                                    style={{
                                                        width: '100%',
                                                        height: Platform.OS == "web" && Dimensions.get("window").width < 1300 ? "2rem" : '2.5rem'
                                                    }}
                                                    resizeMode={'contain'}
                                                />
                                            </a>
                                        </Flex>
                                        <View w={'100%'} height={'1px'} backgroundColor={'white'} opacity={0.25}></View>
                                    </View>
                                    :
                                    null
                            }

                            <Flex direction={Platform.OS == "web" ? 'row' : 'column'} justify={{base: "center", md: "space-around"}} mt={2}
                                  alignItems={'center'} alignContent={"center"} flexWrap={'wrap'}>
                                <SupportButton
                                    text={t('Contact_support')}
                                    isOnLogin
                                />

                                <TermsOfUse loggedOut isOnLogin/>

                            </Flex>
                        </Animated.View>
                    </ScrollView>
                    </KeyboardAvoidingView>

                </Animated.View>

            </Center>

        </>
    );
}
