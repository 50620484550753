import * as React from 'react';
import {Center, Text, VStack, Stack, Flex, Spacer, View, Avatar, Badge, useTheme, Button} from "native-base";
import {useNavigation} from "@react-navigation/native";
import {useMediaQuery} from "react-responsive";
import {useSelector} from "react-redux";
import {useState} from "react";
import {FontAwesome, FontAwesome5, MaterialIcons} from '@expo/vector-icons';
import {useTranslation} from "react-i18next";
import {Platform} from "react-native";

export default function MenuButton(props) {
    const {t, i18n} = useTranslation();
    const isMobile = useMediaQuery({query: '(max-width: 760px)'});
    const {user} = useSelector(state => state.auth);
    const {unreadCount} = useSelector(state => state.notifications);
    const [isHovered, setIsHovered] = useState(false);
    const {colors} = useTheme();
    const navigation = useNavigation();

    function renderText() {

        if (props.iconOnly) {
            return null
        } else if(props.destination === 'Profile'){
            const username = (user?.first_name + " " + user?.last_name).length > 20 ?
                (user?.first_name[0] + ". " + user?.last_name) :
                (user?.first_name + " " + user?.last_name);
            return(
                <View ml={3}>
                    <Text fontWeight={'bold'} fontSize={{base:'sm'}}>{username}</Text>
                    <Text mt={{base:-2.5,lg:-2}} fontSize={{base:'sm'}} maxWidth={'120px'} isTruncated>{user?.user_specialty}</Text>
                </View>
            )
        }
        else return (
            <Text
                ml={props.landscape ? 3 : 0}
                fontSize={{base:'xs',md:'md'}}
                color={props.activePage === props.text ? '#5766D6' : '#1F2357'}
            >
                {props.text}
            </Text>
        );
    }

    function renderIcon() {
        if (props.destination === 'Profile') {
            if (unreadCount > 0) {
                return (
                    <VStack>
                        <Badge
                            position={'absolute'}
                            bg={'#F46977'}
                            rounded="full"
                            bottom={{base:-5,md:-2  }}
                            right={{base:-5,md:-2}}
                            zIndex={1}
                            variant="solid"
                            p={0}
                            alignSelf="flex-end"
                            w={'20px'}
                            h={'20px'}
                        >
                            <Center flex={1}>
                                <Text fontSize={{base: 8, lg:10}} color={'white'} mt={{base:'1px',lg:'0px'}}>{unreadCount}</Text>
                            </Center>
                        </Badge>
                        <Avatar
                            source={{uri: user?.picture}}
                            size={{base:8,lg:10}}
                        >
                            ME
                        </Avatar>
                    </VStack>
                );
            } else {
                return (
                    <Avatar
                        source={{uri: user?.picture}}
                        size={{base:8,lg:10}}
                    >
                        ME
                    </Avatar>
                );
            }
        } else if (props.text === t('Program')) {
            return (
                <FontAwesome5
                    name="calendar-alt"
                    size={24}
                    style={{color: props.activePage === props.text && !isHovered ? '#5766D6' : '#1F2357'}}
                />
            )
        } else if (props.text === t('Exhibition')) {
            return (
                <FontAwesome
                    name="handshake-o"
                    size={24}
                    style={{color: props.activePage === props.text && !isHovered ? '#5766D6' : '#1F2357'}}
                />)
        } else if(props.destination == 'UserList'){
             return <MaterialIcons
                name={props.icon}
                size={props.deviceType == 1 ? 24 : 34}
                style={{color: props.activePage === props.text ? '#5766D6' : '#1F2357'}}
            />
        }
        else return (
            <MaterialIcons
                name={props.icon}
                size={24}
                style={{color: props.activePage === props.text ? '#5766D6' : '#1F2357'}}
            />
        );
    }

    function hoverOn() {
        return setIsHovered(true);
    }

    function hoverOff() {
        return setIsHovered(false);
    }

    function goToDestination() {
        return navigation.navigate(props.destination);
    }

    return (
        <View
            onMouseEnter={hoverOn}
            onMouseLeave={hoverOff}
            my={0}
            borderTopLeftRadius={'xl'}
            borderBottomLeftRadius={'xl'}
            flex={1}
        >
            <Button
                onPress={goToDestination}
                variant={'unstyled'}
                p={0}
            >
                <Flex
                    direction={props.landscape ||props.destination === 'Profile'  ? 'row': 'column'}
                    alignItems={'center'}
                    mr={props.landscape ? 25: 0}
                    ml={props.landscape ? 10: 0}

                >
                    {renderIcon()}
                    {renderText()}
                </Flex>
            </Button>
        </View>

    );
}
