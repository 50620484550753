import React, {useCallback} from "react";
import {Image, Text, View, Link as LinkExternal, Button, useTheme} from "native-base";
import {Linking, Platform} from "react-native";
import {Link} from "@react-navigation/native";
import MasonryList from "@react-native-seoul/masonry-list";
import {TouchableOpacity} from "react-native-web";

export const MasonryExhibitors = React.memo((props) => {
    const {colors} = useTheme();


    /*
 * unique identifier
 */
    const uniqueId = useCallback(() => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    },[]);

    const getLinkType = (item) => {
        if(item?.link_type == 'company_profile'){
            return (
                    <View
                        postData={"relative"}
                        aspectRatio={props.prev.adsRatio}
                        width={props.prev.adsWidth}
                        height={props.prev.adsHeight}>
                        <Link
                            style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                zIndex: 3,
                                elevation: 3
                            }}
                            to={{
                                screen: 'CompanyProfile',
                                params: {id: item?.link_id}
                            }}
                        >
                        </Link>
                        <Image
                            width={'100%'}
                            height={'100%'}
                            source={{uri: item?.company_main_image_url}}
                            borderRadius={10}
                            opacity={1}
                            alt={"companies"}
                        />
                    </View>



            )
        }
        else{
            return (
                <Button
                    variant="unstyled"
                    aspectRatio={props.prev.adsRatio}
                    width={props.prev.adsWidth}
                    height={props.prev.adsHeight}
                    p={0}
                    onPress={() => {
                        if(Platform.OS == 'web'){
                            window.open( item?.link_url, "_blank")
                        }
                        else{
                            Linking.openURL(item?.link_url)
                        }
                    }}
                >
                    <View
                        aspectRatio={props.prev.adsRatio}
                        width={props.prev.adsWidth}
                        height={props.prev.adsHeight}>


                        <Image
                            width={'100%'}
                            height={'100%'}
                            source={{uri: item?.company_main_image_url}}
                            borderRadius={10}
                            opacity={1}
                            alt={"companies"}
                        />

                    </View>
                </Button>


            )
        }

    }

    return (
        <MasonryList
            style={{alignSelf: 'stretch'}}
            contentContainerStyle={{
                paddingHorizontal: 2,
                alignSelf: 'stretch',
            }}
            numColumns={props.colNumber}
            data={props.data}
            keyExtractor={(item) => item.id}
            renderItem={ ({item}) => {
                return (
                    <View
                        p={Platform.OS === "web" ? 2 : 1}>
                        {
                            item?.company_main_image_url !== "" ?
                                // image ratio for companies
                                item?.is_ad ?

                                    // image ratio for ads
                                    (<View
                                        position={"relative"}
                                        bg={colors['backgroundLight'][50]}
                                        borderRadius={10}
                                        aspectRatio={props.prev.adsRatio}
                                        width={props.prev.adsWidth}
                                        height={props.prev.adsHeight}
                                        borderColor={colors['background'][200]}
                                        borderWidth={1}
                                    >
                                        {getLinkType(item)}

                                        <View
                                            position={'absolute'}
                                            bottom={0}
                                            w={"100%"}
                                        >
                                            <Text textAlign={'center'} bold>{item?.company_name}</Text>
                                            <Text textAlign={'center'} bold>{item?.ad_name}</Text>
                                        </View>
                                    </View>)


                                    : (<View
                                        bg={colors['backgroundLight'][50]}
                                        borderRadius={10}
                                        aspectRatio={props.prev.companiesRatio}
                                        width={props.prev.companiesWidth}
                                        height={props.prev.companiesHeight}
                                        borderColor={colors['background'][200]}
                                        borderWidth={1}

                                    >
                                        <Link
                                            style={{
                                                position: "absolute",
                                                width: "100%",
                                                height: "100%",
                                                zIndex: 3,
                                                elevation: 3
                                            }}
                                            to={{
                                                screen: 'CompanyProfile',
                                                params: {id: item?.id}
                                            }}
                                        >
                                        </Link>
                                        <Image
                                            width={'100%'}
                                            height={'100%'}
                                            source={{uri: item?.company_main_image_url}}
                                            borderRadius={10}
                                            alt={"companies"}

                                        />
                                        <View
                                            position={'absolute'}
                                            bottom={0}
                                            w={"100%"}
                                        >
                                            <Text style={{backgroundColor: 'rgba(255, 255, 255, 0.75)'}}
                                                  fontSize={'sm'}
                                                  textAlign={'center'}
                                                  borderBottomLeftRadius={10}
                                                  borderBottomRightRadius={10}
                                                  px={1}
                                                  bold>{item?.sponsor_company_name ? item?.sponsor_company_name?.toUpperCase() : item?.company_name?.toUpperCase()}</Text>
                                        </View>
                                    </View>)
                                :
                                (<View w="100%" h={item.height}/>)
                        }
                    </View>
                );
            }}
        />
    )
} )
