import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    Button,
    Center, Flex,
    Heading,
    ScrollView, Spinner,
    Text,
    useTheme, useToast, View, VStack,
} from "native-base";
import Layout from "../layouts/Layout";
import {useForm} from "react-hook-form";
import InputForm from "../components/InputForm";
import SelectForm from "../components/SelectForm";
import HttpService from "../services/HttpService";
import {useDispatch, useSelector} from "react-redux";
import PicturePicker from "../components/PicturePicker";
import TextAreaForm from "../components/TextAreaForm";
import CheckboxForm from "../components/CheckboxForm";
import {refreshData} from "../actions/auth";
import {useTranslation} from "react-i18next";
import {KeyboardAvoidingView, Platform} from "react-native";
import * as Localization from "expo-localization";
import AccountService from "../services/AccountService";
import {useIsFocused} from "@react-navigation/native";
import Config from "../config.json";




const PersonalInformationsScreen = (props) => {

    const {colors} = useTheme();
    const {control, handleSubmit, formState: {errors}} = useForm();
    const {user, language, congressUser, timezone} = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const toast = useToast();
    const {congress} = useSelector(state => state.global);
    const ref = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [readyToRender, setReadyToRender] = useState(false);
    const [currentTimezone, setCurrentTimezone] = useState(null);

    const genderOptions = [{value: 'm', label: 'male'}, {value: 'f', label: 'female'}];
    const [specialties, setSpecialties] = useState([]);
    const [themes, setThemes] = useState([]);
    const languages = [{value: 'en', label: 'English'}, {value: 'es', label: 'Español'}, {value: 'pt', label: 'Português'}];
    const [timezones, setTimezones] = useState([]);
    const [countries, setCountries] = useState([]);
    const [liveExhibitStatus, setLiveExhibitStatus] = useState(false);
    const [pic, setPic] = useState("");
    const [errorMessages, setErrorMessages] = useState({});
    const isFocused = useIsFocused();

    const chatOptinText = "I accept to be contacted by WhatsApp";
    const partnersOptinText_1 = "I accept to share my contact data with the exhibiting companies I am interacting with (mandatory to get all the exhibit features)";
    const partnersOptinText_2 = "I accept to share my contact data with the sponsoring companies I’ve an interest for (mandatory to watch symposia)";
    const companyChatOptinText = `I accept that ${user?.exhibitor?.sponsor_company_name ?? 'the company I represent'} can be contacted by WhatsApp`;
    const companyEmailOptinText = `I accept that ${user?.exhibitor?.sponsor_company_name ?? 'the company I represent'} can be contacted by email`;

    // Ping
    useEffect(() => {
        let interval;
        if(isFocused) {
            interval = AccountService.startPing(props.navigation, {page: 'personal-informations' });
        }
        return () => clearInterval(interval);
    }, [isFocused]);


    /*
     * Return an array of countries
     */
    const getCountries = async () => {
        await HttpService
            .getData('country/get/all')
            .then(response => {
                let arr = response.data.data;
                arr = arr.map(({name: label, ...rest}) => ({label, ...rest}));
                arr = arr.map(({id: value, ...rest}) => ({value, ...rest}));
                setCountries(arr);
            })
            .catch(error => {
                console.log('Error: ' + error)
            })
    }

    /*
     * Return an array of themes
     */
    const getThemes = async () => {
        await HttpService
            .getData('theme/get/all')
            .then(response => {
                let arr = response.data.data;
                arr = arr.map(({name: label, ...rest}) => ({label, ...rest}));
                arr = arr.map(({id: value, ...rest}) => ({value, ...rest}));
                setThemes(arr.filter(elt => elt.label !== false));
            })
            .catch(error => {
                console.log('Error: ' + error)
            })
    }

    /*
     * Return an array of specialties
     */
    const getSpecialties = async () => {
        await HttpService
            .getData('specialty/get/all')
            .then(response => {
                let arr = response.data.data;
                arr = arr.map(({name: label, ...rest}) => ({label, ...rest}));
                arr = arr.map(({id: value, ...rest}) => ({value, ...rest}));
                setSpecialties(arr);
            })
            .catch(error => {
                console.log('Error: ' + error)
            })
    }

    /*
     * Return an array of timezones
     */
    const getTimezones = async () => {
        await HttpService
            .getData('timezone/get/all')
            .then(response => {
                let arr = response.data.data;
                arr = arr.map(({name: label, ...rest}) => ({label, ...rest}));
                arr = arr.map(({id: value, ...rest}) => ({value, ...rest}));
                setTimezones(arr);
            })
            .catch(error => {
                console.log('Error: ' + error)
            })
    }

    /*
     * get all data used in this form
     */
    useEffect(
        () => {
            dispatch(refreshData()).then(() => {
                    if (language === 'auto') {
                        let deviceLocale = Localization.locale.split('-')[0];
                        if (Config.type == 'imcas' && (deviceLocale !== 'es' && deviceLocale !== 'pt')) {
                            deviceLocale = 'en';
                        }
                        if(Config.type == 'aop'){
                            deviceLocale = 'fr';
                        }
                        i18n.changeLanguage(deviceLocale);
                    } else {
                        i18n.changeLanguage(language);
                    }
                }, () => {
                    //alert('not logged')
                }
            );

            if (timezone) {
                setCurrentTimezone(timezone);
            } else if (congress) {
                setCurrentTimezone(congress.timezone);
            }
            else {
                setCurrentTimezone('Europe/Paris');
            }

            getCountries()
                .then(() => getThemes()
                    .then(() => getSpecialties()
                        .then(() => getTimezones()
                            .then(() => setReadyToRender(true))
                        )
                    )
                );
            setLiveExhibitStatus(congress.liveExhibitStatus);

        }, []
    );

    const goToTop = () => {
        ref.current.scrollTo({y: 0, animated: true});
    }

    /*
     * send data and update user's model
     */
    const onSubmit = data => {
        setIsLoading(true);
        data = {...data, picture: pic}
        return HttpService.postData('account/update', data).then((response) => {
            if (response.data.success) {
                const isFirstTimeUserCompletes = !congressUser.profile_completed;

                dispatch(refreshData()).then((r) => {
                    setIsLoading(false);
                    if (language === 'auto') {
                        let deviceLocale = Localization.locale.split('-')[0];
                        if (Config.type == 'imcas' && (deviceLocale !== 'es' && deviceLocale !== 'pt')) {
                            deviceLocale = 'en';
                        }
                        if(Config.type == 'aop'){
                            deviceLocale = 'fr';
                        }
                        i18n.changeLanguage(deviceLocale);
                    } else {
                        i18n.changeLanguage(language);
                    }

                    if (isFirstTimeUserCompletes) {
                        props.navigation.navigate('Live');
                    }
                });
                toast.show({
                    title: "Success",
                    placement: "top",
                    status: "success",
                    bg: "emerald.500",
                    description: "You have updated your profile",
                })
                setErrorMessages({});
            } else {
                goToTop();
                setErrorMessages(response.data.message);
                // errors from api
                toast.show({
                    title: 'Error',
                    placement: "top",
                    status: "error",
                    description: t('Form_error'),
                });
                setIsLoading(false);
            }
        }).catch((error) => {
            console.log('Error: ' + error);
            setIsLoading(false);
            return error.data;
        })
    };

    let completedProfileFields = null;

    if (congressUser.profile_completed) {
        completedProfileFields = (
            <>
                <InputForm name="address_web" defaultValue={user.address_web} placeholder="Your address"
                           label={t("Address")}
                           width="100%"
                           control={control}
                           errors={errors}
                           apiErrors={errorMessages}
                />

                <InputForm name="city" defaultValue={user.city} placeholder="City"
                           label={t("City")}
                           width="100%"
                           control={control}
                           errors={errors}
                           apiErrors={errorMessages}
                />

                <SelectForm name="country_web_id" defaultValue={user.country_web_id}
                            label={t("Country")}
                            placeholder={t("Country")}
                            width="100%"
                            items={countries}
                            control={control}
                            errors={errors}
                            apiErrors={errorMessages}
                            isSelect2
                            isNotPlaceholderSendValue
                />

                <InputForm name="postal_code" defaultValue={user.postal_code} placeholder="Postal code"
                           label={t("Postal_code")}
                           width="100%"
                           control={control}
                           errors={errors}
                           apiErrors={errorMessages}
                />
                <InputForm name="phone" defaultValue={user.phone} placeholder="Your phone number"
                           label={t("Phone_number")}
                           width="100%"
                           control={control}
                           errors={errors}
                           apiErrors={errorMessages}
                />

                <InputForm name="website" defaultValue={user.website} placeholder="Your website"
                           label={t("Website")}
                           width="100%"
                           control={control}
                           errors={errors}
                           apiErrors={errorMessages}
                />

                <InputForm name="facebook_url" defaultValue={user.facebook_url} placeholder="Facebook"
                           label={"Facebook"}
                           width="100%"
                           control={control}
                           errors={errors}
                           apiErrors={errorMessages}
                />
                <InputForm name="linkedin_url" defaultValue={user.linkedin_url} placeholder="LinkedIn"
                           label={"LinkedIn"}
                           width="100%"
                           control={control}
                           errors={errors}
                           apiErrors={errorMessages}
                />


                <InputForm name="instagram_username" defaultValue={user.instagram_username}
                           label={"Instagram"}
                           placeholder="Instagram"
                           width="100%"
                           control={control}
                           errors={errors}
                           apiErrors={errorMessages}
                />
                <InputForm name="twitter_username" defaultValue={user.twitter_username}
                           label={"Twitter"}
                           placeholder="Twitter"
                           width="100%"
                           control={control}
                           errors={errors}
                           apiErrors={errorMessages}
                />

                <TextAreaForm
                    name="biostatement_en"
                    value="bio"
                    label={t("Biostatement")}
                    height={20}
                    placeholder={t("Biostatement")}
                    width={{
                        base: "100%",
                        md: "100%",
                    }}
                    control={control}
                    errors={errors}
                />
            </>);
    }

    return (
        <Layout withBackButton>
            <Center
                flex={1}
                bg={colors['background'][50]}
            >
                <View maxW={900} flex={1} pb={Platform.OS === 'web' ? 16 : 0}>
                    <Heading textAlign={Platform.OS === 'web' ? 'center' : 'left'}
                             size={'2xl'}
                    >{t('My_profile')}</Heading>

                    {readyToRender ?
                    <>
                        <KeyboardAvoidingView
                            behavior={"position"}
                            style={{flex: 1}}
                        >
                            <ScrollView
                                p={2}
                                ref={ref}
                            >
                                <Center>
                                    <Text textAlign="center" fontSize={{base: 'xs', md: 'md'}}
                                          marginBottom={2}>{t('Complete_profile_helps_us')}</Text>
                                    <PicturePicker setPic={setPic} withDefaultProfilePic/>
                                </Center>
                                <InputForm name="first_name" defaultValue={user.first_name}
                                           label={t("First_name")}
                                           placeholder={t("Your_last_name")}
                                           width="100%"
                                           control={control}
                                           errors={errors}
                                           apiErrors={errorMessages}
                                />
                                <InputForm name="last_name" defaultValue={user.last_name}
                                           label={t("Last_name")}
                                           placeholder={t('Your_last_Name')}
                                           width="100%"
                                           control={control}
                                           errors={errors}
                                           apiErrors={errorMessages}
                                />
                                <SelectForm name="gender" defaultValue={user.gender} placeholder={t('Your_gender')}
                                            label={t("Gender")}
                                            width="100%"
                                            items={genderOptions}
                                            control={control}
                                            errors={errors}
                                            apiErrors={errorMessages}
                                />
                                <SelectForm name="user_specialty_id" defaultValue={user.user_specialty_id}
                                            label={t("Specialty")}
                                            placeholder={t('Your_specialty')}
                                            width="100%"
                                            items={specialties}
                                            control={control}
                                            errors={errors}
                                            apiErrors={errorMessages}

                                            isSelect2
                                            isNotPlaceholderSendValue
                                />
                                <SelectForm name="themes_1" defaultValue={user.themes_1}
                                            label={t("Favorite_theme")+ 1}
                                            placeholder={t("Your_favorite_theme_number")+ 1}
                                            width="100%"
                                            items={themes}
                                            control={control}
                                            errors={errors}
                                            apiErrors={errorMessages}
                                />
                                <SelectForm name="themes_2" defaultValue={user.themes_2}
                                            label={t("Favorite_theme")+ 2}
                                            placeholder={t("Your_favorite_theme_number")+ 2}
                                            width="100%"
                                            items={themes}
                                            control={control}
                                            errors={errors}
                                            apiErrors={errorMessages}
                                />
                                <SelectForm name="themes_3" defaultValue={user.themes_3}
                                            label={t("Favorite_theme")+ 3}
                                            placeholder={t("Your_favorite_theme_number")+ 3}
                                            width="100%"
                                            items={themes}
                                            control={control}
                                            errors={errors}
                                            apiErrors={errorMessages}/>

                                <SelectForm name="themes_4" defaultValue={user.themes_4}
                                            label={t("Favorite_theme")+ 4}
                                            placeholder={t("Your_favorite_theme_number")+ 4}
                                            width="100%"
                                            items={themes}
                                            control={control}
                                            errors={errors}
                                            apiErrors={errorMessages}
                                />

                                <SelectForm name="language" defaultValue={user.language}
                                            label={t("Language")}
                                            placeholder={t('Your_language')}
                                            width="100%"
                                            items={languages}
                                            control={control}
                                            errors={errors}
                                            apiErrors={errorMessages}
                                />
                                <SelectForm name="timezone" defaultValue={currentTimezone}
                                            label={t("Timezone")}
                                            placeholder={t('Your_timezone')}
                                            width="100%"
                                            items={timezones}
                                            control={control}
                                            errors={errors}
                                            apiErrors={errorMessages}

                                            isSelect2
                                            valueShow={"label"}
                                            valueSend={"value"}
                                            isNotPlaceholderSendValue
                                />

                                {completedProfileFields}

                                <VStack>
                                    <Box p={5}>
                                        <CheckboxForm
                                            control={control}
                                            name="chat_optin"
                                            text={chatOptinText}
                                            checkboxValue={congressUser.chat_optin}
                                        />
                                        {user.exhibitor ?
                                            <>
                                                <CheckboxForm
                                                    control={control}
                                                    name="company_chat_optin"
                                                    text={companyChatOptinText}
                                                    checkboxValue={user.exhibitor?.chat_active}
                                                />
                                                <CheckboxForm
                                                    control={control}
                                                    name="company_email_optin"
                                                    text={companyEmailOptinText}
                                                    checkboxValue={user.exhibitor?.contact_active}
                                                />
                                            </>
                                            : <></>}
                                        {liveExhibitStatus ?
                                            (<CheckboxForm
                                                control={control}
                                                name="partners_optin"
                                                text={partnersOptinText_1}
                                                checkboxValue={congressUser.partners_optin}/>) :
                                            (<CheckboxForm
                                                control={control}
                                                name="partners_optin"
                                                text={partnersOptinText_2}
                                                checkboxValue={congressUser.partners_optin}/>)}
                                    </Box>
                                </VStack>
                            </ScrollView>
                        </KeyboardAvoidingView>
                        <Button
                            onPress={handleSubmit(onSubmit)}
                            isLoading={isLoading}
                            my={2}
                            fontSize={{base: 2, md: "lg"}}
                        >Update your profile</Button>
                    </>
                    : <Center flex={1}>
                            <Spinner color="#5766D6"  />
                        </Center>
                    }
                </View>
            </Center>
        </Layout>
    );
}

export default PersonalInformationsScreen;
