import * as React from "react";
import {Box, Button, Center, Flex, Heading, Link, Modal, Pressable, Text, useTheme} from "native-base";
import {Platform} from "react-native";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import * as Linking from "expo-linking";

const PartnerModal = ({partner, showModal, setShowModal}) => {
    const {facebook_page, instagram_page, twitter_page, website, email} = partner;
    const cleanTwitterUrl = twitter_page?.replace('@', '')
    const {colors} = useTheme();

    function openEmailLink() {
        return Linking.openURL(`mailto:${email}`);
    }

    function hideModal() {
        return setShowModal(false);
    }

    const renderSocialMedia = () => {
        return (

            <Flex width={{base: '100%'}} flexDirection="row" justify='space-around' alignItems="center">
                {(website && website != "undefined") &&
                (<Box m={2}>
                    <Link href={website} isExternal>
                        <MaterialCommunityIcons name="web" size={30} color={colors['action']['300']}/>
                    </Link>
                </Box>)}

                {email &&
                <Box>
                    <Pressable onPress={openEmailLink}>
                        <MaterialCommunityIcons name="email-outline" size={34} color={colors['action']['300']}/>
                    </Pressable>
                </Box>}

                {(facebook_page && facebook_page != "undefined") &&
                (<Box m={2}>
                    <Link href={facebook_page} isExternal>
                        <MaterialCommunityIcons name="facebook" size={30} color={colors['action']['300']}/>
                    </Link>
                </Box>)}
                {(instagram_page && instagram_page != "undefined") &&
                (<Box m={2}>
                    <Link href={instagram_page} isExternal>
                        <MaterialCommunityIcons name="instagram" size={30} color={colors['action']['300']}/>
                    </Link>
                </Box>)}
                {(cleanTwitterUrl && cleanTwitterUrl != "undefined") &&
                (<Box m={2}>
                    <Link href={cleanTwitterUrl} isExternal>
                        <MaterialCommunityIcons name="twitter" size={30} color={colors['action']['300']}/>
                    </Link>
                </Box>)}
            </Flex>
        )
    }

    const renderHeader = () => {
        return (
            <Flex justify='center' align='center'>
                <Heading size={'lg'}>{partner.acronym}</Heading>
                <Text textAlign={'center'} bold>{partner.name}</Text>
                {renderSocialMedia()}
            </Flex>
        )
    }

    return (
        <Center>
            <Modal isOpen={showModal} onClose={hideModal}>
                <Modal.Content maxWidth="400px">
                    <Modal.CloseButton/>
                    <Modal.Header>{renderHeader()}</Modal.Header>
                    <Modal.Body>
                        <Text textAlign='justify'>{partner.content}</Text>
                    </Modal.Body>
                </Modal.Content>
            </Modal>
        </Center>);
};

export default PartnerModal;
