import * as React from 'react';
import {
    Flex,
    View,
    useTheme,
    Button,
    Modal, Text, Box, Pressable
} from "native-base";
import {Link, useNavigation} from "@react-navigation/native";
import {useMediaQuery} from "react-responsive";
import {useSelector} from "react-redux";
import {useRef, useState, useEffect} from "react";
import {Feather, FontAwesome, MaterialCommunityIcons} from '@expo/vector-icons';
import * as Linking from "expo-linking";
import {Dimensions, Platform} from "react-native";
import WhatsappButton from "./WhatsappButton";
import {useTranslation} from "react-i18next";
import {MaterialIcons} from '@expo/vector-icons';
import * as Device from "expo-device";

export default function SupportButton(props) {
    const {t, i18n} = useTranslation();
    const {congress} = useSelector(state => state.global);
    const [modalVisible, setModalVisible] = useState(false);
    const [deviceType, setDeviceType] = useState();

    const initialRef = useRef(null);
    const finalRef = useRef(null);

    useEffect(
        () => {
            async function setLayout() {
                const type = await getDeviceType();
                setDeviceType(type);
            }

            setLayout();
        }, []
    );

    async function getDeviceType() {
        return await Device.getDeviceTypeAsync();
    }

    const renderIcon = () => {
        return (
            <MaterialIcons
                name="contact-support"
                size={props.isOnLogin || deviceType === 1 ? 24 : 32}
                color={'#1F2357'}
            />
        );
    }

    const getMarginLeftText = () => {
        if (props.isOnLogin) return 1;
        else if (deviceType === 2 && Dimensions.get('window').width < Dimensions.get('window').height) return 0;
        else return deviceType === 3 ? 1 : 0;
    }

    function hideModal() {
        return setModalVisible(false);
    }

    function toggleModal() {
        return setModalVisible(!modalVisible);
    }

    function openContactLink() {
        if (congress.type === "IMCAS")
            return Linking.openURL(`mailto:contact@imcas.com`);
        else return Linking.openURL(`mailto:contact@aop.com`);
    }

    function openWhatsappLink() {
        return Linking.openURL(`https://wa.me/` + congress.support_phone_number);
    }

    const renderText = () => {
        return (props.isOnLogin ?
            <Text
                ml={getMarginLeftText()}
                mt={deviceType === 3 ? 0 : 1}
                fontSize={props.isOnLogin || deviceType === 3 ? deviceType >= 2 ? "2xs" : "xs" : "2xs"}
            >
                {props.text}
            </Text>
            : null)
    }

    return (
        <>
            <Modal
                isOpen={modalVisible}
                onClose={hideModal}
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
            >
                <Modal.Content
                    maxW={{md: '70%'}}
                >
                    <Modal.CloseButton/>
                    <Modal.Header>{t('Contact_us')}</Modal.Header>
                    <Modal.Body>
                        <Pressable
                            onPress={openContactLink}
                            style={{cursor: 'pointer'}}
                            my={2}
                        >
                            <Flex direction='row' align={'center'}>
                                <Text fontSize={'sm'} px={2}>{t('Contact_by_email')}</Text>
                                <MaterialCommunityIcons name="email-outline" size={Platform.OS === 'web' ? 34 : 24}
                                                        color="#1F2357"/>
                            </Flex>
                        </Pressable>
                        <Pressable
                            marginRight={{md: 2}}
                            onPress={openWhatsappLink}
                            style={{cursor: 'pointer'}}
                        >
                            <Flex direction='row' align={'center'}>
                                <Text fontSize={'sm'} px={2}>{t('Contact_by_whatsapp')}</Text>
                                <FontAwesome name="whatsapp" size={Platform.OS === 'web' ? 34 : 24} color="#1F2357"/>
                            </Flex>
                        </Pressable>
                    </Modal.Body>
                </Modal.Content>
            </Modal>
            <View
                my={props.isOnLogin || deviceType >= 2 ? 0 : 2}
                mb={props.isOnLogin || deviceType >= 2 ? 0 : 2}
                flex={props.isOnLogin && deviceType < 3 || !props.isOnLogin ? 1 : 0}
                borderTopLeftRadius={'xl'}
                borderBottomLeftRadius={'xl'}
                overflow={"visible"}
            >
                <Flex direction={'row'}
                      justify={props.isOnLogin ? 'center' : 'flex-start'}
                      alignItems={'center'}
                      alignContent={"center"}
                      px={{base: 2}}
                      opacity={props.isOnLogin ? 0.5 : 1}
                      mx={"auto"}
                >
                    <Button
                        variant={'unstyled'}
                        onPress={toggleModal}
                        p={0}
                    >
                        <Flex
                            direction={deviceType === 2 && !props.isOnLogin ? 'column' : 'row'}
                            alignItems={'center'}
                            alignContent={"center"}
                            justify={props.isOnLogin ? 'center' : 'flex-start'}
                            my={props.isOnLogin || (deviceType === 2 && !props.isOnLogin) ? 0 : 0}
                        >
                            {renderIcon()}
                            {renderText()}
                        </Flex>
                    </Button>
                </Flex>
            </View>
        </>

    );
}
