import {Link} from "@react-navigation/native";
import {Text, useTheme, View} from "native-base";
import * as React from "react";

export default function(props) {
    const {colors} = useTheme();
    return(
        <Link to={{screen: props.destination}}>
            <View
                py={2}
                px={6}
                bg={colors['action'][50]}
                borderRadius={'md'}
            >
                <Text color={'white'}>{props.text}</Text>
            </View>
        </Link>
    );
}
