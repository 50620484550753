import {REFRESH_NOTIFICATIONS} from "../actions/types";

const initialState = {all: null, unreadCount: 0};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case REFRESH_NOTIFICATIONS:
            return {
                ...state,
                all: payload.all,
                unreadCount: payload.unreadCount,
            }
        default:
            return state;
    }
}
