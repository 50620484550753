import {Box, Button, Flex, Heading, ScrollView, Text, View} from "native-base";
import * as React from "react";
import Layout from "../layouts/Layout";
import {useTranslation} from "react-i18next";
import {AntDesign} from "@expo/vector-icons";
import Constants from "expo-constants";
import {useEffect} from "react";
import Config from "../config.json";


export default function TermsOfUseScreen({navigation, ...props}) {
    const {t, i18n} = useTranslation();
    const type = Config.type.toUpperCase();

    const websiteType = () => {
        if(type === 'imcas'.toUpperCase()) return 'imcas.com'
        if(type === 'aop'.toUpperCase()) return 'aopcongress.com'
    }

    useEffect(()=>{
        websiteType()
    }, []);

    function goToPreviousPage() {
        return navigation.goBack();
    }

    function renderHeader() {
        if (props.route.params?.loggedIn) {
            return (
                <Flex direction={{md: 'row'}} w={{md: '100%'}} maxW={900} mt={10}
                      alignItems={{base: 'flex-start', md: 'center'}} px={5}>
                    <Button variant={'unstyled'} onPress={goToPreviousPage}>
                        <AntDesign name="arrowleft" size={32} color="#1F2357"/>
                    </Button>
                    <Heading width={'100%'} size={'2xl'}
                             textAlign={{base: 'left', md: 'center'}}>{t('Terms_of_use')}</Heading>
                </Flex>
            );
        } else {
            return (
                <Flex direction={'row'} w={'100%'} maxW={900} style={{marginRight: 'auto', marginLeft: 'auto'}}>
                    <View flex={1}>
                        <Heading size={'2xl'} textAlign={{base: 'left', md: 'center'}}>{t('Terms_of_use')}</Heading>
                    </View>
                </Flex>
            );
        }
    }

    return (
        <Layout withBackButton activePage={navigation.isFocused ? 'TermsOfUse' : ''}>
            {renderHeader()}
            <ScrollView maxW={900} style={{marginRight: 'auto', marginLeft: 'auto'}}>
                <Box p={5}>
                    <Text>Effective as of 01 December 2021</Text>
                    {"\n"}
                    <Text textAlign={'justify'}>
                        <Text bold>Definitions :</Text>
                        {"\n"}- Visitor : Any person using the Website, with or without a registered account
                        {"\n"}- User : Any visitor who creates a registered account, with a specific access
                        (paid or free) on the Website
                    </Text>
                    {"\n"}
                    <Heading size={'md'}>1 - Agreement to the Terms of Use</Heading>
                    <Text textAlign={'justify'}>
                        These Terms and Conditions of Use (the “Terms of Use”) apply to the {type} web site located
                        at
                        www.{websiteType()}, and all associated sites linked to www.{websiteType()} by {type}, its subsidiaries
                        and
                        affiliates, including {type} sites around the world (hereinafter “the Website”). The Website
                        is edited
                        and owned by {type} c/o Comexposium Healthcare SAS, a “Sociétés par Actions Simplifiées”,
                        established
                        under the laws of France (hereinafter “{type}”, “we” or “us”).
                    </Text>
                    <Text textAlign={'justify'}>
                        The Website is considered as a digital platform and/or service and integrates content which
                        is not
                        supplied on a tangible medium and is provided only through internet connected devices.
                    </Text>
                    <Text textAlign={'justify'}>
                        The following Terms of Use constitute important information regarding the Website, our
                        copyright on its
                        contents and they govern your use of the Website as a Visitor or as a User.
                    </Text>
                    <Text textAlign={'justify'}>
                        By using the Website you agree to bound by these Terms of Use, our Privacy Policy, and any
                        additional
                        terms and conditions that are referenced herein (the “Agreement”). Accessing the content of
                        the Website,
                        through any means, whether automated or manual, will be considered as use of our site, and
                        an acceptance
                        of these Terms of Use.
                    </Text>
                    <Text textAlign={'justify'}>
                        We reserve the right to change these Terms of Use or to impose new conditions on use of the
                        Website,
                        from time to time. By continuing to use our service after we post any such changes, you
                        accept the Terms
                        of Use, as modified.
                    </Text>
                    {"\n"}
                    <Heading size={'md'}>2 - Your license to access and use the Website</Heading>
                    <Text textAlign={'justify'}>
                        You may access and use the Website, provided that you:
                        {"\n"}- are over the age of eighteen (18) years old. By using this site, you declare that
                        you are over 18
                        years of age
                        {"\n"}- in cases, create a User account with accurate registration information and keep your
                        password
                        securely stored and confidential
                        {"\n"}- do not initiate or participate in any activities that are prohibited below, illegal,
                        harmful, or
                        interfere with anyone's use of the Website, including the sending of e-mail or instant
                        messaging spam
                        {"\n"}- comply with all applicable local, national, and international laws and regulations
                        and these
                        Terms of Use
                    </Text>
                    <Text textAlign={'justify'}>
                        All content and materials featured on the Website are the exclusive property of {type} and/or
                        any of the
                        authors of such content (the practicing physician/s and/or speaker/s and/or patient/s). The
                        material is
                        protected by copyright law, trademark law, data privacy law, patent law, database rights and
                        protection,
                        image and intimacy rights and may also be protected by other laws depending on the country.
                    </Text>
                    <Text textAlign={'justify'}>
                        Certain features or services offered through the Website may require you to open an account
                        (including
                        setting up an {type} web account and personal password), and therefore a personal “User” ID
                        will be
                        assigned to you. Visitors and Users may access material of the Website only for their own
                        personal and
                        non-commercial use. Promoting a product, service, medical procedure, business, doctor or
                        other
                        individual on the Website is prohibited unless counting with the explicit permission from
                        {type}.
                    </Text>
                    <Text textAlign={'justify'}>
                        Visitors and Users may not modify, translate, adapt, merge, make derivative works,
                        disassemble,
                        decompile, reverse compile or reverse engineer any part and or content of the Website, nor
                        use any
                        manual or automated software, devices, or other processes (including but not limited to
                        spiders, robots,
                        scrapers, crawlers, avatars, data mining tools, or any related technique) to “scrape” or
                        download data
                        from any web pages contained in the Website.
                    </Text>
                    <Text textAlign={'justify'}>
                        The visual interfaces, graphics, design compilation, information, trade dress, database and
                        computer
                        code (including source code and object code), software, services, and all other elements of
                        the Website
                        are the exclusive property of {type} and shall not be extracted, nor copied, reproduced,
                        retransmitted,
                        broadcasted, distributed, published, commercially exploited or otherwise transfer ed by any
                        means,
                        without the written permission of {type}.
                    </Text>
                    <Text textAlign={'justify'}>
                        Visitors and Users are free to post a hypertext link directing to the Website on other
                        websites or
                        social networks, as long as the link does not state or imply any sponsorship of other sites
                        with {type}.
                        However, Visitors and Users may not, without our prior written permission, frame or link any
                        of the
                        content of the Website, or incorporate into another website or other service any of our
                        material,
                        content or intellectual property.
                    </Text>
                    {"\n"}
                    <Heading size={'md'}>3 - Our license to access and use your content on the Website</Heading>
                    <Text textAlign={'justify'}>
                        Users of the Website may provide and share content such as documents, videos, photos,
                        investigation
                        cases and so on. By posting your content on the Website, you grant us a non-exclusive
                        (except under the
                        conditions referred in the first paragraph above), transferable, sub-licensable,
                        royalty-free, worldwide
                        license to use, edit, modify, reproduce, copy, display, transmit, adapt, prepare derivative
                        works of,
                        publish and/or distribute your content on the Website in any and all media or distribution
                        formats and
                        channels in connection with the Website (whether currently known or later developed).
                    </Text>
                    <Text textAlign={'justify'}>
                        Users retain all ownership rights of their content and acknowledge responsibility for it and
                        any
                        consequences of its display on the Website. Posting content that would infringe any third
                        party
                        intellectual or other property rights or violate anyone's privacy or data protection rights,
                        including
                        the legal right for the patient to consent to any sharing of its medical information or
                        otherwise
                        violate any statutory or common law right of anyone is not permitted on any of the Websites.
                    </Text>
                    <Text>
                        If you are not in a position or do not have the appropriate authorizations to grant such
                        license, please
                        do not upload your content on the Website. Content owners have the right to withdraw their
                        authorization
                        of this license at any time. Up until that withdraw takes place, the license remains valid
                        and {type} is
                        authorized to use this content.
                    </Text>
                    {"\n"}
                    <Heading size={'md'}>4 - Availability</Heading>
                    <Text textAlign={'justify'}>
                        The Website and/or service is provided “as is” and “as available”. Any content might be
                        changed,
                        temporarily
                        unavailable or removed from the Website without prior notice. In such event, {type} will not
                        be held
                        liable.
                    </Text>
                    {"\n"}
                    <Heading size={'md'}>5 - Accessibility</Heading>
                    <Text textAlign={'justify'}>
                        The Website’s content can be accessed through laptops, computers and sometimes made
                        available on tablets
                        and/or mobile phones. We make every effort to ensure that the Website remains accessible
                        24/7 but are
                        not
                        responsible for the unavailability of the platform or website in the event of internet
                        malfunction,
                        maintenance operations, failure or bug, or any event of ‘force majeure’ beyond the
                        reasonable control of
                        {type}.
                    </Text>
                    <Text textAlign={'justify'}>
                        {type} may also use encryption, tracking, coding or any other software technique to ensure
                        that access to
                        the
                        Website remains restricted to each Users access.
                    </Text>
                    <Text textAlign={'justify'}>
                        The quality of the service may be affected by the device used and the bandwidth access. The
                        minimum
                        connection speed that is needed for an effective experience is 10 Mbps upload speed. {type}
                        is not
                        responsible for any internet access charges you may encounter from your internet provider.
                    </Text>
                    {"\n"}
                    <Heading size={'md'}>6 - Interaction and communication</Heading>
                    <Text textAlign={'justify'}>
                        Visitors and Users may interact and communicate on the Website through public and/or private
                        messages,
                        webinars, commentaries and/or discussion forums.
                    </Text>
                    <Text textAlign={'justify'}>
                        You are solely responsible for the reliability and accuracy of any communication and/or
                        information you
                        will
                        provide on the Website. You shall not provide any communication that could be considered as
                        inappropriate
                        including notably but without limitation any offensive, defamatory or illicit communication.
                        Users and Visitors shall refrain from publishing any communication or content that would
                        infringe any
                        third
                        party intellectual or other property rights or violate anyone's privacy or data protection
                        rights,
                        including
                        the legal right for the patient to consent to any sharing of its medical information on the
                        Website, or
                        otherwise violate any statutory or common law right of anyone.
                    </Text>
                    <Text textAlign={'justify'}>
                        Any content published contrary to the above may be held liable for the eventual damages
                        caused by it and
                        the
                        publisher agrees to eventually indemnify {type} for any claim or action alleging facts,
                        which, if true,
                        constitute a breach of any of this Agreement.
                    </Text>
                    <Text textAlign={'justify'}>
                        {type} has the right to delete any inappropriate communication or content posted on the
                        Website but does
                        not
                        exercise any prior control on the interaction of Visitors or Users and will not assume any
                        liability or
                        endorsement for any communication or content posted by them.
                    </Text>
                    {"\n"}
                    <Heading size={'md'}>7 - Limitation of Liability</Heading>
                    <Text textAlign={'justify'}>
                        All conduct that constitutes a breach of these Terms of Use or third party rights, whether
                        it’d be
                        intentional or unintentional, will constitute an automatic violation to the Terms of Use.
                        Likewise, any
                        such
                        conduct might also result in liability towards the rights of the holders of the content
                        featured in the
                        Website.
                    </Text>
                    <Text textAlign={'justify'}>
                        This conduct includes but is not limited to: downloading, filming, broadcasting or
                        reproducing before
                        third
                        parties, taking pictures or screenshots, sharing the use of an account with third parties.
                        Any conduct by means of reverse engineering or any software technique that leads to the
                        conduct
                        mentioned in
                        the paragraph above as well as breaching the security related features of the Website, is
                        equally
                        prohibited
                        and will be equally prosecuted.
                    </Text>
                    <Text textAlign={'justify'}>
                        {type} shall not be liable for indirect, incidental, special, exemplary, punitive, or
                        consequential
                        damages,
                        including lost profits, lost data, personal injury, or property damage related to, in
                        connection with,
                        or
                        otherwise resulting from any use of the Website. Since the aforementioned exclusion or
                        limitation of
                        certain
                        categories of damages may vary depending on the country, the above limitation may not apply
                        to you. In
                        such
                        case, liability is limited to the fullest extent permitted by the law of such country.
                        {"\n"}{type} does not guarantee the scientific accuracy, reliability, timeliness, quality or
                        suitability
                        of the
                        content of the Website.
                    </Text>
                    {"\n"}
                    <Heading size={'md'}>8 - Other Terms and Conditions applicable</Heading>
                    <Text textAlign={'justify'}>
                        {type} may make changes to any products or services offered on the Website, or to the
                        applicable prices
                        for
                        any such products or services, at any time, without notice.
                    </Text>
                    <Text textAlign={'justify'}>
                        Additional terms and conditions may apply to purchases of goods or services and to specific
                        portions or
                        features of the Website, including contests, promotions or other similar features, all of
                        which terms
                        are
                        made a part of these Terms of Use. If there is a conflict between these Terms of Use and the
                        terms
                        posted
                        for or applicable to a specific portion of the Website or for any service offered on or
                        through the
                        Website,
                        the latter terms shall control with respect to your use of that portion of the Website or
                        the specific
                        service.
                    </Text>
                    <Text textAlign={'justify'}>
                        {type}’s obligations, if any, with regard to its products and services are governed solely by
                        the
                        agreements
                        pursuant to which they are provided, and nothing on the Website should be construed to alter
                        such
                        agreements.
                    </Text>
                    {"\n"}
                    <Heading size={'md'}>9 - Indemnification</Heading>
                    <Text textAlign={'justify'}>
                        You agree to indemnify and hold harmless each director, officer, manager, employee,
                        shareholder, agent,
                        representative and licensor of {type}, from and against any and all losses, expenses, damages
                        and costs,
                        including reasonable attorneys' fees, that arise out of your use of the Website in violation
                        of these
                        Terms
                        of Use, incurred by you or any other person using your account, or your violation of any
                        third party
                        rights,
                        including but not limited to intellectual property rights and data privacy rights.
                        {"\n"}We reserve the right to take over the exclusive defense of any claim for which we are
                        entitled to
                        indemnification under this section. In such event, you agree to provide us with such
                        cooperation as is
                        reasonably requested by us.
                    </Text>
                    {"\n"}
                    <Heading size={'md'}>10 - Third parties</Heading>
                    <Text textAlign={'justify'}>
                        We may provide or repost links on the Website to other sites hosted, maintained or owned by
                        third
                        parties.
                        Our link to such third-party sites does not imply any endorsement of the information,
                        offers, products
                        or
                        services offered on or through these third party sites. We do not operate or control in any
                        manner the
                        information, products or services posted on these third-party sites.
                    </Text>
                    {"\n"}
                    <Heading size={'md'}>11 - Notice of Copyright Infringement</Heading>
                    <Text textAlign={'justify'}>
                        If you are a copyright, trademark, patent or intellectual property owner who believes your
                        protected
                        subject
                        matter has been illegitimately featured on the Website in a manner that constitutes an
                        infringement,
                        please
                        inform us at: support@{websiteType()}.
                    </Text>
                    <Text textAlign={'justify'}>
                        Please include the following information in your written notice: (1) a detailed description
                        of the
                        intellectual property subject matter that is allegedly infringed upon; (2) a description of
                        the location
                        of
                        the allegedly infringing material; (3) your contact information, including your address,
                        telephone
                        number
                        and e-mail address; (4) a statement by you indicating that you have a good-faith belief that
                        the
                        allegedly
                        infringing use is not authorized by the copyright owner, its agent, or the law; (5) a
                        statement by you,
                        made
                        under penalty of perjury, affirming that the information in your notice is accurate and that
                        you are
                        authorized to act on the copyright owner's behalf; and (6) an electronic or physical
                        signature of the
                        copyright owner or someone authorized on the owner's behalf to assert infringement of
                        copyright and to
                        submit the statement.
                    </Text>
                    <Text textAlign={'justify'}>
                        You may also notify us of any material featured on the Website that you believe is illicit
                        or breaches
                        any
                        of your or third party other rights. In such case, you must provide a clear description of
                        the rights
                        you
                        wish to invoke including a description of the material and the manner in which it infringes
                        your rights.
                    </Text>
                    {"\n"}
                    <Heading size={'md'}>12 - Termination</Heading>
                    <Text textAlign={'justify'}>
                        A User may terminate his/her account and this Agreement by requesting the closure of his/her
                        account on
                        the
                        Website. In such event, the termination will operate from the moment the termination request
                        is
                        received.
                        In the event of a violation of this Agreement, {type} may decide to suspend or terminate the
                        User’s
                        account.
                        The decision will be fully discretional and will not be subject to review.
                        To terminate your User account, please contact us at: support@{websiteType()}
                    </Text>
                    {"\n"}
                    <Heading size={'md'}>13 - Data processing & Privacy</Heading>
                    <Text textAlign={'justify'}>
                        In order to allow the proper functioning of the Website, as well as for statistical
                        anonymous purposes,
                        {type} may retrieve information related to the Visitors and/or Users. This includes but is
                        not limited
                        to:
                        name, address, email, profession/medical specialty, website, country, location,
                        hardware/software of Textuse,
                        payment method and cookies.
                    </Text>
                    <Text textAlign={'justify'}>
                        The {type} Privacy Policy applies to use of the Website and defines our use of your personal
                        data. By
                        using
                        the Website, you acknowledge and consent to the collection and use of your data, including
                        the transfer
                        of
                        information to suppliers or partners for storage, processing and/or use, under the terms of
                        such.
                    </Text>
                    <Text textAlign={'justify'}>
                        The information collected from you is subject to data processing by {type}. Any person whose
                        data is
                        collected has a right to access, rectify and delete the data concerning him/her, and the
                        right to oppose
                        for
                        legitimate reasons the processing of his/her data. To exercise these rights, please write
                        to:
                        support@{websiteType()}
                    </Text>
                    {"\n"}
                    <Heading size={'md'}>14 - Assignment</Heading>
                    <Text textAlign={'justify'}>
                        We may assign transfer, sub-license or sub-contract any of the rights or obligations under
                        the Agreement
                        to
                        any third party without your prior written approval.
                    </Text>
                    {"\n"}
                    <Heading size={'md'}>15 - Severability</Heading>
                    <Text textAlign={'justify'}>
                        If any provision of these Terms of Use is found by a court of competent jurisdiction to be
                        invalid or
                        unenforceable, any other provisions of these Terms of Use will continue in full force and
                        effect and you
                        agree that the court should endeavor to give maximum effect to the parties’ intentions as
                        reflected in
                        the
                        provision.
                    </Text>
                    {"\n"}
                    <Heading size={'md'}>16 - Waiver</Heading>
                    <Text textAlign={'justify'}>
                        Any waiver of any provision of these Terms will be effective only if made in writing and
                        signed by {type};
                        any delay or silence by {type} to exercise or enforce any right or provision of these Terms
                        of Use shall not constitute a waiver of such right or provision.
                    </Text>
                    {"\n"}
                    <Heading size={'md'}>17 - Applicable law and jurisdiction</Heading>
                    <Text textAlign={'justify'}>
                        The terms, interpretation and all claims arising from this agreement will be governed by the
                        applicable laws of France. Any claims arising out of this agreement will be ruled by the jurisdictions of
                        France.
                    </Text>
                </Box>
            </ScrollView>
        </Layout>
    );
}
