import {Button, Link, Pressable, Text, useTheme} from "native-base";
import * as React from "react";
import {Flex} from "native-base";
import {View} from "native-base";
import {useEffect} from "react";
import {Linking, Platform} from "react-native";
import {WebView} from "react-native-webview";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Config from "../config.json";
import {backgroundColor} from "styled-system";

function Publication(props)
{
    const publication = props.publication;
    const {congress} = useSelector(state => state.global);
    const {t} = useTranslation();
    const {colors} = useTheme();
    function handleWebsiteLink(){
        Linking.openURL(publication.website);
    }

    function getHeyzineUrl() {
        const pub = props.publication.partnerships.find(partnership => partnership.congress_id === congress.id);
        return pub ? pub.heyzine_url : "";
    }

    function goToHeyzine() {
        return Linking.openURL(getHeyzineUrl());
    }

    return(
        <>
            <View w={"100%"} px="2" py="2" alignItems={"center"} my={'2'}>
                    <View w={"100%"} borderColor={colors['background'][100]}  bg={colors['background'][50]} style={{shadowColor: '#000', shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.15, shadowRadius: 4, elevation: 5}} py="4" px={"4"}
                          borderWidth={1}
                          borderRadius='md'
                          flexDirection={(Platform.OS === "web" ? 'row' : 'column')}
                          alignItems={"center"}
                          >
                        <View w={(Platform.OS === "web" ? '40%' : '80%')}>
                            {(Platform.OS === "web"?
                                <iframe
                                    id="inlineFrameExample"
                                    title="Inline Frame Example"
                                    width="400"
                                    height="400"
                                    style={{border: 'none'}}
                                    src={getHeyzineUrl()}>
                                </iframe>
                                :
                                <WebView style={{width:700, height: 350}} source={{
                                    html: `<iframe
                                      id="inlineFrameExample"
                                      title="Inline Frame Example"
                                      width="auto"
                                      height="100%"
                                      style="border:none"
                                       src="${getHeyzineUrl()}">
                                    </iframe>`}}>
                                </WebView>
                            )}
                            <Button onPress={goToHeyzine} mx={(Platform.OS === 'web' ? 8 : 0)} mb={(Platform.OS === 'web' ? 0 : 4)}>{t('fullscreen')}</Button>
                        </View>

                        <View w={(Platform.OS === 'web' ? "60%" : '100%')}>
                            <Text fontSize={(Platform.OS === 'web' ? '2xl' : 'lg')} bold mb={(Platform.OS === 'web' ? 10 : 5)}>{publication.name}</Text>
                            <Text fontSize={(Platform.OS === 'web' ? 'xl' : "sm")} mb={2} color={colors['background'][400]}>{t('edited_by')} <Text fontSize={(Platform.OS === 'web' ? 'xl' : "sm")}>{publication.editor_name}</Text> - {publication.publisher_specialty} ({publication.mass_audience})</Text>
                            <Text fontSize={(Platform.OS === 'web' ? 'xl' : "sm")}><Text fontSize={(Platform.OS === 'web' ? 'xl' : "sm")} color={colors['background'][400]}>{t('published_by')}</Text> {publication.publisher}</Text>
                            <Text textDecoration={'underline'} fontSize={(Platform.OS === 'web' ? 'xl' : "sm")} mt={4} onPress={handleWebsiteLink}>{publication.website}</Text>
                        </View>
                    </View>

            </View>
        </>
    )
}

export default Publication
