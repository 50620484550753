import {Image, Platform} from "react-native";
import {AspectRatio, Button, Flex, Input, KeyboardAvoidingView, Modal, Text, useToast, View} from "native-base";
import PicturePicker from "./PicturePicker";
import * as React from "react";
import {useEffect, useState} from "react";
import HttpService from "../services/HttpService";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import * as Device from 'expo-device';
import Constants from "expo-constants";
import Config from "../config.json";


const bandeauUrlSponsor = require('../../assets/bandeau_virtual_booth_sponsor.png');


export default function PhotoBoothForm(props) {
    const [comment,setComment] = useState("");
    const [focusInput,setFocusInput] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [sendingFeedback, setSendingFeedback] = useState(false);
    const [userPic, setUserPic] = useState("");
    const [serverError, setServerError] = useState({});
    const [hasTakenPicture, setHasTakenPicture] = useState(false);
    const toast = useToast();
    const {congress} = useSelector(state => state.global);
    const [t, i18n] = useTranslation();

    useEffect(() => {
        setSendingFeedback(false);
    }, [userPic]);

    function sendPhoto() {
        setIsSending(true);
        setSendingFeedback(false);
        if (userPic) {
            HttpService.postData('photo-booth/snap', {picture: userPic,text: comment.trim()})
                .then((r) => {
                    setIsSending(false);
                    if (r.data.success === false) {
                        setSendingFeedback(r.data.message);
                    } else {
                        setUserPic("");
                        closeForm();
                    }
                })
                .catch((err) => {
                    setServerError(err);
                    setIsSending(false);
                });
        } else {
            setIsSending(false);
        }
    }

    async function openForm() {
        setSendingFeedback(false);
        const deviceType = await Device.getDeviceTypeAsync();
        if (deviceType === 3) {
            // alert install the app message
            toast.show({
                title: "This feature is only available on mobile",
                status: "error",
                description: "Please download IMCAS Live on your mobile or tablet device to take a picture in the Photo HomeBooth.",
            })
        } else {
            setShowModal(true);
        }
    }

    function closeForm() {
        setFocusInput(false);
        setSendingFeedback(false);
        setShowModal(false);
        setHasTakenPicture(false);
    }

    function inputFocusOn() {
        return setFocusInput(true);
    }

    function inputFocusOff() {
        return setFocusInput(false);
    }

    function setUserPicture(pic) {
        if (!hasTakenPicture) setHasTakenPicture(true);
        setUserPic(pic);
    }

    if (hasTakenPicture) {
        return (
            <>
                <Modal isOpen={showModal} onClose={closeForm}>
                    <Modal.Content maxWidth={'600px'}>
                        <Modal.CloseButton/>
                        <Modal.Body>
                            <Flex justify={'space-around'} align={'center'} direction={'column'}
                                  mt={10} width={"100%"}>
                                <View top={focusInput ? "-12.5%" : "0%"}>
                                    <PicturePicker setPic={setUserPicture} square cameraOnly/>

                                    <View bg={'white'} style={{position: 'absolute', bottom: 0, width: 300, height: 50}}>
                                        <Image source={bandeauUrlSponsor} alt={'IMCAS Academy'}
                                               style={{position: 'absolute', bottom: 0, width: 300, height: 50}}
                                               resizeMode={'contain'}
                                        />
                                    </View>
                                </View>

                                    {sendingFeedback ? <Text fontSize={'sm'} color={'red.500'}>{sendingFeedback}</Text> : <></>}

                                <KeyboardAvoidingView
                                    behavior={"position"}
                                    style={{flex: 1}}
                                >
                                    <Input  placeholder={t('My_comment')}
                                            w="100%"
                                            mt={5}
                                            maxLength={280}
                                            onChangeText={newText => setComment(newText)}
                                            onFocus={inputFocusOn }
                                            onBlur={inputFocusOff } />


                                    <Flex my={2}>
                                        <Text fontSize={'sm'}>{t('Photobooth_description')}</Text>
                                        {/*{Config.type === 'aop' ? <></> :*/}
                                        {/*    <Text fontSize={'xs'} italic>{t('Photobooth_sponsor_signature')}</Text>*/}
                                        {/*}*/}
                                    </Flex>


                                    <Button onPress={sendPhoto} isLoading={isSending}>{t('Photobooth_send')}</Button>
                                </KeyboardAvoidingView>

                            </Flex>


                        </Modal.Body>
                    </Modal.Content>
                </Modal>

                <Button
                    onPress={openForm}
                    position={'absolute'}
                    bottom={4}
                    right={0}
                    width={20}
                    aspectRatio={1}
                    elevation={10}
                    zIndex={10}
                >
                    <Text fontSize={'2xl'}>+</Text>
                </Button>
            </>
        );
    } else return (
        <>
            <Modal isOpen={showModal} onClose={closeForm}>
                <Modal.Content maxWidth={'600px'}>
                    <Modal.CloseButton/>
                    <Modal.Body>
                        <Flex justify={'space-around'} align={'center'} direction={{base: 'column', md: 'row'}} mt={10}>
                            <View>
                                <PicturePicker setPic={setUserPicture} buttonOnly cameraOnly/>
                            </View>
                        </Flex>
                    </Modal.Body>
                </Modal.Content>
            </Modal>

            <Button
                onPress={openForm}
                position={'absolute'}
                bottom={4}
                right={0}
                width={20}
                aspectRatio={1}
                elevation={10}
                zIndex={10}
                rounded="999px"
            >
                <Text fontSize={'2xl'} color={'white'}>+</Text>
            </Button>
        </>
    );

}
