import {Center, Heading, ScrollView, Spinner, Text} from "native-base";
import * as React from "react";
import LayoutLivePanelMobile from "../components/LayoutLivePanelMobile";
import SessionLectures from "../components/SessionLectures";
import SessionFeatures from "../components/SessionFeatures";
import {Platform} from "react-native";
import SessionCurrentLecture from "../components/SessionCurrentLecture";

export default function LiveInfoMobileScreen({navigation, ...props}) {
    return(
        <LayoutLivePanelMobile navigation={navigation} activePage={navigation.isFocused ? 'Info' : ''}>
            <ScrollView>
                <Text fontSize={"sm"} my={2} color={'black'}>{props.session.subtitle}</Text>
                {
                    props.isLoadingCurrentLecture ?
                        <Center flex={1}><Spinner color="#5766D6"  /></Center>
                        :
                        <SessionCurrentLecture
                            verySmall
                            lecture={props.lecture}
                        />
                }
                <SessionFeatures
                    verySmall
                    session={props.session}
                />
                <SessionLectures
                    verySmall
                    lectures={props.session.lectures}
                />
            </ScrollView>
        </LayoutLivePanelMobile>
    );
}
