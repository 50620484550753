import HttpService from './HttpService';
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Localization from "expo-localization";
import Config from "../config.json";

export const LoginUserService = async (credentials, deviceType) => {
    let expoPushToken = await AsyncStorage.getItem('expo-push-token');
    credentials = {
        username: credentials.username,
        password: credentials.password,
        expo_push_token: expoPushToken,
        device_type: deviceType
    };
    return HttpService.postData('auth/login', credentials).then(async (response) => {
        if (response.data.success) {
            try {
                await AsyncStorage.setItem('auth-token', response.data.token)
            } catch (e) {
                return false;
            }
        }
        return response.data;
    }).catch((error) => {
        console.log('Error: ' + error);
        return error.data;
    })
}

export const LogOutUserService = async () => {
    try {
        await AsyncStorage.removeItem('auth-token');
        await AsyncStorage.removeItem('login-intended-url');
        return true;
    } catch (exception) {
        return false;
    }
    try {
        let deviceLocale = Localization.locale.split('-')[0];
        if (Config.type == 'imcas' && (deviceLocale !== 'es' && deviceLocale !== 'pt')) {
            deviceLocale = 'en';
        }
        if(Config.type == 'aop'){
            deviceLocale = 'fr';
        }
        await AsyncStorage.setItem('language', deviceLocale);
    } catch (e) {
        return false;
    }
    return Promise.resolve();
}

export const GetDataUserService = () => {
    return HttpService.getData('account/data').then(async (response) => {
        try {
            await AsyncStorage.setItem('language', response.data.data.language);
        } catch (e) {
            return false;
        }
        return response.data;
    }).catch((error) => {
        return {success: false};
    })
}

export const GetNotificationsUserService = () => {
    return HttpService.getData('account/notifications').then((response) => {
        return response.data;
    }).catch((error) => {
        return {success: false};
    })
}

