import React, {useEffect, useMemo, useRef, useState} from "react";
import {FlatList, Platform} from "react-native";
import Layout from "../layouts/Layout";
import {Flex, Heading, ScrollView, Spinner, Text, useTheme, View} from "native-base";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import {useTranslation} from "react-i18next";
import Lecture from "../components/Lecture";
import {format, parseISO} from "date-fns";
import ProgramDayButtonForDesktop from "../components/ProgramDayButtonForDesktop";
import HttpService from "../services/HttpService";
import {useSelector} from "react-redux";

const LecturesScreen = (props) => {
    const [speaker, setSpeaker] = useState({});
    const {congressUser, user} = useSelector(state => state.auth);
    const {t, i18n} = useTranslation();
    const {colors} = useTheme();
    const currentDay = format(new Date(), 'y-MM-dd');
    const [activeDay, setActiveDay] = useState('');
    const [filteredLectures, setFilteredLectures] = useState([]);
    const [days, setDays] = useState([]);
    const [loading, setLoading] = useState(false);
    const timezone =  congressUser.timezone.replace(/[-_]/g, ' ');
    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };

    function getSession() {
        if (loading) {
            return null;
        }
        setLoading(true);
        return HttpService
            .getData(`user/get/speaker/${user.id}`).then(response => {
                setSpeaker(response.data);
            })
            .catch(error => {
                console.log(error.data);
                setLoading(false);
            });
    }

    useEffect(() => {
        getSession();
        // check if any file_presentation_status has changed
        const intervalId = setInterval(getSession, 300000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const obj = {};
        if(Object.keys(speaker).length > 0) {
            speaker.data.lectures.forEach(lecture => {
               if(lecture.session) {
                   if(lecture.is_eposter) {
                       if (obj['e_poster'] && obj['e_poster'].length > 0) {
                           obj['e_poster'].push(lecture);
                       } else {
                           obj['e_poster'] = [lecture];
                       }
                   } else {
                       if (lecture.session.date_begin === lecture.session.date_end) {
                           if (obj[lecture.session.date_begin] && obj[lecture.session.date_begin].length > 0) {
                               obj[lecture.session.date_begin].push(lecture);
                           } else {
                               obj[lecture.session.date_begin] = [lecture];
                           }
                       }
                   }
               }
            });
            Object.keys(obj).forEach(key => {
                if (key !== 'e_poster') {
                    obj[key].sort((a,b) => {return new Date(parseISO(a.session.datetime_begin)) - new Date(parseISO(b.session.datetime_begin));});
                }
            });
            setDays(Object.keys(obj).filter(key => key !== 'e_poster').sort((a,b) => new Date(a) - new Date(b)));
            if (days.find(day => day === currentDay)) {
                setActiveDay(currentDay);
            } else {
                setActiveDay(Object.keys(obj)[0]);
            }
            setFilteredLectures(obj);
            setLoading(false);
        }
    }, [speaker])

    const renderDayButtons = useMemo(() => {
        let buttons = [];
        days.forEach(day => {
            let isActiveDay = day === activeDay;
            if (day !== 'throughoutCongress') {
                buttons.push(
                    <ProgramDayButtonForDesktop
                        key={uniqueId()}
                        changeActiveDay={changeActiveDay}
                        isActiveDay={isActiveDay}
                        filteredSessionsDisplayed={filteredLectures[day]}
                        day={day}
                    />
                );
            }
        });
        if (filteredLectures['e_poster'] && filteredLectures['e_poster'].length > 0 ) {
            buttons.push(
                <ProgramDayButtonForDesktop
                    key={uniqueId()}
                    changeActiveDay={changeActiveDay}
                    date={false}
                    isActiveDay={activeDay === 'e_poster'}
                    filteredSessionsDisplayed={filteredLectures['E_posters']}
                    day={t('Eposter')}
                />
            );
        }
        if (Platform.OS === 'web'){
            return <Flex direction={'row'} justify={'center'}>{buttons}</Flex>;
        } else {
            return <ScrollView horizontal={true} w={'100%'}><Flex direction={'row'} justify={'center'}>{buttons}</Flex></ScrollView>;
        }
    });

    function changeActiveDay(day) {
        if (t('Eposter') === day) {
            setActiveDay('e_poster')
        } else {
            setActiveDay(day);
        }
    }


    if(Platform.OS === 'web') {
        return (
            <Layout activePage={props.navigation.isFocused ? t('my_lectures') : ''}>
                <Heading size='2xl' textAlign={'center'}
                         position={'absolute'} top={2} left={'50%'} style={{transform:'translateX(-50%)'}}
                >{t('my_lectures')}</Heading>

                <View my={4} />
                {renderDayButtons}
                {
                    loading ?
                        <View alignItems={"center"} w={'100%'} h={'100%'} justifyContent={"center"}>
                            <Spinner size="lg" color={colors['backgroundLight'][300]}/>
                        </View>
                        :
                        <ScrollView>
                            <FlatList data={filteredLectures[activeDay]} renderItem={({item}) => (<Lecture lecture={item}></Lecture>)}>

                            </FlatList>
                        </ScrollView>
                }

            </Layout>
        );
    }
    else {
        return (
            <Layout withBackButton activePage={props.navigation.isFocused ? t('my_lectures') : ''}>
                <Flex flexDirection={Platform.OS === 'web' ? 'row' : 'column'} alignItems={Platform.OS === 'web' ? "center": 'flex-start' } justifyContent={"space-between"}>
                    <Heading size={'xl'}>{t('my_lectures')}</Heading>
                    <Flex direction={'row'} align={'center'} backgroundColor={colors['action'][50]} px={3} py={1}
                          borderRadius={50}>
                        <Text fontSize={'sm'} color={'white'} mr={2}> {timezone}</Text>
                        <MaterialCommunityIcons name="clock-time-eight" size={20} color={'#5766D6'} style={{
                            borderRadius: 12.5,
                            backgroundColor: 'white',
                            padding: 2,
                            overflow: 'hidden'
                        }}/>
                    </Flex>
                </Flex>
                {renderDayButtons}
                {
                    loading ?
                        <View alignItems={"center"} w={'100%'} h={'100%'} justifyContent={"center"}>
                            <Spinner size="lg" color={colors['backgroundLight'][300]}/>
                        </View>
                        :
                        <ScrollView>
                            <FlatList data={filteredLectures[activeDay]} renderItem={({item}) => (<Lecture lecture={item}></Lecture>)}>

                            </FlatList>
                        </ScrollView>
                }
            </Layout>
        );
    }
}
export default LecturesScreen;
