import * as React from "react";
import AvatarWithText from "./AvatarWithText";
import {Flex, Heading, useTheme, View} from "native-base";
import LiveTag from "./LiveTag";

export default function SessionCurrentLecture(props) {
    const {colors} = useTheme();

    function renderSpeaker() {
        if(props.lecture.user) {
            return (
                <Flex direction={'row'}>
                    <AvatarWithText
                        key={props.lecture.id}
                        size={props.verySmall ? 'xs' : {base: 'sm', md: 'lg'}}
                        avatarSize={props.verySmall ? 'md' : {base: 'sm', md: 'lg'}}
                        picture={props.lecture.user.picture}
                        text={[props.lecture.user.fullname, props.lecture.user.specialty, props.lecture.user.country]}
                        direction={'row'}
                    />
                </Flex>
            );
        }
        else return <></>;
    }

    if (props.lecture) {
        return (
            <Flex align={'center'} borderBottomWidth={1} borderTopWidth={1} borderColor={colors['background'][100]} py={2}>
                <View flex={props.verySmall ? 1 : 'none'} width={props.verySmall ? '100%' : 'auto'}>
                    <LiveTag right={!!props.verySmall} />
                    <Heading size={props.verySmall ? 'xs' : 'md'} flex={1}
                             pl={props.verySmall ? 0 : 16}
                             pr={props.verySmall ? 12 : 0}
                             mt={props.verySmall ? 2 : 0}
                    >{props.lecture.title}</Heading>

                    {renderSpeaker()}
                </View>
            </Flex>
        );
    }
    else return <></>;
}
