import {
    Text,
    Heading,
    VStack,
    ScrollView,
    View,
    useTheme,
    Button,
    AspectRatio,
    Flex,
    Center,
    AlertDialog
} from "native-base";
import * as React from "react";
import Layout from "../layouts/Layout";
import {Link, useFocusEffect, useIsFocused} from "@react-navigation/native";
import AccountService from "../services/AccountService";
import {useEffect, useMemo, useState} from "react";
import ConferencesLive from "../components/ConferencesLive";
import HomeBooth from "../components/HomeBooth";
import ConferencesReplay from "../components/ConferencesReplay";
import {ImageBackground, Platform, Modal, Image} from "react-native";
import HomeNews from "../components/HomeNews";
import ConferencesSoon from "../components/ConferencesSoon";
import {useSelector} from "react-redux";
import TermsOfUse from "../components/TermsOfUse";
import Constants from "expo-constants";
import {useTranslation} from "react-i18next";
import VideoPlayer from "../components/VideoPlayer";
import HttpService from "../services/HttpService";
import {format, compareAsc} from "date-fns";
import Config from "../config.json";
import * as Device from "expo-device";
import {FontAwesome, MaterialCommunityIcons} from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import pusher from "../pusher";
import {Link as Link2} from "native-base";
import WebView from "react-native-webview";
import ViewAll from "../components/ViewAll";
import NextLecture from "../components/NextLecture";

const bannerImage = require('../../assets/homepage_banner.jpg');
const bannerImageAop = require('../../assets_aop/homepage_banner.jpg');

export default function HomeScreen({navigation, ...props}) {
    const [shouldPlayVideo, setShouldPlayVideo] = useState(false);
    const isFocused = useIsFocused();
    const {t, i18n} = useTranslation();
    const {isLoggedIn} = useSelector(state => state.auth);
    const {colors} = useTheme();
    const {congress} = useSelector(state => state.global);
    const [deviceType, setDeviceType] = useState();
    const [pusherUpdate, onPusherUpdate] = useState(false);
    const {user} = useSelector(state => state.auth);
    const [isOpen, setIsOpen] = React.useState(true);
    const [isSpeaker, setIsSpeaker] = useState({});

    //const [isSplashScreen, setIsSplashScreen] = useState(false);
    //const [splashScreenUrls, setSplashScreenUrls] = useState('');

    const onClose = () => setIsOpen(false);
    const cancelRef = React.useRef(null);
    const [visible, setVisible] = React.useState(false);
    const openLinkInWebview = () => setVisible(true)

// Ping
    useEffect(() => {
        async function setLayout() {
            const type = await getDeviceType();
            setDeviceType(type);
        }

        setLayout();
        goBackToIntendedUrl();
    }, [isFocused]);


    /*
    useEffect(() => {
        if (Platform.OS !== 'web') {
            HttpService
                .getData('ads/splashscreen/has')
                .then(response => {
                    setIsSplashScreen(response.data);
                    if(response.data) {
                        HttpService
                            .getData('ads/splashscreen/get')
                            .then(response => {
                                setSplashScreenUrls({
                                    'mobile': response.data.mobile.image_file_url,
                                    'tablet_landscape': response.data.tablet_landscape.image_file_url,
                                    'tablet_portrait': response.data.tablet_portrait.image_file_url
                                });
                            });
                    }
                })
        }
    }, [deviceType]);
     */


    useEffect(() => {
        let interval;
        if (isFocused) {
            const channelLive = pusher.subscribe('onsite-room');

            channelLive.bind('update', function (data) {
                onPusherUpdate(prev => !prev);
            });

            interval = AccountService.startPing(navigation, {page: 'homepage'});

            setShouldPlayVideo(true);
        }

        return () => {
            pusher.unsubscribe('onsite-room');
            setShouldPlayVideo(false);
            clearInterval(interval);
        }
    }, [isFocused]);


    const goBackToIntendedUrl = async () => {

        if (Platform.OS === 'web') {
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            if (!isSafari) {
                try {
                    let intendedUrl = await AsyncStorage.getItem('login-intended-url');
                    await AsyncStorage.removeItem('login-intended-url');
                    if (intendedUrl) {
                        setTimeout(() => {
                            window.location.assign(intendedUrl);
                        }, 500);
                    }
                } catch (e) {
                    return false;
                }
            }
        }
    }

    async function getDeviceType() {
        return await Device.getDeviceTypeAsync();
    }

    function renderVideoAds() {
        const congressStart = congress.date_start;
        const currentDay = new Date();

        if (congress.prelive_homepage_videos
            && Array.isArray(congress.prelive_homepage_videos)
            && congress.prelive_homepage_videos.length > 0
            && compareAsc(currentDay, new Date(congressStart)) !== 1
            && shouldPlayVideo) {
            let url = '';
            if (Array.isArray(congress.prelive_homepage_videos) && congress.prelive_homepage_videos.length > 0) {
                url = congress.prelive_homepage_videos.map(v => v.video_file_url);
            }

            if (Platform.OS === 'web') {
                return (
                    <>
                        <Flex direction={'row'} align={'center'} width={{base: '100%', md: '95%'}}
                              justifyContent={'space-between'} mb={2} ml={{base: 0, lg: 4}}>
                            <Flex direction={'row'} align={'center'}>
                                <MaterialCommunityIcons name="account-group" size={32} color="#1F2357"/>
                                <Text fontSize={"lg"} fontWeight="bold" ml={2}>{t('Partner_news')}</Text>
                            </Flex>
                        </Flex>
                        <View w={'100%'} maxW={900} m={'auto'}>
                            <VideoPlayer
                                file={url}
                                isLooping={true}
                                isMuted
                                noControls
                            />
                        </View>
                    </>
                );

            } else {
                return (
                    <>
                        <Flex direction={'row'} align={'center'} width={{base: '100%', md: '95%'}}
                              justifyContent={'space-between'} mb={2} ml={{base: 0, lg: 4}}>
                            <Flex direction={'row'} align={'center'}>
                                <MaterialCommunityIcons name="account-group" size={32} color="#1F2357"/>
                                <Text fontSize={"lg"} fontWeight="bold" ml={2}>{t('Partner_news')}</Text>
                            </Flex>
                        </Flex>
                        <AspectRatio w={'100%'} ratio={2 / 1}>
                            <VideoPlayer
                                file={url}
                                isLooping={true}
                                isMuted
                                noControls
                            />
                        </AspectRatio>
                    </>
                );
            }
        } else return <></>;
    }


    function renderSplashScreen() {
        //if (Platform.OS !== 'web') {
        return (
            <>
                <Flex w={'100%'} justifyContent={"center"} alignItems={'center'}>
                    <Image
                        source={{uri: 'https://comexposium-healthcare-uploads.s3-eu-west-1.amazonaws.com/ads/182/image_file/ssU198G4qfFEQqSwQGrF7EGYiUIrXbglhpoLrZfY.jpg'}}
                        alt={'Splash image from sponsor'}
                        resizeMode={'contain'}
                        style={{width: 400, height: 250}}
                    ></Image>
                </Flex>
            </>
        );
        //}
        return (<></>);
    }

    function getIsSpeaker() {
        return HttpService
            .getData(`user/is/speaker/${user.id}`).then(response => {
                setIsSpeaker(response.data.is_speaker);
            })
            .catch(error => {
                console.log(error.data);
            });
    }

    useEffect(() => {
        getIsSpeaker();
    }, []);

    if (!isLoggedIn) {
        return false;
    } else {
        return (
            <Layout activePage={navigation.isFocused ? t('Live') : ''}>
                <ScrollView overflowX={"visible"} overflowY={"visible"}>
                    <VStack>
                        {
                            isSpeaker ?
                                <NextLecture></NextLecture>
                                :
                                <></>
                        }
                        {renderSplashScreen()}
                        <HomeNews/>
                        {renderVideoAds()}
                        <ConferencesLive deviceType={deviceType} pusherUpdate={pusherUpdate}/>
                        <ConferencesSoon deviceType={deviceType} pusherUpdate={pusherUpdate}/>
                        <ConferencesReplay deviceType={deviceType} pusherUpdate={pusherUpdate}/>
                        {congress.is_alert_enabled && congress.can_evaluate && user.may_evaluate ?
                            <Center>
                                <AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen} onClose={onClose}
                                             width={Platform.OS === 'web' ? '50%' : '90%'} marginX={'auto'}>
                                    <AlertDialog.Content>
                                        <AlertDialog.CloseButton/>
                                        <AlertDialog.Header>{t('Evaluation')}</AlertDialog.Header>
                                        <AlertDialog.Body>
                                            <Text>{t('Submit_evaluation')} {congress.name}.</Text>
                                        </AlertDialog.Body>
                                        <AlertDialog.Footer>
                                            {Platform.OS === 'web' ?
                                                <Button onPress={onClose} colorScheme="primary">
                                                    <Link2
                                                        href={`https://www.imcas.com/login-fast/${user.personal_token}?go=https://www.imcas.com/en/attend/${congress.slug}/evaluation`}
                                                        isExternal
                                                        onPress={onClose}
                                                    >
                                                        <Text color={'white'}>{t('Evaluate')}</Text>
                                                    </Link2>
                                                </Button>
                                                :
                                                <Button onPress={() => {
                                                    openLinkInWebview();
                                                    onClose()
                                                }} colorScheme="primary">
                                                    {t('Evaluate')}
                                                </Button>
                                            }
                                        </AlertDialog.Footer>
                                    </AlertDialog.Content>
                                </AlertDialog>
                                <Modal
                                    visible={visible}
                                    presentationStyle={'pageSheet'}
                                    animationType={'slide'}
                                    onRequestClose={() => setVisible(false)}
                                >
                                    <WebView
                                        source={{uri: `https://www.imcas.com/login-fast/${user.personal_token}?go=https://www.imcas.com/en/attend/${congress.slug}/evaluation`}}/>
                                </Modal>
                            </Center>
                            : <></>}
                        <HomeBooth/>
                        <TermsOfUse/>
                    </VStack>
                </ScrollView>
            </Layout>
        );
    }
}
