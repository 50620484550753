import * as React from "react";
import {Center, Flex, Heading, ScrollView, SectionList, Spinner, Text, useTheme, View} from "native-base";
import {useTranslation} from "react-i18next";
import Layout from "../layouts/Layout";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {refreshData} from "../actions/auth";
import SpeakerCard from "../components/SpeakerCard";
import Slider from "../components/Slider";
import {compareAsc, format} from "date-fns";
import SessionCard from "../components/SessionCard";
import Session from "../components/Session";
import {useIsFocused} from "@react-navigation/native";
import CompanyListCard from "../components/CompanyListCard";
import {Platform} from "react-native";
export default function UserListScreen({navigation}) {
    const {t, i8n} = useTranslation();
    const {list} = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const {colors} = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [reduxError, setReduxError] = useState({});
    const [sessionsNow, setSessionsNow] = useState([]);
    const [sessionsReplay, setSessionsReplay] = useState([]);
    const [sessionsToRender, setSessionsToRender] = useState([]);
    const [speakers, setSpeakers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const isFocused = useIsFocused();
    const carouselDesktopHeight = 175;
    const carouselItemDesktopWidth = 250;
    const carouselMobileHeight = 175;
    const carouselItemMobileWidth = 225;
    const sessionMaxChar = 65;

    useEffect(() => {
        setIsLoading(true);
        dispatch(refreshData())
            .then((r) => {

                if (list.length > 0) {
                    let sessionsNowArr = [];
                    let sessionsReplayArr = [];
                    let speakersArr = [];
                    let companiesArr = [];
                    list.forEach((item) => {
                        if (item.entity_type === 'LectureSession') {
                            if (item.entity.replayAvailable) {
                                sessionsReplayArr.push(item.entity);
                            } else {
                                sessionsNowArr.push(item.entity);
                            }
                        } else if (item.entity_type === 'User') {
                            speakersArr.push(item.entity);
                        } else if (item.entity_type === 'Exhibitor') {
                            companiesArr.push(item.entity)
                        }
                    });
                    setSessionsNow(sessionsNowArr);
                    setSessionsReplay(sessionsReplayArr);
                    const array = [...speakersArr, ...speakersArr, ...speakersArr];
                    setSpeakers(array);
                    setCompanies(companiesArr);
                    setIsLoading(false);
                }
                setIsLoading(false);
            })
            .catch((error) => setReduxError(error));
    }, [isFocused]);

    useEffect(() => {
        sortSessions();
    }, [sessionsNow]);

    function truncate(string) {
        if (string && string.length > sessionMaxChar) {
            return string.substring(0, sessionMaxChar) + '...';
        } else return string;
    }

    function sortSessions() {
        const sessions = [];
        const sessionsByDate = sessionsNow.sort((a, b) => {
            if(compareAsc(new Date(a.date_begin), new Date(b.date_begin)) === 1) {
                return 1;
            } else return -1;
        });

        for (const session of sessionsByDate) {
            if (!sessions.find(s => s.title === session.date_begin)) {
                sessions.push({title: session.date_begin, data: []});
            }
            let day = sessions.find(sessions => sessions.title === session.date_begin);
            day.data.push(session);
        }

        sessions.forEach((day) => {
            day.data.forEach((session) => {
                session.time_begin = session.date_begin + ' ' + session.time_begin;
                session.time_end = session.date_end + ' ' + session.time_end;
            });
            day.data = day.data.sort((a, b) => {
                if(compareAsc(new Date(a.time_begin), new Date(b.time_begin)) === 1) {
                    return 1;
                } else return -1;
            });
        });

        sessions.forEach((day) => {
            day.data.forEach((session) => {
                session.subtitle = truncate(session.subtitle);
            })
        });

        setSessionsToRender(sessions);
    }

    function renderSpeakerContent(item, index) {
        return (
            <SpeakerCard
                fullname={item.fullname}
                avatarSize={'lg'}
                uri={item.picture}
                customWidth={'100%'}
                customHeight={'100%'}
                withListButton={false}
                id={item.id}
            />
        );
    }

    function renderSessionContent(item, index) {
        return (
            <Session
                item={item}
                isReplay
                key={item.id}
            />
        );
    }

    function renderSessionsReplayCarousel() {
        if (sessionsReplay.length > 0) {
            return (
                <View my={4}>
                    <Slider
                        sliderTitle={t('My sessions in replay')}
                        data={sessionsReplay}
                        sliderMobileWidth={carouselItemMobileWidth}
                        sliderDesktopWidth={carouselItemDesktopWidth}
                        itemContent={renderSessionContent}
                        btnHeight={120}
                        videos
                    />
                </View>
            );
        } else {
            return (<></>);
        }
    }

    function renderSpeakersCarousel() {

        if (speakers.length > 0) {
            return (
                <View my={4}>
                    <Slider
                        data={speakers}
                        sliderTitle={t('My_speakers')}
                        sliderDesktopWidth={carouselItemDesktopWidth}
                        sliderDesktopHeight={carouselDesktopHeight}
                        sliderMobileHeight={carouselMobileHeight}
                        sliderMobileWidth={carouselItemMobileWidth}
                        sliderMarginX={Platform.OS === 'web' ? 2 : 4}
                        itemContent={renderSpeakerContent}
                        btnHeight={175}
                        videos
                    />
                </View>
            );
        } else {
            return (<></>);
        }
    }

    const renderCompaniesContent = (item) => {
        return (
            <CompanyListCard company={item} cardWidth={'100%'}/>
        );
    }

    const renderCompaniesCarousel = () => {
        if (companies.length > 0) {
            return (
                <View my={4}>
                    <Slider
                        data={companies}
                        sliderTitle={t('My_companies')}
                        sliderDesktopWidth={carouselItemDesktopWidth}
                        sliderDesktopHeight={carouselDesktopHeight}
                        sliderMobileHeight={carouselMobileHeight}
                        sliderMobileWidth={carouselItemMobileWidth}
                        itemContent={renderCompaniesContent}
                        btnHeight={175}
                        videos
                    />
                </View>
            )
        }
    }

    function renderUserProgram() {
        if (sessionsToRender.length > 0) {
            return (
                <SectionList
                    sections={sessionsToRender}
                    keyExtractor={(item, index) => item + index}
                    stickySectionHeadersEnabled='true'
                    renderSectionHeader={({section}) => {
                        return (
                            <Heading
                                fontSize='2xl'
                                bg={colors['backgroundLight'][50]}
                                borderRadius={5}
                                pl={2}
                            >
                                {format(new Date(section.title), 'EEEE')}
                            </Heading>
                        );
                    }}
                    renderItem={({item, index, section}) => {
                        let isChecked = false;
                        if (sessionsNow.find(session => session.id === item.id)) isChecked = true;
                        return (
                            <SessionCard
                                item={item}
                                isChecked={isChecked}
                            />
                        );
                    }}
                    ItemSeparatorComponent={renderItemSeparator}
                />
            );
        } else return (
            <Center pt={8}>
                <Text fontSize={{base: 'xs', md: 'md'}}>There are not upcoming sessions in your list.</Text>
            </Center>
        );
    }

    function renderItemSeparator() {
        return <View borderTopWidth={1} borderColor={colors['backgroundLight'][50]}/>;
    }

    if (isLoading) {
        return (
            <Layout>
                <Center flex={1}><Spinner color="#5766D6" /></Center>
            </Layout>
        );
    } else if (reduxError.message) {
        return (
            <Layout>
                <Center flex={1}><Text>{reduxError.message}</Text></Center>
            </Layout>
        );
    }
    return (
        <Layout withBackButton>
            <Heading px={{base: 0, md: 4}} size={'2xl'} textAlign={'center'}>{t('My_list')}</Heading>
            <ScrollView px={{base: 0, md: 4}} maxW={900} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                <View my={4}>
                    <Text fontSize={{base: 'xs', md: 'md'}}>{t('My_list_description')}</Text>
                </View>
                {renderUserProgram()}
                {renderSessionsReplayCarousel()}
                {renderSpeakersCarousel()}
                {renderCompaniesCarousel()}
            </ScrollView>
        </Layout>
    );
}
