import * as React from "react";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Layout from "../layouts/Layout";
import {Center, Flex, Heading, ScrollView, SectionList, Spinner, Text, useTheme, View} from "native-base";
import {useTranslation} from "react-i18next";
import HttpService from "../services/HttpService";
import {Dimensions, Platform} from "react-native";
import CountResult from "../components/CountResult";
import PartnerListCard from "../components/PartnerListCard";
import CompanyListCard from "../components/CompanyListCard";
import PartnerFilters from "../components/PartnerFilters";
import ScrollToTopButton from "../components/ScrollToTopButton";
import * as Device from "expo-device";

export default function PartnerListScreen(props) {
    const {t, i18n} = useTranslation();
    const listRef = useRef(null);
    const [contentVerticalOffset, setContentVerticalOffset] = useState(0);
    const CONTENT_OFFSET_THRESHOLD = 100;
    const {colors} = useTheme();
    const [partners, setPartners] = useState([]);
    const [filteredPartners, setFilteredPartners] = useState([]);
    const [deviceType,setDeviceType] = useState();


    const uniqueId = useCallback(() => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    },[]);

    const getPartners = useCallback(() => {
        HttpService
            .getData(`partners/get/all`)
            .then(response => {
                setPartners(response.data.data);
                setFilteredPartners(response.data.data);
            })
            .catch(error => {
                console.log('Error: ' + error)
            })
    },[])

    useEffect(() => {
        getPartners();
        async function setLayout() {
            const type = await getDeviceType();
            setDeviceType(type);

        }
        setLayout();
    }, []);

    /*
     * array of specialties
     */
    const specialties = useMemo(() => {
        const allSpecialty = filteredPartners.map(partner => partner.specialty);
        return [...new Set(allSpecialty)];
    },[filteredPartners])

    /*
     * returns array of partner sorted by specialties
     */
    const partnersBySpecialty = useMemo(() => {
        if (filteredPartners.length > 0) {
            let partnersData = [];
            //loop on array of specialties
            for (const specialty of specialties) {
                let data = {'title': "", 'data': []}
                //create objects with a specialty and his corresponding partners
                for (const partner of filteredPartners) {
                    if (partner.specialty?.toLowerCase() === specialty?.toLowerCase()) {
                        data =
                            {
                                'title': specialty,
                                'data': [...data.data, partner]
                            }
                    }
                }
                partnersData.push(data)
            }
            return partnersData;
        } else return [];
    },[filteredPartners])

    const renderItemSeparator = () => {
        return <View borderTopWidth={1} borderColor={colors['backgroundLight'][50]}/>;
    }

    async function getDeviceType() {
        return await Device.getDeviceTypeAsync();
    }


    const renderPartnerList = useMemo(function(){
        if (filteredPartners.length > 0) {
            //for desktop
            if (Platform.OS === 'web'|| (deviceType == 2 && Dimensions.get('window').width > Dimensions.get('window').height)) {
                return (
                    <>
                        <ScrollView
                            width="100%"
                            maxWidth={{md: 900}}
                            margin={{md: 'auto'}}
                            ref={listRef}
                            onScroll={event => {
                                setContentVerticalOffset(event.nativeEvent.contentOffset.y);
                            }}
                            scrollEventThrottle={16}

                        >
                            {partnersBySpecialty.map(item => (<React.Fragment key={uniqueId()}>
                                <Heading m={2}  mt={4}>{item.title?.toUpperCase()}</Heading>
                                <Flex
                                    w="100%"
                                    flexDirection="row"
                                    flexWrap="wrap"
                                >
                                    {item.data.map(partner => (<PartnerListCard key={uniqueId()} partner={partner}/>))}
                                </Flex>
                            </React.Fragment>))}
                        </ScrollView>

                    </>
                );
            } else {
                //for mobile
                return (
                    <>
                        <SectionList
                            ref={listRef}
                            sections={partnersBySpecialty}
                            keyExtractor={(item, index) => item + index}
                            stickySectionHeadersEnabled={true}
                            renderSectionHeader={({section: {title}}) => (
                                <Heading
                                    fontSize="xl"
                                    mt={4}
                                    bg={colors['background'][50]}
                                >
                                    {title.toUpperCase()}
                                </Heading>)
                            }
                            renderItem={({item}) => (
                                <PartnerListCard partner={item}/>
                            )}
                            ItemSeparatorComponent={renderItemSeparator}
                        />
                    </>
                );
            }
        } else {
            return <Center flex={1}><Text>{t('No_results')}</Text></Center>

        }
    },[filteredPartners]);

    const renderHeader = useMemo(() => {
        if (Platform.OS === 'web') {
            return (
                <Flex
                    w={'100%'}
                    align={'center'}
                    _dark={{borderColor: colors['backgroundLight'][50],}}
                    borderBottomWidth={0.5}
                    p={2}
                >
                    <Heading size={'2xl'}>{t('Scientific_partner')}</Heading>
                    <Flex w={'100%'} direction={'row'} justify={'space-around'} align={'flex-end'}>
                        <View>
                            <CountResult result={filteredPartners?.length}/>
                        </View>
                    </Flex>
                </Flex>
            );
        } else return (
            <Flex>
                <Heading textAlign={{lg:"center"}}>{t('Scientific_partner')}</Heading>
                <Center paddingBottom={2}>
                    <CountResult result={filteredPartners?.length}/>
                </Center>
            </Flex>
        );
    },[])

    // render
    if (partners.length > 0) {
        return (
            <Layout withBackButton activePage={props.navigation.isFocused ? 'Explore' : ''}>
                {renderHeader}
                <PartnerFilters partners={partners} setFilteredPartners={setFilteredPartners}/>
                {renderPartnerList}
                {contentVerticalOffset > CONTENT_OFFSET_THRESHOLD &&
                <ScrollToTopButton eltRef={listRef}/>}

            </Layout>
        );
    } else
        return (
            <Layout activePage={props.navigation.isFocused ? 'Explore' : ''}>
                {renderHeader}
                <Center h={'100%'} w={'100%'}>
                    <Spinner color="#5766D6" />
                </Center>
            </Layout>
        );
}
