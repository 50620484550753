import {
    Fab, useTheme
} from "native-base";
import * as React from "react";
import {SimpleLineIcons} from '@expo/vector-icons';
import {Platform} from "react-native";

function ScrollToTopButton(props) {

    const scrollToTop = () => {
        props.eltRef.current.scrollTo({y: 0, animated: true});
    }

    return <Fab onPress={props.scroll ?  () => props.scroll(0) : () => scrollToTop()}
                renderInPortal={false} shadow={2} size="sm"
                colorScheme={'primary'}
                icon={<SimpleLineIcons name="arrow-up" size={Platform.OS === 'web' ? 24 : 18} color="white"/>} />
}

export default ScrollToTopButton


