import {Button, Flex, Text, useTheme, View, Pressable} from "native-base";
import AvatarWithText from "./AvatarWithText";
import * as React from "react";
import {format, parseISO} from "date-fns";
import AbstractModal from "./AbstractModal";
import {useState} from "react";

export default function LectureCard(props) {
    const {colors} = useTheme();
    const [showModal, setShowModal] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    function renderSpeaker() {
        if (props.lecture.user) {
            return (
                <AvatarWithText
                    key={props.lecture.id}
                    wrap={'nowrap'}
                    size={props.verySmall ? 'xs' : 'sm'}
                    avatarSize={props.verySmall ? 'xs' : 'sm'}
                    picture={props.lecture.user.picture}
                    text={props.lecture.user.fullname}
                    direction={'row'}
                    wrap={'nowrap'}
                />
            );
        }
    }

    const toggleAbstractModal = () => {
        if(props.lecture.user){
            setShowModal(true)
        }
    }

    function hoverOn() {
        return setIsHovered(true);
    }

    function hoverOff() {
        return setIsHovered(false);
    }

    return (
        <Pressable onPress={toggleAbstractModal} style={props.lecture.user ? {cursor: 'pointer'} : null}>
            <Flex
                direction={'row'}
                borderRadius={'md'}
                wrap={"nowrap"}
                my={2}
                py={props.verySmall ? 1 : 2}
                bg={isHovered ? colors['background'][100]['500'] : colors['background'][100]}
                onMouseEnter={hoverOn}
                onMouseLeave={hoverOff}
            >
                <Flex
                    flex={1}
                    align={'flex-start'}
                    justify={'center'}
                    px={2}
                    width={'60%'}
                >
                    <Text fontSize={props.verySmall ? 'xs' : {
                        base: 'xs',
                        md: 'md'
                    }}>{format(parseISO(props.lecture.datetime_begin), 'p')}</Text>
                </Flex>
                <Flex
                    flex={props.verySmall ? 8 : {base: 8, md: 12}}
                    direction={props.verySmall ? 'column' : {base: 'column', md: 'row'}}
                    align={props.verySmall ? '' : {md: 'center'}}
                    justify={'space-between'}
                    px={2}
                    width={'40%'}
                >
                    <View flex={3}>
                        <Text fontSize={props.verySmall ? 'xs' : {base: 'sm', md: 'md'}}>{props.lecture.title}</Text>
                    </View>
                    <Flex height={props.avatarHeight} flex={1} my={props.verySmall ? 0.25 : {base: 0, md: 8}}>
                        {renderSpeaker()}
                    </Flex>
                </Flex>
            </Flex>
            <AbstractModal
                lecture={props.lecture} showModal={showModal} setShowModal={setShowModal}/>
        </Pressable>
    );
}

LectureCard.defaultProps =
{
    avatarHeight: 'auto'
}
