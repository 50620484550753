import React, {useState} from 'react';
import {
    Avatar, Box, Button,
    Flex, HStack, Text, useTheme, View,
} from "native-base";
import {Link} from "@react-navigation/native";
import MyListCheckbox from "./MyListCheckbox";

const SpeakerCard = React.memo(({uri, avatarSize, fullname, specialty, country, id, isHorizontal, isChecked, customWidth,customHeight,customMarginX, withListButton}) => {
    const {colors} = useTheme();
    const [isHovered, setIsHovered] = useState(false);
    function hoverOn() {
        return setIsHovered(true);
    }

    function hoverOff() {
        return setIsHovered(false);
    }

    return (
        <>
            {
                isHorizontal ? (
                    <Flex w={"100%"} px={Platform.OS === 'web' ? "2" : 1} py="2" >
                        <HStack
                            alignItems="center"
                            minW={"100%"}
                            w="100%"
                            py="4"
                            mx={customMarginX}
                            bg={colors['background'][50]}
                            borderRadius='lg'
                            px={"2"}
                            style={{shadowColor: '#000', shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.15, shadowRadius: 4, elevation: 5}}
                        >
                            <Avatar size={avatarSize} source={{uri}} mr={2}/>
                            <Flex w={"75%"} >
                                {fullname ? <Text fontSize="sm" bold>{fullname}</Text> : <></>}
                                <Flex direction={"row"} justifyContent={"space-between"}>
                                    <Flex
                                    >
                                        {specialty ? <Text fontSize="sm">{specialty}</Text> : <></>}
                                        {country ? <Text fontSize="sm">{country}</Text> : <></>}
                                    </Flex>
                                    <View>
                                        <MyListCheckbox
                                            item={{id: id, type: 'User'}}
                                            size={'sm'}
                                            isChecked={isChecked}/>
                                    </View>
                                </Flex>
                            </Flex>
                        </HStack>
                        <Link to={{screen: 'UserProfile', params: {id}}} style={{position: 'absolute', width: '75%', height: '100%'}} ></Link>
                    </Flex>
                ) : (
                    <Flex
                        justifyContent="center" alignItems="center"
                        w={customWidth}
                        m={3}
                        minW={"250px"}
                        borderColor={colors['background'][100]}
                        borderWidth={1}
                        borderRadius='md'
                        mx={customMarginX}
                        bg={isHovered ? colors['background'][100]['500'] : colors['background'][100]}
                        onMouseEnter={hoverOn}
                        onMouseLeave={hoverOff}
                    >
                        <Flex justifyContent="center" alignItems="center" w={'100%'} h={customHeight ? customHeight : {base:"100%",lg:'275px'}} p={5} >
                            <Avatar size={avatarSize} source={{uri}} m={2}/>
                            <Flex justifyContent="center" alignItems="center">
                                {fullname ? <Text >{fullname}</Text> : <></>}
                                {specialty ? <Text >{specialty}</Text> : <></>}
                                {country ? <Text >{country}</Text> : <></>}
                            </Flex>
                            <Link to={{screen: 'UserProfile', params: {id}}} style={{position: 'absolute', width: '100%', height: '100%'}} />
                        </Flex>
                    </Flex>
                )
            }
        </>
    )
})

export default SpeakerCard;

SpeakerCard.defaultProps = {
    customWidth: '25%',
    withListButton: true,
}
