import {
    LOGIN_SUCCESS,
    REFRESH_DATA_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
} from "../actions/types";

const initialState = {isLoggedIn: false, user: null, congressUser: null, language: null, timezone: null};

export default function (state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
                congressUser: payload.congressUser,
                language: payload.congressUser.language,
                timezone: payload.congressUser.timezone,
                list: payload.list,
            };
        case REFRESH_DATA_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
                congressUser: payload.congressUser,
                language: payload.congressUser.language,
                timezone: payload.congressUser.timezone,
                list: payload.list,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                congressUser: null,
                language: 'auto',
                timezone: null
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                congressUser: null,
                language: 'auto',
                timezone: null
            };
        default:
            return state;
    }
}
