import React, {useEffect, useState} from "react";
import {Box, Button, Center, Flex, Heading, Image, Link, ScrollView, Spinner, Text, useTheme, View} from "native-base";
import {useTranslation} from "react-i18next";
import Layout from "../layouts/Layout";
import {AntDesign} from "@expo/vector-icons";
import HttpService from "../services/HttpService";
import {Linking, Platform} from "react-native";

export const WelcomePackScreen = ({navigation}) => {
    const {t, i18n} = useTranslation();
    const {colors} = useTheme();
    const [exhibitors,setExhibitors] = useState([]
    );
    const [global, setGlobalPdf] = useState("")
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getCompanies();
    },[])

    const getCompanies = () => {
        HttpService
            .getData('welcome/get/exhibitors')
            .then(response => {
                const data = shuffle(response.data.data);
                setExhibitors(data)
                setGlobalPdf(data[0])
                setIsLoading(true);
            })
            .catch(error => {
                console.log('Error: ' + error)
            })
    }

    function shuffle(array) {
        let currentIndex = array.length,  randomIndex;

        while (currentIndex != 0) {

            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    function goToPreviousPage() {
        return navigation.goBack();
    }


    const renderCompanies = exhibitors.map((exhibitor) =>{
            return (
                <Link
                    key={exhibitor.id}
                    height={150}
                    width={150}
                    m={2}
                    borderRadius={10}
                    aspectRatio={1}
                    onPress={() => {
                        if(Platform.OS == 'web'){
                            window.open( exhibitor.company_document_pdf !== "none" ? exhibitor.company_document_pdf
                                : exhibitor.company_website, "_blank")
                        }
                        else{
                            Linking.openURL(exhibitor.company_document_pdf !== "none" ? exhibitor.company_document_pdf
                                : exhibitor.company_website)
                        }
                    }}
                >
                    <View
                        position={"absolute"}
                        bg={"#333"}
                        borderRadius={10}
                        aspectRatio={1}
                        height={150}
                        width={150}

                    >


                        <Image
                            height={150}
                            width={150}
                            borderRadius={10}
                            alt={"Logo of " + exhibitor.company_name}
                            source={{uri: exhibitor.logo}}
                            resizeMode='contain'

                        />
                        <View
                            position={'absolute'}
                            bottom={0}
                            w={"100%"}
                        >
                            <Text style={{backgroundColor: 'rgba(50, 50, 50, 0.5)'}}
                                  fontSize={'sm'}
                                  textAlign={'center'}
                                  borderBottomLeftRadius={10}
                                  borderBottomRightRadius={10}
                                  px={1}
                                  bold>
                                {exhibitor.company_name}
                            </Text>
                        </View>
                    </View>
                </Link>);


        }
    );


    return(
        <Layout withBackButton activePage={navigation.isFocused ? 'WelcomePack' : ''}>
            { isLoading ?
            <Center
                flex={1}
                bg={colors['background'][50]}
            >
                <View
                    borderColor={colors['backgroundLight'][50]}
                    py={10}
                    px={5}
                    maxW={{base : "auto",md: 700}}
                    shadow={{md: 9}}
                    borderRadius='lg'
                    w={'100%'}
                >
                    <ScrollView>

                        <Flex direction={{md: 'row'}} w={{md: '100%'}} maxW={900}
                              alignItems={{base: 'flex-start', md: 'center'}} px={{base : 5, md: 10}}>
                            <Button variant={'unstyled'} onPress={goToPreviousPage} ml={{base:-5, md:"auto"}}>
                                <AntDesign name="arrowleft" size={32} color="white"/>
                            </Button>
                            <Heading width={'100%'}
                                     size={'xl'}
                                     ml={{base:"auto", md:-16}}
                                     textAlign={{base: 'left', md: 'center'}}
                                     color={"#15A7BF"}
                            >
                                {t("Welcome_pack")}
                            </Heading>
                        </Flex>


                        <Heading textAlign={{base: "left",md:"center"}}                                  fontSize={"28px"}
                                 fontSize={26}
                                 my={{base : 2 ,md:"auto"}}
                                 mx={"auto"}
                                 px={{base : 5, md: 10}}
                                 width={{base : "100%", md: "75%"}}
                                 mt={5}
                                 mb={2}
                        >
                            {t('Prepare_your_participation')}
                        </Heading>


                        <Flex direction={"row"} justify={{base: 'flex-start', md: 'center'}} flexWrap={'wrap'}>
                            <Button
                                    onPress={() => {
                                        if(Platform.OS == 'web'){
                                            window.open( global.program_imcas, "_blank")
                                        }
                                        else{
                                            Linking.openURL(global.program_imcas)
                                        }
                                    }}
                                    w={{base : "90%",md: global.exhibitor_directory !== "none" ? "35%" : "70%"}}
                                    m={{base : "auto" ,md: 3}}
                                    my={2}>
                                {t("Congress_program")}
                            </Button>
                            {global.exhibitor_directory !== "none" ?
                                <Button
                                    onPress={() => {
                                        if(Platform.OS == 'web'){
                                            window.open( global.exhibitor_directory, "_blank")
                                        }
                                        else{
                                            Linking.openURL(global.exhibitor_directory)
                                        }
                                    }}
                                    w={{base : "90%",md: "35%"}}
                                    m={{base : "auto" ,md:3}}
                                    my={2}
                                >
                                    {t("Exhibitor_directory")}
                                </Button>
                                : null}
                        </Flex>


                        <Heading
                                 fontSize={26}
                                 textAlign={{base: "left",md:"center"}}
                                 mt={5}
                                 px={{base : 5, md: 10}}>{t("Explore partner offers")}</Heading>
                        <Flex
                            direction={'row'}
                            flexWrap={'wrap'}
                            justify={'center'}
                            align={'center'}
                            alignItems={'center'}
                        >
                            {renderCompanies}
                        </Flex>


                    </ScrollView>

                </View>
            </Center> :
                <Center flex={1}>
                    <Spinner color="#5766D6" />
                </Center>
            }
        </Layout>);

}
