import * as React from "react";
import Layout from "../layouts/Layout";
import {Center, Flex, Heading, ScrollView, Spinner, Text, View, useTheme} from "native-base";
import {useEffect, useState} from "react";
import HttpService from "../services/HttpService";
import StatsTopBlock from "../components/StatsTopBlock";
import StatsItemBlock from "../components/StatsItemBlock";
import { Ionicons } from '@expo/vector-icons';

export default function StatsScreen(props) {
    const [stats, setStats] = useState();
    const [topSessions, setTopSessions] = useState([]);
    const [topSessionsForNonPhysicians, setTopSessionsForNonPhysicians] = useState([]);
    const [topSessionsForPhysicians, setTopSessionsForPhysicians] = useState([]);
    const [topSessionsReplay, setTopSessionsReplay] = useState([]);
    const [worstSessionsReplay, setWorstSessionsReplay] = useState([]);
    const [worstSessions, setWorstSessions] = useState([]);
    const [totalDuration, setTotalDuration] = useState();
    const [visitorsByRoom, setVisitorsByRoom] = useState([]);
    const [visitorsByCompanyPage, setVisitorsByCompanyPage] = useState([]);
    const [visitorsCount, setVisitorsCount] = useState();
    const [visitorsFirstDay, setVisitorsFirstDay] = useState();
    const [visitorsSecondDay, setVisitorsSecondDay] = useState();
    const [visitorsThirdDay, setVisitorsThirdDay] = useState();
    const [visitorsReplay, setVisitorsReplay] = useState();
    const [visitorsLive, setVisitorsLive] = useState();
    const [launchAdView, setLaunchAdView] = useState();
    const [avgTimeSpent, setAvgTimeSpent] = useState();
    const [avgWatchTime, setAvgWatchTime] = useState();
    const [avgWatchTimeLive, setAvgWatchTimeLive] = useState();
    const [avgWatchTimeReplay, setAvgWatchTimeReplay] = useState();
    const [visitorsDuringCongress, setVisitorsDuringCongress] = useState();
    const [visitorsBeforeAndAfterCongress, setVisitorsBeforeAndAfterCongress] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [fetchError, setFetchError] = useState();
    const {colors} = useTheme();

    useEffect(() => {
        setIsLoading(true);
        HttpService.getData('stats/live/post-congress')
            .then(r => {
                setStats(r.data);
                setIsLoading(false);
            })
            .catch(err => {
                setFetchError(err);
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        if(stats) {
            if(stats.topSessions) {
                setTopSessions(stats.topSessions);
            }
            if(stats.topSessionsForNonPhysicians) {
                setTopSessionsForNonPhysicians(stats.topSessionsForNonPhysicians);
            }
            if(stats.topSessionsForPhysicians) {
                setTopSessionsForPhysicians(stats.topSessionsForPhysicians);
            }
            if(stats.topSessionsReplay) {
                setTopSessionsReplay(stats.topSessionsReplay);
            }
            if(stats.worstSessionsReplay) {
                setWorstSessionsReplay(stats.worstSessionsReplay);
            }
            if(stats.worstSessions) {
                setWorstSessions(stats.worstSessions);
            }
            if(stats.totalDuration) {
                setTotalDuration(stats.totalDuration);
            }
            if(stats.uVisitorsByRoom) {
                setVisitorsByRoom(stats.uVisitorsByRoom);
            }
            if(stats.uVisitorsCount) {
                setVisitorsCount(stats.uVisitorsCount);
            }
            if(stats.uVisitorsFirstDay) {
                setVisitorsFirstDay(stats.uVisitorsFirstDay);
            }
            if(stats.uVisitorsSecondDay) {
                setVisitorsSecondDay(stats.uVisitorsSecondDay);
            }
            if(stats.uVisitorsThirdDay) {
                setVisitorsThirdDay(stats.uVisitorsThirdDay);
            }
            if(stats.uVisitorsReplay) {
                setVisitorsReplay(stats.uVisitorsReplay);
            }
            if(stats.uVisitorsLive) {
                setVisitorsLive(stats.uVisitorsLive);
            }
            if(stats.uVisitorsByCompanyPage) {
                setVisitorsByCompanyPage(stats.uVisitorsByCompanyPage);
            }
            if(stats.avgTime) {
                setAvgWatchTime(stats.avgTime);
            }
            if(stats.avgTimeLive) {
                setAvgWatchTimeLive(stats.avgTimeLive);
            }
            if(stats.avgTimeReplay) {
                setAvgWatchTimeReplay(stats.avgTimeReplay);
            }
            if(stats.uVisitorsDuringCongress) {
                setVisitorsDuringCongress(stats.uVisitorsDuringCongress);
            }
            if(stats.uVisitorsBeforeAndAfterCongress) {
                setVisitorsBeforeAndAfterCongress(stats.uVisitorsBeforeAndAfterCongress);
            }

            setLaunchAdView(1920);
            setAvgTimeSpent('1h48');
        }
    }, [stats]);

    function renderUniqueStats() {
        let blocks = [];

        if(visitorsCount) {
            blocks.push(
                <StatsItemBlock text={'used the app'} score={visitorsCount} />
            );
        }
        if(visitorsLive) {
            blocks.push(
                <StatsItemBlock text={'watched live content'} score={visitorsLive} />
            );
        }
        if(visitorsReplay) {
            blocks.push(
                <StatsItemBlock text={'watched replay content'} score={visitorsReplay} />
            );
        }
        if(launchAdView) {
            blocks.push(
              <StatsItemBlock text={'ad impressions at app launch*'} score={launchAdView} noIcon />
            );
        }
        if(visitorsFirstDay) {
            blocks.push(
                <StatsItemBlock text={'livestream viewers on Friday'} score={visitorsFirstDay} />
            )
        }
        if(visitorsSecondDay) {
            blocks.push(
                <StatsItemBlock text={'livestream viewers on Saturday'} score={visitorsSecondDay} />
            )
        }
        if(visitorsThirdDay) {
            blocks.push(
                <StatsItemBlock text={'livestream viewers on Sunday'} score={visitorsThirdDay} />
            )
        }
        if(avgTimeSpent) {
            blocks.push(
                <StatsItemBlock text={'average time spent by user*'} score={avgTimeSpent} noIcon />
            );
        }
        if(totalDuration) {
            blocks.push(
                <StatsItemBlock text={'hours viewed in total'} score={Math.round(parseInt(totalDuration) / 3600)} noIcon />
            );
        }
        if(avgWatchTime) {
            blocks.push(
                <StatsItemBlock text={'average watch time'} score={Math.round(avgWatchTime / 60) + 'm'} noIcon />
            )
        }
        if(avgWatchTimeLive) {
            blocks.push(
                <StatsItemBlock text={'average watch time (live, '+visitorsDuringCongress+' users)'} score={Math.round(avgWatchTimeLive / 60) + 'm'} noIcon />
            )
        }
        if(avgWatchTimeReplay) {
            blocks.push(
                <StatsItemBlock text={'average watch time (replay, '+visitorsReplay+' users)'} score={Math.round(avgWatchTimeReplay / 60) + 'm'} noIcon />
            )
        }

        return blocks;
    }

    function renderListStats() {
        let blocks = [];

        if(topSessions) {
            const sessions = topSessions.map(s => {
                return <Text>
                    <Text bold bg={colors['backgroundLight'][300]} color={'white'} p={1} borderRadius={'md'}>{s.total_live}
                        <View mr={1} />
                        <Ionicons name="people" size={16} color="white" />
                    </Text> {s.title + ' - ' + s.subtitle}
                </Text>
            });
            blocks.push(
                <StatsTopBlock title={'Top sessions (live)'} items={sessions} />
            );
        }
        if(topSessionsReplay) {
            const sessions = topSessionsReplay.map(s => {
                return <Text>
                    <Text bold bg={colors['backgroundLight'][300]} color={'white'} p={1} borderRadius={'md'}>{s.total_replay}
                        <View mr={1} />
                        <Ionicons name="people" size={16} color="white" />
                    </Text> {s.title + ' - ' + s.subtitle}
                </Text>
            });
            blocks.push(
                <StatsTopBlock title={'Top sessions (replay)'} items={sessions} />
            );
        }
        if(worstSessions) {
            const sessions = worstSessions.map(s => {
                return <Text>
                    <Text bold bg={colors['backgroundLight'][300]} color={'white'} p={1} borderRadius={'md'}>{s.total_live}
                        <View mr={1} />
                        <Ionicons name="people" size={16} color="white" />
                    </Text> {s.title + ' - ' + s.subtitle}
                </Text>
            });
            blocks.push(
                <StatsTopBlock title={'Worst sessions (live)'} items={sessions} />
            );
        }
        if(worstSessionsReplay) {
            const sessions = worstSessionsReplay.map(s => {
                return <Text>
                    <Text bold bg={colors['backgroundLight'][300]} color={'white'} p={1} borderRadius={'md'}>{s.total_replay}
                        <View mr={1} />
                        <Ionicons name="people" size={16} color="white" />
                    </Text> {s.title + ' - ' + s.subtitle}
                </Text>
            });
            blocks.push(
                <StatsTopBlock title={'Worst sessions (replay)'} items={sessions} />
            );
        }
        if(topSessionsForNonPhysicians) {
            const sessions = topSessionsForNonPhysicians.map(s => {
                return <Text>
                    <Text bold bg={colors['backgroundLight'][300]} color={'white'} p={1} borderRadius={'md'}>{s.total_participants_non_physician}
                        <View mr={1} />
                        <Ionicons name="people" size={16} color="white" />
                    </Text> {s.title + ' - ' + s.subtitle}
                </Text>
            });
            blocks.push(
                <StatsTopBlock title={'Top sessions (non physicians, live & onsite)'} items={sessions} />
            );
        }
        if(topSessionsForPhysicians) {
            const sessions = topSessionsForPhysicians.map(s => {
                return <Text>
                    <Text bold bg={colors['backgroundLight'][300]} color={'white'} p={1} borderRadius={'md'}>{s.total_participants_physician}
                        <View mr={1} />
                        <Ionicons name="people" size={16} color="white" />
                    </Text> {s.title + ' - ' + s.subtitle}
                </Text>
            });
            blocks.push(
                <StatsTopBlock title={'Top sessions (physicians, live & onsite)'} items={sessions} />
            );
        }
        if(visitorsByCompanyPage) {
            const companies = [];
            for(let company in visitorsByCompanyPage) {
                companies.push(
                    <Text>
                        <Text bold bg={colors['backgroundLight'][300]} color={'white'} p={1} borderRadius={'md'}>{visitorsByCompanyPage[company]}
                            <View mr={1} />
                            <Ionicons name="people" size={16} color="white" />
                        </Text> {company}
                    </Text>
                );
            }
            blocks.push(
                <StatsTopBlock title={'Visitors by company page'} items={companies} />
            )
        }
        if(visitorsByRoom) {
            const rooms = [];
            for(let room in visitorsByRoom) {
                rooms.push(
                    <Text>
                        <Text bold bg={colors['backgroundLight'][300]} color={'white'} p={1} borderRadius={'md'}>{visitorsByRoom[room]}
                            <View mr={1} />
                            <Ionicons name="people" size={16} color="white" />
                        </Text> {room}
                    </Text>
                );
            }
            blocks.push(
                <StatsTopBlock title={'Visitors by room'} items={rooms} />
            )
        }

        return blocks;
    }

    if(isLoading) {
        return (
            <Layout withBackButton>
                <Center flex={1}>
                    <Spinner color="#5766D6"  />
                </Center>
            </Layout>
        );
    }
    else if(fetchError) {
        return (
            <Layout withBackButton>
                <Center flex={1}>
                    <Text>Sorry, we weren't able to load the content you are looking for.</Text>
                    <Text>{fetchError.message}</Text>
                </Center>
            </Layout>
        );
    }
    else if(stats) {
        return (
            <Layout withBackButton>
                <Heading size={"2xl"} textAlign={'center'}>Stats</Heading>
                <Text textAlign={'right'}>* : between September 22th and October 14th</Text>
                    <ScrollView w={'100%'} m={'auto'} maxW={1400}>
                        <Flex direction={"row"} wrap={'wrap'} alignItems={'baseline'} justifyContent={'center'}>
                            {renderUniqueStats()}
                        </Flex>
                        <Flex direction={"row"} wrap={'wrap'} alignItems={'baseline'} justifyContent={'center'}>
                            {renderListStats()}
                        </Flex>
                    </ScrollView>
            </Layout>
        );
    }
    else return (
        <Layout withBackButton>
            <Center flex={1}>
                <Spinner color="#5766D6"  />
            </Center>
        </Layout>
    );
}
