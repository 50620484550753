import React, {useEffect, useState} from 'react';
import HttpService from "../services/HttpService";
import {
    Center, Flex,
    Heading,
    Spinner,
    Text, useTheme, View, VStack,
} from "native-base";
import Layout from "../layouts/Layout";
import {Platform} from "react-native";
import Program from "../components/Program";
import {useTranslation} from "react-i18next";
import {Link, useFocusEffect, useIsFocused} from "@react-navigation/native";
import {useSelector} from "react-redux";
import AccountService from "../services/AccountService";
import Config from "../config.json";
import { MaterialIcons } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';


export default function ProgramScreen({navigation}) {
    const [sessions, setSessions] = useState([]);
    const [themes, setThemes] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const {list,congressUser} = useSelector(state => state.auth);
    const {t, i18n} = useTranslation();
    const {colors} = useTheme();
    const isFocused = useIsFocused();
    const timezone =  congressUser.timezone.replace(/[-_]/g, ' ');
    let fetchError = false;

    // Ping
    useEffect(() => {
        let interval;
        if(isFocused) {
            interval = AccountService.startPing(navigation, {page: 'program'});
        }
        return () => {
            clearInterval(interval);
        };
    }, [isFocused]);

    function getSessions() {
        return HttpService
            .getData('session/get/all')
            .catch(error => {
                fetchError = error.data;
            });
    }

    function getThemes() {
        let rawThemes = sessions.map(session => session.theme);
        rawThemes = rawThemes.filter((theme, index, self) => (
            index === self.findIndex((t) => {
                if(theme && t) return t.name === theme.name;
                else return false;
            })
        ));
        rawThemes.sort((a, b) => {
            return a.name > b.name
        });
        setThemes(rawThemes);
    }

    useEffect(() => {
        getSessions().then((response) => {
            setSessions(response.data.data);
        });
    }, []);

    useEffect(() => {
        if(sessions.length > 0) {
            getThemes();
        }
    }, [sessions]);

    if(fetchError) {
        return (
            <Layout activePage={navigation.isFocused ? 'Program' : ''}>
                <Center flex={1}>
                    <Text>{t("There_was_a_problem_loading_your_data")}</Text>
                </Center>
            </Layout>
        );
    }
    else if(Platform.OS === 'web') {
        return (
            <Layout activePage={navigation.isFocused ? t('Program') : ''}>
                <Heading size='2xl' textAlign={'center'}
                         position={'absolute'} top={2} left={'50%'} style={{transform:'translateX(-50%)'}}
                >{t('Program')}</Heading>

                <View my={4} />

                <Program
                    themes={themes}
                    userList={list}
                    isOpen={showFilters}
                />
            </Layout>
        );
    }
    else return (
            <Layout activePage={navigation.isFocused ? t('Program') : ''}>
                <Flex direction={'row'} align={'center'} justify={'space-between'} px={{md:2}} mt={{base:3, lg:0}}>
                    <Heading size={'xl'}>{t('Program')}</Heading>
                    <Link to={{screen: "PersonalInformations"}} >
                        <Flex direction={'row'} align={'center'} backgroundColor={colors['action'][50]} px={3} py={1} borderRadius={50} >
                            <Text fontSize={'sm'} color={'white'} mr={2}> {congressUser.timezone}</Text>
                            <MaterialCommunityIcons name="clock-time-eight" size={20} color={'#5766D6'} style={{
                                borderRadius : 12.5,
                                backgroundColor : 'white',
                                padding : 2,
                                overflow:'hidden'
                            }}/>
                        </Flex>
                    </Link>
                </Flex>

                <Program
                    themes={themes}
                    userList={list}
                    isOpen={showFilters}
                />

            </Layout>
        );
}
