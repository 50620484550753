import {Center, Checkbox, Flex, Heading, Image, Spacer, Text, useTheme, View} from "native-base";
import {format, parseISO} from "date-fns";
import React, {useEffect} from "react";
import LiveTag from "./LiveTag";
import ReplayTag from "./ReplayTag";
import {Link} from "@react-navigation/native";
import MyListCheckbox from "./MyListCheckbox";
import {ImageBackground, Platform} from "react-native";
import speakersPictureUrl from "../../assets/explore_speakers.jpg";
import {useSelector} from "react-redux";
import FullTag from "./FullTag";

export default function SessionCard(props) {
    const {colors} = useTheme();
    const {language, congressUser} = useSelector(state => state.auth);

    useEffect(() => {
        if (props.indexes) {
            props.setLiveIndexes(props.indexes);
        }
    }, []);

    function checkIsFull(){
        return props.indexes && props.item.room.seat_occupancy >= 95;
    }

    function renderTag() {
        let tag;
        if (props.indexes) tag = <LiveTag relative/>;
        else if (props.item.replayAvailable) tag = <ReplayTag relative/>;
        return (
            <Flex direction={'row'}>
                {checkIsFull() ? <FullTag relative/> : null}
                {tag}
            </Flex>)

    }

    function getLinkTo() {
        let screen = 'Session';
        let id = props.item.id;

        if(props.indexes && congressUser.physical_attendance) {
            screen = 'LiveStagePhysicalScreen';
            id = props.item.room.id;
        }
        else if(props.indexes && Platform.OS !== 'web') {
            screen = 'LiveStageScreenForMobile';
            id = props.item.room.id;
        }
        else if(props.indexes) {
            screen = 'LiveStageScreenForDesktop';
            id = props.item.room.id;
        }

        return ({
            screen: screen,
            params: {
                id: id
            }
        });
    }

    function getLinkOnPress() {
        if(props.indexes && Platform.OS === 'web') {
            return () => props.toggleLiveRoom(props.item.room.id);
        }
        else return null;
    }

    return (
        <Center>
            <Flex
                direction='row'
                justify={'space-between'}
                my={2}
                py={2}
                px={3}
                width='100%'
                maxW='500px'
            >
                <Flex
                    flex={2}
                    direction={'column'}
                    justify={'center'}
                >
                    <Text
                        fontSize={'sm'}
                    >
                        {language === "en" ? format(parseISO(props.item.datetime_begin), 'p') :
                            format(parseISO(props.item.datetime_begin), 'H:mm')}
                    </Text>
                    <Text
                        fontSize={'sm'}
                    >
                        {language === "en" ? format(parseISO(props.item.datetime_end), 'p') :
                            format(parseISO(props.item.datetime_end), 'H:mm')}
                    </Text>
                </Flex>

                <Flex
                    flex={6}
                    justify={'center'}
                >
                    <Heading
                        fontSize='sm'>{props.item.subtitle}{props.item.subtitle ? ' - ' : ''}{props.item.title}</Heading>

                    <View mt={2}>
                        <Text fontSize={'sm'}>{props.item.room?.translations[0]?.name}</Text>
                    </View>
                    {renderTag()}

                </Flex>


                <Link
                    to={getLinkTo()}
                    onPress={getLinkOnPress()}
                    style={{
                        width: '95%',
                        height: '100%',
                        position: 'absolute'
                    }}
                />

                <View style={{position: 'absolute', bottom: 6, right: 6}}>
                    <MyListCheckbox
                        item={{id: props.item.id, type: 'LectureSession'}}
                        size={'sm'}
                        isChecked={props.isChecked}
                    />
                </View>
            </Flex>
        </Center>
    );
}
