import {
    Box,
    Button,
    Center, CheckIcon,
    Flex, Heading,
    Modal,
    NativeBaseProvider,
    ScrollView, Select,
    Spinner,
    Text, useTheme,
    View,
    VStack
} from "native-base";
import * as React from "react";
import Layout from "../layouts/Layout";
import {useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {StyleSheet, Dimensions, Platform} from "react-native";
import Map from "../components/Map";
import {useSelector} from "react-redux";
import AccountService from "../services/AccountService";
import SvgVenueLevel3Map from "../maps/level3";
import SvgVenueLevel2Map from "../maps/level2";
import HttpService from "../services/HttpService";
import CompanyCardMap from "../components/CompanyCardMap";
import {useIsFocused} from "@react-navigation/native";
import {ReactNativeZoomableView} from '@openspacelabs/react-native-zoomable-view';
import Select2Form from "../components/Select2Form";
import * as Device from "expo-device";
import Config from "../config.json";
import LiveTag from "../components/LiveTag";
import pusher from "../pusher";
import {MaterialIcons} from "@expo/vector-icons";

export default function MapScreen({navigation, ...props}) {
    const [fetchErr, setFetchErr] = useState();
    const [deviceType, setDeviceType] = useState();
    const {t, i18n} = useTranslation();
    const {congress} = useSelector(state => state.global);
    const [showExhibitorDetails, setShowExhibitorDetails] = useState(false);
    const [showRoomDetails, setShowRoomDetails] = useState(false);
    const [currentVenueLevel, setCurrentVenueLevel] = useState(3);
    const [exhibitors, setExhibitors] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [selectedExhibitor, setSelectedExhibitor] = useState(null);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [highlightedBooth, setHighlightedBooth] = useState(0);
    const [highlightedRoom, setHighlightedRoom] = useState(0);
    const isFocused = useIsFocused();
    const {congressUser} = useSelector(state => state.auth);
    const [venueLevels, setVenueLevels] = useState([
        {level: 2, label: 'Level 2'},
        {level: 3, label: 'Level 3'},
    ]);
    const [formRender, setFormRender] = useState(false);
    const [sizeSelect, setSizeSelect] = useState([0, 0]);
    const [ready, setReady] = useState(false);
    const mapRef = useRef(null);
    const {colors} = useTheme();
    // Ping
    useEffect(() => {
        getCompanies()
            .catch(error => console.log('Error: ' + error));

        getRooms()
            .catch(error => console.log('Error: ' + error));

        getDeviceType().then(type => setDeviceType(type));

        return () => {
            setSizeSelect([0, 0])
        };
    }, []);

    useEffect(() => {
        let interval;
        if(isFocused) {
            const channelLive = pusher.subscribe('onsite-room');

            channelLive.bind('update', function (data) {
                getRooms()
                    .catch(error => console.log('Error: ' + error));
            });

            interval = AccountService.startPing(navigation, {page: 'map'});

            if (props.route.params?.id && props.route.params?.type === 'booth' && exhibitors.length > 0) {
                selectBooth(props.route.params.id);
                resetRoom();
            } else setReady(true);
            if (props.route.params?.id && props.route.params?.type === 'room' && rooms.length > 0) {
                selectRoom(props.route.params.id);
                resetBooth();
            } else setReady(true);
        }

        return () => {
            setSelectedExhibitor(null);
            setShowExhibitorDetails(false);
            setHighlightedBooth(0);
            setCurrentVenueLevel(0);

            pusher.unsubscribe('onsite-room');
            clearInterval(interval);
        }
    }, [isFocused]);

    async function getDeviceType() {
        return await Device.getDeviceTypeAsync();
    }

    function handleClickOnRoomInfo() {
        return navigation.navigate(getScreenName(), {id: selectedRoom.id})
    }

    const renderForm = () => {
        return (
            <View>
                <Select
                    styles={{
                        marginTop: Dimensions.get('window').width < Dimensions.get('window').height ? 9 : 5,
                        height: Platform.OS === 'web' ? '100%' : sizeSelect[1] + 2,
                        width: sizeSelect[0] + 30,
                        opacity: 0.75,
                    }}
                    selectedValue={selectedExhibitor ? selectedExhibitor.booth_id : (selectedRoom ? selectedRoom.id : null)}
                    placeholder={'Room, exhibitor, booth...'}
                    _selectedItem={{
                        bg: colors.white,
                        paddingRight:10,
                        _icon: {
                            color: '#fff'
                        },
                        _text: {
                            overflow: "hidden",
                            noOfLines:3
                        },
                        startIcon: <MaterialIcons name={"radio-button-checked"} size={20} color={colors['action'][50]}></MaterialIcons>,
                    }}
                    _item={{
                        bg:colors.white,
                        paddingRight:10,
                        _text: {
                            overflow: "hidden",
                            noOfLines:3
                        },
                        startIcon:<MaterialIcons name={"radio-button-unchecked"} size={20} color={colors}></MaterialIcons>
                    }}
                    _customDropdownIconProps={{
                        size: 5
                    }}
                    _actionSheetContent={{
                        bg: colors.white
                    }}
                    p={2}
                    my={2}
                    onValueChange={(itemValue) => navigation.navigate('Map', {
                        id: itemValue,
                        type: (itemValue < 30000) ? 'room' : 'booth'
                    })}
                >
                    <Select.Item label="" value=""/>
                    {exhibitors.concat(rooms).map(c => (
                        <Select.Item index={c.booth_id} label={c.map_label} value={c.booth_id} key={c.booth_id}/>))}
                </Select>
            </View>
        );
    };

    useEffect(() => {
        if (props.route.params?.id && props.route.params?.type === 'booth' && exhibitors.length > 0) {
            selectBooth(props.route.params.id);
            resetRoom();
        }
        if (props.route.params?.id && props.route.params?.type === 'room' && rooms.length > 0) {
            selectRoom(props.route.params.id);
            resetBooth();
        }
        if (exhibitors.length > 0 && rooms.length > 0) {
            setFormRender(true);
        }
    }, [exhibitors, rooms]);

    useEffect(() => {
        if (props.route.params?.id && props.route.params?.type === 'booth' && exhibitors.length > 0) {
            selectBooth(props.route.params.id);
            resetRoom();
        }
        if (props.route.params?.id && props.route.params?.type === 'room' && rooms.length > 0) {
            selectRoom(props.route.params.id);
            resetBooth();
        }
    }, [props.route.params?.id]);

    const getCompanies = () => {
        return HttpService
            .getData('exhibitor/get/map')
            .then(response => {
                setExhibitors(response.data.data);
            });
    }

    const getRooms = () => {
        return HttpService
            .getData('room/get/all')
            .then(response => {
                setRooms(response.data.data);
            });
    }

    const selectBooth = (id) => {
        setReady(false);
        setShowExhibitorDetails(false);
        const exhibitor = exhibitors.find(e => parseInt(e.booth_id) === parseInt(id));
        if (exhibitor) {
            setCurrentVenueLevel(+exhibitor.booth_level);
            setSelectedExhibitor(exhibitor);
            setShowExhibitorDetails(true);
            setHighlightedBooth(exhibitor.booth_id);
            setReady(true);
        } else {
            setReady(true);
        }
    }

    const selectRoom = (id) => {
        setReady(false);
        setShowRoomDetails(false);
        const room = rooms.find(e => parseInt(e.id) === parseInt(id));
        if (room) {
            setCurrentVenueLevel(+room.level);
            setSelectedRoom(room);
            setShowRoomDetails(true);
            setHighlightedRoom(room.id);
            setReady(true);
        } else {
            setReady(true);
        }
    }

    const resetBooth = () => {
        setHighlightedBooth(0);
        setShowExhibitorDetails(false);
        setSelectedExhibitor(null);
    }

    const resetRoom = () => {
        setHighlightedRoom(0);
        setShowRoomDetails(false);
        setSelectedRoom(null);
    }

    const changeCurrentLevel = (id) => {
        setCurrentVenueLevel(+id);
    }

    const showBoothInfo = (id) => {
        navigation.navigate('Map', {id: id, type: 'booth'});
    }

    const showRoomInfo = (id) => {
        navigation.navigate('Map', {id: id, type: 'room'});
    }


    function renderMap() {
        if (congress) {
            if (Platform.OS === 'web') {
                return (
                    <View w={'60%'} h={'100%'}>
                        {currentVenueLevel === 2 &&
                            <SvgVenueLevel2Map ref={mapRef}
                                               showBoothInfo={showBoothInfo}
                                               highlightedBooth={highlightedBooth}
                                               showRoomInfo={showRoomInfo}
                                               highlightedRoom={highlightedRoom}
                            ></SvgVenueLevel2Map>
                        }
                        {currentVenueLevel === 3 &&
                            <SvgVenueLevel3Map ref={mapRef}
                                               showBoothInfo={showBoothInfo}
                                               highlightedBooth={highlightedBooth}
                                               showRoomInfo={showRoomInfo}
                                               highlightedRoom={highlightedRoom}
                            ></SvgVenueLevel3Map>
                        }
                    </View>
                );
            } else {
                return (
                    <View
                        flex={1}
                        w={(deviceType === 2 && Dimensions.get('window').width > Dimensions.get('window').height) ? '70%' : '100%'}
                        h={(deviceType === 2 && Dimensions.get('window').width > Dimensions.get('window').height) ? '100%' : '80%'}
                    >
                        {currentVenueLevel === 2 &&
                            <ReactNativeZoomableView
                                maxZoom={5}
                                minZoom={0.8}
                                zoomStep={0.5}
                                initialZoom={1}
                                bindToBorders={true}
                            >
                                <SvgVenueLevel2Map ref={mapRef}
                                                   showBoothInfo={showBoothInfo}
                                                   highlightedBooth={highlightedBooth}
                                                   showRoomInfo={showRoomInfo}
                                                   highlightedRoom={highlightedRoom}
                                ></SvgVenueLevel2Map>
                            </ReactNativeZoomableView>
                        }
                        {currentVenueLevel === 3 &&
                            <ReactNativeZoomableView
                                maxZoom={5}
                                minZoom={0.8}
                                zoomStep={0.5}
                                initialZoom={(deviceType === 2 && Dimensions.get('window').width > Dimensions.get('window').height) ? 1.15 : 1}
                                bindToBorders={true}
                            >
                                <SvgVenueLevel3Map ref={mapRef}
                                                   showBoothInfo={showBoothInfo}
                                                   highlightedBooth={highlightedBooth}
                                                   showRoomInfo={showRoomInfo}
                                                   highlightedRoom={highlightedRoom}
                                ></SvgVenueLevel3Map>
                            </ReactNativeZoomableView>
                        }
                    </View>
                );
            }

        }
    }

    function renderDetails() {
        if (!ready) {
            return (
                <Center flex={1}><Spinner color="#5766D6"/></Center>
            );
        } else if (showExhibitorDetails && selectedExhibitor) {
            if (Platform.OS === 'web' || (deviceType === 2 && Dimensions.get('window').width > Dimensions.get('window').height)) {
                return (<>
                        <CompanyCardMap company={selectedExhibitor} isChecked={false}/>
                    </>
                );
            } else return (
                <View>
                    <CompanyCardMap company={selectedExhibitor} isChecked={false}/>
                </View>
            );
        } else if (showRoomDetails && selectedRoom) {
            return (
                <Flex>
                    <Text bold fontSize={{
                        base: 'md',
                        md: '2xl'
                    }} style={{flexShrink: 1}}>{selectedRoom.name}
                        {selectedRoom.live_current_session ?
                            <View>
                                <Text fontSize={!props.relative ? {base: "2xs", xl: 'xs'} : "xs"}
                                      bg={'red.500'} borderRadius={'sm'} px={4} py={1} ml={2}
                                      top={Platform.OS === 'web' ? 0 : 1}>
                                    {"live".toUpperCase()}
                                </Text>
                            </View>
                            : <></>}
                    </Text>
                    {selectedRoom.live_current_session ?
                        <Text noOfLines={2} size={'sm'} w={'100%'} style={{flexShrink: 1}}
                              onPress={handleClickOnRoomInfo}>
                            {selectedRoom.live_current_session.title + ' - ' + selectedRoom.live_current_session.subtitle}
                        </Text>

                        :
                        <Text>This room has no live session at the moment.</Text>
                    }
                </Flex>
            );
        }
    }

    function getScreenName() {
        let screen = 'LiveStagePhysicalScreen';

        if (Platform.OS !== 'web' && !congressUser.physical_attendance) {
            screen = 'LiveStageScreenForMobile';
        } else if (!congressUser.physical_attendance) {
            screen = 'LiveStageScreenForDesktop';
        }

        return screen;
    }

    if (Platform.OS === 'web' || (deviceType === 2 && Dimensions.get('window').width > Dimensions.get('window').height)) {
        return (
            <Layout activePage={navigation.isFocused ? t('Map') : ''} withBackButton>
                <Heading size={'2xl'} textAlign={'center'}
                         position={'absolute'} top={3} left={'50%'} style={{transform: 'translateX(-50%)'}}
                >{t('Map')}</Heading>
                <Center flex={1}>
                    <Flex flexDirection={'row'} flex={1} w={'100%'} px={2} pt={10}>

                        {renderMap()}

                        <View w={'40%'}>
                            <Flex
                                w={"100%"}
                                h={"150px"}
                            >
                                <Select
                                    selectedValue={currentVenueLevel}
                                    accessibilityLabel={t('Venue level')}
                                    placeholder={'Venue level'}
                                    onValueChange={(itemValue) => changeCurrentLevel(+itemValue)}
                                    my={2}
                                    w={{base: '50%', md: 'auto'}}
                                    onLayout={(event) => {
                                        const {width, height} = event.nativeEvent.layout;
                                        setSizeSelect([width, height]);
                                    }}
                                >
                                    {venueLevels.map((venueLevel) => (
                                        <Select.Item label={venueLevel.label} value={venueLevel.level}
                                                     key={venueLevel.level}
                                        />))}
                                </Select>

                                {formRender && renderForm()}

                            </Flex>

                            {renderDetails()}

                        </View>

                    </Flex>
                </Center>
            </Layout>
        );
    } else return (
        <Layout activePage={navigation.isFocused ? t('Map') : ''} withBackButton>
            <Center flex={1}>
                <Flex flexDirection={'column'} w={'100%'} flex={1}>
                    <View mb={4}>
                        {congress &&
                            (
                                <Flex
                                    flexDirection={'column'} w={'100%'}
                                >
                                    <Select
                                        _selectedItem={{
                                            bg: colors.white,
                                            paddingRight:2,
                                            _icon: {
                                                color: '#fff'
                                            },
                                            _text: {
                                                overflow: "hidden",
                                            },
                                            startIcon: <MaterialIcons name={"radio-button-checked"} size={20} color={colors['action'][50]}></MaterialIcons>,
                                        }}
                                        _item={{
                                            bg:colors.white,
                                            paddingRight:10,
                                            _text: {
                                                overflow: "hidden",
                                            },
                                            startIcon:<MaterialIcons name={"radio-button-unchecked"} size={20} color={colors}></MaterialIcons>
                                        }}
                                        _customDropdownIconProps={{
                                            size: 5
                                        }}
                                        _actionSheetContent={{
                                            bg: colors.white
                                        }}
                                        selectedValue={currentVenueLevel}
                                        accessibilityLabel={t('Venue level')}
                                        placeholder={'Venue level'}
                                        onValueChange={(itemValue) => changeCurrentLevel(+itemValue)}
                                        my={2}
                                        w={{base: '50%', lg: 'auto'}}
                                        onLayout={(event) => {
                                            const {width, height} = event.nativeEvent.layout;
                                            setSizeSelect([width, height]);
                                        }}
                                    >
                                        {venueLevels.map((venueLevel) => (
                                            <Select.Item label={venueLevel.label} value={venueLevel.level}
                                                         key={venueLevel.level}
                                            />))}
                                    </Select>
                                    {formRender && renderForm()}
                                </Flex>
                            )
                        }
                    </View>
                    {renderMap()}
                    {renderDetails()}
                </Flex>
            </Center>
        </Layout>
    );
}

