import {INIT_CONGRESS, CLEAR_CONGRESS} from "../actions/types";

const initialState = {congress: null};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case INIT_CONGRESS:
            return {
                ...state,
                congress: payload.congress,
            }
        case CLEAR_CONGRESS:
            return {
                ...state,
                congress: null,
            }
        default:
            return state;
    }
}
