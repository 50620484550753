import React from 'react';
import {Link} from "@react-navigation/native";
import {Flex, Text, useTheme} from "native-base";
import {MaterialIcons} from "@expo/vector-icons";
import {useTranslation} from "react-i18next";

const ViewAll = (props) => {
    const {t, i18n} = useTranslation();
    const {colors} = useTheme();


    return (
        <Link to={{screen: props.to}} >
            <Flex direction={'row'} align={'center'} backgroundColor={colors['action'][50]} px={3} py={1} borderRadius={25} >
                <Text fontSize={'xs'} color={'white'} fontWeight={'bold'} mr={3}> {t('View_all')}</Text>
                <MaterialIcons name="more-vert" size={24} color={'#5766D6'} style={{
                    borderRadius : 15,
                    backgroundColor : 'white',
                    padding : 2,
                    overflow:'hidden'
                }}/>
            </Flex>

        </Link>
    )

}

export default ViewAll;
