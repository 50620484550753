import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Slider from "../components/Slider";
import Session from "./Session";
import {useSelector} from "react-redux";
import HttpService from "../services/HttpService";
import AccountService from "../services/AccountService";
import * as Device from "expo-device";

const UserSession = (props) => {

    const {id, userSessions, sliderMobileWidth, sliderDesktopWidth,} = props
    const {t, i18n} = useTranslation();
    const {list} = useSelector(state => state.auth);
    const [deviceType,setDeviceType] = useState()

    useEffect(() => {
        async function setLayout() {
            const type = await getDeviceType();
            setDeviceType(type);
        }
        setLayout();
    }, []);

    async function getDeviceType() {
        return await Device.getDeviceTypeAsync();
    }

    let isLive = false;
    let isSoon = false;
    let isReplay = false;

    const itemContent = (item, index) => {
        const {subtitle} = item
        item?.liveCurrent?.length > 0 && item?.physical_only === 0 ? isLive = true : isLive = false;
        item.replay ? isReplay = true : isReplay = false;

        let isChecked = false;
        if (list.find(listItem => listItem.entity_id === item.id)) isChecked = true;
        return (
            <Session
                item={item}
                isLive={isLive}
                showHours={isLive}
                isSoon={isSoon}
                isReplay={isReplay}
                roomId={item.room_session_id}
                withListButton={true}
                isChecked={isChecked}
                screen={`SpeakerProfile/${id}`}
            />
        );
    }

    return (
        <Slider
            sliderTitle={t('Lectures')}
            data={userSessions}
            sliderMobileWidth={sliderMobileWidth*(deviceType == 1 ? 0.675 : 1)}
            sliderMobileHeight={200}
            sliderDesktopWidth={sliderDesktopWidth}
            itemContent={itemContent}
            btnHeight={120}
            videos
        />
    );
};

export default UserSession;

UserSession.defaultProps = {
    sliderMobileWidth: 240,
    sliderDesktopWidth: 240,

}
