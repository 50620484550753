import React, {useState} from 'react';
import {
    Pressable,
    Flex, Spacer, Text, useTheme, Image, HStack, Avatar, Button
} from "native-base";
import {Link} from "@react-navigation/native";
import LinkBtn from "./LinkBtn";
import {Platform, TouchableOpacity} from "react-native";
import PartnerModal from "./PartnerModal";

const PartnerListCard = ({partner}) => {
    const {colors} = useTheme();
    const [showModal, setShowModal] = useState(false);

    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };

    function handlePressOnOpenModal() {
        return setShowModal(true);
    }

    const renderCompanyListCard = () => {
        if (Platform.OS === 'web') {
            return (
                <Flex
                    key={uniqueId()}
                    justifyContent="center"
                    alignItems="center"
                    w="25%"
                    m={5}
                >
                    {/*<LinkBtn
                    to={{
                        screen: 'CompanyProfile',
                        params: {id: partner.id}
                    }}
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        zIndex: 3,
                        elevation: 3,
                    }}></LinkBtn>*/}
                    <Pressable
                        onPress={handlePressOnOpenModal} style={{cursor: 'pointer'}}
                        width={'100%'}
                    >
                        <Image
                            size={'lg'}
                            resizeMethod={"scale"}
                            width={'100%'}
                            source={{uri: partner.picture ? partner.picture : null}}
                            alt="logo of scientific partners"
                        />
                    </Pressable>


                    {/* <Flex justifyContent="center" alignItems="center">
                            <LinkBtn
                                to={{
                                    screen: 'CompanyProfile',
                                    params: {id: partner.id}
                                }}>
                                <Text textAlign='center' fontSize="sm" bold>{partner?.name?.toUpperCase()}</Text>
                            </LinkBtn>
                        </Flex> */}
                    <Spacer/>
                </Flex>
            )
        } else {
            return (
                <>
                    {/*<LinkBtn to={{screen: 'CompanyProfile', params: {id: partner.id}}}
                             style={{
                                 width: '100%',
                                 height: '100%',
                                 position: 'absolute',
                                 zIndex: 3,
                                 elevation: 3,
                             }}
                    >
                    </LinkBtn>*/}
                    <Pressable width={'100%'} onPress={handlePressOnOpenModal} style={{cursor: 'pointer'}}>
                        <Flex w="100%" py={2}>
                            <HStack alignItems="center" width="100%">
                                <Avatar size="md" source={{uri: partner.picture ? partner.picture : null}}/>
                                <Flex flex={(4)} width={'100%'} flexWrap={'wrap'} p={2}>
                                    <Text fontSize="sm" bold ml={2} width={'100%'}>{partner?.name?.toUpperCase()}</Text>
                                </Flex>
                            </HStack>
                        </Flex>
                    </Pressable>
                </>
            )
        }
    }
    return (
        <>
            {renderCompanyListCard()}
            <PartnerModal partner={partner} showModal={showModal} setShowModal={setShowModal}/>
        </>)
};

export default PartnerListCard;

