import React, {useEffect, useState} from 'react';
import {
    AspectRatio,
    Avatar,
    Box,
    Center,
    Container,
    Divider,
    Flex,
    Heading,
    HStack,
    Image,
    Text,
    VStack,
    useTheme, Badge, View,
} from "native-base";
import SocialMedia from "./SocialMedia";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import HttpService from "../services/HttpService";
import LiveChatButton from "./LiveChatButton";
import MyListCheckbox from "./MyListCheckbox";
import {useWindowDimensions} from 'react-native';
import {useTranslation} from "react-i18next";
import { MaterialIcons } from '@expo/vector-icons';



const UserCard = ({speakerId, isChecked, user, use}) => {
    const {colors} = useTheme();
    const {t, i18n} = useTranslation();
    const [truncate, setTruncate] = useState(true)
    const {first_name, last_name, picture, country, user_specialty, biostatement_en, banner_url} = user;
    const id = speakerId;

    function toggleTruncate() {
        return setTruncate(!truncate);
    }

    return (
        <>
            <View>

                <Box
                    overflow="hidden"
                    height={150}
                    width={'100%'}
                >
                    <AspectRatio width={'100%'} ratio={16 / 9}>
                        <View
                            width='100%'
                            height={200}
                        >
                            <Image
                                source={{
                                    uri: banner_url,
                                }}
                                width={'100%'}
                                height={'100%'}
                                alt="banner picture"
                            />
                        </View>
                    </AspectRatio>
                </Box>
                {/* Profile Picture*/}
                <Flex
                    position="absolute"
                    top={{base: "45%" ,xl:"60%"}}
                    px={5}
                    zIndex={10}
                    py="0"
                    w={'100%'}
                    flexDirection='row'
                >
                    <Avatar
                        size={120}
                        resizeMode={"contain"}
                        borderRadius={80}
                        source={{
                            uri: picture,
                        }}
                        alt="speaker picture"
                    />
                </Flex>
            </View>

            {/*Add to my list button*/}
            {/* Chat Button */}
            <Flex
                direction="row"
                width={'100%'}
                position="absolute"
                top={150}
                justify={'flex-end'}
            >
                {/*<LiveChatButton size={34} />*/}
            </Flex>


            {/* User Description */}
            <Box marginTop={5} mx={5}>
                <VStack mt={10}>
                    <View style={{marginLeft: 10}}>
                        <View style={{position: 'absolute', bottom: 0, zIndex: 3, elevation: 3, right:0}}>
                            <MyListCheckbox
                                item={{id: id, type: 'User'}}
                                size={'sm'}
                                isChecked={isChecked}/>
                        </View>
                    </View>
                    <Flex direction={{base:"column",xl:"row"}} align={{base:"flex-start",xl:"flex-end"}} justify={"space-between"}>
                        <View>
                            <Flex direction={"row"} align={"center"} flexWrap={"wrap"} mt={2}>
                                <Heading size="lg">{first_name} </Heading>
                                <Heading size={"lg"}>{last_name}</Heading>

                            </Flex>


                            <Flex direction={"row"} mt={{base:0,md:-2,xl:-1}}>
                                <Flex direction={"row"} align={"center"} opacity={0.75} >
                                    <MaterialIcons name="location-pin" size={18} color="#1F2357" /><Heading size="sm" fontWeight={"regular"}> {country}</Heading>
                                </Flex>
                                <Flex direction={"row"} align={"center"} opacity={0.75} ml={8}>
                                    <MaterialIcons name="work" size={18} color="#1F2357"/><Heading size="sm" fontWeight={"regular"}> {user_specialty}</Heading>
                                </Flex>
                            </Flex>
                        </View>


                        <HStack mt={{base:1,md:0}}>
                            <SocialMedia data={user} base={36} md={36}/>
                        </HStack>
                    </Flex>

                    <Divider my="2" bg={colors['background'][100]}/>
                    {biostatement_en !== '' &&
                    (<>
                        <Text fontSize={{base: "sm", md: 'md'}} noOfLines={truncate ? 5 : false}
                              textAlign={"justify"}
                              maxW={1000}
                              opacity={0.75}
                              w="100%">{biostatement_en}</Text>
                        <Text
                            fontSize={{base: 'sm', md: 'md'}}
                            onPress={toggleTruncate}
                            color={colors['action'][50]}
                            style={{lineHeight: 21, marginTop: 5}}
                        >
                            {truncate ? t('Read_more') : t('Read_less')}
                        </Text>
                    </>)}
                </VStack>
            </Box>
        </>
    )
};

export default UserCard;
