import {Path} from "react-native-svg";
import * as React from "react";
import {useEffect, useState} from "react";

export default function MapBooth(props) {
    const [color, setColor] = useState(props.style?.fill ?? false);
    const [strokeWidth, setStrokeWidth] = useState(1);
    const [strokeColor, setStrokeColor] = useState('#000');
    const [id, setId] = useState(null);

    useEffect(() => {
        if(props.id && typeof props.id === 'string') {
            const id = props.id.split('-')[3];
            setId(parseInt(id, 10));
        }
    }, []);

    useEffect(() => {
        if(props.highlightedRoom === id) {
            setColor('#10f700');
            setStrokeWidth(4);
            setStrokeColor('#10f700');
        }
        else{
            setColor(props.style?.fill ?? false);
            setStrokeWidth(1);
            setStrokeColor('#000');
        }
    }, [id, props.highlightedRoom]);

    function showRoomInfo() {
        setStrokeWidth(2);
        setStrokeColor('#10f700');

        return props.showRoomInfo(id);
    }

    return (
        <Path
            onPressIn={showRoomInfo}
            onClick={showRoomInfo}
            style={{
                fill: color ?? props.style?.fill,
                stroke: strokeColor ?? (props.style?.stroke ?? ''),
                strokeWidth: strokeWidth ?? (props.style?.strokeWidth ?? ''),
                strokeMiterlimit: props.style?.strokeMiterlimit ?? '',
            }}
            transform={props.transform}
            d={props.d}
        />
    );
}
