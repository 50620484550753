import * as React from "react";
import {useTranslation} from "react-i18next";
import HttpService from "../services/HttpService";
import {useEffect, useState} from "react";
import News from "./News";
import {Button, Flex, Heading, View} from "native-base";
import Constants from "expo-constants";
import Config from "../config.json"
import {Entypo, MaterialIcons} from '@expo/vector-icons';
import pusher from "../pusher";

function HomeNews(props) {
    const {t, i18n} = useTranslation();
    const [news, setNews] = useState();
    const [reload, setReload] = useState(false);

    const getLiveNews = () => {
        HttpService
            .getData(`news/get/homepage`)
            .then(response => {
                setNews(response.data.data);
            })
            .catch(error => {
                console.log('Error: ' + error)
            })
    }

    useEffect(() => {
        getLiveNews()
    }, [reload]);

    useEffect(() => {
        const channelLiveNews = pusher.subscribe('live-news');
        channelLiveNews.bind('update', function (data) {
            setReload(prev => !prev);
        });

    }, []);

    return (
        <View my={2} mt={2}>

            {
                news?.length > 0 &&
                <>
                    <Flex direction={'row'} mb={4} alignItems={'center'} ml={{base:0,lg:3}}>
                        <Entypo name="megaphone" size={32} color="#1F2357" />
                        <Heading size={'md'} ml={2}>
                            {t('News_feed')}
                        </Heading>
                    </Flex>
                    <News
                        itemMobileWidth={300}
                        itemMobileHeight={110}
                        itemDesktopWidth={420}
                        itemDesktopHeight={110}
                        data={news}
                    />
                </>

            }
        </View>
    );
}

export default HomeNews


