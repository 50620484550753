import React from 'react';
import {Box, Flex, HStack, Link, useBreakpointValue, useTheme} from "native-base";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import {Platform} from "react-native";
import HttpService from "../services/HttpService";

const SocialMedia = ({data, base, md, website}) => {
    const {colors} = useTheme();

    const {facebook_url, instagram_username, linkedin_url, twitter_username} = data;

    const cleanTwitterUrl = twitter_username?.replace('@', '');

    const addHit = (id, type) => {
        HttpService
            .getData(`exhibitor/hit/${id}/${type}`)
            .then(response => {
                console.log('Hit: ' + type)
            })
            .catch(error => {
                console.log('Error: ' + error)
            })
    }

    function addFacebookHit() {
        return addHit(data.id, 'contact_facebook');
    }

    function addInstagramHit() {
        return addHit(data.id, 'contact_instagram');
    }

    function addLinkedinHit() {
        return addHit(data.id, 'contact_linkedin');
    }

    function addTwitterHit() {
        return addHit(data.id, 'contact_twitter');
    }

    function addWebsiteHit() {
        return addHit(data.id, 'contact_website');
    }

    let instagramUrl ='';
    if(instagram_username && instagram_username != "undefined") {
        if (instagram_username.startsWith('www')) {
            instagramUrl = 'https://' + instagram_username;
        } else if (instagram_username.startsWith('https://')) {
            instagramUrl =instagram_username;
        } else {
            instagramUrl = 'https://instagram.com/'+ instagram_username;
        }
    }
    return (

        <Flex flexDirection="row" alignItems="center">
            {(facebook_url && facebook_url != "undefined") &&
                (<Box mr={5}>
                    <Link href={facebook_url} onPress={addFacebookHit} isExternal>
                        {
                            Platform.OS !== 'web' ?
                                <MaterialCommunityIcons name="facebook" size={base} color="#1F2357"/> :
                                <MaterialCommunityIcons name="facebook" size={md} color="#1F2357"/>
                        }
                    </Link>
                </Box>)}

            {(instagram_username && instagram_username != "undefined") &&
                (<Box mr={5}>
                    <Link href={instagramUrl}
                          onPress={addInstagramHit} isExternal>
                        {
                            Platform.OS !== 'web' ?
                                <MaterialCommunityIcons name="instagram" size={base} color="#1F2357"/> :
                                <MaterialCommunityIcons name="instagram" size={md} color="#1F2357"/>
                        }
                    </Link>
                </Box>)}

            {(linkedin_url && linkedin_url != "undefined") &&
                (<Box mr={5}>
                    <Link href={linkedin_url} isExternal onPress={addLinkedinHit}>
                        {
                            Platform.OS !== 'web' ?
                                <MaterialCommunityIcons name="linkedin" size={base} color="#1F2357"/> :
                                <MaterialCommunityIcons name="linkedin" size={md} color="#1F2357"/>
                        }
                    </Link>
                </Box>)}

            {(twitter_username && twitter_username != "undefined") &&
                (<Box mr={5}>
                    <Link href={`https://twitter.com/${cleanTwitterUrl}`} isExternal
                          onPress={addTwitterHit}>
                        {
                            Platform.OS !== 'web' ?
                                <MaterialCommunityIcons name="twitter" size={base} color="#1F2357"/> :
                                <MaterialCommunityIcons name="twitter" size={md} color="#1F2357"/>
                        }
                    </Link>
                </Box>)}

            {website &&
                (<Link href={website} isExternal mr={5} onPress={addWebsiteHit}>
                    {
                        Platform.OS !== 'web' ?
                            <MaterialCommunityIcons name="web" size={base} color="#1F2357"/> :
                            <MaterialCommunityIcons name="web" size={md} color="#1F2357"/>
                    }
                </Link>)}
        </Flex>
    );
};

export default SocialMedia;
