import React, {useEffect, useMemo, useState} from 'react';
import {
    Box,
    Center,
    Divider,
    Flex,
    Image,
    Text,
    VStack,
    ScrollView,
    useTheme, Badge, Pressable, Avatar, AspectRatio, Link, View, Modal, Button, Spinner
} from "native-base";
import SocialMedia from "./SocialMedia";
import WhatsappButton from "./WhatsappButton";
import {FlatList, Platform} from "react-native";
import {useTranslation} from "react-i18next";
import * as Linking from "expo-linking";
import {FontAwesome, MaterialCommunityIcons} from "@expo/vector-icons";
import VideoPlayer from "./VideoPlayer";
import HttpService from "../services/HttpService";
import {useIsFocused, useNavigation} from "@react-navigation/native";
import Config from "../config.json";

const CompanyCard = ({company, isChecked}) => {
        const {
            company_name,
            picture,
            company_video_banner_url,
            company_profile,
            country,
            sponsorship_level,
            website,
            booth_number_clean,
            company_banner_url,
            sponsor_company_name,
            booth_id,
        } = company;

        const {colors} = useTheme();
        const {t, i18n} = useTranslation();
        let categories = company.categories;
        if (categories && categories.length > 0) {
            categories = categories.map(category => category.name)
        }
        let contactsWhatsapp = company.contacts;
        if (contactsWhatsapp && contactsWhatsapp.length > 0) {
            contactsWhatsapp = contactsWhatsapp.filter(contact => contact.whatsapp_number && contact.whatsapp_number.length > 0);
        }

        let contactsEmail = company.contacts;
        if (contactsEmail && contactsEmail.length > 0) {
            contactsEmail = contactsEmail.filter(contact => contact.email && contact.email.length >0);
        }

        const [boothNumber, setBoothNumber] = useState();
        const text = [company_name, boothNumber] //props for AvatarWithText
        const [truncate, setTruncate] = useState(true);
        const [showMailModal, setShowMailModal] = useState(false);
        const [showWhatsappModal, setShowWhatsappModal] = useState(false);
        const [ready, setReady] = useState(false);
        const navigation = useNavigation();
        const isFocused = useIsFocused();

        useEffect(() => {
            setReady(false);
            // Wait for proper banner render
            setTimeout(() => {
                setReady(true);
            }, 500);

            return () => {setReady(false)}
        }, [isFocused]);

        const uniqueId = () => {
            const dateString = Date.now().toString(36);
            const randomness = Math.random().toString(36).substr(2);
            return dateString + randomness;
        };

        const addHit = (id, type) => {
            HttpService
                .getData(`exhibitor/hit/${id}/${type}`)
                .then(response => {
                    console.log('Hit: ' + type)
                })
                .catch(error => {
                    console.log('Error: ' + error)
                })
        }

        function renderBanner() {
            if (company_video_banner_url !== '') {
                return (
                        <AspectRatio w="100%" ratio={4 / 1} zIndex={1}>
                            <VideoPlayer file={company_video_banner_url}
                                         aspectRatio={'4:1'}
                                         isLooping noControls
                            />
                        </AspectRatio>

                );
            } else if (company_banner_url !== '') {
                return (
                        <AspectRatio w="100%" ratio={4 / 1} zIndex={1}>
                            <Image
                                source={{uri: company_banner_url}}
                                alt="Company banner"
                                width={'100%'}
                                height={'100%'}
                                resizeMode={'cover'}
                            />
                        </AspectRatio>
                );
            }
        }

        function toggleTruncate() {
            return setTruncate(!truncate);
        }

        function goToMapBooth() {
            if(Config.isMapReady) {
                return navigation.navigate('Map', {id: booth_id, type: 'booth'});
            }
        }

        function openEmailModal() {
            return setShowMailModal(true);
        }

        function addWebsiteHit() {
            return addHit(company.id, 'contact_website');
        }

        function hideModal() {
            return setShowMailModal(false);
        }

        function contactByEmail(email) {
           addHit(company.id, 'contact_email');
           return Linking.openURL(`mailto:${email}`)
        }

        function openWhatsappModal() {
            return setShowWhatsappModal(true);
        }

        function hideWhatsappModal() {
            return setShowWhatsappModal(false);
        }

        const renderWhatsappDisableButton = useMemo(() => {
            if (contactsWhatsapp && contactsWhatsapp.length > 0) {
                if (Platform.OS === 'web') {
                    return (
                        <Button marginRight={{md: 2}}
                                borderRadius={0}
                                pl={"20%"}
                                py={1}
                                pr={5}
                                onPress={openWhatsappModal}
                                borderBottomRightRadius={"full"}>
                            <Flex direction='row' align={'center'}>
                                <Text fontSize={'sm'} px={2} color="white">
                                    {t('Contact_by_Whatsapp')}
                                </Text>
                                <FontAwesome name="whatsapp" size={28} color="white"/>
                            </Flex>
                        </Button>)
                } else {
                    return(<Button
                        borderRadius={0}
                        pl={{base: "35%",lg : "17.5%"}}
                        pr={{base:5,md:10}}
                        onPress={openWhatsappModal}
                        borderBottomRightRadius={"full"}
                    >
                        <Flex direction='row' justify={"flex-end"} align={"center"} pr={0} w={"100%"} >
                            <Text fontSize={{base:"xs",md:'sm'}} px={2} w={"auto"} noOfLines={2} color={'white'}>
                                {t('Contact_by_Whatsapp')}
                            </Text>
                            <FontAwesome name="whatsapp" size={24} color="white" />
                        </Flex>
                    </Button>)
                }
            } else {
                return null
            }

        });

        if(!ready) {
            return (
                <Center flex={1}>
                    <Spinner color="#5766D6"  />
                </Center>
            );
        }
        else return (
            <>
                <Box position={"relative"}>
                    {renderBanner()}
                    <Center
                        position="absolute"
                        top={{base: "35%" ,lg:"55%"}}
                        px={5}
                        zIndex={10}

                    >
                        <Flex flex={1} wrap={'wrap'}>
                            <Avatar source={{uri: picture}}
                                    size={Platform.OS === 'web' ? 140 : 'xl'}
                                    borderColor={colors['background'][200]}
                                    borderWidth={1}/>
                        </Flex>
                    </Center>
                    <Flex
                        direction={'row'}
                        width={'100%'}
                    >
                        {renderWhatsappDisableButton}
                    </Flex>
                </Box>

                <Flex direction={{base:"column",xl:"row"}} align={{base:"flex-start",xl:"flex-end"}} justify={"space-between"}>
                    <Box px={5} style={{marginTop: 40}}>
                        {/* Can't add company to list so we hide the add to list button for now
                    <View style={{marginLeft: 130}}>
                        <View style={{position: 'absolute', bottom: 0, zIndex: 3, elevation: 3}}>
                            <MyListCheckbox
                                item={{id: company.id, type: 'Exhibitor'}}
                                size={'sm'}
                                isChecked={isChecked}/>
                        </View>
                    </View>*/}
                        <Text bold mb={{base:0,xl:-1}}
                              fontSize={{base: 'xl', md: '3xl'}}
                        >{sponsor_company_name ? sponsor_company_name?.toUpperCase() : company_name?.toUpperCase()}</Text>
                        <Flex direction={'row'} alignItems={'center'}>
                            <Text fontSize={{base: 'md', md: 'xl'}} textTransform={'uppercase'} fontWeight={"light"} opacity={0.75}>
                                {t('Booth_number') + ' : '}
                            </Text>
                            <Button px={2} py={0} onPress={goToMapBooth}>
                                {booth_number_clean}
                            </Button>
                        </Flex>

                    </Box>

                    <Flex direction='row' alignItems={"center"} my={{base:2,xl:0,}} px={5}>
                        <SocialMedia data={company} website={website} base={30} md={36} />
                        {(contactsEmail && contactsEmail.length > 0) &&
                        <Pressable p={0} m={0} onPress={openEmailModal}>
                            <MaterialCommunityIcons name="email-outline" size={Platform.OS === 'web' ? 42 : 32}
                                                    color="#1F2357"/>
                        </Pressable>}
                    </Flex>
                </Flex>

                <Flex mx={5} w={"auto"}>
                    <Divider my="2" bg={colors['background'][100]}/>
                    {company_profile ?
                        (<>
                            <Text textAlign="justify"
                                  fontSize={{base: "xs", md: 'md'}}
                                  noOfLines={truncate ? 5 : false}
                                  maxW={1000}
                                  opacity={0.75}
                                  w="100%">
                                {company_profile}
                            </Text>
                            <Text
                                fontSize={{base: 'xs', md: 'md'}}
                                onPress={toggleTruncate}
                                color={colors['action'][50]}
                                style={{ marginTop: 5,}}
                                opacity={0.75}
                                width={"auto"}
                                alignSelf='flex-start'
                            >
                                {truncate ? t('Read_more') : t('Read_less')}
                            </Text>
                        </>) : null}
                    {website ?
                        (<Link  href={website} isExternal mr={5} mt={5} ml={1}  onPress={addWebsiteHit}>
                            <Text border={1}
                                  borderRadius={5}
                                  borderColor={colors['action'][50]}
                                  color={colors['action'][50]}
                                  alignSelf='flex-start'

                                  p={2}>
                                {t('Go_to_website')}
                            </Text>
                        </Link>) :null}

                    <VStack>
                        <Flex direction={"row"} wrap={"wrap"} my={2}>
                            <Badge m={1} colorScheme="default" alignSelf="center" variant="outline">
                                {sponsorship_level}
                            </Badge>
                            {country &&
                                (<Badge m={1} colorScheme="success" alignSelf="center" variant="outline">
                                    {country[0].name}
                                </Badge>)}

                            {categories && categories.length > 0 && categories.map(category => (
                                <Badge m={1} alignSelf="center" variant="outline"
                                       key={uniqueId()}>
                                    {category}
                                </Badge>))}
                        </Flex>
                    </VStack>
                </Flex>
                <Modal isOpen={showMailModal} onClose={hideModal}>
                    <Modal.Content maxW={600}>
                        <Modal.CloseButton/>
                        <Modal.Body  mt={5}>
                            <ScrollView maxH={(Platform.OS === 'web' ? 600 : 400)}>
                                <FlatList mt={(Platform.OS === 'web' ? 10 : 50)}  data={contactsEmail}  keyExtractor={(item) => item.id} renderItem={({item}) => (
                                    <Flex
                                        w={'100%'}
                                        m={(Platform.OS === 'web' ? 3 : 1)}
                                        borderColor={colors['background'][100]}
                                        borderWidth={1}
                                        borderRadius='md'
                                        bg={colors['background'][100]}
                                    >
                                        <Flex w={'100%'}>
                                            <Flex justifyContent="center" alignItems="center" py={2}>
                                                <Text fontSize={( Platform.OS === 'web' ? 'md' : "sm")} bold><Text style={{textTransform: "uppercase"}} bold>{item.last_name}</Text> {item.first_name}</Text>
                                                <Text fontSize={(Platform.OS === 'web' ? 'sm' : "xs")} italic>{item.role}</Text>
                                            </Flex>
                                            <Flex w={(Platform.OS === 'web' ? '70%' : '90%')} flexDirection={"row"} alignItems={"center"} ml={1} mb={1} >
                                                <MaterialCommunityIcons id={company.id + item.last_name} name="email-outline" size={Platform.OS === 'web' ? 42 : 34}
                                                                        color="#1F2357"/>
                                                <Pressable onPress={() => contactByEmail(item.email)} w={(Platform.OS === 'web'? '100%' : '85%')}>
                                                    <Text numberOfLines={1} ellipsizeMode="tail"  mx={1} overflowX={"hidden"} fontSize={(Platform.OS === 'web' ? 'md' : 'sm')} underline={true}>{item.email}</Text>
                                                </Pressable>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                )}>
                                </FlatList>
                            </ScrollView>
                        </Modal.Body>
                    </Modal.Content>
                </Modal>
                <Modal isOpen={showWhatsappModal} onClose={hideWhatsappModal}> {
                    <Modal.Content maxW={600}>
                        <Modal.CloseButton/>
                        <Modal.Body mt={4}>
                            <ScrollView maxH={(Platform.OS === 'web' ? 600 : 400)}>
                                <FlatList mt={(Platform.OS === 'web' ? 10 : 50)}  data={contactsWhatsapp} keyExtractor={(item) => item.id} renderItem={({item}) => (
                                    <Flex
                                        w={'100%'}
                                        m={(Platform.OS === 'web' ? 3 : 1)}
                                        borderColor={colors['background'][100]}
                                        borderWidth={1}
                                        borderRadius='md'
                                        bg={colors['background'][100]}
                                    >
                                        <Flex w={'100%'}>
                                            <Flex justifyContent="center" alignItems="center" py={2}>
                                                <Text fontSize={( Platform.OS === 'web' ? 'md' : "sm")} bold><Text style={{textTransform: "uppercase"}} bold>{item.last_name}</Text> {item.first_name}</Text>
                                                <Text fontSize={(Platform.OS === 'web' ? 'sm' : "xs")} italic>{item.role}</Text>
                                            </Flex>
                                            <Flex w={'60%'}>
                                                <WhatsappButton exhibitor_id={company.id} live_whatsapp={item.whatsapp_number}></WhatsappButton>
                                            </Flex>
                                        </Flex>
                                    </Flex>)}>
                                </FlatList>
                            </ScrollView>
                        </Modal.Body>
                    </Modal.Content>
                }
                </Modal>
            </>
        )
    }
;

export default CompanyCard;
