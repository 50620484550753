import * as React from "react";
import {
    Animated,
    Dimensions,
    Platform,
} from "react-native";
import {Button, Heading, ScrollView, Text, useTheme, View} from "native-base";
import {useEffect, useRef, useState} from "react";
import { MaterialIcons } from '@expo/vector-icons';


const {width: windowWidth} = Dimensions.get('window');

function Slider(props) {
    const {
        data,
        sliderTitle,
        itemContent,
        sliderMobileWidth,
        sliderMobileHeight,
        sliderDesktopWidth,
        sliderDesktopHeight,
        sliderMarginX,
        itemMobileSpace,
        itemDesktopSpace,
        btnSize,
        btnHeight,
        videos
    } = props

    // Data for desktop rendering
    const [viewWidth, setViewWidth] = useState();
    const containerViewRef = useRef(null)
    const animatedViewRef = useRef(null);
    const scrollAnim = useRef(new Animated.Value(0)).current
    const [scrollPosition, setScrollPosition] = useState(0);
    const [scrollIndex, setScrollIndex] = useState(0)
    const [indexMax, setIndexMax] = useState(0);
    const [disabledLeftBtn, setDisabledLeftBtn] = useState(true)
    const [disabledRightBtn, setDisabledRightBtn] = useState(false)
    const {colors} = useTheme();


    /*
    * Only for desktop slider
    * Set indexMax
    * Trigger when view's width is set
    * (view's width is set on container view, on onLayout props)
    */
    useEffect(() => {
        if (viewWidth) setIndexMax(data?.length - (Math.floor(viewWidth / sliderDesktopWidth)));
    }, [viewWidth]);

    useEffect(() => {
        if(indexMax <= 0) setDisabledRightBtn(true);
        else setDisabledRightBtn(false);
    }, [indexMax]);

    /*
     * Only for desktop slider
     * Next button Desktop slider
     */
    const goToNext = () => {
        if (scrollIndex < indexMax) {
            setScrollIndex(prev => prev + 1)
            setScrollPosition(prev => prev + sliderDesktopWidth)
            setDisabledLeftBtn(false)
        }
        if (scrollIndex === indexMax - 1) {
            setDisabledRightBtn(true)
        }
    }
    /*
     * Only for desktop slider
     * Previous button Desktop slider
     */
    const goToPrev = () => {
        if (scrollIndex > 0) {
            setScrollIndex(prev => prev - 1)
            setScrollPosition(prev => prev - sliderDesktopWidth)
            setDisabledRightBtn(false)
        }
        if (scrollIndex === 1) {
            setDisabledLeftBtn(true)
        }
    }

    function setViewportWidth() {
        return setViewWidth(containerViewRef.current?.getBoundingClientRect().width);
    }

    /*
     * Trigger scroll animation
     */
    useEffect(() => {
        Animated.timing(
            scrollAnim,
            {
                toValue: scrollPosition,
                duration: 500,
                useNativeDriver: false
            }
        ).start();
    }, [scrollPosition]);

    return (
        <>
            {
                Platform.OS !== "web" ?
                    // Mobile slider
                    (
                        <>
                            {sliderTitle ? <Text fontSize={"sm"} m={2} color={'darkblue.800'}>{sliderTitle}</Text> : null}
                            <ScrollView
                                data={data}
                                keyExtractor={item => item.id}
                                horizontal
                                snapToInterval={sliderMobileWidth}
                            >
                                {
                                    data?.length > 0 && data.map((item, index) => {
                                        return <View
                                            key={item.id}
                                            // justifyContent="center"
                                            alignItems="center"
                                            w={sliderMobileWidth}
                                            h={sliderMobileHeight}
                                            px={itemMobileSpace}
                                            mx={sliderMarginX}
                                            // borderWidth={1}
                                            // borderColor={"white"}
                                        >
                                            {itemContent(item, index)}
                                        </View>
                                    })
                                }
                            </ScrollView>
                        </>) :
                    // Desktop slider
                    (
                        <>
                            <Text fontSize={"sm"} m={2} color={'darkblue.800'}>{sliderTitle}</Text>
                            <View
                                ref={containerViewRef}
                                onLayout={setViewportWidth}
                                overflow="hidden"
                                mx={"10"}
                            >
                                <Animated.View
                                    ref={animatedViewRef}
                                    style={{
                                        flexDirection: "row",
                                        right: scrollAnim,
                                    }}
                                >
                                    <>
                                        {
                                            data?.length > 0 && data.map((item, index) => {
                                                return (
                                                    <View
                                                        key={index.toString()}
                                                        w={sliderDesktopWidth}
                                                        h={sliderDesktopHeight}
                                                        px={itemDesktopSpace}
                                                        mx={sliderMarginX}
                                                        // borderWidth={1}
                                                        // borderColor={"white"}
                                                    >
                                                        {itemContent(item, index)}
                                                    </View>
                                                )
                                            })
                                        }
                                    </>
                                </Animated.View>

                            </View>
                            {disabledLeftBtn ? <></> :
                                <Button
                                    variant={"unstyled"}
                                    bg={colors['action'][50]}
                                    top={(btnHeight/2+ (videos ? 50 : 0))+'px'}
                                    height={'40px'}
                                    width={'40px'}
                                    left={videos ? 0 : -15 +'px'}
                                    position={"absolute"}
                                    zIndex={3}
                                    elevation={3}
                                    borderRadius={'50%'}
                                    style={{
                                        transform: [{ translateY: -20 }]
                                    }}
                                    onPress={goToPrev}
                                >
                                    <MaterialIcons name="arrow-left" size={50} color="white" />
                                </Button>
                            }
                            {disabledRightBtn ? <></> :
                                <Button
                                    variant={"unstyled"}
                                    bg={colors['action'][50]}
                                    top={(btnHeight/2 + (videos ? 50 : 0))+'px'}
                                    height={'40px'}
                                    width={'40px'}
                                    position={"absolute"}
                                    borderRadius={'50%'}
                                    right={videos ? 0 : -15 +'px'}
                                    zIndex={3}
                                    elevation={3}
                                    style={{
                                        transform: [{ translateY: -20 }]
                                    }}
                                    onPress={goToNext}
                                >
                                    <MaterialIcons name="arrow-right" size={50} color="white" />
                                </Button>
                            }
                        </>)
            }
        </>
    );
}

export default Slider

Slider.defaultProps = {
    sliderMobileWidth: 150,
    sliderMobileHeight: "auto",
    sliderDesktopWidth: 350,
    sliderDesktopHeight: "auto",
    itemMobileSpace: 1,
    itemDesktopSpace: 2,
    btnSize: 32,
    btnHeight: "auto",
    sliderTitle: null,
}


