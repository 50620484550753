import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Platform} from "react-native";
import HttpService from "../services/HttpService";
import Constants from "expo-constants";
import {Center, Flex, Heading, ScrollView, Spinner, Text, useTheme, View} from "native-base";
import Session from "../components/Session";
import Layout from "../layouts/Layout";
import CountResult from "../components/CountResult";
import {useFocusEffect, useIsFocused} from "@react-navigation/native";
import Config from "../config.json";
import pusher from "../pusher";

function LiveSessionListScreen(props) {

    const {t, i18n} = useTranslation();
    const {user} = useSelector(state => state.auth);
    const {list} = useSelector(state => state.auth);
    const [currentLiveRoomConferences, setCurrentLiveRoomConferences] = useState([]);
    const [currentLiveConferences, setCurrentLiveConferences] = useState([]);
    const [fetchError, setFetchError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const isFocused = useIsFocused();
    const {colors} = useTheme();
    let isLive = false;
    
    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };

    const getCurrentLiveConferences = () => {
        setIsLoading(true);
        HttpService
            .getData('room/get/liveSessions')
            .then(response => {
                setCurrentLiveRoomConferences(response.data.data);

                const arr = response.data.data.map(r => {
                    return r.live_current_session
                });
                setCurrentLiveConferences(arr);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                setFetchError(error);
                setIsLoading(false);
            })
    }

    useEffect(() => {
        getCurrentLiveConferences();
    }, []);

    useEffect(() => {
        const channelLive = pusher.subscribe('onsite-room');
        channelLive.bind('update', function (data) {
            getCurrentLiveConferences();
        });

        return () => {
            pusher.unsubscribe('onsite-room');
        };
    }, [isFocused]);

    /*
     * Return an array of conferences sorted by user's themes preferences
     */
    const sortedCurrentLiveConferences = () => {
        let temp = [];
        let sortedArray = []
        let copyArray = [...currentLiveConferences]

        for (let j = 1; j < 5; j++) {
            for (let i = 0; i < copyArray.length; i++) {
                if (user[`themes_${j}`] === copyArray[i].theme_id) {
                    temp = copyArray.splice(i, 1);
                    sortedArray = [...sortedArray, ...temp]
                }
            }
        }
        return [...sortedArray, ...copyArray];
    }

    const renderItemContent = (item, index) => {
        item?.liveCurrent.length > 0 ? isLive = true : isLive = false;
        let isChecked = false;
        if (list.find(listItem => listItem.entity_id === item.id)) isChecked = true;

        return (
            <Session
                item={item}
                isLive
                screen={'LiveSessionList'}
                key={item.id}
                cardWidth={240}
                cardMobileHeight={'100%'}
                cardMobileWidth={'100%'}
            />
        )
    }

    const renderLiveSession = () => {
        if (sortedCurrentLiveConferences()?.length > 0) {
            return (
                <ScrollView
                    width="100%"
                    maxWidth={{md: 900}}
                    margin={{md: 'auto'}}
                >
                    {
                        Platform.OS === 'web' ?
                            // Desktop
                            <Flex direction='row' wrap='wrap'>
                                {sortedCurrentLiveConferences().map(item =>
                                    <View key={uniqueId()} p={2} margin={{md: 'auto'}}>
                                        {renderItemContent(item)}
                                    </View>)}
                            </Flex> :
                            // Mobile
                            <Flex direction='row' wrap='wrap'>
                                {sortedCurrentLiveConferences().map(item =>
                                    <View key={uniqueId()} p={2} width={'50%'}>
                                        {renderItemContent(item)}
                                    </View>)}
                            </Flex>
                    }

                </ScrollView>
            );
        } else if (isLoading) {
            return (
                <Center h={'100%'} w={'100%'}>
                    <Spinner color="#5766D6" />
                </Center>
            );
        } else return <></>;
    }

    const renderHeader = () => {
        if (Platform.OS === 'web') {
            return (
                <Flex
                    w={'100%'}
                    align={'center'}
                    _dark={{borderColor: colors['backgroundLight'][50],}}
                    borderBottomWidth={0.5}
                    p={2}
                >
                    <Heading size={'2xl'}>{t('Livestream_sessions')}</Heading>
                    <Flex w={'100%'} direction={'row'} justify={'space-around'} align={'flex-end'}>
                        <View>
                            <CountResult result={sortedCurrentLiveConferences()?.length}/>
                        </View>
                    </Flex>
                </Flex>
            );
        } else return (
            <Flex>
                <Heading>{t('Livestream_sessions')}</Heading>
                <Center paddingBottom={2}>
                    <CountResult result={sortedCurrentLiveConferences()?.length}/>
                </Center>
            </Flex>);
    }

    return (
        <Layout withBackButton activePage={props.navigation.isFocused ? 'LiveSessionList' : ''}>
            {renderHeader()}
            {renderLiveSession()}
        </Layout>);

}

export default LiveSessionListScreen





