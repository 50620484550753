import React from "react";
import {Badge, Box, Text, View} from "native-base";

export default function CounterTag({count, position}) {
    return(
            <Badge
                colorScheme="info"
                alignSelf="center"
                position={position}
                top={2}
                right={{base: 2, md: 4}}
                px={1}
                borderRadius={'sm'}
                bg={"#5766D6"}
            >
                <Text fontSize={{base:'2xs',xl:'xs'}} color={'white'}>{count}</Text>
            </Badge>
    );
}


CounterTag.defaultProps = {
    position:'absolute'
}
