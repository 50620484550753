import {
    Button,
    Center, Checkbox,
    CheckIcon,
    Flex,
    Heading,
    HStack, Input, Modal,
    ScrollView,
    Select,
    Spinner,
    Text, theme,
    useTheme,
    View,
    VStack
} from "native-base";
import React, {useEffect, useRef, useState, useContext} from "react";
import HttpService from "../services/HttpService";
import {format} from "date-fns";
import LiveTag from "./LiveTag";
import ReplayTag from "./ReplayTag";
import {Link} from "@react-navigation/native";
import {useTranslation} from "react-i18next";
import MyListCheckbox from "./MyListCheckbox";
import ProgramFilters from "./ProgramFilters";
import ProgramDayButtonForDesktop from "./ProgramDayButtonForDesktop";
import {useSelector} from "react-redux";
import Config from "../config.json";
import FullTag from "./FullTag";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import {Platform, StyleSheet, ImageBackground, Animated, PanResponder} from "react-native";
import * as ScreenOrientation from "expo-screen-orientation";
import * as Device from "expo-device";
import pusher from "../pusher";

export default function Program(props) {
    const [sessions, setSessions] = useState([]);
    const [filteredSessions, setFilteredSessions] = useState([]);
    const [labs, setLabs] = useState([]);
    const [activeDay, setActiveDay] = useState('');
    const [fetchError, setFetchError] = useState(false);
    const [showLabsModal, setShowLabsModal] = useState(false);
    const [activeFilterCount, setActiveFilterCount] = useState(0);
    const {congressUser} = useSelector(state => state.auth);
    const [landscape, setLandscape] = useState(null);
    const [deviceType,setDeviceType] = useState();
    const [filterDay, setFilterDay] = useState([]);
    const {t, i18n} = useTranslation();
    const {colors} = useTheme();
    const [calendarHourWidth,setCalendarHourWidth] = useState(0)
    // scroll positions are not stateful because we don't want scroll to cause rerender
    const horizontalScrollPosition = useRef(0);
    const verticalScrollPosition = useRef(0)
    const pos = useRef({x : 0,y:0})
    const scrollX = useRef(null);
    const totalW = useRef(0);
    const totalH = useRef(0);
    const scrollY = useRef(null);
    const timesRef = useRef(null);
    const liveSession = useRef([]);
    const currentDay = format(new Date(), 'y-MM-dd');
    const type = Config.type;
    const pan = useRef(new Animated.ValueXY()).current;
    const panResponder = useRef(
        PanResponder.create({
            onMoveShouldSetPanResponder: (event, gestureState) => {
                return (Math.abs(gestureState.vx) > 0.1 || Math.abs(gestureState.vy) > 0.1);
            },
            onPanResponderGrant: () => {
                pan.setOffset({
                    x: pan.x._value,
                    y: pan.y._value
                });
            },
            onPanResponderMove: (event, gestureState) => {
                // Modify those values to accelerate or decelerate the move speed
                const ratioSpeed = 1.5;
                if(Platform.OS !== 'web'){
                    const calcVector = (axe,ratio) => {
                        const sizeCalendar = axe === 'x' ? totalW.current : totalH.current;
                        const velocity = (axe === 'x' ? gestureState.dx : gestureState.dy) * ratio;
                        const initialPosition = (axe === 'x' ?  pos.current.x : pos.current.y) + velocity;
                        if(initialPosition >= 0 ){ // Manage Overflow Left
                            return velocity - initialPosition;
                        }
                        else if(initialPosition <= -sizeCalendar){ // Manage Overflow Right
                            const overflow = initialPosition + sizeCalendar;
                            return velocity - overflow;
                        }
                        else {
                            return velocity
                        }
                    }

                    pan.setValue({
                        x: calcVector('x',ratioSpeed),
                        y: calcVector('y',1)
                    })
                }
                else{
                    scrollX.current?.scrollTo({
                        x: horizontalScrollPosition.current - (gestureState.vx*10),
                        animated: false
                    });
                    scrollY.current?.scrollTo({
                        y: verticalScrollPosition.current - (gestureState.vy*10),
                        animated: false
                    });
                    timesRef.current?.scrollTo({
                        x: horizontalScrollPosition.current - (gestureState.vx*10),
                        animated: false
                    });
                }
            },
            onPanResponderRelease: () => {
                pan.flattenOffset();
                pos.current.x = pan.x._value
                pos.current.y =  pan.y._value


            }
        })
    ).current;
    const timezone = congressUser.timezone.replace(/[-_]/g, ' ');
    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };

    function getSessions() {
        HttpService
            .getData('session/get/allForCalendar')
            .then(response => {
                setSessions(response.data);
            })
            .catch(error => {
                setFetchError(error);
            })
    }

    async function getDeviceType() {
        return await Device.getDeviceTypeAsync();
    }

    // We fetch data from API once
    useEffect(() => {
        getSessions();

        pusher.subscribe('onsite-room');

        pusher.bind('update', function (data) {
            getSessions();
        });

    }, []);

    useEffect(() => {
        async function setLayout() {
            const orientation = await ScreenOrientation.getOrientationAsync();

            if (orientation === 3 || orientation === 4) {
                setLandscape(true);
            } else setLandscape(false);

            const type = await getDeviceType();
            setDeviceType(type);
            setCalendarHourWidth(type === 1 ? 150 : 200) ;

        }
        setLayout();

    }, []);



    // After data is fetched, we set data for default display once (unfiltered)
    useEffect(() => {
        if (sessions.length > 0) {
            const days = Object.keys(sessions[0]);
            if (days.find(day => day === currentDay)) {
                setActiveDay(currentDay);
            } else {
                setActiveDay(days[0]);
            }
            setFilteredSessions(sessions);
            if (sessions[1]) setLabs(sessions[1]);
        }
    }, [sessions]);

    // After default display is ready, we scroll to the first live session
    useEffect(() => {
        if (liveSession.current.length > 0) {
            scrollY.current?.scrollTo({
                y: liveSession.current[0]?.parentElement?.offsetTop,
                animated: true
            });
            scrollX.current?.scrollTo({
                x: liveSession.current[0].offsetLeft - calendarHourWidth, // to leave space for room column
                animated: true
            });
        }
    }, [filteredSessions, activeDay]);


    function applyFilters({title, theme, access}) {
        // this is the only way to truly create a copy of an array of objects in JS
        let filteredByTheme = JSON.parse(JSON.stringify(sessions));
        let filterCount = 0;

        if (theme !== '') {
            for (const day in filteredByTheme[0]) {
                if (day !== 'throughoutCongress') {
                    for (const room in filteredByTheme[0][day]) {
                        if (room !== 'data') {
                            for (const session in filteredByTheme[0][day][room]) {
                                if (filteredByTheme[0][day][room][session] && filteredByTheme[0][day][room][session].theme?.name !== theme) {
                                    filteredByTheme[0][day][room][session].hidden = true;
                                } else filteredByTheme[0][day][room][session].hidden = false;
                            }
                        }
                    }

                }
            }
            filterCount++;
        }

        let filteredByAccess = JSON.parse(JSON.stringify(filteredByTheme));

        if (access) {
            for (const day in filteredByAccess[0]) {
                if (day !== 'throughoutCongress') {
                    for (const room in filteredByAccess[0][day]) {
                        if (room !== 'data') {
                            for (const session in filteredByAccess[0][day][room]) {
                                if (filteredByAccess[0][day][room][session] && !filteredByAccess[0][day][room][session].access) {
                                    filteredByAccess[0][day][room][session].hidden = true;
                                } else filteredByAccess[0][day][room][session].hidden = false;
                            }
                        }
                    }
                }
            }
            filterCount++;
        }

        let filteredByTitle = JSON.parse(JSON.stringify(filteredByAccess));

        if (title !== '') {
            for (const day in filteredByTitle[0]) {
                if (day !== 'throughoutCongress') {
                    for (const room in filteredByTitle[0][day]) {
                        if (room !== 'data') {
                            for (const session in filteredByTitle[0][day][room]) {
                                if (filteredByTitle[0][day][room][session]
                                    && (
                                        !filteredByTitle[0][day][room][session].subtitle?.toLowerCase().includes(title.toLowerCase())
                                        &&
                                        !filteredByTitle[0][day][room][session].title?.toLowerCase().includes(title.toLowerCase())
                                    )
                                ) {
                                    filteredByTitle[0][day][room][session].hidden = true;
                                } else filteredByTitle[0][day][room][session].hidden = false;
                            }
                        }
                    }
                }
            }
            filterCount++;
        }

        setFilteredSessions(filteredByTitle);
        setActiveFilterCount(filterCount);
    }

    function changeActiveDay(day) {
        // We expect day to be a string formatted as yyyy-mm-dd
        setActiveDay(day);
    }

    /*
     * returns sessions that are not hidden after applying filters
     */
    function filteredSessionsDisplayed () {
        let arrayDay = [];
        let arraySessions = {};
        for (const day in filteredSessions[0]) {
            if (day !== 'throughoutCongress') {
                for (const room in filteredSessions[0][day]) {
                    if (room !== 'data') {
                        for (const session in filteredSessions[0][day][room]) {
                            if (filteredSessions[0][day][room][session].hidden !== undefined && filteredSessions[0][day][room][session].hidden === false) {
                                arrayDay.push(filteredSessions[0][day][room][session].date_begin)
                                if (arraySessions[filteredSessions[0][day][room][session].date_begin] && arraySessions[filteredSessions[0][day][room][session].date_begin].length > 0) {
                                    arraySessions[filteredSessions[0][day][room][session].date_begin].push(filteredSessions[0][day][room][session])
                                } else {
                                    arraySessions[filteredSessions[0][day][room][session].date_begin] = [filteredSessions[0][day][room][session]]
                                }
                            }
                        }
                    }
                }
            }
        }
        return arraySessions;
    }

    function renderDayButtons() {
        let buttons = [];
        for (const day in filteredSessions[0]) {
            let isActiveDay = day === activeDay;
            if (day !== 'throughoutCongress') {
                buttons.push(
                    <ProgramDayButtonForDesktop
                        key={uniqueId()}
                        changeActiveDay={changeActiveDay}
                        isActiveDay={isActiveDay}
                        filteredSessionsDisplayed={filteredSessionsDisplayed()[day]}
                        day={day}
                        count={activeFilterCount}
                    />
                );
            }
        }
        return <Flex direction={'row'} justify={'center'}>{buttons}</Flex>;
    }

    function getLink(isLive, session) {
        let screen = 'Session';
        let id = session.id;

        if(isLive && congressUser.physical_attendance) {
            screen = 'LiveStagePhysicalScreen';
            id = session.room.id;
        }
        else if(isLive) {
            screen = 'LiveStageScreenForDesktop';
            id = session.room.id;
        }

        return ({
            screen: screen,
            params: {
                id: id
            }
        });
    }

    function renderSessionsInRoom(room) {
        // Where room is an object with sessions as properties
        // properties can be null because of filtering
        // i is incrementor for live session refs
        let sessions = [];
        const maxLength = () => {
            if(deviceType === 1){
                return 40;
            }
            else {
                return 70
            }

        }

        for (const session of room) {
            if (session) {
                const duration = session.duration_in_minutes / 60;
                const timeFromStart = session.timeFromStart / 60 + 1;
                let isLive = false;
                let isChecked = false;
                const sizeWidth = duration * calendarHourWidth;
                let lengthSubtitle = maxLength();
                let truncated;
                const ignoredSymbols = [' ','-','(',')',' & ',', ',': ']
                if (session.subtitle.length <= lengthSubtitle) {
                    truncated = session.subtitle;
                }
                else{
                    truncated = session.subtitle.substring(0, lengthSubtitle)
                    if(!ignoredSymbols.includes(truncated.charAt(lengthSubtitle))) {
                        const tabIndex = [];
                        ignoredSymbols.forEach((elt) => {
                            if(truncated.lastIndexOf(elt) >= 0)
                                tabIndex.push(truncated.lastIndexOf(elt));
                        })
                        truncated = truncated.substring(0, Math.max(...tabIndex));
                    }
                    truncated = truncated.concat('...');
                }

                if (session && session.liveCurrent?.length > 0 && session.physical_only === 0) {
                    isLive = true;
                }
                if (props.userList.find(item => item.entity_id === session.id)) isChecked = true;
                sessions.push(
                    <View
                        position='absolute'
                        left={timeFromStart * calendarHourWidth}
                        style={{width: +sizeWidth}}
                        px={2}
                        h={32}
                        key={session.id}
                        ref={isLive ? (el => liveSession.current.push(el)) : null}
                    >
                        <Center
                            w={'100%'}
                            h={'100%'}
                            overflow={'hidden'}
                            bg={session.hidden ? colors['backgroundLight'][50] : colors['backgroundLight'][700]}
                            borderRadius={'md'}
                        >
                            <Text fontSize='xs'
                                  position={"absolute"}
                                  left="2.5%"
                                  color={session.hidden ? 'grey' : 'white'}
                                  bold={!session.hidden}
                                  textAlign={'left'}
                                  maxWidth={'90%'}
                            >{session.title} - {truncated}</Text>
                            <ImageBackground
                                source={{uri : session?.thumb_url}}
                                imageStyle={{opacity: session.hidden ? 0.1 : 0.3}}
                                style={{width: '100%', height: '100%', paddingLeft: deviceType !== 3 ? 0 : 8, paddingRight: deviceType !== 3 ? 0 : 8}}
                            >
                            </ImageBackground>
                            <Link
                                style={{
                                    position: 'absolute',
                                    zIndex: 3,
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 5,
                                    paddingTop: Platform.OS !== "web" && (session.replayAvailable || isLive) ? 30 : 10
                                }}
                                to={getLink(isLive, session)}
                            ></Link>
                        </Center>
                        <View style={{position: 'absolute', bottom: 4, right: 12}}>
                            <MyListCheckbox
                                item={{id: session.id, type: 'LectureSession'}}
                                size={'sm'}
                                direction={'row-reverse'}
                                isChecked={isChecked}
                            />
                        </View>
                        {session.replayAvailable ? <ReplayTag/> : <></>}
                        {isLive ? <LiveTag right/> : <></>}
                        {isLive && session.room?.seat_occupancy >= 95 ? <FullTag/> : <></>}
                    </View>
                );
            }
        }
        return sessions;
    }

    function renderCalendar() {
        let times = [];
        for (const day in filteredSessions[1]) {
            if (day === activeDay) {
                for (const time of filteredSessions[1][day]) {
                    times.push(
                        <View>
                            <View
                                position={'absolute'}
                                top={0}
                                left={'50%'}
                                h={'50%'}
                                key={time}
                                borderLeftWidth={1}
                                borderColor={colors['background'][100]}
                            ></View>
                            <Text
                                w={calendarHourWidth}
                                px={2}
                                borderLeftWidth={1}
                                borderColor={colors['background'][100]}
                            >{time}</Text>
                        </View>
                    );
                }
            }
        }

        const rooms = [];
        const sessions = [];
        let counter = 0;
        for (const room in filteredSessions[0][activeDay]) {
            if (room !== 'data' && room !== 'all rooms') {
                let cleanRoomName = room.slice(4);
                counter+= 1;
                cleanRoomName = cleanRoomName.charAt(0).toUpperCase() + cleanRoomName.slice(1);
                rooms.push(
                    <View
                        w={Platform.OS === 'web' ? (filteredSessions[0][activeDay].data.durationInMinutes + 60) / 60 * calendarHourWidth : calendarHourWidth*0.65}
                        h={32}
                        my={4}
                        key={room}
                    >
                        <Flex
                            h={{base:'80%', md: '100%'}} w={{base:'100%',md:calendarHourWidth*0.65}} direction={'column'} justify={'center'} align={'center'}
                            mt={deviceType === 1 && '25%'}
                            px={{base:2,md:4}} bg={colors['background'][50]}
                            borderTopWidth={1} borderBottomWidth={1} borderRightWidth={1}
                            borderColor={colors['background'][100]}
                            borderBottomRightRadius={15} borderTopRightRadius={15}
                            style={{left: 0, zIndex: 15, position: Platform.OS === 'web' ? 'sticky' : 'relative'}}
                        >
                            <Text fontSize='sm'
                                  fontWeight={{base:'bold',md:'normal'}}
                                  textAlign={{base:'center',md:'right'}}
                            >{cleanRoomName}</Text>
                        </Flex>
                        {Platform.OS === 'web' ? renderSessionsInRoom(filteredSessions[0][activeDay][room]) : null}
                    </View>
                );
                sessions.push(
                    <View
                        w={(filteredSessions[0][activeDay].data.durationInMinutes + 60) / 60 * calendarHourWidth}
                        h={32}
                        my={4}
                        key={room}
                    >
                        <Flex
                            h={'100%'} w={calendarHourWidth} direction={'column'} justify={'center'} align={'center'}
                            px={4} bg={colors['background'][50]}
                            borderTopWidth={1} borderBottomWidth={1} borderRightWidth={1}
                            borderColor={colors['background'][50]}
                            style={{left: 0, zIndex: 15
                            }}
                        >
                        </Flex>
                        {renderSessionsInRoom(filteredSessions[0][activeDay][room])}
                    </View>
                )
            }
        }
        const ratioOverflowY = !(deviceType === 2 & !landscape) ? (counter+0.5) : (counter-5)
        totalH.current = ratioOverflowY*32*4;
        return Platform.OS !== 'web' ? [rooms,sessions] : rooms;
    }

    function renderTimes() {
        let times = [];
        for (const day in filteredSessions[2]) {
            if (day === activeDay) {
                for (const time of filteredSessions[2][day]) {
                    times.push(
                        <View
                            key={uniqueId()}
                            w={calendarHourWidth}
                            borderLeftWidth={1}
                            borderColor={colors['background'][100]}
                            px={2}
                            py={2}
                        >
                            <View
                                position={'absolute'}
                                bottom={0}
                                left={'50%'}
                                h={'50%'}
                                key={time}
                                borderLeftWidth={1}
                                borderColor={colors['background'][100]}
                            />
                            <Text fontSize={'sm'}
                                  fontWeight={{base:'bold',md:'normal'}}>
                                {time}
                            </Text>
                        </View>
                    );
                }
            }
        }
        let ratioOverflowX;
        if(deviceType === 1 ) ratioOverflowX = 1.5;
        else if(deviceType === 2 && !landscape) ratioOverflowX = 3;
        else ratioOverflowX = 4;
        totalW.current = (filteredSessions[0][activeDay].data.durationInMinutes + 30) / 60 * calendarHourWidth - calendarHourWidth*ratioOverflowX ;
        return (
            <View maxH={10} overflow={'hidden'}
                  ml={deviceType ===3 && calendarHourWidth}
            >
                <ScrollView
                    horizontal
                    ref={timesRef}
                    scrollEventThrottle={16}
                    showsHorizontalScrollIndicator={false}
                    scrollEnabled={deviceType === 3}
                    style={{
                        flexShrink:0,
                        borderColor:colors['background'][100],
                    }}
                >
                    <Animated.View
                        style={{
                            left:deviceType !== 3 && pan.x,
                            width: (filteredSessions[0][activeDay].data.durationInMinutes + 30) / 60 * calendarHourWidth,
                            height:40,
                            maxHeight:40,
                            marginLeft:deviceType !==3 && calendarHourWidth,
                            flexDirection : 'row'
                        }}>

                            {times.map(time => time)}
                    </Animated.View>

                </ScrollView>
            </View>

        );
    }

    function renderLabs() {
        let items = [];
        if (labs) {
            for (const [key, value] of Object.entries(labs)) {
                let dayBegin = '', timeBegin = '', dayEnd = '', timeEnd = '';
                let i = 0;
                for (let day in value['data']['days']) {
                    if (i === 0) {
                        dayBegin = day;
                        timeBegin = value['data']['days'][day]['time_begin'];
                        i++;
                    } else {
                        dayEnd = day;
                        timeEnd = value['data']['days'][day]['time_end'];
                        i++;
                    }
                }
                items.push(
                    <View my={2}>
                        <Text bold>{key}</Text>
                        <Text fontSize={'sm'} italic>
                            {dayBegin + ' (' + timeBegin + ')'} - {dayEnd + ' (' + timeEnd + ')'}
                        </Text>
                    </View>
                );
            }
            return items;
        } else return <></>;
    }

    const renderContent = () => {
        let heightView;
        if(deviceType === 1) heightView = 500;
        else if(deviceType === 2 && !landscape) heightView = '82.5%';
        else heightView = '100%';

        if(Platform.OS === 'web'){
            return (
                <View mt={10} flex={1}>

                    {renderDayButtons()}
                    {renderTimes()}
                    <Animated.View
                        style={{
                            overflow:'hidden',
                            height:  '85%'
                        }}
                        {...panResponder.panHandlers}
                    >
                        <ScrollView
                            ref={scrollY}
                            onScroll={handleVerticalScroll}
                            scrollEventThrottle={16}
                            style={{userSelect: 'none'}}
                        >
                            <ScrollView
                                horizontal={true}
                                overflow={'hidden'}
                                ref={scrollX}
                                onScroll={handleHorizontalScroll}
                                scrollEventThrottle={16}
                                showsHorizontalScrollIndicator={true}
                            >
                                <VStack
                                    style={{cursor: 'all-scroll'}}
                                >
                                    {renderCalendar()}
                                </VStack>
                            </ScrollView>
                        </ScrollView>
                    </Animated.View>
                </View>
            )
        }
        else {


            const calendar = renderCalendar();
            return (
                <>
                    {renderDayButtons()}

                    {renderTimes()}

                    <Animated.View
                        style={{
                            marginTop: 10,
                            overflow:'hidden',
                            height:  heightView
                        }}
                        {...panResponder.panHandlers}
                    >

                        <Animated.View
                            style={{
                                position: 'absolute',
                                left: 0,
                                top:pan.y,
                                zIndex:10,
                                cursor: 'all-scroll',
                            }}
                        >
                            {calendar[0]}
                        </Animated.View>
                        <Animated.View
                            style={{
                                left: pan.x,
                                top : pan.y,
                            }}
                        >
                            <VStack
                                style={{cursor: 'all-scroll'}}
                                zIndex={10}
                            >
                                {calendar[1]}
                            </VStack>
                        </Animated.View>
                    </Animated.View>
                </>
            )
        }

    }

    function handleHorizontalScroll(e) {
        timesRef.current?.scrollTo({
            x: e.nativeEvent.contentOffset.x,
            animated: false
        });
        horizontalScrollPosition.current = e.nativeEvent.contentOffset.x;
    }

    function handleVerticalScroll(e) {
        if (e.nativeEvent.contentOffset.y) {
            verticalScrollPosition.current = e.nativeEvent.contentOffset.y;
        }
    }

    function handlePressShowModal() {
        return setShowLabsModal(true);
    }

    function handlePressHideModal() {
        return setShowLabsModal(false);
    }


    if (fetchError) {
        return (
            <Center flex={1}>
                <Text>{t("There_was_a_problem_loading_your_data")}</Text>
            </Center>
        );
    } else if (filteredSessions.length > 0) {
        return (
            <>
                {
                    Platform.OS === 'web' &&
                    <Link to={{screen: "PersonalInformations"}} style={{
                        position : 'absolute',
                        top: 90,
                        right : 128

                    }}>
                        <Flex direction={'row'} align={'center'} backgroundColor={colors['action'][50]} px={3} py={1} borderRadius={50} mb={Platform.OS === 'web' ? 0 : 10} >
                            <Text fontSize={'md'} color={'white'} mr={2}> {congressUser.timezone}</Text>
                            <MaterialCommunityIcons name="clock-time-eight" size={28} color={'#5766D6'} style={{
                                borderRadius : 999,
                                backgroundColor : 'white',
                                padding : 2,
                                overflow:'hidden'
                            }}/>
                        </Flex>
                    </Link>
                }

                <ProgramFilters
                    themes={props.themes}
                    apply={applyFilters}
                    toggleFilters={props.toggleFilters}
                    isOpen={props.isOpen}
                    count={activeFilterCount}
                />
                {type === 'aop' ? (<View position={'absolute'} right={{base:'150px',md:12}} top={{base:-45,md:90}} zIndex={1} borderRadius={50}  >
                    <Button
                        onPress={handlePressShowModal}
                        style={{borderRadius:50}}
                        px={3} py={2}
                    >Labs</Button>
                </View>) : null}

                {renderContent()}

                <Modal isOpen={showLabsModal} onClose={handlePressHideModal}>
                    <Modal.Content maxW={600}>
                        <Modal.CloseButton/>
                        <Modal.Header>
                            <Heading>Labs</Heading>
                        </Modal.Header>
                        <Modal.Body>
                            {type === 'aop' ? renderLabs() : null}
                        </Modal.Body>
                    </Modal.Content>
                </Modal>
            </>
        );}
    else return (
            <Center flex={1}><Spinner color="#5766D6" /></Center>
        );
}

const styles = StyleSheet.create({
    borderChanged: {
        left: 0,
        zIndex: 15,
        position: Platform.OS === 'web' ? 'sticky' : 'relative',
        '@media (maxWidth: 599px)': {
            borderBottomRightRadius: 15,
            borderTopRightRadius: 15
        }
    }
});
