import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {useIsFocused, useNavigation} from "@react-navigation/native";
import {
    Button,
    Center,
    Flex,
    Heading,
    ScrollView,
    Spinner,
    Text,
    useTheme,
    View,
    AlertDialog, Box, AspectRatio, Image
} from "native-base";
import {useTranslation} from "react-i18next";
import Layout from "../layouts/Layout";
import LinkButton from "../components/LinkButton";
import Constants from "expo-constants";
import HttpService from "../services/HttpService";
import Chat from "../components/Chat";
import SessionFeatures from "../components/SessionFeatures";
import SessionLectures from "../components/SessionLectures";
import Config from "../config.json";
import { Entypo } from '@expo/vector-icons';
import pusher from "../pusher";
import {Linking, Platform} from "react-native";

export default function LiveStagePhysicalScreen(props) {
    const [isLoadingCurrentSession, setIsLoadingCurrentSession] = useState(false);
    const [fetchError, setFetchError] = useState();
    const [emptyRoom, setEmptyRoom] = useState(false);
    const [ready, setReady] = useState(false);
    const [room, setRoom] = useState();
    const [currentSession, setCurrentSession] = useState();
    const isFocused = useIsFocused();
    const navigation = useNavigation();
    const {colors} = useTheme();
    const {t, i18n} = useTranslation();
    const [isOpen, setIsOpen] = React.useState(true);
    const onClose = () => setIsOpen(false);
    const cancelRef = React.useRef(null);
    const [virtual, setVirtual] = useState(false);
    /*
    @todo : ping
     */

    useEffect(() => {
        pusher.subscribe('onsite-room');

        pusher.bind('update', function (data) {
            setTimeout(function () {
                if (data.roomId?.toString() === props.route.params?.id?.toString()) {
                    setIsLoadingCurrentSession(true);
                    getRoom(props.route.params?.id).then((room) => {
                        setCurrentSession(room.live_current_session);
                        setIsLoadingCurrentSession(false);
                    });
                }
            }, 2000);
        });

        return () => {
            pusher.unsubscribe('onsite-room');
        };
    }, [isFocused]);

    useEffect(() => {
        if (props.route.params.id && isFocused) {
            getRoom(props.route.params.id)
                .then((room) => {
                    setCurrentSession(room.live_current_session);
                });
        } else setFetchError({message: 'No room ID.'});

        return (() => {
            setCurrentSession(null);
            setFetchError(null);
        })
    }, [isFocused]);

    useEffect(() => {
        if (room) {
            if (room.live_current_session) {
                setEmptyRoom(false);
            } else {
                setEmptyRoom(true);
            }
            if (room.virtual_event === 0){
                setVirtual(true)
            } else {
                setVirtual(false)
            }
        }
    }, [room]);

    function getRoom(id) {
        /*
        @todo : a changer je pense pour distinguer les physicals des virtuals
         */
        return HttpService.getData('room/get/one-inside/' + id)
            .then((response) => {
                setRoom(response.data.data);
                return response.data.data;
            })
            .catch((error) => {
                setFetchError(error);
                return error;
            });
    }
    function handlePressAcademy(){
        Linking.openURL('https://www.imcas.com/en/academy');
    }
    function renderAlertAd()
    {
        return(
            <>
                <AspectRatio w={'35%'} ratio={9/ 1}>
                    <Image
                        source={{uri: currentSession.alert_logo}}

                        style={{
                        }}
                        alt="session image"
                    />
                </AspectRatio>
                <Flex marginTop={10}>
                    <Text>
                        {t('this_case_is_a_clinical_case')}
                    </Text>
                    <Button  onPress={handlePressAcademy}>
                        {t('go_to_imcas_academy')}
                    </Button>
                </Flex>
            </>


        );
    }

    const virtualEvent = useMemo(() => {
        {if(virtual){
            return(<Box>
                <AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen} onClose={onClose} width={Platform.OS === 'web' ? '50%' : 'auto'} ml={Platform.OS === 'web' ? '30%' : '0%'} >
                    <AlertDialog.Content>
                        <AlertDialog.CloseButton />
                        <AlertDialog.Header>The congress is in presential only</AlertDialog.Header>
                        <AlertDialog.Body>
                            Live streaming of the session is not available for this congress.
                        </AlertDialog.Body>
                    </AlertDialog.Content>
                </AlertDialog>
            </Box>
            )
        }}
    })
    function handlePressRoomLocation() {
        return navigation.navigate('Map', {id: room?.id, type: 'room'});
    }

    if (fetchError) {
        return (
            <Layout withBackButton>
                <Center flex={1}>
                    <Text>{t("There_was_a_problem_loading_your_data")}</Text>
                    <Text>{fetchError.message}</Text>
                </Center>
            </Layout>
        );
    } else if (room && currentSession && !currentSession.hasAccess) {
        return (
            <Layout withBackButton>
                <Flex direction={'row'} justify={'center'}>
                    <Heading size={'2xl'}>{t('Live')}</Heading>
                </Flex>
                <Center flex={1}>
                    <Flex
                        justify={'center'}
                        align={'center'}
                        bg={colors['backgroundLight'][50]}
                        py={10}
                        px={16}
                        borderRadius='lg'
                        maxW={700}
                    >
                        <Text>{t('You_dont_have_access_to_this_session')}</Text>
                        <View h={8}/>
                        <LinkButton destination={'Program'} text={t('Program')}/>
                    </Flex>
                </Center>
            </Layout>
        );
    } else if (room && emptyRoom) {
        return (
            <Layout withBackButton>
                <Flex direction={'row'} justify={'center'}>
                    <Heading size={'2xl'}>{t('Live')}</Heading>
                </Flex>
                <Center flex={1}>
                    <Flex
                        justify={'center'}
                        align={'center'}
                        bg={colors['backgroundLight'][50]}
                        py={10}
                        px={16}
                        borderRadius='lg'
                        maxW={700}
                    >
                        <Text>{t('Break_message_browse')}</Text>
                        <View h={8}/>
                        <LinkButton destination={'Exhibition'} text={t('Exhibition')}/>
                    </Flex>
                </Center>
            </Layout>
        );
    } else if (room && currentSession) {
        return (
            <Layout withBackButton>
                {virtualEvent}
                <ScrollView width={'100%'} maxW={900} m={'auto'}>
                    <Heading size={'2xl'}>{currentSession.title}</Heading>
                    <Heading size={'lg'}>{room?.name + '  '}
                        <Button p={1} onPress={handlePressRoomLocation}>
                            <Entypo name="location-pin" size={24} color="white" />
                        </Button>
                    </Heading>
                    <View h={'500px'} w={'100%'} mb={4}>
                    <Chat
                        room={room}
                        sessionId={currentSession.id}
                        roomId={room.id}
                        isSessionLoading={isLoadingCurrentSession}
                        background={colors['backgroundLight'][50]}
                        physical
                    />
                    <View marginTop={10}>
                        {(currentSession && currentSession.is_alert)?renderAlertAd():null}
                    </View>
                    </View>
                    <SessionFeatures session={currentSession}/>

                    <SessionLectures lectures={currentSession.lectures}/>
                </ScrollView>
            </Layout>
        );
    } else {
        return (
            <Layout withBackButton>
                <Center flex={1}><Spinner color="#5766D6" /></Center>
            </Layout>
        );
    }
}
