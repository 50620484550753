import * as React from "react";
import {Button, Center, Flex, Heading, Text, useTheme, useToast, View} from "native-base";
import {useTranslation} from "react-i18next";
import InputForm from "../components/InputForm";
import {useForm} from "react-hook-form";
import HttpService from "../services/HttpService";
import {useState} from "react";
import {Link} from "@react-navigation/native";
import {useDispatch} from "react-redux";
import {refreshData} from "../actions/auth";

export default function ResetPasswordScreen({navigation}) {
    const toast = useToast();
    const {colors} = useTheme();
    const {t, i18n} = useTranslation();
    const {control, handleSubmit, formState: {errors}} = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [serverError, setServerError] = useState({});

    function onSubmit(data) {
        setIsLoading(true);

        return HttpService.postData('auth/password-reset', data)
            .then((response) => {
                if(response.data.success) {
                    setIsLoading(false);
                    navigation.navigate('Login');
                    toast.show({
                        title: response.data.message,
                        placement: "top",
                        bg: "emerald.500"
                    });
                }
                else {
                    setServerError(response.data.message);
                    setIsLoading(false);
                }
            })
            .catch((error) => error.data);
    }

    return (
        <Center
            flex={1}
            bg={colors['background'][50]}
        >
            <Flex
                justify={'center'}
                align={'flex-start'}
                borderColor={colors['backgroundLight'][50]}
                py={10}
                px={5}
                maxW={700}
                shadow={{md: 9}}
                borderRadius='lg'
                w={'100%'}
            >
                <Heading size={'2xl'}>{t('Forgot_your_password')}</Heading>
                <View my={2}/>
                <View width={'100%'}>
                    <InputForm name="email" defaultValue={''} placeholder={t('Email')}
                               control={control}
                               required
                               errors={errors}
                               apiErrors={serverError}
                    />
                </View>
                <Text>{t('Link_to_reset_your_password')}</Text>
                <View width={'100%'} my={4}>
                    <Button
                        onPress={handleSubmit(onSubmit)}
                        isLoading={isLoading}
                    >{t('Reset_password')}</Button>
                </View>
                <Flex width={'100%'} direction={'row'} justify={'center'}>
                    <Link to={{screen: 'Login'}}>
                        <Text>{t('Or_return_to_login')}</Text>
                    </Link>
                </Flex>
            </Flex>
        </Center>
    );
}
