import * as React from "react";
import LayoutLivePanelMobile from "../components/LayoutLivePanelMobile";
import Chat from "../components/Chat";

export default function LiveChatMobileScreen({navigation, ...props}) {

    return(
        <LayoutLivePanelMobile navigation={navigation} activePage={navigation.isFocused ? 'Chat' : ''}>
            <Chat
                room={props.room}
                messages={props.session.comments}
                sessionId={props.session.id}
                size={'xs'}
                isSessionLoading={props.isSessionLoading}
            />
        </LayoutLivePanelMobile>
    );
}
