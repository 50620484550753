import React, {useEffect, useRef, useState} from "react";
import {Platform} from "react-native";
import HttpService from "../services/HttpService";
import Layout from "../layouts/Layout";
import {
    Button,
    Center,
    Divider,
    Flex,
    Heading,
    HStack, Image,
    PresenceTransition, Pressable,
    ScrollView,
    Spinner,
    Text,
    useTheme,
    View
} from "native-base";
import VideoPlayer from "../components/VideoPlayer";
import {format, parseISO} from "date-fns";
import SessionFeatures from "../components/SessionFeatures";
import SessionLectures from "../components/SessionLectures";
import {AntDesign, Entypo} from "@expo/vector-icons";
import {useTranslation} from "react-i18next";
import LinkButton from "../components/LinkButton";
import Chat from "../components/Chat";
import SessionCurrentLecture from "../components/SessionCurrentLecture";
import Constants from "expo-constants";
import AccountService from "../services/AccountService";
import {useIsFocused} from "@react-navigation/native";
import Config from "../config.json";
import pusher from "../pusher";

export default function LiveStageScreenForDesktop({navigation, ...props}) {
    const [room, setRoom] = useState();
    const [fetchError, setFetchError] = useState();
    const [emptyRoom, setEmptyRoom] = useState(false);
    const [deviceReady, setDeviceReady] = useState(false);
    const [chatIsShown, setChatIsShown] = useState(true);
    const [playerHeight, setPlayerHeight] = useState(0);
    const [currentLecture, setCurrentLecture] = useState(null);
    const [currentSession, setCurrentSession] = useState(null);
    const [isLoadingCurrentLecture, setIsLoadingCurrentLecture] = useState(false);
    const [isLoadingCurrentSession, setIsLoadingCurrentSession] = useState(false);
    const isFocused = useIsFocused();
    const {colors} = useTheme();
    const {t, i18n} = useTranslation();
    const player = useRef(null);


    // Ping
    useEffect(() => {
        let interval;
        if(isFocused) {
            interval = AccountService.startPing(navigation, {page: 'conference-room', sessionRoomId: props.route.params.id, sessionId: (currentSession ? currentSession.id : null)});
        }
        return () => clearInterval(interval);
    }, [currentSession, isFocused])

    useEffect(() => {
        setDeviceReady(false);
        if (props.route.params.id && isFocused) {
            getRoom(props.route.params.id)
                .then((room) => {
                    setCurrentSession(room.live_current_session);
                    setCurrentLecture(room.live_current_lecture);
                });
        } else setFetchError({message: 'No room ID.'});

        return (() => {
            setCurrentLecture(null);
            setCurrentSession(null);
        })
    }, [isFocused]);

    useEffect(() => {
        if (room) {
            if (room.live_current_session) {
                setEmptyRoom(false);
                setDeviceReady(true);
            } else {
                setEmptyRoom(true);
                setDeviceReady(true);
            };
        }
    }, [room]);

    useEffect(() => {
        pusher.subscribe('onsite-room');

        pusher.bind('launch-lecture', function (data) {
            setTimeout(function () {
                if (data.roomId?.toString() === props.route.params?.id?.toString()) {
                    setIsLoadingCurrentLecture(true);
                    getRoom(props.route.params?.id).then((room) => {
                        setCurrentLecture(room.live_current_lecture);
                        setIsLoadingCurrentLecture(false);
                    });
                }
            }, 2000);
        });

        pusher.bind('update', function (data) {
            setTimeout(function () {
                if (data.roomId?.toString() === props.route.params?.id?.toString()) {
                    setIsLoadingCurrentSession(true);
                    getRoom(props.route.params?.id).then((room) => {
                        setCurrentSession(room.live_current_session);
                        setCurrentLecture(room.live_current_lecture);
                        setIsLoadingCurrentSession(false);
                    });
                }
            }, 2000);
        });

        return () => {
            pusher.unsubscribe('onsite-room');
        };
    }, [isFocused]);

    function setChatHeight() {
        if (chatIsShown) {
            // Chat gets its height from playerHeight state
            setPlayerHeight(player.current?.getBoundingClientRect().height);
        }
    }

    function toggleChat() {
        setChatIsShown(!chatIsShown);
    }

    function getRoom(id) {
        return HttpService.getData('room/get/one-inside/' + id)
            .then((response) => {
                setRoom(response.data.data);
                return response.data.data;
            })
            .catch((error) => {
                setFetchError(error);
                return error;
            });
    }

    function changeStreamLanguage(language) {
        setIsLoadingCurrentSession(true);
        return HttpService.postData('account/change-stream-language', {language: language})
            .then((response) => {
                getRoom(props.route.params?.id).then((room) => {
                    setCurrentSession(room.live_current_session);
                    setCurrentLecture(room.live_current_lecture);
                    setIsLoadingCurrentSession(false);
                });
            })
            .catch((error) => {
                setIsLoadingCurrentSession(false);
                setFetchError(error);
                return error;
            });
    }

    function handlePressOnRoomLocation() {
        return navigation.navigate('Map', {id: room.id, type: 'room'});
    }

    function renderTranslations() {
        if (room.stream_available_languages && room.stream_available_languages.length > 1) {
            return (
                <>
                    <Divider orientation="vertical" mx="3"/>
                    <Text>{t('Available_translations')} : </Text>
                    {
                        room.stream_available_languages.map((language, index) => {
                            if (room.stream_language !== language.code) {
                                return (
                                    <Pressable key={index} onPress={() => changeStreamLanguage(language.code)}
                                               style={{cursor: 'pointer'}}>
                                        <Image
                                            source={{uri: language.flag_url}}
                                            style={{
                                                width: 16,
                                                height: 11,
                                                marginTop: 8,
                                                marginRight: 5,
                                                opacity: 0.5
                                            }}/>
                                    </Pressable>
                                );
                            } else {
                                return (
                                    <Image
                                        source={{uri: language.flag_url}}
                                        style={{
                                            width: 16,
                                            height: 11,
                                            marginTop: 8,
                                            marginRight: 5,
                                        }}/>
                                );
                            }
                        })
                    }
                </>
            );
        }
    }

    function renderCurrentLecture() {
        if (isLoadingCurrentLecture) {
            return <Center flex={1}><Spinner color="#5766D6" /></Center>;
        } else if (currentLecture) {
            return <SessionCurrentLecture lecture={currentLecture}/>;
        } else return <></>;
    }


    if(!deviceReady) {
        return (
            <Layout>
                <Center flex={1}><Spinner color="#5766D6" /></Center>
            </Layout>
        );
    } else if (deviceReady && emptyRoom) {
        return (
            <Layout withBackButton>
                <Flex direction={'row'} justify={'center'}>
                    <Heading size={'2xl'}>{t('Live')}</Heading>
                </Flex>
                <Center flex={1}>
                    <Flex
                        justify={'center'}
                        align={'center'}
                        bg={colors['backgroundLight'][50]}
                        py={10}
                        px={16}
                        borderRadius='lg'
                        maxW={700}
                    >
                        <Text>{t('Break_message_browse')}</Text>
                        <View h={8}/>
                        <LinkButton destination={'Exhibition'} text={t('Exhibition')}/>
                    </Flex>
                </Center>
            </Layout>
        );
    }
    else if (deviceReady && !emptyRoom && !currentSession.hasAccess) {
        return (
            <Layout withBackButton>
                <Flex direction={'row'} justify={'center'}>
                    <Heading size={'2xl'}>{t('Live')}</Heading>
                </Flex>
                <Center flex={1}>
                    <Flex
                        justify={'center'}
                        align={'center'}
                        bg={colors['backgroundLight'][50]}
                        py={10}
                        px={16}
                        borderRadius='lg'
                        maxW={700}
                    >
                        <Text>{t('You_dont_have_access_to_this_session')}</Text>
                        <View h={8}/>
                        <LinkButton destination={'Program'} text={t('Program')}/>
                    </Flex>
                </Center>
            </Layout>
        );
    }
    else if (deviceReady && !emptyRoom && currentSession.hasAccess) {
        return (
            <Layout noMaxW withBackButton>
                <ScrollView flex={1} px={2}>
                    <Flex direction={'row'} justify={'center'}>
                        <Heading size={'2xl'}>Live</Heading>
                    </Flex>

                    <Flex direction={'row'}>
                        <View flex={18} ref={player} onLayout={setChatHeight}>
                            <VideoPlayer file={room.stream_url}/>
                        </View>
                        <View flex={1}>
                            <Button
                                onPress={toggleChat}
                                mr={2}
                                variant={'unstyled'}
                                h={'100%'}
                                w={'100%'}
                                p={0}
                                borderWidth={1}
                                borderColor={'#5766D6'}
                            >
                                <Entypo name={chatIsShown ? "chevron-right" : "chevron-left"} size={32}
                                        color='#5766D6' />
                            </Button>
                        </View>

                        <View
                            style={{display: chatIsShown ? 'flex' : 'none'}}
                            height={playerHeight}
                            py={2} px={4}
                            flex={5}
                            borderRadius={5}
                            bg={colors['backgroundLight'][50]}
                        >
                            <Chat
                                room={room}
                                onToggle={toggleChat}
                                sessionId={currentSession.id}
                                isSessionLoading={isLoadingCurrentSession}
                                background={colors['backgroundLight'][50]}
                            />
                        </View>
                    </Flex>
                    <Heading size={'lg'}>{currentSession.subtitle}</Heading>
                    <Flex direction={'row'} alignItems={'center'}>
                        <Button py={1} px={2} onPress={handlePressOnRoomLocation}>{room.name}</Button>
                        <Divider orientation="vertical" mx="3"/>
                        <Text>{format(parseISO(currentSession.datetime_begin), 'p')}</Text>
                        <Text> - {format(parseISO(currentSession.datetime_end), 'p')}</Text>
                        {renderTranslations()}
                    </Flex>

                    {renderCurrentLecture()}

                    <SessionFeatures session={currentSession}/>

                    <SessionLectures lectures={currentSession.lectures}/>
                </ScrollView>
            </Layout>
        );
    } else if (fetchError) {
        return (
            <Layout>
                <Center flex={1}>
                    <Text>{t("There_was_a_problem_loading_your_data")}</Text>
                    <Text>{fetchError.message}</Text>
                </Center>
            </Layout>
        );
    }
}
