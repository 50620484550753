import React from 'react';
import {FormControl, Text, TextArea} from "native-base";
import {Controller} from "react-hook-form";
import {height} from "styled-system";


const TextAreaForm = ({name, control, defaultValue, placeholder, width, required, validation, errors, height, label}) => {
    return (
        <>
            <Controller
                control={control}
                name={name}
                rules={{
                    ...validation,
                    required: required,
                }}
                defaultValue={defaultValue}
                render={({field: {onChange, ref, onBlur, value}}) => (
                    <>
                        <FormControl.Label>{label}</FormControl.Label>
                        <TextArea
                            my={1}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            width={width}
                            h={height}
                            placeholder={placeholder}
                        />
                    </>
                )}

            />
            {errors && errors[name] && <Text>This field is required</Text>}

        </>

    );

}
export default TextAreaForm;
