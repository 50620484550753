import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Layout from "../layouts/Layout";
import {
    Center,
    Flex,
    Heading, HStack,
    ScrollView, SectionList,
    Spinner,
    Text, useTheme, View
} from "native-base";
import HttpService from "../services/HttpService";
import {Dimensions, Platform} from "react-native";
import {useTranslation} from "react-i18next";
import CompanyFilters from "../components/CompanyFilters";
import CountResult from "../components/CountResult";
import FilterButton from "../components/FilterButton";
import CompanyListCard from "../components/CompanyListCard";
import AccountService from "../services/AccountService";
import ScrollToTopButton from "../components/ScrollToTopButton";
import * as Device from "expo-device";
import {useIsFocused} from "@react-navigation/native";

function CompanyListScreen({navigation}) {
    const listRef = useRef(null);
    const [contentVerticalOffset, setContentVerticalOffset] = useState(0);
    const CONTENT_OFFSET_THRESHOLD = 100;
    const {colors} = useTheme();
    const {t, i18n} = useTranslation();
    const [companies, setCompanies] = useState([]);
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [deviceType,setDeviceType] = useState();
    const isFocused = useIsFocused();
    const orderedListOfSponsorshipLevels = [
        'ultimate',
        'elite',
        'diamond',
        'emerald',
        'platinum',
        'gold',
        'silver',
        'bronze',
        'classic',
        'standard',
        'sponsor',
        'in_progress',
    ];
    const [truncate, setTruncate] = useState(true);
    const [showFilters, setShowFilters] = useState(false);

    const uniqueId = useCallback(() => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    },[]);

    /*
     * return the List of exhibitors for this congress with contact_status = exhibiting
     */
    const getCompanies = useCallback(() => {
        HttpService
            .getData('exhibitor/get/all')
            .then(response => {
                setCompanies(response.data.data);
                setFilteredCompanies(response.data.data);
            })
            .catch(error => {
                console.log('Error: ' + error)
            })
    },[])

    // Ping
    useEffect(() => {
        let interval;
        if(isFocused) {
            interval = AccountService.startPing(navigation, {page: 'company-list'});
        }
        return () => clearInterval(interval);
    }, [isFocused]);

    useEffect(
        () => {
            getCompanies();
            async function setLayout() {
                const type = await getDeviceType();
                setDeviceType(type);
            }
            setLayout();
        }, []
    );

    async function getDeviceType() {
        return await Device.getDeviceTypeAsync();
    }

    const toggleFilters = useCallback(() => {
        setShowFilters(!showFilters);
    },[showFilters])

    function toggleTruncate() {
        return setTruncate(!truncate);
    }

    /*
     * Parameter = an array of companies (companies or filteredCompanies)
     * Return an array of ordered list of sponsorship levels
     */
    const arrayOfSponsorshipLevels = useCallback((arrayOfCompanies) => {
        let tempArray = [];
        let sponsorshipLevels = [];

        if (arrayOfCompanies.length > 0) {
            // retrieve only sponsorship_level include in the company list
            tempArray = arrayOfCompanies.map(item => item.sponsorship_level);

            // sort sponsorshipLevels in the same order than orderedListOfSponsorshipLevels
            for (const allSponsorshipLevel of orderedListOfSponsorshipLevels) {
                for (const value of tempArray) {
                    if (allSponsorshipLevel?.toLowerCase() === value?.toLowerCase()) {
                        sponsorshipLevels.push(allSponsorshipLevel.toLowerCase())
                    }
                }
            }
            // remove the duplicates
            return sponsorshipLevels = sponsorshipLevels.filter((sponsorshipLevel, index) => sponsorshipLevels.indexOf(sponsorshipLevel) === index);
        }
    },[])

    /*
     * Return an array of object.
     * each object include a key: sponsorship level (string) and a key: companies (array of companies corresponding to the sponsorship level)
     */
    const arrayOfCompaniesBySponsorshipLevel = useMemo(() => {
        if (filteredCompanies.length > 0) {
            let companiesData = [];
            //loop on array of sponsorship level
            for (const sponsorshipLevel of arrayOfSponsorshipLevels(filteredCompanies)) {
                let data = {'title': "", 'data': []}
                //create objects with a sponsorship level and his corresponding companies
                for (const company of filteredCompanies) {
                    if (company.sponsorship_level?.toLowerCase() === sponsorshipLevel?.toLowerCase()) {
                        data =
                            {
                                'title': sponsorshipLevel,
                                'data': [...data.data, company]
                            }
                    }
                }
                companiesData.push(data)
            }
            return companiesData;
        } else return [];
    },[filteredCompanies])

    /*
    * Render list of companies sorted by sponsorship level
    * display for desktop or mobile
    */
    const renderCompaniesListLayout = useMemo(() => {
        if (filteredCompanies.length > 0) {
            //for desktop
            if (Platform.OS === 'web' || (deviceType == 2 && Dimensions.get('window').width > Dimensions.get('window').height)) {
                return (
                    <>
                        <ScrollView
                            width="100%"
                            maxWidth={{md: 900}}
                            margin={{lg: 'auto'}}
                            ref={listRef}
                            onScroll={event => {
                                setContentVerticalOffset(event.nativeEvent.contentOffset.y);
                            }}
                            scrollEventThrottle={16}

                        >
                            {
                                arrayOfCompaniesBySponsorshipLevel.map(item => (
                                        <React.Fragment key={uniqueId()}>
                                            <Heading m={2}>{item.title?.toUpperCase()}</Heading>
                                            <Flex
                                                w="100%"
                                                flexDirection="row"
                                                flexWrap="wrap"
                                            >
                                                {
                                                    item.data.map(company => (
                                                        <CompanyListCard company={company} key={uniqueId()}/>
                                                    ))
                                                }
                                            </Flex>
                                        </React.Fragment>
                                    )
                                )
                            }
                        </ScrollView>

                    </>
                );
            } else {
                //for mobile
                return (
                    <>
                        <SectionList
                            ref={listRef}
                            // onScroll={event => {
                            //     setContentVerticalOffset(event.nativeEvent.contentOffset.y);
                            // }}
                            sections={arrayOfCompaniesBySponsorshipLevel}
                            keyExtractor={(item, index) => item + index}
                            stickySectionHeadersEnabled={true}
                            renderSectionHeader={({section: {title}}) => (
                                <Heading
                                    fontSize="xl"
                                    bg={colors['background'][50]}
                                >
                                    {title.toUpperCase()}
                                </Heading>)
                            }
                            renderItem={({item}) => (
                                <CompanyListCard company={item}/>
                            )}
                            ItemSeparatorComponent={renderItemSeparator}
                        />
                    </>
                );
            }
        } else {
            return <Center flex={1}><Text>{t('No_results')}</Text></Center>
        }
    },[filteredCompanies])

    const renderItemSeparator = useMemo(() => {
        return <View borderTopWidth={1} borderColor={colors['backgroundLight'][50]}/>;
    },[])

    const renderCompanyFilters = useMemo(() => {
        return (<CompanyFilters
            companies={companies}
            filteredCompanies={filteredCompanies}
            setFilteredCompanies={setFilteredCompanies}
            isOpen={showFilters}
            toggleFilters={toggleFilters}
        />);
    },[companies,filteredCompanies,setFilteredCompanies,showFilters,toggleFilters])

    const renderHeader = useMemo(() => {
        if (Platform.OS === 'web' || (deviceType == 2 && Dimensions.get('window').width > Dimensions.get('window').height)) {
            return (
                <Flex
                    w={'100%'}
                    align={'center'}
                    _dark={{borderColor: colors['backgroundLight'][50],}}
                    borderBottomWidth={0.5}
                    p={2}
                >
                    <Heading size={'2xl'}>{t('All_exhibitors')}</Heading>
                    <Flex w={'100%'} direction={'row'} justify={'space-around'} align={'flex-end'}>
                        <View><CountResult result={filteredCompanies?.length}/></View>
                    </Flex>
                </Flex>
            );
        } else return (
            <Flex>
                <Heading>{t('All_exhibitors')}</Heading>
                <Center paddingBottom={2}><CountResult result={filteredCompanies?.length}/></Center>
            </Flex>
        );
    },[filteredCompanies])

    const renderDescription = useMemo(() => {
        if (Platform.OS === 'web' || (deviceType == 2 && Dimensions.get('window').width > Dimensions.get('window').height)) {
            return (<Flex maxWidth={900} mx={"auto"} >
                <Text marginTop={5} isTruncated={truncate} maxW={1000}
                      w="100%">{t('All_companies_listed_below_are_leaders')}</Text>
                <Text
                    fontSize={{base: 'xs', md: 'sm'}}
                    onPress={toggleTruncate}
                    color={colors['action'][50]}>
                    {truncate ? t('Read_more') : t('Read_less')}
                </Text>
            </Flex>);
        } else {
            return (<Flex w={'100%'} direction={'column'} justify={'space-around'} align={'flex-end'}>
                <Flex w={'100%'}>
                    <Text isTruncated={truncate} maxW={1000} fontSize={'sm'}
                          w="100%">{t('All_companies_listed_below_are_leaders')}</Text>
                    <Text
                        fontSize={{base: 'xs', md: 'sm'}}
                        onPress={toggleTruncate}
                        color={colors['action'][50]}>
                        {truncate ? t('Read_more') : t('Read_less')}
                    </Text>
                </Flex>
            </Flex>);
        }
    },[setTruncate,truncate])

    if (companies.length > 0) {
        return (<Layout withBackButton activePage={navigation.isFocused ? 'Explore' : ''}>
            {renderCompanyFilters}
            {renderHeader}
            {renderDescription}
            {renderCompaniesListLayout}
            {contentVerticalOffset > CONTENT_OFFSET_THRESHOLD &&
                <ScrollToTopButton eltRef={listRef}/>}
        </Layout>);
    } else return (<Layout withBackButton activePage={navigation.isFocused ? 'Explore' : ''}>
        <Center flex={1}>
            <HStack space={2} alignItems="center">
                <Spinner color="#5766D6" />
                <Heading color="#5766D6" fontSize="md">
                    Loading ...
                </Heading>
            </HStack>
        </Center>
    </Layout>);
}

export default CompanyListScreen;
