import {GetNotificationsUserService} from "../services/AuthService";
import {REFRESH_NOTIFICATIONS} from "./types";
import {logout} from "./auth";

export const refreshNotifications = () => (dispatch) => {
    return GetNotificationsUserService().then(
        (data) => {
            if(data.success) {
                dispatch({
                    type: REFRESH_NOTIFICATIONS,
                    payload: {
                        all: data.notifications,
                        unreadCount: data.unreadCount,
                    }
                });
                return Promise.resolve();
            }
            else {
                dispatch(logout());
                return Promise.reject();
            }
        },
        (error) => {
            dispatch(logout());
            return Promise.reject();
        }
    )
}
