import * as React from "react";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Layout from "../layouts/Layout";
import {useTranslation} from "react-i18next";
import {Badge, Center, Flex, Heading, ScrollView, Text, useTheme, View} from "native-base";
import AccountService from "../services/AccountService";
import HttpService from "../services/HttpService";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {useIsFocused} from "@react-navigation/native";
import {format, parseISO} from "date-fns";
import ProgramDayButtonForDesktop from "../components/ProgramDayButtonForDesktop";
import {FlatList, Platform} from "react-native";

/*
* NOTA BENE : title format: COMPANY NAME TRAINING LAB: ROOM 254
* */
export default function SponsoredWorkshops({navigation}) {
    const {t} = useTranslation();
    const {colors} = useTheme();
    const [workshops,setWorkshops] = useState([]);
    const [filteredWorkshops, setFilteredWorkshops] = useState({});
    const [tabs, setTabs] = useState([]);
    const isFocused = useIsFocused();
    const [activeTab, setActiveTab] = useState('');
    // Ping
    useEffect(() => {
        let interval;
        if(isFocused) {
            interval = AccountService.startPing(navigation, {page: 'explore'});
            HttpService
                .getData('session/get/workshops')
                .then(response => {
                    setWorkshops(response.data.data);
                })
                .catch(error => {
                    console.log('Error: ' + error)
                })
        }
        return () => clearInterval(interval);
    }, [isFocused]);

    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };

    useEffect(() => {
        if(workshops && workshops.length > 0) {
            let obj = {}
            workshops.forEach(session => {
                if(session) {
                    if (session.format && session.format.id === 299)
                    {
                        if(obj[session.format.name] && obj[session.format.name].length > 0)
                        {
                            obj[session.format.name].push(session);
                        } else {
                            obj[session.format.name] = [session];
                        }
                    } else if (session.format && session.format.id === 300) {
                        if(obj[session.format.name]&& obj[session.format.name].length > 0)
                        {
                            obj[session.format.name].push(session);
                        } else {
                            obj[session.format.name] = [session];
                        }
                    }
                }
            });
            Object.values(obj).forEach(value => value.sort((a,b) => {
                const c = getTitle(a.room.name, String(a.title).split(": ")[0]);
                const d = getTitle(b.room.name, String(b.title).split(": ")[0]) ;
                return c.localeCompare(d, 'en', { sensitivity: 'base' });
            }));
            setTabs(Object.keys(obj));
            setActiveTab(Object.keys(obj)[0]);
            setFilteredWorkshops(obj);
        }
    }, [workshops]);

    function changeActiveTab(tab) {
        setActiveTab(tab);
    }

    const renderDayButtons = useMemo(() => {
        let buttons = [];
        tabs.forEach(tab => {
            let isActive = activeTab === tab;
            buttons.push(
                <ProgramDayButtonForDesktop
                    key={uniqueId()}
                    date={false}
                    changeActiveDay={changeActiveTab}
                    isActiveDay={isActive}
                    filteredSessionsDisplayed={filteredWorkshops[tab]}
                    day={tab}
                />
            );
        });
        return <Flex direction={'row'} justify={'center'}>{buttons}</Flex>;
    });

    function getTitle(type, title) {
        if (type === 299) {
            const trainingLabsToRemove = /\-*\s*training\s*lab/gi;
            return title.replace(trainingLabsToRemove, '');
        } else if (type === 300 ) {
            const hospitalitySuitesToRemove = /\-*\s*hospitality\s*suite/gi;
            return title.replace(hospitalitySuitesToRemove, '');
        } else {
            return title;
        }
    }

    return (
        <Layout withBackButton activePage={navigation.isFocused ? 'Explore' : ''}>
            <Center px={4} mb={1}>
                <Flex w={'100%'} maxW={900} align={{base: 'flex-start', md: 'center'}}>
                    <Heading size={'2xl'}>{t('Training_labs_hospitality_suites')}</Heading>
                </Flex>
            </Center>
            {renderDayButtons}
            <ScrollView>
                <FlatList data={filteredWorkshops[activeTab]} renderItem={({item}) => (
                    <View width={{base :"100%", md:"70%"}} mx={"auto"} mt={19}>
                        <View flexDirection={Platform.OS === 'web' ? 'row' : 'column'} justifyContent={Platform.OS === 'web' ? 'space-between' : "center"} alignItems={Platform.OS === 'web' ? 'normal' : 'center'}  p={2} bg={colors['backgroundLight'][50]} borderTopRadius={"lg"}>
                            <Text my={Platform.OS === 'web' ? 0 : 2} fontSize={"md"} fontWeight="bold" color={colors['backgroundLight'][600]}>{getTitle(item.format.id, String(item.title).split(": ")[0])}</Text>
                            <Text fontSize={Platform.OS === 'web' ?  "md" : 'sm'} alignSelf={Platform.OS === 'web' ? 'normal' : 'flex-end'} fontWeight="bold" color={colors['backgroundLight'][600]}><MaterialCommunityIcons name="home-variant" size={20} color={colors['backgroundLight'][600]} />{String(item.title).split(": ")[1]}</Text>
                        </View>
                        <View p={2} border={`2px solid ${colors['backgroundLight'][50]}`} borderBottomRadius={'lg'}>
                            <Text fontSize={"sm"}>{item.subtitle}</Text>
                            <Text fontSize={"sm"} mt={6} italic>{item.objectives}</Text>
                        </View>
                    </View>
                )}></FlatList>
            </ScrollView>
        </Layout>
        );
}
