import {
    Center, Flex,
    Heading,
    HStack, ScrollView,
    Spinner,
    Text, View
} from "native-base";
import * as React from "react";
import Layout from "../layouts/Layout";
import Exhibitors from "../components/Exhibitors";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import HttpService from "../services/HttpService";
import CompanyFilters from "../components/CompanyFilters";
import {Platform} from "react-native";
import {useMediaQuery} from "react-responsive";
import AccountService from "../services/AccountService";
import {FontAwesome} from "@expo/vector-icons";
import ViewAll from "../components/ViewAll";
import MasonryList from "@react-native-seoul/masonry-list";
import {useIsFocused} from "@react-navigation/native";

export default function ExhibitionScreen({navigation}) {
    const {t, i18n} = useTranslation();
    const [companies, setCompanies] = useState([]);
    const [filteredCompanies, setFilteredCompanies] = useState(companies);
    const isMobile = useMediaQuery({query: '(max-width: 760px)'});
    const [fetchError, setFetchError] = useState();
    const isFocused = useIsFocused();

    // Ping
    useEffect(() => {
        let interval;
        if(isFocused) {
            interval = AccountService.startPing(navigation, {page: 'exhibition'});
        }
        return () => clearInterval(interval);
    }, [isFocused]);

    /*
     * Return an integer between min and max both included
     */
    function getRandomIntInclusive(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    /*
     * Insert ads on array of data
     * insertIndex = index where ad is inserted
     * spaceIndex = number of items between ads
     */
    const dataWithAds = (arrayOfData, arrayOfAds, firstAdIndex = 2, secondAdIndex = 4, initialAdIndexMin = 6, initialAdIndexMax = 8, adSpaceMin = 2, adSpaceMax = 4) => {
        if (initialAdIndexMin < 0) initialAdIndexMin = 6;
        if (initialAdIndexMax < initialAdIndexMin) initialAdIndexMax = initialAdIndexMin + 2;
        let adIndex = getRandomIntInclusive(initialAdIndexMin, initialAdIndexMax);

        if (adSpaceMin < 1) adSpaceMin = 4;
        if (adSpaceMax < adSpaceMin) adSpaceMax = adSpaceMin + 4;

        for (let add of arrayOfAds) {
                arrayOfData.splice(adIndex, 0, add);
                adIndex = adIndex + getRandomIntInclusive(adSpaceMin, adSpaceMax);
        }
        return arrayOfData;
    }

    const getCompanies = () => {
        HttpService
            .getData('exhibitor/get/all')
            .then(response => {
                let arr = response.data.data;

                HttpService
                    .getData('exhibitor/get/featured')
                    .then(response => {
                        const featured = response.data.data;
                        if (featured?.length > 0) {
                            arr = featured.concat(arr);
                        }

                        HttpService
                            .getData('ads/get/exhibition')
                            .then(response => {
                                let ads = response.data.data;
                                if (Platform.OS == 'web' || !isMobile) {
                                    arr = dataWithAds(arr, ads, 1, 3, 5, 20, 6, 10);
                                } else {
                                    arr = dataWithAds(arr, ads, 1, 4, 5, 20, 6, 10);
                                }
                                setFilteredCompanies(arr);
                                setCompanies(arr);
                            })
                            .catch(error => {
                                console.log('Error: ' + error)
                                setFetchError(error);
                            })
                    })
                    .catch(error => {
                        console.log('Error: ' + error)
                        setFetchError(error);
                    });
            })
            .catch(error => {
                console.log('Error: ' + error)
                setFetchError(error);
            })
    }

    useEffect(
        () => {
            getCompanies();
        }, []
    );

    const renderExhibitors = () => {
        return (Platform.OS === 'web' || !isMobile ?
            (// for desktop
                <Exhibitors
                    companies={companies}
                    filteredCompanies={filteredCompanies}
                    companiesWidth={150}
                    companiesHeight={225}
                    adsWidth={150}
                    adsHeight={150}
                />) :

            ( // for mobile
                <Exhibitors
                    companies={companies}
                    filteredCompanies={filteredCompanies}
                    companiesRatio={2 / 3}
                    adsRatio={1}
                />))
    }

    const renderHeading = () => {
        if(Platform.OS === 'web') {
            return <Heading size={'2xl'}
                     position={'absolute'} top={2} left={'50%'} style={{transform:'translateX(-50%)'}}
            > {t('Exhibition')}</Heading>
        }
        else {
            return <Heading size={'xl'}> {t('Exhibition')}</Heading>
        }
    }

    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };

    const renderPlaceholder = () => {
        return (
            <ScrollView
                my={2}
                mt={4}
                mx={{base:0,md:4}}
            >
                <MasonryList
                    style={{alignSelf: 'stretch',
                        maxWidth:900,
                        marginLeft : 'auto',
                        marginRight : 'auto',
                    }}
                    contentContainerStyle={{
                        paddingHorizontal: 2,

                    }}
                    numColumns={isMobile ? 2 : 5}
                    data={new Array(isMobile ? 6 : 25).fill({})}
                    keyExtractor={(item) => uniqueId()}
                    renderItem={(item) => {
                        return (
                            <View
                                bg={'#dedede'}
                                height={225}
                                width={150}
                                m={2}
                                borderRadius={5}
                            >

                            </View>
                        );
                    }
                    }
                />

            </ScrollView>
        );
    }


    if(fetchError) {
        return (
            <Layout activePage={navigation.isFocused ? t('Exhibition') : ''}>
                {renderHeading()}
                    <Center flex={1}>
                        <Text>{t("There_was_a_problem_loading_your_data")}</Text>
                    </Center>
            </Layout>
        );
    }
    else if(companies.length <= 0) {
        return (
            <Layout activePage={navigation.isFocused ? t('Exhibition') : ''}>
                {renderHeading()}
                {renderPlaceholder()}
            </Layout>
        );
    }
    else return (
        <Layout activePage={navigation.isFocused ? t('Exhibition') : ''}>
            {renderHeading()}
                <CompanyFilters
                    companies={companies}
                    filteredCompanies={filteredCompanies}
                    setFilteredCompanies={setFilteredCompanies}
                />
                {filteredCompanies?.length <= 0 ? <Center flex={1}><Text>{t('No_results')}</Text></Center> :
                    renderExhibitors()}
        </Layout>
    );
}
