import React, {useEffect, useState} from "react";
import {Video,Audio} from "expo-av";
import {Platform, StyleSheet} from "react-native";
import JWPlayer from '@jwplayer/jwplayer-react';
import * as ScreenOrientation from 'expo-screen-orientation';

/*
Comme rien n'est simple...
Ici on utilise deux players différents.
Expo-AV pour ios/android
et
ReactJWPlayer pour le web
Car le format HLS ne fonctionnait pas sur l'un ou sur l'autre en fonction de l'ambiance...
 */


export default function VideoPlayer(props) {
    const video = React.useRef(null);
    const videoId = (Math.random() + 1).toString(36).substring(7);
    let index = 0;
    const [rerender, setRerender] = useState(true)
    const [loading, setLoading] = useState(true)
    const [playlist, setPlaylist] = useState([]);

    useEffect(() => {
        // case where we play a playlist and not a single file
        if(props.file && Array.isArray(props.file)) {
            const files = props.file.map(f => { return {file: f}});
            setPlaylist(files);
        }
        else if(props.file) {
            setPlaylist([{file: props.file}]);
        } else setPlaylist([]);
    }, [props.file, rerender]);

    useEffect(() => {
        Audio.setAudioModeAsync({
            playsInSilentModeIOS: !props.isMuted,
        })
       setTimeout(() => {
           setLoading(false)
       }, 2000);
    }, []);

    useEffect(() => {
        loadNewPlaybackInstance().then().catch(() => {
            console.log('Failed to load video file.');
        });
    }, [playlist]);

    async function loadNewPlaybackInstance() {
        const source = { uri: playlist.length > 1 ? playlist[index].file : playlist[0].file }

        await video.current?.loadAsync(source, {shouldPlay: true}).then().catch(() => {
            setRerender(prev => !prev);
        });
    }

    async function onPlaybackStatusUpdate(status) {
        if(status.isLoaded) {
            if(status.didJustFinish) {
                index = index + 1;
                loadNewPlaybackInstance().then().catch(() => {
                    console.log('Failed to load video file.');
                });
            }
        } else if(status.error) {
            console.log('Error on playback status update :');
            console.log(status.error);
        }
    }

    async function onFullScreenUpdate({fullscreenUpdate}) {
        switch (fullscreenUpdate) {
            case Video.FULLSCREEN_UPDATE_PLAYER_DID_PRESENT:
                await ScreenOrientation.unlockAsync() // only on Android required
                break;
            case Video.FULLSCREEN_UPDATE_PLAYER_WILL_DISMISS:
                await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT) // only on Android required
                break;
        }
    }

    if (Platform.OS === 'ios' || Platform.OS === 'android') {
        return (
            <Video
                ref={video}
                style={styles.video}
                onFullscreenUpdate={onFullScreenUpdate}
                useNativeControls={!props.noControls}
                resizeMode="contain"
                isMuted={props.isMuted ?? false}
                shouldPlay={true}
                isLooping={props.isLooping}
                onPlaybackStatusUpdate={onPlaybackStatusUpdate}
            />
        );
    } else return (
        !loading &&
        <JWPlayer
            playerId={videoId}
            library='https://cdn.jwplayer.com/libraries/3ZEZRGg3.js'
            file={playlist.length === 1 ? playlist[0] : null}
            playlist={playlist.length > 0 ? playlist : null}
            isAutoPlay={true}
            isMuted={props.isMuted ?? false}
            customProps={{
                repeat: props.isLooping,
                controls: !props.noControls
            }}
            aspectRatio={props.aspectRatio ? props.aspectRatio : 'inherit'}
        />
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: '#ecf0f1',
    },
    video: {
        width: '100%',
        height: '100%',
    }
});
