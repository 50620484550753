import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Heading,
    Input, Pressable,
    ScrollView,
    Spacer,
    Text,
    useTheme,
    useToast,
    View
} from "native-base";
import {Controller, useForm} from "react-hook-form";
import * as React from "react";
import {useTranslation} from "react-i18next";
import InputForm from "../components/InputForm";
import {useEffect, useState} from "react";
import HttpService from "../services/HttpService";
import {useDispatch, useSelector} from "react-redux";
import {logout, refreshData} from "../actions/auth";
import AccountService from "../services/AccountService";
import SupportButton from "../components/SupportButton";
import TermsOfUse from "../components/TermsOfUse";
import {AntDesign} from "@expo/vector-icons";
import {useIsFocused} from "@react-navigation/native";

export default function NewAccountScreen({navigation, ...props}) {
    const {colors} = useTheme();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const {control, handleSubmit, setError, formState: {errors}} = useForm();
    const {user} = useSelector(state => state.auth);
    const [isLoading, setIsLoading] = useState(false);
    const [serverError, setServerError] = useState({});
    const toast = useToast();
    const isFocused = useIsFocused();

    // Ping
    useEffect(() => {
        let interval;
        if(isFocused) {
            interval = AccountService.startPing(navigation, {page: 'new-account'});
        }
        return () => clearInterval(interval);
    }, [isFocused]);

    function onSubmit(data) {
        setIsLoading(true);

        return HttpService.postData('account/ready', data)
            .then((response) => {
                if (response.data.success) {
                    dispatch(refreshData()).then(() => {
                        navigation.navigate('Homepage');
                        toast.show({
                            title: "Account created !",
                            placement: "top",
                            bg: "emerald.500"
                        })
                    }, () => {
                        // feedback on dispatch error (user not logged)
                    });
                } else {
                    setServerError(response.data.message);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                return error.data;
            })
    }

    function handlePressLogout() {
        return dispatch(logout());
    }

    return (
        <Center
            flex={1}
            bg={colors['background'][50]}
        >
            <Flex
                justify={'center'}
                align={'flex-start'}
                borderColor={colors['backgroundLight'][50]}
                py={10}
                px={5}
                maxW={700}
                shadow={{md: 9}}
                borderRadius='lg'
                w={'100%'}
            >
                <ScrollView>

                    <Heading size={'2xl'}>{user.first_name + ' ' + user.last_name}</Heading>

                    <Heading size={'lg'}>{t('Ready_message')}</Heading>

                    <Text>{t('Ready_message2')} {t('Ready_message3')}</Text>
                    <View my={1}/>
                    <Text>{t('Ready_message4')}</Text>

                    <View width={'100%'}>
                        <InputForm name="email" defaultValue={''} placeholder={t('Email')}
                                   control={control}
                                   errors={errors}
                                   apiErrors={serverError}
                        />
                    </View>
                    <Flex direction={'row'} width={'100%'}>
                        <View flex={1}>
                            <InputForm name="password" defaultValue={''} placeholder={t('Password')}
                                       control={control}
                                       errors={errors}
                                       type={'password'}
                                       apiErrors={serverError}
                            />
                        </View>
                        <View mx={4}/>
                        <View flex={1}>
                            <InputForm name="password_confirmation" defaultValue={''}
                                       placeholder={t('Password_confirmation')}
                                       control={control}
                                       errors={errors}
                                       type={'password'}
                                       apiErrors={serverError}
                            />
                        </View>
                    </Flex>

                    <View width={'100%'} mt={2}>
                        <Button
                            onPress={handleSubmit(onSubmit)}
                            isLoading={isLoading}
                        >{t('Validate_my_account_button')}</Button>
                    </View>
                    <Flex direction={"row"} justify={{base: "flex-end"}} mt={10}
                          align={'center'} alignContent={"center"}>
                        <Button
                            variant={'unstyled'}
                            onPress={handlePressLogout}
                            p={0}
                        >
                            <Text fontSize={15}>{t('Or_logout')}</Text>
                        </Button>
                    </Flex>
                </ScrollView>
            </Flex>
        </Center>
    );
}
