
import {AspectRatio, Button, Image, Progress, Text, useTheme, View} from "native-base";
import {Animated, Platform} from "react-native";
import * as React from "react";
import {useEffect,useRef} from "react";
import MyListCheckbox from "./MyListCheckbox";
import {Link} from "@react-navigation/native";
import Session from "./Session";



function SessionLoader(props){
    const {
        sliderMobileWidth,
        sliderDesktopWidth,
        sliderMobileHeight,
        cardMobileWidth,
        height,
        cardWidth,
        deviceType,
        inCompany,
    } = props;


    const fadeAnim = useRef(new Animated.Value(0.75)).current;

    useEffect(() => {
        Animated.loop(
            Animated.sequence([
                Animated.timing(fadeAnim, {
                    toValue: 1,
                    duration: 500,
                    delay: 250,
                    useNativeDriver : true
                }),
                Animated.timing(fadeAnim, {
                    toValue: 0.75,
                    duration:500,
                    useNativeDriver : true

                })
            ]),
        ).start()
    },[])


    function getMinHeight(){
        if(deviceType == 1 || deviceType == 2){
            return !inCompany ? "55px" : "30px"
        }
        else if(deviceType == 3){
            return "100px"
        }
    }


    return (
        <>
            {Platform.OS !== 'web' ?
                // Mobile
                (
                    <Animated.View
                        style={{
                            opacity: fadeAnim,
                        }}
                    >

                        <View>
                            <View
                                style={{
                                    width : "100%",
                                    borderRadius: 5,
                                    backgroundColor: '#dedede',
                                    aspectRatio: 2/1
                                }}
                            >
                            </View>
                            <View px={1}
                                  pb={2}
                                  w={cardMobileWidth ? cardMobileWidth : "100%"}
                                  minHeight={getMinHeight()}
                                  borderBottomRightRadius={5}
                                  borderBottomLeftRadius={5}>
                                {/*{showHours ?*/}
                                <View
                                    style={{
                                        marginVertical: 7.5,
                                        width: '75%',
                                        height: 15,
                                        backgroundColor: '#e8e8e8',
                                    }}
                                >
                                </View>
                                {/*// : null}*/}
                                <View
                                    style={{
                                        width: '100%',
                                        height: 15,
                                        backgroundColor: '#e8e8e8',
                                    }}
                                >
                                </View>
                                <View
                                    style={{
                                        marginTop : 2,
                                        width: '100%',
                                        height: 15,
                                        backgroundColor: '#e8e8e8',
                                    }}
                                >
                                </View>
                            </View>
                        </View>
                    </Animated.View>
                ) :
                // Desktop
                (<>
                    <View w={cardWidth ? cardWidth : "100%"}>
                        {/*{showHours ?*/}
                        <Animated.View
                            style={{
                                opacity: fadeAnim,
                            }}
                        >
                            <View
                                style={{
                                    width : "100%",
                                    borderRadius: 5,
                                    backgroundColor: '#dedede',
                                    aspectRatio: 2/1
                                }}
                            >
                            </View>
                            <View px={1}
                                  pb={2}
                                  w={cardMobileWidth ? cardMobileWidth : "100%"}
                                  minHeight={getMinHeight()}
                                  borderBottomRightRadius={5}
                                  borderBottomLeftRadius={5}>
                                {/*{showHours ?*/}
                                <View
                                    style={{
                                        marginVertical: 7.5,
                                        width: '75%',
                                        height: 15,
                                        backgroundColor: '#e8e8e8',
                                    }}
                                >
                                </View>
                                {/*// : null}*/}
                                <View
                                    style={{
                                        width: '100%',
                                        height: 15,
                                        backgroundColor: '#e8e8e8',
                                    }}
                                >
                                </View>
                                <View
                                    style={{
                                        marginTop : 2,
                                        width: '100%',
                                        height: 15,
                                        backgroundColor: '#e8e8e8',
                                    }}
                                >
                                </View>
                            </View>
                        </Animated.View>
                    </View>
                </>)}
        </>
    );
}


export default SessionLoader;



