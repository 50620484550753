import LectureCard from "./LectureCard";
import {Heading, Stack, Text} from "native-base";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {Platform} from "react-native";

export default function SessionLectures(props) {
    const {t, i18n} = useTranslation();
    const isWeb = Platform.OS === "web";

    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };
    
    return (
        <>
            <Text fontSize={props.verySmall ? 'xs' : {base: 'md', md: 'xl'}}
                  mt={6} mb={2}
                  bold
                  color={'darkblue.800'}
                  textAlign={props.verySmall ? 'left' : {base: 'left', md: 'center'}}
            >
                {props.lectures.length > 0 && t('Lectures_of_the_session')}
            </Text>
            <Stack>{props.lectures.map((lecture) => {
                    if (lecture.title?.toLowerCase() !== 'chair') {
                        return <LectureCard key={uniqueId()}
                                            verySmall={props.verySmall}
                                            lecture={lecture}
                                            avatarHeight={isWeb ? '100%' : 'auto'}
                        />
                    }
                }
            )}
            </Stack>
        </>
    );
}
