import {Center, Flex, Heading, HStack, NativeBaseProvider, Text, useTheme, View, VStack} from "native-base";
import * as React from "react";
import Layout from "../layouts/Layout";
import {useCallback, useEffect, useMemo, useState} from "react";
import Publication from "../components/Publication";
import HttpService from "../services/HttpService";
import {ScrollView} from "native-base";
import {Dimensions, FlatList, Platform} from "react-native";
import PartnerListCard from "../components/PartnerListCard";
import CountResult from "../components/CountResult";
import {useTranslation} from "react-i18next";
import {useIsFocused} from "@react-navigation/native";
import AccountService from "../services/AccountService";

export default function KioskScreen({navigation}) {

    const [getPublications, setPublications] = useState([]);
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const isFocused = useIsFocused();
    const {colors} = useTheme();

    // Ping
    useEffect(() => {
        let interval;
        if(isFocused) {
            interval = AccountService.startPing(navigation, {page: 'speakers-list'});
        }
        return () => clearInterval(interval);
    }, [isFocused]);

    const uniqueId = useCallback(() => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    },[]);

    useEffect(() => {
        if (loading) {
            return null;
        }
        setLoading(true);
        HttpService
            .getData('partners/get/publications/congress')
            .then(response => {
                const pub = response.data.data.filter(publications => publications.partnerships.find(partnership => partnership.heyzine_url));
                setPublications(pub);
                setLoading(false);
            }).catch(error => {
            setLoading(false);
        })
    }, []);

    const renderHeader = useMemo(() => {
        if (Platform.OS === 'web' || (Dimensions.get('window').width > Dimensions.get('window').height)) {
            return (<Flex align={'center'}>
                <Heading size={'2xl'}>{t('kiosk')}</Heading>
            </Flex>);
        }else {
            return (<Flex>
                <Heading size={'xl'}>{t('kiosk')}</Heading>
            </Flex>);
        }
    },[getPublications]);

    const renderBody = useMemo(() => {
        if (loading) {
            return (    <>
                <FlatList
                    data={new Array(10).fill({})}
                    renderItem={({item}) => (
                        <Flex w={"100%"} px="2" py="2">
                            <View alignItems="center" w="100%" flexDirection={(Platform.OS === 'web' ? 'row' : 'column')}>
                                <View w={'100px'} height={'150px'} bg={"#e0e0e0"}></View>
                                <View w={'100%'} ml={(Platform.OS === 'web' ? 3 : 0)} mt={(Platform.OS === 'web' ? 0 : 4)} alignItems={(Platform.OS === 'web' ? 'flex-start' : 'center')}>
                                    <View
                                        style={{
                                            width: '30%',
                                            height: 15,
                                            backgroundColor: '#e8e8e8',
                                        }}
                                    >
                                    </View>
                                    <View
                                        style={{
                                            marginTop : 2,
                                            width: '50%',
                                            height: 15,
                                            backgroundColor: '#e8e8e8',
                                        }}
                                    >
                                    </View>
                                    <View
                                        style={{
                                            marginTop :2,
                                            width: '20%',
                                            height: 15,
                                            backgroundColor: '#e8e8e8',
                                        }}
                                    >
                                    </View>
                                </View>
                            </View>
                        </Flex>)}
                    keyExtractor={(item) => uniqueId()}
                />

            </>);
        } else {
            return ( <FlatList
                data={getPublications}
                p={'2'}
                keyExtractor={item => item.id}
                renderItem={({item}) => (
                    <Publication publication={item}></Publication>
                )}
            >
            </FlatList>);
        }
    });

    return (
        <>
            <Layout withBackButton activePage={navigation.isFocused ? 'Explore' : ''} >
                {renderHeader}
                {renderBody}
            </Layout>
        </>
    );
}
