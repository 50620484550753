import {CLEAR_CONGRESS, INIT_CONGRESS} from "./types";
import HttpService from "../services/HttpService";

export const initCongress = () => (dispatch) => {
    return HttpService.getData('congress/get')
        .then((response) => {
            dispatch({
                type: INIT_CONGRESS,
                payload: {
                    congress: response.data.data
                }
            });
            return Promise.resolve();
        })
        .catch((err) => {
            return Promise.reject();
        })
}

export const clearCongress = () => async (dispatch) => {
    return await dispatch({
        type: CLEAR_CONGRESS,
    })
};
