import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {FlatList, Platform} from "react-native";
import HttpService from "../services/HttpService";
import Constants from "expo-constants";
import {AspectRatio, Center, Flex, Heading, ScrollView, Spinner, Text, useTheme, View} from "native-base";
import Session from "../components/Session";
import Layout from "../layouts/Layout";
import CountResult from "../components/CountResult";
import Config from "../config.json";
import ProgramFilters from "../components/ProgramFilters";
import SessionLoader from "../components/SessionLoader";
import {useIsFocused} from "@react-navigation/native";
import pusher from "../pusher";

function ReplaySessionListScreen(props) {

    const {t, i18n} = useTranslation();
    const {user} = useSelector(state => state.auth);
    const {list} = useSelector(state => state.auth);
    const [replayConferences, setReplayConferences] = useState([]);
    const [filteredSessions, setFilteredSessions] = useState([]);
    const [fetchError, setFetchError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTheme, setSelectedTheme] = useState('');
    const [selectedAccess, setSelectedAccess] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState('');
    const [activeFilterCount, setActiveFilterCount] = useState(0);
    const [themes, setThemes] = useState([]);
    const isFocused = useIsFocused();
    const {colors} = useTheme();

    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };

    function getOnReplayConferences() {
        setIsLoading(true);
        HttpService
            .getData('session/get/replays/all')
            .then(response => {
                setReplayConferences(response.data.data);
                setFilteredSessions(response.data.data);
                setIsLoading(false);
            })
            .catch(error => {
                setFetchError(error);
                setIsLoading(false);
            })
    }

    function getThemes() {
        let rawThemes = replayConferences.map(session => session.theme);
        rawThemes = rawThemes.filter((theme, index, self) => (
            index === self.findIndex((t) => {
                if(theme && t) return t.name === theme.name;
                else return false;
            })
        ));
        rawThemes.sort((a, b) => {
            return a.name > b.name
        });
        setThemes(rawThemes);
    }

    useEffect(() => {
        if(replayConferences.length > 0) {
            getThemes();
        }
    }, [replayConferences]);

    useEffect(() => {
        getOnReplayConferences();

        const channelLive = pusher.subscribe('onsite-room');

        channelLive.bind('update', function (data) {
            getOnReplayConferences();
        });

        return () => {
            pusher.unsubscribe('onsite-room');
        };
    }, [isFocused]);

    function applyFilters({title, theme, access}) {
        let filterCount = 0;
        if (replayConferences.length > 0) {
            // this is the only way to truly create a copy of an array of objects in JS
            let filteredByTheme = JSON.parse(JSON.stringify(replayConferences));

            if (theme !== '') {
                filteredByTheme = filteredByTheme.filter((session) => session.theme?.name === theme);
                filterCount++;
            }

            let filteredByAccess = JSON.parse(JSON.stringify(filteredByTheme));

            if (access) {
                filteredByAccess = filteredByAccess.filter((session) => session.access === true);
                filterCount++;
            }

            let filteredByTitle = JSON.parse(JSON.stringify(filteredByAccess));

            if (title !== '') {
                filteredByTitle = filteredByTitle.filter(
                    (session) => (session.subtitle?.toLowerCase().includes(title.toLowerCase()) || session.title?.toLowerCase().includes(title.toLowerCase()))
                );
                filterCount++;
            }

            setFilteredSessions(filteredByTitle);
            setActiveFilterCount(filterCount);
        }
    }

    const renderItemContent = (item, index) => {
        return (
            <Session
                item={item}
                isReplay
                key={item.id}
                cardWidth={240}
                cardMobileHeight={'100%'}
                cardMobileWidth={'100%'}
            />
        )
    }

    function renderSession({item}) {
        if(Platform.OS === 'web') {
            // Desktop
            return (
                    <View key={uniqueId()} p={2} width={240} mx={2}>
                        {renderItemContent(item)}
                    </View>
            );
        }
        else {
            // Mobile
            return (
                <View key={uniqueId()} p={1} width={'50%'}>
                    {renderItemContent(item)}
                </View>
            );
        }
    }

    const renderHeader = () => {
        if (Platform.OS === 'web') {
            return (
                <Flex
                    w={'100%'}
                    align={'center'}
                    _dark={{borderColor: colors['backgroundLight'][50],}}
                    borderBottomWidth={0.5}
                    p={2}
                >
                    <Heading size={'2xl'}>{t('Replay_available')}</Heading>
                    <Flex w={'100%'} direction={'row'} justify={'space-around'} align={'flex-end'}>
                        <View>
                            <CountResult result={filteredSessions?.length}/>
                        </View>
                    </Flex>
                </Flex>
            );
        } else return (
            <Flex>
                <Heading>{t('Replay_available')}</Heading>
                <Center paddingBottom={2}>
                    <CountResult result={filteredSessions?.length}/>
                </Center>
            </Flex>);
    }

    const renderPlaceholder = () => {
        let items = [];
        for (let i = 0; i < (Platform.OS !== 'web' ? 6 : 15); i++) {
            items.push(
                <View
                    key={i}
                    // justifyContent="center"
                    alignItems="center"
                    w={252.5*(Platform.OS !== 'web' ? 0.675 : 1)}
                    px={1}
                    my={2}
                    flexBasis={Platform.OS !== 'web' && {md:'50%'}}

                >
                    <SessionLoader></SessionLoader>
                </View>
            )
        }
        return (items);
    }

    if(isLoading) {
        return (
            <Layout withBackButton activePage={props.navigation.isFocused ? 'ReplaySessionList' : ''}>

                {renderHeader()}
                    <Flex direction={'row'} flexWrap={'wrap'} w={Platform.OS === 'web' ? 260*3 :'100%'} mx={'auto'} justifyContent={'center'}
                    >
                        {renderPlaceholder()}
                    </Flex>
            </Layout>
        );
    }
    else if(filteredSessions.length > 0) {
        return (
            <Layout withBackButton activePage={props.navigation.isFocused ? 'ReplaySessionList' : ''}>

                <ProgramFilters
                    themes={themes}
                    apply={applyFilters}
                    selectedTheme={selectedTheme}
                    selectedAccess={selectedAccess}
                    selectedTitle={selectedTitle}
                    setSelectedTheme={setSelectedTheme}
                    setSelectedAccess={setSelectedAccess}
                    setSelectedTitle={setSelectedTitle}
                    count={activeFilterCount}
                />

                {renderHeader()}

                <Center flex={1}>
                    <FlatList
                        data={filteredSessions}
                        renderItem={(item) => renderSession(item)}
                        keyExtractor={uniqueId}
                        numColumns={Platform.OS === 'web' ? 3 : 2}
                        initialNumToRender={12}
                    />
                </Center>
            </Layout>);
    }
    else {
        return (
            <Layout withBackButton activePage={props.navigation.isFocused ? 'ReplaySessionList' : ''}>
                <Center flex={1}>
                </Center>
            </Layout>
        );
    }

}

export default ReplaySessionListScreen





