import {Button, Center, Flex, Text, useTheme, View} from "native-base";
import * as React from "react";
import {Link} from "@react-navigation/native";
import {FontAwesome} from "@expo/vector-icons";
import {useTranslation} from "react-i18next";
import {Dimensions, Platform, StyleSheet} from 'react-native';



function TermsOfUse(props) {
    const {colors} = useTheme();
    const {t, i18n} = useTranslation();


    return (
        <>
            <View
                p={props.isOnLogin ? 0 : 2}
                my={props.isOnLogin ? 0 : 2}
                style={props.isDesktop ? styles.desktop : null}
                opacity={props.isOnLogin ? 0.5 : 1}
                position={'relative'}
            >
                <Link style={{
                    color: 'white',
                    zIndex: 10,
                    elevation: 10,
                    fontFamily:"Poppins_400Regular"
                }}
                      to={{screen: 'TermsOfUse', params: {loggedIn: props.loggedOut}}}
                >
                    <Flex direction='row' justifyContent={'center'} alignItems='center' alignContent={'center'}  mx={'auto'}>
                        <FontAwesome name="external-link" size={props.isOnLogin ? 18 : 20} color="#1F2357"/>
                        <Text m={2} fontSize={props.isOnLogin && (Platform.OS == "web" && Dimensions.get("window").width < 1300) ? "xs" :"sm"} style={{fontFamily:"Poppins_400Regular"}}>{t('Read_terms_of_use')}</Text>
                    </Flex>
                </Link>

            </View>
        </>
    )
}

export default TermsOfUse;

const styles = StyleSheet.create({
    desktop: {
        position : "absolute",
        right : 0,
        bottom : 0,
        opacity : 0.5
    }
});

