import {Box, Center, useTheme, Spinner} from "native-base";
import * as React from "react";
import '@expo/match-media';
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useIsFocused} from "@react-navigation/native";
import * as Device from "expo-device";
import * as ScreenOrientation from 'expo-screen-orientation';
import LayoutForMobile from './LayoutForMobile';
import LayoutForTablet from './LayoutForTablet';
import LayoutForDesktop from './LayoutForDesktop';

export default function Layout(props) {
    const [emptyLayout, setEmptyLayout] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [deviceType, setDeviceType] = useState(null);
    const {colors} = useTheme();
    const {congressUser} = useSelector(state => state.auth);
    const isFocused = useIsFocused();

    useEffect(() => {
        // We check redux data each time this is focused, even though it increases the number of renders
        if ((congressUser && congressUser.profile_completed && !props.emptyLayout)) {
            setEmptyLayout(false);
        }
    }, [isFocused]);

    useEffect(() => {
        async function setLayout() {
            const type = await getDeviceType();

            setDeviceType(type);

            if(type === 1 && !props.orientationFree) {
                await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
            }
        }

        setLayout().then(() => setIsLoading(false));
    }, []);

    async function getDeviceType() {
        return await Device.getDeviceTypeAsync();
    }

    if (isLoading) {
        return (
            <Center flex={1} p={4} bg={colors['background'][50]}>
                <Spinner color="#5766D6" />
            </Center>
        );
    } else if (emptyLayout) {
        return (
            <Center flex={1} bg={colors['background'][50]}>
                <Box
                    flex={1}
                    maxW={'1400px'}
                    zIndex={2}
                >
                    {props.children}
                </Box>
            </Center>
        );
    } else if (deviceType === 1) {
        return (
            <LayoutForMobile activePage={props.activePage} withBackButton={props.withBackButton}>
                {props.children}
            </LayoutForMobile>
        );
    } else if (deviceType === 3) {
        return (
            <LayoutForDesktop activePage={props.activePage} withBackButton={props.withBackButton}>
                {props.children}
            </LayoutForDesktop>
        );
    } else if (deviceType === 2) {
        return (
            <LayoutForTablet activePage={props.activePage} withBackButton={props.withBackButton}>
                {props.children}
            </LayoutForTablet>
        );
    } else {
        return (
            <LayoutForDesktop activePage={props.activePage} withBackButton={props.withBackButton}>
                {props.children}
            </LayoutForDesktop>
        );
    }
}
