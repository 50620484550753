import React from "react";
import {Text, useTheme, View} from "native-base";

export default function ReplayTag(props) {

    const {colors} = useTheme();

    return(
        <View
            bg={'white'}
            position={props.relative ? 'relative' : 'absolute'}
            height={props.relative ? 5 : "auto"}
            top={2}
            right={props.right ? {base: 2, md: 4} : null}
            left={props.right ? null : {base: 2, md: 4}}
            px={1}
            borderRadius={'sm'}
            backgroundColor={colors['background'][100]}
        >
            <Text fontSize={!props.relative ? {base:"2xs",xl:'xs'} : "xs"} color={'black'}>{"replay".toUpperCase()}</Text>
        </View>
    );
}
