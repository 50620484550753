import React,{useEffect, useState} from "react";
import {Text, View} from "native-base";
import { FontAwesome } from '@expo/vector-icons';
import * as Device from "expo-device";
import AccountService from "../services/AccountService";



export default function FullTag(props) {

    const [deviceType,setDeviceType] = useState()


    useEffect(() => {
        async function setLayout() {
            const type = await getDeviceType();
            setDeviceType(type);
        }
        setLayout();
    }, []);

    async function getDeviceType() {
        return await Device.getDeviceTypeAsync();
    }


    return (
    <View
        bg={'red.700'}
        position={props.relative ?  'relative' : 'absolute'}
        height={props.relative ? 5 : 'auto'}
        top={2}
        mr={props.relative ? 1 : 0}
        left={!props.relative ? {base: 2, xl: 4 } : 0}
        px={1}
        borderRadius={'sm'}
    >
        <Text fontSize={!props.relative ? {base:"2xs",xl:'xs'} : "xs"} color={'white'}><FontAwesome name="group" size={deviceType == 1 && !props.relative ? 10 : 12} color="white"/>  {"Room full"}</Text>
    </View>
    );
}
