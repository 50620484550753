import React from 'react';
import {StyleSheet, View, TextInput, ActivityIndicator} from 'react-native';
import PropTypes from 'prop-types';
import {connectSearchBox} from 'react-instantsearch-native';
import {Button, Icon, Input} from "native-base";
import {MaterialIcons} from "@expo/vector-icons";
import {useTranslation} from "react-i18next";


const styles = StyleSheet.create({
    container: {
        padding: 16,
        backgroundColor: '#252b33',
    },
    input: {
        height: 48,
        padding: 12,
        fontSize: 16,
        backgroundColor: '#fff',
        borderRadius: 4,
        borderWidth: 1,
        borderColor: '#ddd',
        shadowColor: '#000',
        shadowOffset: {width: 0, height: 2},
        shadowOpacity: 0.2,
        shadowRadius: 2,
    },
});

const SearchBox = ({currentRefinement, refine, isSearchStalled}) => {
    const {t, i18n} = useTranslation();

    function handlePressClose() {
        return refine('');
    }

    return (
        <>
            <Input
                value={currentRefinement}
                onChangeText={value => refine(value)}
                placeholder={t('Search')}
                width={'100%'}
                borderRadius={4}
                py="3"
                px="1"
                fontSize={14}
                _web={{
                    _focus: {borderColor: 'darkblue.800', style: {boxShadow: 'none'}},
                }}
                InputLeftElement={
                    <Icon
                        m="2"
                        ml="3"
                        size="6"
                        color="gray.400"
                        as={<MaterialIcons name="search"/>}
                    />
                }
                InputRightElement={
                    <Button
                        variant={'unstyled'}
                        size={'xs'}
                        onPress={handlePressClose}
                    >
                        <Icon
                            m="2"
                            mr="3"
                            size="6"
                            color="gray.400"
                            as={<MaterialIcons name="close"/>}
                        />
                    </Button>
                }
            />
            <ActivityIndicator animating={isSearchStalled} />
        </>
    );
}
SearchBox.propTypes = {
    currentRefinement: PropTypes.string.isRequired,
    refine: PropTypes.func.isRequired,
};

export default connectSearchBox(SearchBox);
