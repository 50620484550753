import React,{useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Slider from "../components/Slider";
import Session from "./Session";
import {useSelector} from "react-redux";
import * as Device from "expo-device";

const CompanySession = (props) => {

    const {id, companySessions, sliderMobileWidth, sliderDesktopWidth, deviceType} = props
    const {t, i18n} = useTranslation();
    const {list} = useSelector(state => state.auth);

    let isLive = false;
    let isSoon = false;
    let isReplay = false;

    const itemContent = (item, index) => {
        const {subtitle} = item
        item.liveCurrent.length > 0 ? isLive = true : isLive = false;
        item.replay ? isReplay = true : isReplay = false;

        let isChecked = false;
        if (list.find(listItem => listItem.entity_id === item.id)) isChecked = true;
        return (
            <>
                <Session
                    item={item}
                    isLive={isLive}
                    progressBar={isLive}
                    showHours={isLive}
                    isSoon={isSoon}
                    isReplay={isReplay}
                    roomId={item.room_session_id}
                    withListButton={true}
                    isChecked={isChecked}
                    screen={`CompanyProfile/${id}`}
                    tagRight
                    deviceType={deviceType}
                    inCompany
                />
            </>
        );
    }

    return (
        <Slider
            // sliderTitle={t('Company_sessions')}
            data={companySessions}
            sliderMobileWidth={sliderMobileWidth *(deviceType == 1 ? 0.675 : 1)}
            sliderMobileHeight={200}
            sliderDesktopWidth={sliderDesktopWidth}
            // sliderDesktopHeight={300}
            itemContent={itemContent}
            btnHeight={120}
            inCompany
            videos
        />
    );
};

export default CompanySession;

CompanySession.defaultProps = {
    sliderMobileWidth: 240,
    sliderDesktopWidth: 240,

}
