import {Flex, Text, useTheme, View} from "native-base";
import * as React from "react";

export default function StatsTopBlock(props) {
    const {colors} = useTheme();

    return (
        <Flex maxW={400} py={4} backgroundColor={colors['background'][100]} borderRadius="lg" m={4}>
            <Text fontSize={'2xl'} px={4} fontWeight={"bold"} mb={2}>{props.title}</Text>
            {props.items.map(item => {
                return <View backgroundColor={colors['background'][100]['600']} p={4} my={1.5} mb={0}>
                    {item}
                </View>
            })}
        </Flex>
    );
}
