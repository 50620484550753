import {useLinkProps} from "@react-navigation/native";
import React from "react";
import {Platform, TouchableOpacity} from "react-native";
import {Text, View} from "native-base";

const LinkBtn = ({to, action, children, ...rest}) => {
    const {onPress, ...props} = useLinkProps({to, action});
    const [isHovered, setIsHovered] = React.useState(false);
    if (Platform.OS === 'web') {
        return (
            <View
                onClick={onPress}
                onMouseEnter={hoverOn}
                onMouseLeave={hoverOff}
                style={{transitionDuration: '150ms', opacity: isHovered ? 0.7 : 1, width: '100%'}}
                {...props}
                {...rest}
            >
                <Text>{children}</Text>
            </View>
        );
    }

    function hoverOn() {
        return setIsHovered(true);
    }

    function hoverOff() {
        return setIsHovered(false);
    }

    return (
        <TouchableOpacity onPress={onPress} {...props} {...rest} >
            <Text>{children}</Text>
        </TouchableOpacity>
    );
};

export default LinkBtn;
