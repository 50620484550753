import * as React from "react";
import {useEffect, useState} from "react";
import Slider from "./Slider";
import HttpService from "../services/HttpService";
import {useTranslation} from "react-i18next";
import Session from "./Session";
import {useSelector} from "react-redux";
import {Center, Flex, ScrollView, Spinner, Text, useTheme, View} from "native-base";
import Config from "../config.json";
import { Entypo } from '@expo/vector-icons';
import {Platform} from "react-native";
import SessionLoader from "./SessionLoader";
import pusher from "../pusher";

function ConferencesLive(props) {

    const {
        sliderMobileWidth,
        sliderDesktopWidth,
        deviceType
    } = props

    const {user} = useSelector(state => state.auth);
    const {list} = useSelector(state => state.auth);
    const {colors} = useTheme();
    const {t, i18n} = useTranslation();
    const [currentLiveConferences, setCurrentLiveConferences] = useState([]);
    const [sortedCurrentLiveConferences, setSortedCurrentLiveConferences] = useState([]);
    const [fetchError, setFetchError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    let isLive = false;
    let isSoon = false;

    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };

    function getCurrentLiveConferences() {
        setIsLoading(true);
        HttpService
            .getData('room/get/liveSessions')
            .then(response => {
                const arr = response.data.data.map(r => {
                    return r.live_current_session
                });
                setCurrentLiveConferences(arr);
                setIsLoading(false);

            })
            .catch(error => {
                setIsLoading(false);
                setFetchError(error);
                setIsLoading(false);
            })
    }

    useEffect(() => {
        getCurrentLiveConferences();
    }, [props.pusherUpdate]);

    useEffect(() => {
        setSortedCurrentLiveConferences(getSortedCurrentLiveConferences());
    }, [currentLiveConferences]);

    /*
     * Return an array of conferences sorted by user's themes preferences
     */
    const getSortedCurrentLiveConferences = () => {
        let temp = [];
        let sortedArray = []
        let copyArray = [...currentLiveConferences]

        for (let j = 1; j < 5; j++) {
            for (let i = 0; i < copyArray.length; i++) {
                if (user[`themes_${j}`] === copyArray[i].theme_id) {
                    temp = copyArray.splice(i, 1);
                    sortedArray = [...sortedArray, ...temp]
                }
            }
        }
        return [...sortedArray, ...copyArray];

    }

    const itemContent = (item) => {
        const timeBegin = Date.parse(item?.datetime_begin);
        item.liveCurrent.length > 0 ? isLive = true : isLive = false;
        timeBegin > Date.now() ? isSoon = true : isSoon = false;
        let isChecked = false;
        if (list.find(listItem => listItem.entity_id === item.id)) isChecked = true;
        return (
            <Session
                item={item}
                // progressBar={isLive}
                isLive={isLive}
                isSoon={isSoon}
                showHours
                roomId={item.room_session_id}
                withListButton={true}
                isChecked={isChecked}
                screen={'Live'}
                tagRight
                deviceType={deviceType}

            />
        )
    }


    const renderPlaceholderSlider = () => {
        let items = [];
        for (let i = 0; i < (Platform.OS !== 'web' ? 12 : 18); i++) {
            items.push(
                <View
                    key={i}
                    // justifyContent="center"
                    alignItems="center"
                    w={sliderMobileWidth*(deviceType == 1 ? 0.675 : 1)}
                    px={1}
                    my={2}
                    mx={{lg:2}}
                >
                    <SessionLoader></SessionLoader>
                </View>
            )
        }
        return (items);
    }


    if(sortedCurrentLiveConferences.length > 0) {
        return (
            <View my={2} width={'100%'} mx={{base:0,lg:3}} mt={6}>
                <Flex direction={'row'} align={'center'} width={{base:'100%',md:'95%'}} justifyContent={'space-between'} mb={2}>
                    <Flex direction={'row'} align={'center'}>
                        <Entypo name="video-camera" size={28} color="#1F2357" />
                        <Text fontSize={"lg"} fontWeight="bold" ml={2}>{t('Conferences_live_now')}</Text>
                    </Flex>
                </Flex>
                <Flex direction={'row'} flexWrap={'wrap'} w={'100%'} mx={'auto'}>
                    {
                        sortedCurrentLiveConferences.length > 0 && sortedCurrentLiveConferences.map((item, index) => {
                            return <View
                                key={item.id}
                                // justifyContent="center"
                                alignItems="center"
                                w={sliderMobileWidth*(deviceType == 1 ? 0.675 : 1)}
                                px={1}
                                my={2}
                                mx={{lg:2}}
                            >
                                {itemContent(item,index)}
                            </View>
                        })
                    }
                </Flex>


            </View>
        );
    }
    else if(isLoading) {
        return (
            <View my={2} width={'100%'} mx={{base:0,lg:3}} mt={6}>
                <Flex direction={'row'} align={'center'} width={{base:'100%',md:'95%'}} justifyContent={'space-between'} mb={2}>
                    <Flex direction={'row'} align={'center'}>
                        <Entypo name="video-camera" size={28} color="#1F2357" />
                        <Text fontSize={"lg"} fontWeight="bold" ml={2}>{t('Conferences_live_now')}</Text>
                    </Flex>
                </Flex>
                <Flex direction={'row'} flexWrap={'wrap'} w={'100%'} mx={'auto'}>
                    {renderPlaceholderSlider()}
                </Flex>
            </View>
        );
    }
    else return <></>;
}

export default ConferencesLive

ConferencesLive.defaultProps = {
    sliderMobileWidth: 240,
    sliderDesktopWidth: 240,

}




