import * as React from 'react';
import {Center, Flex, Spinner} from "native-base";
import {Text} from "react-native";

export default function LoadingFallback(props) {
    return (
        <Center width={"100%"} height={"100%"} bg={"#fff"}>
            <Spinner color="#5766D6"  size={"lg"} mb={4} style={{transform: [{scale: 1.5}]}}/>
            <Flex direction={'row'}>
                <Text color="#1F2357" fontWeight={"light"} fontSize="3xl">
                    THE APP IS
                </Text>
                <Text color="#5766D6" fontSize="3xl" mt={props.deviceType === 3 || props.deviceType === 1 ? 1 : 0}
                      fontWeight={"regular"}>
                    {" "}LOADING
                </Text>
            </Flex>
            <Text fontWeight={"light"} opacity={0.5} fontSize="xl" mt={-1.5} color={"#1F2357"}>
                Please wait<Text>.</Text><Text>.</Text><Text>.</Text>
            </Text>
        </Center>
    );
}
