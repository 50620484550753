import * as React from "react";
import {useEffect, useState} from "react";

import Slider from "./Slider";
import HttpService from "../services/HttpService";
import {useTranslation} from "react-i18next";
import Session from "./Session";
import {useSelector} from "react-redux";
import {Center, Flex, ScrollView, Spinner, Text, useTheme, View} from "native-base";
import Constants from "expo-constants";
import {Link} from "@react-navigation/native";
import Config from "../config.json";
import { MaterialIcons } from '@expo/vector-icons';
import ViewAll from "./ViewAll";
import SessionLoader from './SessionLoader'
import {Platform} from "react-native";
import pusher from "../pusher";
import {isAfter} from "date-fns";


function ConferencesReplay(props) {

    const {
        sessionImgMobileWidth,
        sessionImgMobileHeight,
        sessionImgDesktopWidth,
        sessionImgDesktopHeight,
        sliderMobileWidth,
        sliderDesktopWidth,
        deviceType
    } = props

    const {t, i18n} = useTranslation();
    const {user} = useSelector(state => state.auth);
    const {list} = useSelector(state => state.auth);
    const [replayConferences, setReplayConferences] = useState([]);
    const [fetchError, setFetchError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {colors} = useTheme();
    const {congress} = useSelector(state => state.global);
    const MAX_ITEMS = 20;

    function getOnReplayConferences() {
        setIsLoading(true);
        HttpService
            .getData('session/get/replays/homepage')
            .then(response => {
                setReplayConferences(response.data.data);
                setIsLoading(false);
            })
            .catch(error => {
                setFetchError(error);
                setIsLoading(false);
            })
    }

    useEffect(() => {
        getOnReplayConferences();
    }, [props.pusherUpdate]);

    const renderItemContent = (item, index) => {
        // const timeBegin = Date.parse(item?.datetime_begin);
        // item.liveCurrent.length > 0 ? isLive = true : isLive = false;
        // timeBegin > Date.now() ? isSoon = true : isSoon = false;
        let isChecked = false;
        if (list.find(listItem => listItem.entity_id === item.id)) isChecked = true;
        return (
            <Session
                item={item}
                isReplay
                isChecked={isChecked}
                key={item.id}
                tagRight
                deviceType={deviceType}
            />
        )
    }


    const renderPlaceholder = () => {
        let items = [];
        for (let i = 0; i < (Platform.OS !== 'web' ? 12 : 18); i++) {
            items.push(
                <View
                    key={i}
                    // justifyContent="center"
                    alignItems="center"
                    w={sliderMobileWidth*(deviceType == 1 ? 0.675 : 1)}
                    px={1}
                    my={2}
                    mx={{lg:2}}
                >
                    <SessionLoader></SessionLoader>
                </View>
            )
        }
        return (items);
    }

    if (replayConferences?.length > 0) {
        return (
            <View my={2} width={'100%'} mx={{base:0,lg:3}} mt={6}>
                <Flex direction={'row'} align={'center'} width={{base:'100%',md:'95%'}} justifyContent={'space-between'} mb={2}>
                    <Flex direction={'row'} align={'center'}>
                        <MaterialIcons name="replay-circle-filled" size={32} color="#1F2357" />
                        <Text fontSize={"lg"} fontWeight="bold" ml={1}>{t('Watch_replays_now')}</Text>
                    </Flex>
                    <ViewAll to={'ReplaySessionList'}></ViewAll>
                </Flex>
                {isAfter(new Date(), congress.date_end) ?
                    <Slider
                        data={replayConferences?.slice(0,MAX_ITEMS - 1)}
                        sliderMobileWidth={sliderMobileWidth*(deviceType == 1 ? 0.675 : 1)}
                        sliderDesktopWidth={sliderDesktopWidth}
                        itemContent={renderItemContent}
                        btnHeight={deviceType == 1 ? 100 : 120}
                        videos
                    /> :
                    <Flex direction={'row'} flexWrap={'wrap'} w={'100%'} mx={'auto'}>
                        {
                            replayConferences?.length > 0 && replayConferences.map((item, index) => {
                                return <View
                                    key={item.id}
                                    // justifyContent="center"
                                    alignItems="center"
                                    w={sliderMobileWidth*(deviceType == 1 ? 0.675 : 1)}
                                    px={1}
                                    my={2}
                                    mx={{lg:2}}
                                >
                                    {renderItemContent(item,index)}
                                </View>
                            })
                        }
                    </Flex>
                }

            </View>
        );
    } else if (isLoading) {
        return (
            <View my={2} width={'100%'} mx={{base:0,lg:3}} mt={6}>
                <Flex direction={'row'} align={'center'} width={{base:'100%',md:'95%'}} justifyContent={'space-between'} mb={2}>
                    <Flex direction={'row'} align={'center'}>
                        <MaterialIcons name="replay-circle-filled" size={32} color="#1F2357" />
                        <Text fontSize={"lg"} fontWeight="bold" ml={1}>{t('Watch_replays_now')}</Text>
                    </Flex>
                    <ViewAll to={'ReplaySessionList'}></ViewAll>
                </Flex>
                <Flex direction={'row'} flexWrap={'wrap'} w={'100%'} mx={'auto'}>
                    {renderPlaceholder()}
                </Flex>
            </View>
        );
    } else return <></>;
}

export default ConferencesReplay

ConferencesReplay.defaultProps = {
    sliderMobileWidth: 240,
    sliderDesktopWidth: 240,

}




