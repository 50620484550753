import {Button, Checkbox, CheckIcon, Flex, Input, Modal, Select, Spacer, Text, useTheme} from "native-base";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import FilterButton from "./FilterButton";
import {Platform} from "react-native";
import {MaterialIcons} from "@expo/vector-icons";

export default function ProgramFilters({
                                           selectedTheme,
                                           selectedAccess,
                                           selectedTitle,
                                           setSelectedTheme,
                                           setSelectedTitle,
                                           setSelectedAccess,
                                           ...props
                                       }) {
    const [t, i18n] = useTranslation();
    const {colors} = useTheme();
    const [showFilters, setShowFilters] = useState(false);
    const [formTitle, setFormTitle] = useState('');
    const [formAccess, setFormAccess] = useState(false);
    const [formTheme, setFormTheme] = useState('');

    function resetFilters() {
        setFormAccess(false);
        setFormTheme('');
        setFormTitle('');
    }

    function toggleFilters() {
        setShowFilters(!showFilters);
    }

    function applyAndToggleFilters() {
        props.apply({title: formTitle, theme: formTheme, access: formAccess});
        toggleFilters();
    }

    function handleInputChange(input) {
        return setFormTitle(input);
    }

    function renderFilters() {
        let filterButtons = [];

        filterButtons.push(
            <Input
                value={formTitle}
                onChangeText={handleInputChange}
                placeholder={t('Search_a_session')}
                autoFocus={false}
                key={0}
                my={2}
            />
        );

        if (props.themes?.length > 0) {
            filterButtons.push(
                Platform.OS === 'web' ?
                <Select
                    selectedValue={formTheme}
                    minWidth="200"
                    accessibilityLabel={t('Filter_by_topic_series')}
                    placeholder={t('Filter_by_topic_series')}
                    _selectedItem={{
                        bg: colors['actionDark'][50],
                        endIcon: <CheckIcon size="5"/>,
                    }}
                    onValueChange={(itemValue) => setFormTheme(itemValue)}
                    key={formTheme}
                    my={2}
                >
                    <Select.Item label="" value=""/>
                    {props.themes?.map((theme) => (<Select.Item label={theme.name} value={theme.name} key={theme.id}/>))}
                </Select> :
                    <Select
                        selectedValue={formTheme}
                        placeholder={t("Filter_by_topic_series")}
                        _selectedItem={{
                            bg: colors.white,
                            paddingRight:2,
                            _icon: {
                                color: '#fff'
                            },
                            _text: {
                                overflow: "hidden",
                                whiteSpace: "normal",
                            },
                            startIcon: <MaterialIcons name={"radio-button-checked"} size={20} color={colors['action'][50]}></MaterialIcons>,
                        }}
                        _item={{
                            bg:colors.white,
                            paddingRight:10,
                            _text: {
                                overflow: "hidden",
                                whiteSpace: "normal",
                            },
                            startIcon:<MaterialIcons name={"radio-button-unchecked"} size={20} color={colors}></MaterialIcons>
                        }}
                        _customDropdownIconProps={{
                            size: 5
                        }}
                        _actionSheetContent={{
                            bg: colors.white
                        }}
                        p={2}
                        my={2}
                        onValueChange={(itemValue) => setFormTheme(itemValue)}
                    >
                        <Select.Item label="" value=""/>
                        {props.themes?.map((theme) => (<Select.Item label={theme.name} value={theme.name} key={theme.id}/>))}
                    </Select>
            );
        }

        /*
        filterButtons.push(
            <Checkbox
                isChecked={formAccess}
                onChange={(isSelected) => setFormAccess(isSelected)}
                aria-label={t('Only_session_I_access')}
                size='md'
                key={0}
                my={2}
            ><Text color={'#1F2357'} ml={2} opacity={0.5}>{t('Only_session_I_access')}</Text></Checkbox>
        );
         */

        return filterButtons;
    }

    return (
        <>
            <FilterButton toggleFilters={toggleFilters} count={props.count}/>
            <Modal isOpen={showFilters} onClose={toggleFilters}>
                <Modal.Content maxWidth={'600px'}>
                    <Modal.CloseButton/>
                    <Modal.Body pt={8}>

                        {renderFilters()}

                        <Flex direction={'row'} mt={4}>
                            <Button
                                flex={10}
                                onPress={resetFilters}
                                variant={'outline'}
                                _text={{
                                    color: colors['action'][50]
                                }}
                            >{t('Reset_filters')}</Button>
                            <Spacer flex={1}/>
                            <Button
                                flex={10}
                                onPress={applyAndToggleFilters}>Apply filters</Button>
                        </Flex>
                    </Modal.Body>
                </Modal.Content>
            </Modal>
        </>
    );
}
