import {
    Center,
    Heading, Flex, Text, View, ScrollView, Button, useTheme, Spinner
} from "native-base";
import * as React from "react";
import Layout from "../layouts/Layout";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import AccountService from "../services/AccountService";
import HttpService from "../services/HttpService";
import { MaterialCommunityIcons,AntDesign } from '@expo/vector-icons';
import {Linking, Platform} from "react-native";
import {Link, useIsFocused} from "@react-navigation/native";
import Config from "../config.json";
import {useSelector} from "react-redux";



export default function ExploreScreen({navigation}) {
    const {t} = useTranslation();
    const {colors} = useTheme();
    const [registration, setRegistration] = useState({});
    const {user} = useSelector(state => state.auth);
    const {congress} = useSelector(state => state.global);
    const [badgeProduct, setBadgeProduct] = useState({});
    const isFocused = useIsFocused();

    // Ping
    useEffect(() => {
        let interval;
        if(isFocused) {
            interval = AccountService.startPing(navigation, {page: 'my-badge'});
        }
        return () => clearInterval(interval);
    }, [isFocused]);

// Data
    useEffect(() => {
        HttpService
            .getData('account/my-badge')
            .then(response => {
                setRegistration(response.data.data.registration);
                setBadgeProduct(response.data.data.badgeProduct);
            })
            .catch(error => {
                console.log('Error: ' + error)
            })

    }, []);

    function handlePressOnConfletter() {
        if(Platform.OS === 'web'){
            return window.open(registration.confletter_url,"_blank")
        }
        else{
            return Linking.openURL(registration.confletter_url)
        }
    }

    function handlePressUpgradeBadge(){
        Linking.openURL('https://'+(Config.environment=='production'?'www':'dev')+'.imcas.com/login-fast/'+user.personal_token+'/?go=https://'+(Config.environment=='production'?'www':'dev')+'.imcas.com/en/registration/'+congress.slug+'/upgrade/'+registration.id);
    }


    return (
        <Layout withBackButton activePage={navigation.isFocused ? 'Explore' : ''}>
            <Center px={4}>
                <Flex w={'100%'} maxW={900} align={{base: 'flex-start', md: 'center'}}>
                    <Heading size={'2xl'}>{t('My_badge')}</Heading>
                </Flex>
            </Center>

            {(registration && registration.id) ?

                <ScrollView>
                    <Flex direction={'column'} mt={6} alignItems={"center"}>
                        <View p={6} width={{base: "100%" ,lg:"75%"}} >
                            <Button
                                onPress={handlePressOnConfletter}>{t('View_confirmation_letter')}</Button>
                        </View>

                        <View p={6} width={{base: "100%" ,lg:"75%"}} >
                            <Button
                                onPress={handlePressUpgradeBadge}>{t('add_change_products_badge')}</Button>
                        </View>
                        <Flex direction={{base: 'column'}} mt={4}  width={{base: "100%" ,lg:"75%"}} justifyContent={"center"}>

                            <View py={4} backgroundColor={colors['backgroundLight']['200']} borderRadius="lg" m={4}  >
                                <Text fontSize={'2xl'} px={4} fontWeight={"bold"} mb={2} color="white"><MaterialCommunityIcons name="badge-account-alert" size={34} color="white"/> {t('Badge_access')}</Text>
                                {
                                    registration.allBadges.map((badge) => {
                                        return (
                                            <View key={badge.id} backgroundColor={colors['backgroundLight']['50']} p={4} my={1.5} mb={0}>
                                                <Text>{badge.translated_name}</Text>

                                                <View>
                                                    <Text style={{marginTop:20,textDecorationLine: 'underline' }}>{t('view_access_on_program')}</Text>
                                                    <Link to={{screen: 'Program'}}
                                                          style={{position: 'absolute', width: '100%', height: '100%'}}
                                                    />
                                                </View>

                                            </View>

                                        )
                                    })
                                }

                            </View>

                            { registration.allOptions.length > 0 ?
                                <View backgroundColor={colors['backgroundLight']['200']} borderRadius="lg" m={4} py={4}>
                                    <Text fontSize={'2xl'} px={4} fontWeight={"bold"} mb={2}>
                                        <AntDesign
                                        name="tag"
                                        size={34}
                                        color="white"/> {t('Badge_options')}
                                    </Text>
                                    {
                                        registration.allOptions.map((registrationProduct) => {

                                            return (
                                                <View key={registrationProduct.id}
                                                      backgroundColor={colors['backgroundLight']['50']}  p={4} my={1.5}
                                                      mb={0}>
                                                    <Text>
                                                        {registrationProduct.product.translated_name}
                                                        {registrationProduct.details.length > 1 &&
                                                            <View p={2} w={'100%'}>
                                                                {
                                                                    registrationProduct.details.map((detail, index) => {
                                                                        return (
                                                                            <Text key={index} fontWeight={"light"}
                                                                                  my={1} mb={0} opacity={0.65}
                                                                                  fontSize={"xs"}>
                                                                                {detail}
                                                                            </Text>
                                                                        )
                                                                    })
                                                                }
                                                            </View>
                                                        }
                                                    </Text>
                                                </View>
                                            )
                                        })
                                    }
                                </View>
                                :
                                null
                            }
                        </Flex>
                    </Flex>
                </ScrollView>

                :
                <Center h={'100%'} w={'100%'}>
                    <Spinner color="#5766D6" />
                </Center>
            }
        </Layout>
    );
}
