import * as React from "react";
import {
    Text,
    Flex,
    Heading, View,
} from "native-base";
import {useTranslation} from "react-i18next";

function DisclosureModal({lecture}) {
    const {t, i18n} = useTranslation();

    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };

    function renderDisclosure() {
        let disclosures = [];
        let disclosure1, disclosure2, disclosure3, disclosure4, disclosure5 = null;
        let disclosure1_details, disclosure2_details, disclosure3_details, disclosure4_details,
            disclosure4_details_date = null;

        if (lecture?.disclosure1) {
            disclosure1 = (
                <View marginBottom={2} key={uniqueId()}>
                    <Text bold fontSize={{base: 'xs', md: 'sm'}}
                          textAlign={'justify'}>{lecture?.disclosure1_question}</Text>
                    <Text fontSize={{base: 'sm', md: 'sm'}} textAlign={'justify'}>{lecture?.disclosure1}</Text>
                </View>
            )
        }
        if (lecture?.disclosure1_details) {
            disclosure1_details = (
                <View marginBottom={2} key={uniqueId()}>
                    <Text bold fontSize={{base: 'xs', md: 'sm'}}
                          textAlign={'justify'}>{lecture?.disclosure1_details_question}</Text>
                    <Text fontSize={{base: 'sm', md: 'sm'}} textAlign={'justify'}>{lecture?.disclosure1_details}</Text>
                </View>
            )
        }

        if (lecture?.disclosure2 !== null) {
            disclosure2 = (
                <View marginBottom={2} key={uniqueId()}>
                    <Text bold fontSize={{base: 'xs', md: 'sm'}}
                          textAlign={'justify'}>{lecture?.disclosure2_question}</Text>
                    <Text fontSize={{base: 'sm', md: 'sm'}} textAlign={'justify'}>{lecture?.disclosure2}</Text>
                </View>
            )
        }
        if (lecture?.disclosure2_details) {
            disclosure2_details = (
                <View marginBottom={2} key={uniqueId()}>
                    <Text bold fontSize={{base: 'xs', md: 'sm'}}
                          textAlign={'justify'}>{lecture?.disclosure2_details_question}</Text>
                    <Text fontSize={{base: 'sm', md: 'sm'}} textAlign={'justify'}>{lecture?.disclosure2_details}</Text>
                </View>
            )
        }

        if (lecture?.disclosure3 !== null) {
            disclosure3 = (
                <View marginBottom={2} key={uniqueId()}>
                    <Text bold fontSize={{base: 'xs', md: 'sm'}}
                          textAlign={'justify'}>{lecture?.disclosure3_question}</Text>
                    <Text fontSize={{base: 'sm', md: 'sm'}} textAlign={'justify'}>{lecture?.disclosure3}</Text>
                </View>
            )
        }
        if (lecture?.disclosure3_details) {
            disclosure3_details = (
                <View marginBottom={2} key={uniqueId()}>
                    <Text bold fontSize={{base: 'xs', md: 'sm'}}
                          textAlign={'justify'}>{lecture?.disclosure3_details_question}</Text>
                    <Text fontSize={{base: 'sm', md: 'sm'}} textAlign={'justify'}>{lecture?.disclosure3_details}</Text>
                </View>
            )
        }

        if (lecture?.disclosure4 !== null) {
            disclosure4 = (
                <View marginBottom={2} key={uniqueId()}>
                    <Text bold fontSize={{base: 'xs', md: 'sm'}}
                          textAlign={'justify'}>{lecture?.disclosure4_question}</Text>
                    <Text fontSize={{base: 'sm', md: 'sm'}} textAlign={'justify'}>{lecture?.disclosure4}</Text>
                </View>
            )
        }
        if (lecture?.disclosure4_details) {
            disclosure4_details = (
                <View marginBottom={2} key={uniqueId()}>
                    <Text bold fontSize={{base: 'xs', md: 'sm'}}
                          textAlign={'justify'}>{lecture?.disclosure4_details_question}</Text>
                    <Text fontSize={{base: 'sm', md: 'sm'}} textAlign={'justify'}>{lecture?.disclosure4_details}</Text>
                </View>
            )
        }
        if (lecture?.disclosure4_details_date) {
            disclosure4_details_date = (
                <View marginBottom={2} key={uniqueId()}>
                    <Text bold fontSize={{base: 'xs', md: 'sm'}}
                          textAlign={'justify'}>{lecture?.disclosure4_details_date_question}</Text>
                    <Text fontSize={{base: 'sm', md: 'sm'}}
                          textAlign={'justify'}>{lecture?.disclosure4_details_date}</Text>
                </View>
            )
        }

        if (lecture?.disclosure5 !== null) {
            disclosure5 = (
                <View marginBottom={2} key={uniqueId()}>
                    <Text bold fontSize={{base: 'xs', md: 'sm'}} textAlign={'justify'}>{lecture?.disclosure5}</Text>
                </View>
            )
        }

        disclosures.push(
            disclosure1, disclosure1_details,
            disclosure2, disclosure2_details,
            disclosure3, disclosure3_details,
            disclosure4, disclosure4_details, disclosure4_details_date,
            disclosure5);
        return disclosures;
    }

    return (
        <Flex>
            {lecture?.disclosure_status == "accepted" ?
            <Heading size={{base: 'sm', md: 'md'}}>{t('Disclosure')}</Heading> :
                <Text textAlign={'center'}>{t('No_disclosure')}</Text>

            }
            {renderDisclosure()}
        </Flex>
    );
}

export default DisclosureModal
