import {Badge, Center, Text, useTheme} from "native-base";
import React from "react";

const CountResult = ({result}) => {
    const {colors} = useTheme();

    if (result > 0)
        return (
            <Badge bg={colors['background'][100]} rounded={'md'}>
                <Center><Text fontSize={'sm'}>{result} results</Text></Center>
            </Badge>
        );
    else
        return (
            <></>

        );
}

export default CountResult;
