import {format, formatDistance, parseISO} from "date-fns";
import {Platform} from "react-native";
import {
    AspectRatio,
    Badge,
    Button, CheckIcon, Flex,
    Image,
    Input,
    Modal,
    Pressable,
    Progress,
    Select, Spacer,
    Text,
    useTheme,
    View
} from "native-base";
import LiveTag from "./LiveTag";
import * as React from "react";
import { useState} from "react";
import CounterTag from "./CounterTag";
import ReplayTag from "./ReplayTag";
import {Link, useNavigation} from "@react-navigation/native";
import MyListCheckbox from "./MyListCheckbox";
import {useSelector} from "react-redux";
import FullTag from "./FullTag";
import {useTranslation} from "react-i18next";

function Session(props) {
    const {
        item,
        sessionImgMobileHeight,
        sessionImgDesktopWidth,
        sessionImgDesktopHeight,
        progressBar,
        isLive,
        isSoon,
        isReplay,
        isChecked,
        tagRight,
        cardWidth,
        cardMobileWidth,
        inCompany,
        deviceType,
    } = props;
    const {t, i18n} = useTranslation();
    const {title, subtitle,room} = item;
    const {colors} = useTheme();
    const {user, congressUser} = useSelector(state => state.auth);
    let timeBeginObject = parseISO(item.datetime_begin);
    let timeBegin = format(timeBeginObject, 'HH:mm');
    let timeEndObject = parseISO(item.datetime_end);
    let timeEnd = format(timeEndObject, 'HH:mm');
    let date = new Date(item.date_begin)
    let monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    let monthIndex = date.getMonth()
    let monthName = monthNames[monthIndex]
    let formattedDate = `${date.getDate()} ${monthName}`
    let count = formatDistance(
        new Date(timeBeginObject),
        new Date(Date.now()),
        {addSuffix: true}
    );
    const [showModal, setShowModal] = useState(false);
    const [progressBarValue, setProgressBarValue] = useState(50);
    const [truncate, setTruncate] = useState(true);
    const navigation = useNavigation();
    const toggleModal = () => {
        setShowModal(!showModal);
    }

    function renderTag() {
        let right;
        tagRight ? right = true : right = false
        let tag;
        if (isLive) tag =  <LiveTag right={right} inProgram/>;
        else if (isSoon) tag =  <CounterTag count={count} inProgram/>;
        else if (isReplay) tag = <ReplayTag right={right} inProgram/>;
        return (
            <>
                {isLive && item.room.seat_occupancy >= 95 ? <FullTag inProgram></FullTag> : null}
                {tag}
            </>
        )
    }
    
    function renderLink(isDesktop, item) {
        let screen = 'Session';
        let id = item.id;

        if(isLive && congressUser.physical_attendance) {
            screen = 'LiveStagePhysicalScreen';
            id = item.room_id;
        }
        else if(isLive && isDesktop) {
            screen = 'LiveStageScreenForDesktop';
            id = item.room_id;
        }
        else if(isLive && !isDesktop) {
            screen = 'LiveStageScreenForMobile';
            id = item.room_id;
        }
        return ({
            screen: screen,
            params: {id: id}
        });
    }

    function getMinHeight(){
        if(deviceType == 1 || deviceType == 2){
            return !inCompany ? "55px" : "30px"
        }
        else if(deviceType == 3){
            return "100px"
        }
    }

    function toggleTruncate() {
        return setTruncate(!truncate);
    }

    function checkIfSympoAccess() {
        if (!user.congress_user.partners_optin) {
            setShowModal(true);
        }
    }

    function goToProfile() {
        toggleModal();
        navigation.navigate('PersonalInformations');
    }
    return (
        <>
            {Platform.OS !== 'web' ?
                // Mobile
                (<>
                        <View>
                            <AspectRatio w={'100%'} ratio={2 / 1}>
                                <Image
                                    source={{uri: item.thumb_url,}}
                                    borderRadius={5}

                                    alt="session image"
                                />
                            </AspectRatio>
                            <View style={{position: 'absolute', bottom: 2, right: 2, zIndex: 15, elevation: 15,}}>
                                <MyListCheckbox
                                    item={{id: item.id, type: 'LectureSession'}}
                                    size={'sm'}
                                    isChecked={isChecked}
                                    deviceType={deviceType}

                                />
                            </View>
                            { (!congressUser.partners_optin && (item.format.id === 10 || item.format.id === 252) && (isLive || isReplay)) ? (<>
                                <Pressable onPress={checkIfSympoAccess}  style={{
                                    width: '100%',
                                    height: sessionImgMobileHeight,
                                    position: 'absolute',
                                    zIndex: 5
                                }}>
                                </Pressable>
                                <Modal isOpen={showModal} onClose={toggleModal}>
                                    <Modal.Content maxWidth={'600px'}>
                                        <Modal.CloseButton/>
                                        <Modal.Body pt={8}>
                                            <Flex direction={"column"} alignItems={"center"}>
                                                <Text>{t('Watch_replay_sympo')}</Text>
                                               <Button mt={2} onPress={goToProfile}>
                                                   <Text color={'white'}>{t('Modify_my_profile')}</Text>
                                               </Button>
                                            </Flex>
                                        </Modal.Body>
                                    </Modal.Content>
                                </Modal>

                            </>) : (
                                <>
                                    <Link
                                        to={renderLink(false, item)}
                                        style={{
                                            width: '100%',
                                            height: sessionImgMobileHeight,
                                            position: 'absolute',
                                            zIndex: 5
                                        }}
                                    >
                                    </Link>
                                </>)}

                        </View>
                        <View px={1}
                              pb={2}
                              w={cardMobileWidth ? cardMobileWidth : "100%"}
                              minHeight={getMinHeight()}
                              borderBottomRightRadius={5}
                              borderBottomLeftRadius={5}>
                            {progressBar ? <Progress m={1} value={progressBarValue} mx="3"/> : null}
                            {/*{showHours ?*/}
                                <Text textAlign="left" fontSize={"xs"} fontWeight="light" mt={2}>
                                {timeBegin} - {timeEnd} - {formattedDate}  | {room.name}
                            </Text>
                            {/*// : null}*/}
                            <Text
                                textAlign={"left"}
                                noOfLines={truncate ? 2 : 5}
                                fontSize={'xs'}
                                fontWeight="bold"
                                mt={1}
                                maxWidth={1000}
                                onPress={toggleTruncate}
                            >
                                {subtitle}
                            </Text>
                        </View>

                        {renderTag()}
                    </>
                ) :
                // Desktop
                (<>
                    <View width={cardWidth}>
                            <Image
                                source={{
                                    uri: item.thumb_url,
                                }}
                                borderRadius={5}
                                w={sessionImgDesktopWidth}
                                h={sessionImgDesktopHeight}
                                alt="session image"
                            />
                            <View style={{position: 'absolute', bottom: 5, right: 5, zIndex: 15, elevation: 15,}}>
                                <MyListCheckbox
                                    item={{id: item.id, type: 'LectureSession'}}
                                    size={'sm'}
                                    isChecked={isChecked}
                                    deviceType={deviceType}
                                />
                            </View>
                            {renderTag()}
                        { (!congressUser.partners_optin && (item.format.id === 10 || item.format.id === 252) && (isLive || isReplay)) ? (<>
                            <Pressable onPress={checkIfSympoAccess}  style={{
                                width: '100%',
                                height: sessionImgMobileHeight,
                                position: 'absolute',
                                zIndex: 5
                            }}>
                            </Pressable>
                            <Modal isOpen={showModal} onClose={toggleModal}>
                                <Modal.Content maxWidth={'600px'}>
                                    <Modal.CloseButton/>
                                    <Modal.Body pt={8}>
                                        <Flex direction={"column"} alignItems={"center"}>
                                            <Text>{t('Watch_replay_sympo')}</Text>
                                            <Link
                                                to={{screen: 'my-profile'}}

                                            >
                                                <Button>{t('Modify_my_profile')}</Button>
                                            </Link>
                                        </Flex>
                                    </Modal.Body>
                                </Modal.Content>
                            </Modal>

                        </>) : (
                            <>
                            <Link
                            to={renderLink(true, item)}
                        style={{
                            width: '100%',
                            height: sessionImgMobileHeight,
                            position: 'absolute',
                            zIndex: 5
                        }}
                        >
                        </Link>
                    </>)}
                    </View>
                    <View w={cardWidth ? cardWidth : "100%"}>
                        {progressBar ? <Progress m={1} value={progressBarValue} mx="3"/> : null}
                        {/*{showHours ?*/}
                            <Text textAlign="left" fontSize={"xs"} fontWeight="light" mt={2}>
                                {timeBegin} - {timeEnd} - {formattedDate} | {room.name}
                            </Text>
                            {/*// : null}*/}
                        <Text
                            textAlign={"left"}
                            noOfLines={truncate ? 2 : 5}
                            fontSize={'xs'}
                            fontWeight="bold"
                            mt={1}
                            maxWidth={1000}
                            onPress={toggleTruncate}
                        >
                            {subtitle}
                        </Text>
                        { (!congressUser.partners_optin && (item.format.id === 10 || item.format.id === 252)) ? (<>
                            <Pressable onPress={checkIfSympoAccess}  style={{
                                width: '100%',
                                height: sessionImgMobileHeight,
                                position: 'absolute',
                                zIndex: 5
                            }}>
                            </Pressable>
                            <Modal isOpen={showModal} onClose={toggleModal}>
                                <Modal.Content maxWidth={'600px'}>
                                    <Modal.CloseButton/>
                                    <Modal.Body pt={8}>
                                        <Flex direction={"column"} alignItems={"center"}>
                                            <Text>{t('Watch_replay_sympo')}</Text>
                                            <Link
                                                to={{screen: 'my-profile'}}
                                            >
                                                <Button>{t('Modify_my_profile')}</Button>
                                            </Link>
                                        </Flex>
                                    </Modal.Body>
                                </Modal.Content>
                            </Modal>

                        </>) : (
                            <>
                                <Link
                                    to={renderLink(true, item)}
                                    style={{
                                        width: '100%',
                                        height: sessionImgMobileHeight,
                                        position: 'absolute',
                                        zIndex: 5
                                    }}
                                >
                                </Link>
                            </>)}
                    </View>
                </>)}
        </>
    );
}

export default Session

Session.defaultProps = {
    sessionImgMobileWidth: 240,
    sessionImgMobileHeight: 120,
    sessionImgDesktopWidth: 240,
    sessionImgDesktopHeight: 120,
    progressBar: false,
    cardWidth: null,
    cardMobileWidth: null,
    cardMobileHeight: null,
}
