import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserId, setUserProperties } from "firebase/analytics";
import Config from '../../config.json';
class FirebaseAnalytics {
    static app = initializeApp({
        apiKey: Config.FIREBASE_WEB_API_KEY,
        authDomain: Config.FIREBASE_WEB_AUTH_DOMAIN,
        projectId: Config.FIREBASE_WEB_PROJECT_ID,
        storageBucket: Config.FIREBASE_WEB_STORAGE_BUCKET,
        messagingSenderId: Config.FIREBASE_WEB_MESSAGING_SENDER_ID,
        appId: Config.FIREBASE_WEB_APP_ID,
        measurementId: Config.FIREBASE_WEB_MEASUREMENT_ID,
    });
    static webAnalytics = getAnalytics(this.app);
    static async sendAnalyticsEventAsync(eventName, eventParams) {
        await logEvent(this.webAnalytics, eventName, eventParams);
    }

    static async sendUserId(userId) {
        await setUserId(this.webAnalytics, userId);
    }

    static async  sendUserProperties(properties){
        await setUserProperties(this.webAnalytics, properties);
    }
}

export default FirebaseAnalytics;
