import React, {useEffect, useState} from 'react';
import {
    Box,
    Divider,
    Flex,
    Text,
    VStack,
    useTheme, Badge, Avatar, View, Center,
} from "native-base";
import {useTranslation} from "react-i18next";
import {Platform} from "react-native";
import {Link, useNavigation} from "@react-navigation/native";

const CompanyCardMap = ({company, locked}) => {
        const {
            id,
            company_name,
            picture,
            country,
            sponsorship_level,
            website,
            sponsor_company_name,
        } = company;

        const {colors} = useTheme();
        const {t, i18n} = useTranslation();
        const navigation = useNavigation();

        let categories = company.categories;
        if (categories && categories.length > 0) {
            categories = categories.map(category => category.name)
        }
        const uniqueId = () => {
            const dateString = Date.now().toString(36);
            const randomness = Math.random().toString(36).substr(2);
            return dateString + randomness;
        };

        function renderDetails() {
            return (
                <Box mx={5}>

                    <VStack>
                        <Flex direction={"row"} wrap={"wrap"} my={2}>
                            <Badge m={1} colorScheme="default" alignSelf="center" variant="outline">
                                {sponsorship_level}
                            </Badge>
                            {country &&
                                (<Badge m={1} colorScheme="success" alignSelf="center" variant="outline">
                                    {country[0].name}
                                </Badge>)}

                            {categories && categories.length > 0 && categories.map(category => (
                                <Badge m={1} alignSelf="center" variant="outline" maxW={'100%'}
                                       key={uniqueId()}>
                                    {category}
                                </Badge>))}
                        </Flex>
                    </VStack>

                </Box>
            );
        }

        return (
            <Flex>
                <Flex pt={4}>
                    {!locked ?
                        <Link style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        zIndex: 20
                        }} to={{
                            screen: 'CompanyProfile',
                            params: {id: id}
                        }}
                        />
                        : <></>
                    }
                    <Flex direction={"row"} alignItems={'center'}>
                        <View>
                            <Avatar source={{uri: picture}} size={Platform.OS === "web" ? '2xl' : '100px'}/>
                        </View>
                        <View style={{
                            width: 0,
                            flexGrow: 1,
                            flex: 1,
                            marginLeft:12
                        }}>
                            <Text bold fontSize={Platform.OS === 'web' ? '2xl' : 'md'} style={{flexShrink: 1}}>{sponsor_company_name ? sponsor_company_name?.toUpperCase() : company_name?.toUpperCase()}</Text>
                            <Text fontSize={{base: 'sm', md: 'xl'}}>{t('Booth_number') + ' : ' + company.booth_number}</Text>
                        </View>
                    </Flex>
                </Flex>
                {renderDetails()}
            </Flex>
        )
    }
;

export default CompanyCardMap;
