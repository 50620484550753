import {Badge, Button, Center, Flex, Text, useTheme} from "native-base";
import {format, parseISO} from "date-fns";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Platform} from "react-native";

export default function ProgramDayButtonForDesktop(props) {
    const {t, i18n} = useTranslation();
    const {colors} = useTheme();
    const [isHovered, setIsHovered] = useState(false);
    const date = props.date !== undefined ? props.date : true;

    function changeActiveDay() {
        return props.changeActiveDay(props.day);
    }

    function hoverOn() {
        return setIsHovered(true);
    }

    function hoverOff() {
        return setIsHovered(false);
    }

    const renderBadge = () => {
        if (props.filteredSessionsDisplayed && props.filteredSessionsDisplayed.length >0) {
            return (
                <Badge
                    rounded="full"
                    bg={'success.500'}
                    variant="solid"
                    mr={1}
                    minW={4}
                    style={{textAlign: "center"}}
                >
                    <Text fontSize={"2xs"} style={{color: 'white'}}>{props.filteredSessionsDisplayed.length}</Text>
                </Badge>
            );
        }
    }

    return (
        <Button
            flex={1}
            onPress={changeActiveDay}
            variant={'unstyled'}
            key={props.day}
            bg={isHovered ? colors['background'][100] : colors['background'][50]}
            borderBottomLeftRadius={0}
            borderBottomRightRadius={0}
            borderTopRightRadius={5}
            borderTopLeftRadius={5}
            borderBottomWidth={props.isActiveDay ? 1 : 0}
            borderColor={colors['action'][50]}
            onMouseEnter={hoverOn}
            onMouseLeave={hoverOff}
        >
            <Flex flexDirection={Platform.OS === 'web' ? 'row' : 'column'} alignContent="center" alignItems="center" w={'100%'}>
                {renderBadge()}
                <Text style={{color: isHovered ?  colors['action'][50] : (props.isActiveDay ? colors['backgroundLight'][400]  :  colors['action'][50])}}>
                    {date ? t(format(parseISO(props.day), 'EEEE')) : props.day}
                </Text>
            </Flex>

        </Button>
    );
}
