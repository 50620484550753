import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Button,
    Center,
    Divider,
    HStack, Image,
    Modal,
    Spacer, Spinner, Text, View,
    VStack
} from "native-base";
import {Platform} from "react-native";
import {useSelector} from "react-redux";
import * as ImagePicker from 'expo-image-picker';
import {MaterialCommunityIcons} from "@expo/vector-icons";
import {Ionicons} from '@expo/vector-icons';
import {TouchableWithoutFeedback} from "react-native";
import {useTranslation} from "react-i18next";

const PicturePicker = ({setPic, square, withDefaultProfilePic, cameraOnly, buttonOnly}) => {

    const {user} = useSelector(state => state.auth);
    const [image, setImage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isLoading,setIsLoading] = useState(false)
    const {t, i18n} = useTranslation();

    useEffect(() => {
        if (withDefaultProfilePic && user.picture) {
            setImage(user.picture);
        }
    }, []);

    /**
     * get permission for using phone library
     */
    const allowPhoneLibrary = async () => {
        if (Platform.OS !== 'web') {
            let {status} = await ImagePicker.requestMediaLibraryPermissionsAsync();
            if (status !== 'granted') {
                alert('Sorry, we need media library permissions to make this work!');
            } else return status;
        }
    }

    /**
     * get permission for using camera
     */
    const allowCamera = async () => {
        if (Platform.OS !== 'web') {
            await ImagePicker.requestCameraPermissionsAsync().then(
                res => {
                    const status = res.status
                    if (status !== 'granted') {
                        alert('Sorry, we need camera roll permissions to make this work!');
                    } else return status;
                }
            );

        }
    }

    /**
     * get image from phone library
     */
    const pickImage = async () => {
        setIsLoading(true);
        let img = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [1, 1],
            quality: 1,
            base64: true,
        });

        if (!img.cancelled) {
            setImage('data:image/jpeg;base64,' + img.base64);
            setPic('data:image/jpeg;base64,' + img.base64);
        }
        setShowModal(false);
    };

    /**
     * get picture from phone camera
     */
    const takePicture = async () => {
        setIsLoading(true);
        let pic = await ImagePicker.launchCameraAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [1, 1],
            quality: 1,
            base64: true,
        });

        if (!pic.cancelled) {
            setImage('data:image/jpeg;base64,' + pic.base64);
            setPic('data:image/jpeg;base64,' + pic.base64);
        }

        setShowModal(false);
    }

    function takePictureWithPermission() {
        if (cameraOnly) {
            return allowCamera()
                .then(r => takePicture())
                .then(() => setIsLoading(false));
        } else {
            return setShowModal(true);
        }
    }

    function alwaysTakePictureWithPermission() {
        return allowCamera().then(r => takePicture());
    }

    function takePictureWithoutPermission() {
        if (cameraOnly) return takePicture();
        else return setShowModal(true);
    }

    function pickImageWithPermission() {
        return allowPhoneLibrary().then(r => pickImage()).then(() => setIsLoading(false));
    }

    function hideModal() {
        return setShowModal(false);
    }

    const renderPic = () => {
        if (square && image && image.length > 0) {
            return (
                <Image source={{
                    uri: image
                }}
                       style={{width: 300, height: 300}}
                       resizeMode={'cover'}
                />
            );
        } else if (image && image.length > 0) {
            return (
                <Avatar
                    size={{
                        base: "120px",
                        md: "200px",
                    }}
                    source={{
                        uri: image,
                    }}
                />
            );
        } else return <></>;
    }

    const renderCameraButton = () => {
        if (Platform.OS === 'web') {
            return (
                <View>
                    {renderPic()}

                    <Button onPress={takePicture} my={5}
                            position="absolute"
                            top="0"
                            right="0"
                            py="3"
                            px="3"
                    >
                        <MaterialCommunityIcons name="image-edit-outline" size={24} color="#fff"/>
                    </Button>
                </View>
            );
        } else if (buttonOnly) {
            return (
                <View>
                    <TouchableWithoutFeedback
                        onPress={takePictureWithPermission}
                    >
                        {renderPic()}
                    </TouchableWithoutFeedback>

                    {isLoading ?
                        (<Spinner color="#5766D6"  size={'sm'}/>)
                        :
                        <>
                            <Button
                                endIcon={<MaterialCommunityIcons name="image-edit-outline" size={20} color="#fff"/>}
                                mb={4}
                                borderRadius={25}
                                onPress={takePictureWithPermission}
                            >
                                <Text color={'white'}>{t('Take_photo')}</Text>
                            </Button>
                            <Button
                                endIcon={<Ionicons name="md-cloud-upload-outline" size={20} color="#fff"/>}
                                borderRadius={25}

                                onPress={pickImageWithPermission}
                            >
                                <Text color={'white'}>{t('Pick_photo')}</Text>
                            </Button>
                        </>

                    }
                </View>
            );
        } else {
            return (
                <View>
                    <TouchableWithoutFeedback
                        onPress={takePictureWithPermission}
                    >
                        {renderPic()}
                    </TouchableWithoutFeedback>
                    <Button
                        my={5}
                        size={'xs'}
                        borderRadius={'full'}
                        w={12}
                        h={12}
                        style={{position: 'absolute', top: -12, right: -6}}
                        onPress={takePictureWithoutPermission}
                    >
                        <Center>
                            <MaterialCommunityIcons name="image-edit-outline" size={20} color="#fff"/>
                        </Center>
                    </Button>
                </View>
            );
        }
    }

    return (
        <>
            <Center>
                {renderCameraButton()}
            </Center>
            <Modal isOpen={showModal} onClose={hideModal}>
                <Modal.Content maxWidth="400px">
                    <Modal.CloseButton/>
                    <Modal.Header>upload your picture</Modal.Header>
                    <Modal.Body>
                        <Divider/>
                        <HStack justifyContent="center" p={10}>
                            <Button onPress={pickImageWithPermission}>
                                <Ionicons name="md-cloud-upload-outline" size={24} color="#fff"/>
                            </Button>
                            <Spacer/>
                            <Button onPress={alwaysTakePictureWithPermission}>
                                <Ionicons name="ios-camera-outline" size={24} color="#fff"/>
                            </Button>
                        </HStack>
                    </Modal.Body>
                </Modal.Content>
            </Modal>
        </>
    );
}
export default PicturePicker;

PicturePicker.defaultProps = {
    square: false,
}
