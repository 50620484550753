import React, {useEffect, useState} from "react";
import {Button, Checkbox, Flex, Spinner, Text, useTheme, useToast, View} from "native-base";
import HttpService from "../services/HttpService";
import {useDispatch} from "react-redux";
import {refreshData} from "../actions/auth";
import { MaterialIcons } from '@expo/vector-icons';

export default function MyListCheckbox(props) {
    const [isChecked, setIsChecked] = useState(props.isChecked);
    const [isLoading, setIsLoading] = useState(false);
    const [serverError, setServerError] = useState({});
    const [reduxError, setReduxError] = useState({})
    const dispatch = useDispatch();
    const {colors} = useTheme();
    const toast = useToast();

    function toggleMyList({id, type}) {
        setIsLoading(true);

        HttpService.postData('my-list/toggle', {id, type})
            .then((response) => {
                if(!isChecked === false) {
                    toast.show({
                        title: "You have removed an item from your list.",
                        status: "success",
                        placement: "top",
                    });
                }
                else {
                    toast.show({
                        title: "You have added an item to your list.",
                        status: "success",
                        placement: "top",
                    });
                }
                setIsChecked(!isChecked);
                setIsLoading(false);

                dispatch(refreshData())
                    .then(() => {
                        // feedback on redux update ?
                    })
                    .catch((err) => {
                        setReduxError(err);
                    })
            })
            .catch((error) => {
                setServerError(error);
                setIsLoading(false);
            });
    }

    function addOrRemoveItem() {
        return toggleMyList(props.item);
    }

    if(isLoading) {
        return (
            <Button onPress={addOrRemoveItem}
                    variant={'unstyled'}
                    bg={colors['action'][50]}
                    py={1}
                    px={2}
                    borderWidth={1}
                    borderColor={colors['action'][50]}
            >
                <Spinner color="#fff"  size={props.deviceType  == 1 ? 14 : 20} />
            </Button>
        );
    }
    else if(isChecked === undefined) {
        return <></>;
    }
    else if(isChecked === true) {
        return (
            <Button onPress={addOrRemoveItem}
                    variant={'unstyled'}
                    bg={colors['backgroundLight'][200]}
                    py={1}
                    px={2}
                    borderWidth={1}
                    borderColor={colors['action'][50]}
            >
                <MaterialIcons name="playlist-add-check" size={props.deviceType  == 1 ? 14 : 20} color={'white'} />
            </Button>
        );
    }
    else if(isChecked === false) {
        return (
            <Button onPress={addOrRemoveItem}
                    variant={'unstyled'}
                    bg={colors['backgroundLight'][200]}
                    py={1}
                    px={2}
                    borderWidth={1}
                    borderColor={colors['backgroundLight'][400]}
            >
                <MaterialIcons name="playlist-add" size={props.deviceType  == 1 ? 14 : 20} color={'white'} />
            </Button>
        );
    }
}

MyListCheckbox.defaultProps = {
    size: 'sm',
    direction: 'column'
}
