import i18n from "i18next";
import HttpApi from 'i18next-http-backend';
import {initReactI18next} from "react-i18next";
import Constants from 'expo-constants';
import * as Localization from 'expo-localization';
import Config from "./config.json";

const baseURL = Config.apiUrl;
const type = Config.type;

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(HttpApi)
    .init({
        compatibilityJSON: 'v3',
        lng: Localization.locale.split('-')[0],
        interpolation: {
            escapeValue: false
        },
        ns: 'live',
        defaultNS: 'live',
        backend: {
            loadPath: baseURL + 'translations/{{lng}}/' + type + '/{{ns}}.json',
            allowMultiLoading: false,
            crossDomain: false,
            withCredentials: false,
            overrideMimeType: false,
        },
    });

export default i18n;
