import { combineReducers } from "redux";
import auth from "./auth";
import global from "./global";
import message from "./message";
import notifications from "./notifications";

export default combineReducers({
    global,
    auth,
    message,
    notifications,
});
