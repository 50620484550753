import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Platform, Image, FlatList, TouchableOpacity} from "react-native";
import {useSelector} from "react-redux";
import SvgVenueLevel3Map from "../maps/level3";
import SvgVenueLevel2Map from "../maps/level2";
import HttpService from "../services/HttpService";
import CompanyCardMap from "../components/CompanyCardMap";
import {useIsFocused} from "@react-navigation/native";
import Select2Form from "../components/Select2Form";
import {Button, Center, Divider, Flex, Heading, Modal, Select, Spinner, Text, useTheme, View} from "native-base";
import pusher from "../pusher";


export default function OnsiteMapScreen({navigation, ...props}) {
    const {t, i18n} = useTranslation();
    const {congress} = useSelector(state => state.global);
    const [showExhibitorDetails, setShowExhibitorDetails] = useState(false);
    const [showRoomDetails, setShowRoomDetails] = useState(false);
    const [currentVenueLevel, setCurrentVenueLevel] = useState(1);
    const [exhibitors, setExhibitors] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [selectedExhibitor, setSelectedExhibitor] = useState(null);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [highlightedBooth, setHighlightedBooth] = useState(0);
    const [highlightedRoom, setHighlightedRoom] = useState(0);
    const [venueLevels, setVenueLevels] = useState([
        {level: 2, label: 'Level 2'},
        {level: 3, label: 'Level 3'},
    ]);
    const [ready, setReady] = useState(false);
    const [readyForDisplay, setReadyForDisplay] = useState(false);
    const [keyboardInput, setKeyboardInput] = useState('');
    const [showSearchModal, setShowSearchModal] = useState(false);
    const mapRef = useRef(null);
    const isFocused = useIsFocused();
    const {colors} = useTheme();

    useEffect(() => {
        getCompanies()
            .catch(error => console.log('Error: ' + error));

        getRooms()
            .catch(error => console.log('Error: ' + error));
    }, []);

    useEffect(() => {
        const channelLive = pusher.subscribe('onsite-room');

        channelLive.bind('update', function (data) {
            getRooms()
                .catch(error => console.log('Error: ' + error));
        });

        return () => {
            pusher.unsubscribe('onsite-room');
        }
    }, [isFocused]);

    useEffect(() => {
        setReady(false);
        if (props.route.params?.id && props.route.params?.type === 'booth' && exhibitors.length > 0) {
            selectBooth(props.route.params.id);
            resetRoom();
        } else setReady(true);
        if(props.route.params?.id && props.route.params?.type === 'room' && rooms.length > 0) {
            selectRoom(props.route.params.id);
            resetBooth();
        } else setReady(true);
    }, [props.route.params?.id]);

    useEffect(() => {
        if(ready && exhibitors.length > 0 && rooms.length > 0) {
            setReadyForDisplay(true);
        } else setReadyForDisplay(false);
    }, [exhibitors, rooms, ready]);

    const getCompanies = () => {
        return HttpService
            .getData('exhibitor/get/map')
            .then(response => {
                setExhibitors(response.data.data);
            });
    }

    const getRooms = () => {
        return HttpService
            .getData('room/get/all')
            .then(response => {
                setRooms(response.data.data);
            });
    }

    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };

    const hideModal = () => {
        setShowSearchModal(false);
    }

    const selectBooth = (id) => {
        setReady(false);
        setShowExhibitorDetails(false);
        const exhibitor = exhibitors.find(e => parseInt(e.booth_id) === parseInt(id));
        if (exhibitor) {
            setCurrentVenueLevel(+exhibitor.booth_level);
            setSelectedExhibitor(exhibitor);
            setShowExhibitorDetails(true);
            setHighlightedBooth(exhibitor.booth_id);
            setReady(true);
        } else {
            setReady(true);
        }
    }

    const selectRoom = (id) => {
        setReady(false);
        setShowRoomDetails(false);
        const room = rooms.find(e => parseInt(e.id) === parseInt(id));
        if (room) {
            setCurrentVenueLevel(+room.level);
            setSelectedRoom(room);
            setShowRoomDetails(true);
            setHighlightedRoom(room.id);
            setReady(true);
        } else {
            setReady(true);
        }
    }

    const resetBooth = () => {
        setHighlightedBooth(0);
        setShowExhibitorDetails(false);
        setSelectedExhibitor(null);
    }

    const resetRoom = () => {
        setHighlightedRoom(0);
        setShowRoomDetails(false);
        setSelectedRoom(null);
    }

    const changeCurrentLevel = (id) => {
        setCurrentVenueLevel(+id);
    }

    const showBoothInfo = (id) => {
        navigation.navigate('OnsiteMap', {id: id, type: 'booth'});
    }

    const showRoomInfo = (id) => {
        navigation.navigate('OnsiteMap', {id: id, type: 'room'});
    }

    function renderMap() {
        if (congress) {
            return (
                <Center w={'70%'} h={'100%'}>
                    {currentVenueLevel === 2 &&
                        <SvgVenueLevel2Map ref={mapRef}
                                           showBoothInfo={showBoothInfo}
                                           highlightedBooth={highlightedBooth}
                                           showRoomInfo={showRoomInfo}
                                           highlightedRoom={highlightedRoom}
                        ></SvgVenueLevel2Map>
                    }
                    {currentVenueLevel === 3 &&
                        <SvgVenueLevel3Map ref={mapRef}
                                           showBoothInfo={showBoothInfo}
                                           highlightedBooth={highlightedBooth}
                                           showRoomInfo={showRoomInfo}
                                           highlightedRoom={highlightedRoom}
                        ></SvgVenueLevel3Map>
                    }
                </Center>
            );
        }
    }

    function renderDetails() {
        if (!ready) {
            return (
                <Center flex={1} position={'absolute'} top={'5%'}><Spinner color="#5766D6" /></Center>
            );
        } else if (showExhibitorDetails && selectedExhibitor) {
            return (
                <View position={'absolute'} top={'5%'}>
                    <CompanyCardMap company={selectedExhibitor} isChecked={false} locked />
                </View>
            );
        } else if(showRoomDetails && selectedRoom) {
            return (
                <Flex flex={1} position={'absolute'} top={'5%'}>
                    <Text bold fontSize={{
                        base: 'md',
                        md: '2xl'
                    }} style={{flexShrink: 1}} my={4}>
                        {selectedRoom.name}
                    </Text>
                    {selectedRoom.live_current_session ?
                        <Text noOfLines={2} size={'sm'} w={'100%'} style={{flexShrink: 1}}>
                            <View mr={2}>
                                <Text fontSize={!props.relative ? {base:"2xs",xl:'xs'} : "xs"}
                                      bg={'red.500'} borderRadius={'sm'} px={4} py={1} top={Platform.OS === 'web' ? 0 : 1}>
                                    {"live".toUpperCase()}
                                </Text>
                            </View>
                            {selectedRoom.live_current_session.title + ' - ' + selectedRoom.live_current_session.subtitle}
                        </Text>
                        :
                        <Text>This room has no live session at the moment.</Text>
                    }
                </Flex>
            );
        }
        else {
            return <Center flex={1} position={'absolute'} top={'5%'}/>
        }
    }

    function renderSearchList() {
        let exhibitorsWithHeaders = [];

        exhibitors.forEach(item => {
            if(item.sponsor_company_name && item.sponsor_company_name.length > 0) {
                const category = exhibitorsWithHeaders.find(cat => cat.letter.toLowerCase() === item.sponsor_company_name[0].toLowerCase());
                if(!category) {
                    exhibitorsWithHeaders.push({
                        letter: item.sponsor_company_name[0].toLowerCase(),
                        items: [item]
                    });
                } else {
                    category.items.push(item);
                }
            }
        });

        return (
            <View>
                <View mb={8}>
                    <Heading size={'xl'} mb={2}>Rooms</Heading>
                    <View style={{flexDirection:'row',flexWrap:'wrap'}}>
                        {rooms.map(room => {
                            return (
                                <TouchableOpacity onPress={() => {
                                    navigation.navigate('OnsiteMap', {
                                        id: room.id,
                                        type: room.id < 30000 ? 'room' : 'booth'
                                    });
                                    setShowSearchModal(false);
                                }}>
                                    <Text style={{
                                        marginLeft:'5px',
                                        marginRight: '5px',
                                        marginBottom: '5px',
                                        marginTop: '5px',
                                        borderRadius: '5px',
                                        paddingLeft: '5px',
                                        paddingRight: '5px',
                                        backgroundColor: '#e9ebff'
                                    }}>{room.name}</Text>
                                </TouchableOpacity>
                            );
                        })}
                    </View>
                </View>
                <View mb={8}>
                    <Heading size={'xl'} mb={2}>Companies</Heading>
                    {exhibitorsWithHeaders.map(category => {
                        return (
                            <View>
                                <Heading size={'md'} w={'100%'}>{category.letter.toUpperCase()}</Heading>
                                <Divider />
                                <View style={{flexDirection:'row',flexWrap:'wrap',alignItems:'center'}}>
                                    {category.items.map(item => {
                                        return (
                                            <TouchableOpacity onPress={() => {
                                                navigation.navigate('OnsiteMap', {
                                                    id: item.booth_id,
                                                    type: item.booth_id < 30000 ? 'room' : 'booth'
                                                });
                                                setShowSearchModal(false);
                                            }}>
                                                <Text style={{
                                                    marginLeft: '5px',
                                                    marginRight: '5px',
                                                    marginBottom: '5px',
                                                    marginTop: '5px',
                                                    borderRadius: '5px',
                                                    paddingLeft: '5px',
                                                    paddingRight: '5px',
                                                    backgroundColor: '#e9ebff'
                                                }}>{item.sponsor_company_name}</Text>
                                            </TouchableOpacity>
                                        );
                                    })}
                                </View>
                            </View>
                        );
                    })}
                </View>
            </View>
        );
    }

    if(!readyForDisplay) {
        return <Center flex={1}><Spinner color="#5766D6" /></Center>;
    } else return (
            <Center flex={1} bg={colors['background'][50]}>
                <Flex flexDirection={'row'} flex={1} w={'100%'} px={2}>

                    {renderMap()}

                    <View w={'30%'} py={'10%'}>

                        {renderDetails()}

                        <Flex
                            w={"100%"}
                            h={"150px"}
                            p={4}
                            position={'absolute'} top={'55%'} zIndex={20} bg={colors['background'][50]}
                        >
                            <Divider mb={4} />

                            <Select
                                selectedValue={currentVenueLevel}
                                accessibilityLabel={t('Venue level')}
                                placeholder={'Venue level'}
                                onValueChange={(itemValue) => changeCurrentLevel(+itemValue)}
                                my={6}
                                w={{base: '50%', md: 'auto'}}
                            >
                                {venueLevels.map((venueLevel) => (
                                    <Select.Item label={venueLevel.label} value={venueLevel.level}
                                                 key={venueLevel.level}
                                    />))}
                            </Select>

                            <Button onPress={() => setShowSearchModal(true)}>
                                Search a room or a company
                            </Button>

                            <Modal isOpen={showSearchModal} onClose={hideModal}>
                                <Modal.Content w={'90%'}>
                                    <Modal.Body>
                                        {renderSearchList()}
                                    </Modal.Body>
                                </Modal.Content>
                            </Modal>
                        </Flex>
                    </View>

                </Flex>
            </Center>
    );
}
