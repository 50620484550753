import * as React from "react";
import { ScrollView} from "native-base";
import {Platform} from "react-native";
import {useMediaQuery} from "react-responsive";
import { useEffect, useMemo, useRef, useState} from "react";
import ScrollToTopButton from "./ScrollToTopButton";
import {MasonryExhibitors} from "./MasonryExhibitors";

function Exhibitors(props) {

    const {
        filteredCompanies,
    } = props;

    const listRef = useRef(null);
    const CONTENT_OFFSET_THRESHOLD = 10;
    const isMobile = useMediaQuery({query: '(max-width: 760px)'});
    const [column, setColumn] = useState(5)
    const [data,setData] = useState([]);
    const [contentVerticalOffset,setContentVerticalOffset] = useState(0);



    useEffect( () => {
        if(filteredCompanies){
            if(Platform.OS === "web"){
                setData([...filteredCompanies]);
            }
            else{
                const [, ...rest] = filteredCompanies;
                setData([filteredCompanies[0], ...rest]);
            }
        }
    },[filteredCompanies])

    /*
     * Define number of column
     */
    const colNumber = useMemo(() => {
        if (Platform.OS === "web" || !isMobile) {
            return column;
        } else {
            return 2;
        }
    },[isMobile])


    return (
        <>
            <ScrollView
                ref={listRef}
                onScroll={event => {
                    setContentVerticalOffset(event.nativeEvent.contentOffset.y);
                }}
                scrollEventThrottle={16}
                width="100%"
                maxWidth={{md: 900}}
                margin={{md: 'auto'}}
                onContentSizeChange={(width, height) => {
                    if (width < 620) {
                        setColumn(3)
                    } else if (width < 760) {
                        setColumn(4)
                    } else {
                        setColumn(5)
                    }
                }}
            >
                <MasonryExhibitors prev={props} data={data} colNumber={colNumber}></MasonryExhibitors>

            </ScrollView>
            {contentVerticalOffset > CONTENT_OFFSET_THRESHOLD ?  <ScrollToTopButton eltRef={listRef} /> :null}

        </>
    );
}

export default Exhibitors

Exhibitors.defaultProps = {
    companiesRatio: null,
    adsRatio: null,
    companiesWidth: null,
    companiesHeight: null,
    adsWidth: null,
    adsHeight: null,
    scrollViewWidth: 900,
}




