import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Checkbox, Flex, Heading, Radio, ScrollView, Text, useTheme, View} from "native-base";
import HttpService from "../services/HttpService";
import {Link, useIsFocused} from "@react-navigation/native";
import { FontAwesome } from '@expo/vector-icons';
import {useTranslation} from "react-i18next";
import pusher from "../pusher";

export default function PinnedContent(props) {
    const [currentPoll, setCurrentPoll] = useState(null);
    const [showPoll, setShowPoll] = useState(false);
    const [pollAnswers, setPollAnswers] = useState([]);
    const [pollSingleAnswer, setPollSingleAnswer] = useState(null);
    const [pollResults, setPollResults] = useState({});
    const [hasAnsweredPoll, setHasAnsweredPoll] = useState(false);
    const isFocused = useIsFocused();
    const {colors} = useTheme();
    const {t, i18n} = useTranslation();

    useEffect(() => {
        getCurrentPoll()
            .then(r => {
                if(r.data && r.data.data) {
                    pollUpdate(r.data.data)
                }
                else setCurrentPoll(null);
            })
            .catch(err => console.log(err));

        pusher.subscribe('live-session-all');

        pusher.bind('poll-update', function(poll) {
            pollUpdate(poll);
        });

        return () => {
            pusher.unsubscribe('live-session-all');
        }
    }, [isFocused]);

    function getCurrentResults() {
        return HttpService
            .getData('session/'+ props.sessionId + '/poll/results')
            .then(({data}) => {
                if(data) {
                    setPollResults(data);
                }
                else setPollResults({});
            })
            .catch(err => console.log(err));
    }

    function getCurrentPoll() {
         return HttpService
            .getData('session/'+ props.sessionId +'/poll')
            .catch(err => console.log(err));
    }

    function checkForUserAnswer() {
        return HttpService.getData('session/'+ props.sessionId +'/poll/answer')
            .then(({data}) => {
                if(data.hasAnswered) setHasAnsweredPoll(true);
                else setHasAnsweredPoll(false);
            })
            .catch(err => console.log(err));
    }

    function pollUpdate(poll) {

        setCurrentPoll(poll);

        if(poll.is_active === 1) {
            setShowPoll(true);
            getCurrentResults().catch(err => console.log(err));
            checkForUserAnswer().catch(err => console.log(err));
        }
        else {
            getCurrentResults().catch(err => console.log(err));
            setShowPoll(false);
        }
    }

    async function answerPoll() {
        let payload;
        if(pollSingleAnswer && pollSingleAnswer !== '') {
            payload = [pollSingleAnswer];
        }
        else if(pollAnswers && pollAnswers.length > 0) {
            payload = pollAnswers
        }

        HttpService.postData('session/'+ props.sessionId +'/poll/answer', payload)
            .then(({data}) => {
                setPollSingleAnswer(null);
                setPollAnswers([]);
                if(data.success) {
                    setHasAnsweredPoll(true);
                    getCurrentPoll();
                }
            })
            .catch((err) => console.log(err));
    }

    function renderResults() {
        let results = [];

        if(pollResults.from_mcq === 1) {
            let userAnswers = null;
            if(pollResults.userAnswers){
                userAnswers = pollResults.userAnswers.map(elt => elt.live_poll_answer_id);
            }
            for(let i in pollResults.results) {
                results.push(<View w={'100%'} mt={1} key={"answer_mcq_"+i}>
                    <Flex zIndex={15} direction={"row"} justify={"center"} align={"center"} >
                        {userAnswers && userAnswers.includes(parseInt(i)) ? <FontAwesome name="check-square-o" size={20} color="white" style={{marginRight:2.5}}/> : null}

                        <Text color={'white'} textAlign={'center'} zIndex={15}>
                            {pollResults.results[i]['content']} : {pollResults.results[i]['result']}%
                        </Text>
                    </Flex>

                    <View position={'absolute'} h={'100%'} w={'100%'}
                          bg={pollResults.results[i]['right'] === 1 ? 'green.500' : colors['backgroundLight'][100]} borderRadius={5}  />
                </View>);
            }
        }
        else {
            for(let i in pollResults.results) {
                results.push(<View w={'100%'} mt={1} key={"answer_"+i}>
                    <Text color={'white'} textAlign={'center'} zIndex={15}>{pollResults.results[i]['content']} : {pollResults.results[i]['result']}%</Text>
                    <View position={'absolute'} h={'100%'} style={{width:pollResults.results[i]['result']+'%'}}
                          bg={colors['actionDark'][50]} borderRadius={5}  />
                </View>);
            }
        }
        return results;
    }

    function renderPinnedMessage() {
        return (
            <Flex direction={'row'}
                  bg={colors['backgroundLight'][100]}
                  py={2} px={4} borderRadius={5}
                  wrap={'wrap'}
                  mt={1}
            >
                <Text>
                    <Link to={{screen: 'UserProfile', params: {id: props.pinnedMessage.user?.id}}}>
                        <Text fontSize={{base:'xs', md:'sm'}} underline>{props.pinnedMessage.user?.first_name + ' ' + props.pinnedMessage.user?.last_name} :</Text>
                    </Link>
                    <Text fontSize={{base:'xs', md:'sm'}}> {props.pinnedMessage.content}</Text>
                </Text>
            </Flex>
        );
    }

    function renderContent() {
        if(currentPoll && showPoll && !hasAnsweredPoll) {
            if(!currentPoll.allows_multiple_answers) {
                return (
                    <View maxH={'325px'} py={2}>
                        <ScrollView
                            bg={colors['backgroundLight'][100]}
                            px={2} borderRadius={5}
                            nestedScrollEnabled={true}
                        >
                            <Text fontSize={{base:'xs', md:'sm'}} w={'100%'} h={'auto'} p={1}>{currentPoll.question}</Text>
                            <Radio.Group name={"poll"} accessibilityLabel={"poll"} value={pollSingleAnswer}
                                         onChange={v => {setPollSingleAnswer(v)}}
                            >
                                {currentPoll.answers.map(ans => {
                                    return <Radio value={ans.id} size={'sm'} key={ans.id} p={1}>{ans.content}</Radio>;
                                })}
                            </Radio.Group>

                        </ScrollView>
                        <Button
                            my={2} py={1}
                            onPress={answerPoll}
                            position={"absolute"}
                            bottom={3}
                            right={3}
                        >
                            <Flex direction={"row"} alignItems={"center"}>
                                <Text fontSize={"md"} fontWeight={"500"} color={"white"} mr={1}>{t('Send')} </Text>
                                <FontAwesome name="send" size={16} color="white" />
                            </Flex>
                        </Button>
                    </View>
                );
            }
            else if(currentPoll.allows_multiple_answers) {
                return (
                    <View maxH={'325px'} py={2}>
                        <ScrollView
                            bg={colors['backgroundLight'][100]}
                            px={2} borderRadius={5}
                            nestedScrollEnabled={true}
                        >
                            <Text fontSize={{base:'xs', md:'sm'}} w={'100%'} h={'auto'} p={1}>{currentPoll.question}</Text>
                            <Checkbox.Group name={"poll"} accessibilityLabel={"poll"} value={pollAnswers}
                                            onChange={v => {setPollAnswers(v)}}
                            >
                                {currentPoll.answers.map((ans) => {
                                    return <Checkbox value={ans.id} key={ans.id} size={'sm'} p={1}>{ans.content}</Checkbox>;
                                })}
                            </Checkbox.Group>
                        </ScrollView>
                        <Button
                            my={2} py={1}
                            onPress={answerPoll}
                            position={"absolute"}
                            bottom={3}
                            right={3}
                        >
                            <Flex direction={"row"} alignItems={"center"}>
                                <Text fontSize={"md"} fontWeight={"500"} color={"white"} mr={1}>{t('Send')} </Text>
                                <FontAwesome name="send" size={16} color="white" />
                            </Flex>
                        </Button>
                    </View>
                );
            }
        }
        else if(pollResults.results) {
            return (
                <ScrollView
                    bg={colors['backgroundLight'][100]}
                    px={4} borderRadius={5} mt={1}
                    nestedScrollEnabled={true}
                >
                    <Text fontSize={{base:'xs', md:'sm'}} w={'100%'} h={'auto'}>{pollResults.question}</Text>
                    {renderResults()}
                </ScrollView>
            );
        }
        else if(hasAnsweredPoll) {
                return (
                    <ScrollView
                        bg={colors['backgroundLight'][100]}
                        px={4} borderRadius={5} mt={1}
                        nestedScrollEnabled={true}
                    >
                        <Text fontSize={'sm'} w={'100%'} h={'auto'}>
                            You have already answered the current poll.
                        </Text>
                    </ScrollView>
                );
        }
        else if(props.pinnedMessage) {
            return renderPinnedMessage();
        }
    }

    return (
        <View>
            {renderContent()}
        </View>
    );
}
