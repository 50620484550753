import {Box, Flex, Text, Pressable, Button, Link} from "native-base";
import * as React from "react";
import * as Linking from 'expo-linking';
import {Platform} from "react-native";
import {FontAwesome} from '@expo/vector-icons';
import HttpService from "../services/HttpService";

function WhatsappButton({exhibitor_id, live_whatsapp}) {

    const addHit = (type) => {
        HttpService
            .getData(`exhibitor/hit/${exhibitor_id}/${type}`)
            .then(response => {
                console.log('Hit: ' + type)
            })
            .catch(error => {
                console.log('Error: ' + error)
            })
    }

    function handlePressContact() {
        addHit('contact_whatsapp');
        return Linking.openURL(`https://wa.me/${live_whatsapp}`);
    }

    if(Platform.OS === 'web') {
        return (
            <Link
                href={`https://wa.me/${live_whatsapp}`}
                isExternal
            >
            <Button marginRight={{md: 2}} onPress={ () => {addHit('contact_whatsapp')} }
                    borderRadius={0}
                    pl={"65%"}
                    py={1}
                    pr={5}
                    borderBottomRightRadius={"full"}
                    >
                <Flex direction='row' align={'center'}>
                    <Text fontSize={'sm'} px={2} color="white">
                        Contact by Whatsapp
                    </Text>
                    <FontAwesome name="whatsapp" size={28} color="white"/>
                </Flex>
            </Button>
            </Link>
        );
    }
    else {
        return (
            <Button
                onPress={handlePressContact}
                borderRadius={0}
                pl={'20%'}
                py={1}
                borderBottomRightRadius={'full'}
            >
                <Flex direction='row' justify={"flex-end"} align={"center"} pr={0} w={"100%"} >
                    <Text fontSize={{base:"xs",md:'sm'}} px={2} w={"auto"} noOfLines={2} color={'white'}>
                        Contact by Whatsapp
                    </Text>
                    <FontAwesome name="whatsapp" size={24} color="white" />
                </Flex>
            </Button>
        );
    }
}

export default WhatsappButton
