import {Platform} from "react-native";
import {Badge, Button, Center, Flex, Text} from "native-base";
import * as React from "react";
import {useTranslation} from "react-i18next";
import { FontAwesome } from '@expo/vector-icons';

export default function FilterButton(props) {
    const {t, i18n} = useTranslation();

    return (
        Platform.OS === "web" ?
            (
                <Button
                    borderRadius={'md'}
                    size={"sm"}
                    px={6} py={3}
                    position="absolute"
                    left={32} top={'90px'}
                    endIcon={<FontAwesome name="sliders" size={20} color={'white'} />}
                    zIndex={10} elevation={10}
                    onPress={props.toggleFilters}
                >
                    <Text fontSize={'lg'} color={'white'}>
                        {props.count > 0 ?
                            <Badge
                                bg={'red.500'}
                                rounded="999px"
                                variant="solid"
                                w={4}
                                h={4}
                                top={-3}
                                right={2}
                            >
                                <Center flex={1}>
                                    <Text style={{fontSize: 8}} color={'white'}>{props.count}</Text>
                                </Center>
                            </Badge>
                            :
                            <></>
                        }
                        {t("Filters")}
                    </Text>
                </Button>
            ) : (
                <Button
                    borderRadius={'md'}
                    size={"sm"}
                    my={4}
                    endIcon={<FontAwesome name="sliders" size={20} color={'white'} />}
                    zIndex={10} elevation={10}
                    onPress={props.toggleFilters}
                >
                    <Flex direction={'row'}>
                    {props.count > 0 ?
                        <Badge
                            bg={'red.500'}
                            rounded="999px"
                            variant="solid"
                            w={4}
                            h={4}
                            top={1}
                            right={2}
                        >
                            <Center flex={1}>
                                <Text style={{fontSize: 8}} color={'white'} >{props.count}</Text>
                            </Center>
                        </Badge>
                        :
                        <></>
                    }
                    <Text color={'white'}>
                        {t("Filters")}
                    </Text>
                    </Flex>
                </Button>)
    )
}
