import {Center, Flex, Text, useTheme} from "native-base";
import * as React from "react";
import Layout from "../layouts/Layout";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import HttpService from "../services/HttpService";
import AccountService from "../services/AccountService";
import {useIsFocused} from "@react-navigation/native";

export default function LiveChangeRoomScreen({navigation, ...props}) {
    const [fetchErr, setFetchErr] = useState();
    const [firstRoom, setFirstRoom] = useState();
    const [secondRoom, setSecondRoom] = useState();
    const {t, i18n} = useTranslation();
    const {congress} = useSelector(state => state.global);
    const {colors} = useTheme();
    const isFocused = useIsFocused();

    // Ping
    useEffect(() => {
        let interval;
        if(isFocused) {
            interval = AccountService.startPing(navigation, {page: 'live-change-room' });
        }
        return () => clearInterval(interval);
    }, [isFocused]);

    useEffect(() => {
        getRooms();
    }, []);

    function getRooms() {
        if(props.route.params.id) {
            HttpService.getData('room/get/one/' + props.route.params.id)
                .then((r) => setFirstRoom(r.data.data))
                .catch((err) => setFetchErr(err));
        }
        if(props.route.params.id2) {
            HttpService.getData('room/get/one/' + props.route.params.id2)
                .then((r) => setSecondRoom(r.data.data))
                .catch((err) => setFetchErr(err));
        }
    }

    return (
        <Layout activePage={navigation.isFocused ? t('You_can_only_watch_one_channel') : ''}>

            <Center flex={1}>
                <Flex maxW={900} borderRadius={5} bg={colors['backgroundLight'][50]} py={4} px={6}>
                    <Text>{t('You_can_only_watch_one_channel')}</Text>
                    <Text>{t('It_looks_like_you_are_already_watching')}{secondRoom ? secondRoom.name : ''}</Text>
                    <Text>{t('Please_close_all_other_windows')}</Text>
                </Flex>
            </Center>

        </Layout>
    );
}
