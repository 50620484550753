import * as React from "react";
import {useEffect, useState} from "react";
import Slider from "./Slider";
import HttpService from "../services/HttpService";
import {useTranslation} from "react-i18next";
import Session from "./Session";
import {useSelector} from "react-redux";
import {Center, Flex, ScrollView, Spinner, Text, View} from "native-base";
import Config from "../config.json";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {Platform} from "react-native";
import SessionLoader from "./SessionLoader";
import pusher from "../pusher";


function ConferencesLive(props) {

    const {
        sessionImgMobileWidth,
        sessionImgMobileHeight,
        sessionImgDesktopWidth,
        sessionImgDesktopHeight,
        sliderMobileWidth,
        sliderDesktopWidth,
        deviceType
    } = props

    const {user} = useSelector(state => state.auth);
    const {list} = useSelector(state => state.auth);

    const {t, i18n} = useTranslation();
    const [soonConferences, setSoonConferences] = useState([]);
    const [fetchError, setFetchError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const MAX_ITEMS = 20;
    let isLive = false;
    let isSoon = false;

    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };

    function getSoonConferences() {
        setIsLoading(true);
        HttpService
            .getData('session/get/soonSessions')
            .then(response => {
                setSoonConferences(response.data.data);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                setFetchError(error);
                setIsLoading(false);
            })
    }

    useEffect(() => {
        getSoonConferences();
    }, [props.pusherUpdate]);


    const itemContent = (item) => {
        const timeBegin = Date.parse(item?.datetime_begin);
        item.liveCurrent.length > 0 ? isLive = true : isLive = false;
        timeBegin > Date.now() ? isSoon = true : isSoon = false;
        let isChecked = false;
        if (list.find(listItem => listItem.entity_id === item.id)) isChecked = true;
        return (
            <Session
                item={item}
                progressBar={isLive}
                isLive={isLive}
                isSoon={isSoon}
                showHours
                roomId={item.room_session_id}
                withListButton={true}
                isChecked={isChecked}
                screen={'Live'}
                isNotTruncated
                deviceType={deviceType}

            />
        )
    }

    const renderPlaceholderSlider = () => {
        let items = [];
        for (let i = 0; i < (Platform.OS !== 'web' ? 12 : 18); i++) {
            items.push(
                <View
                    key={i}
                    // justifyContent="center"
                    alignItems="center"
                    w={sliderMobileWidth*(deviceType == 1 ? 0.675 : 1)}
                    px={1}
                    mx={{lg:2}}
                >
                    <SessionLoader></SessionLoader>
                </View>
            )
        }
        return (
            <ScrollView horizontal>
                {items}
            </ScrollView>
        );
    }

    if (soonConferences?.length > 0) {
        return (
            <View my={2}>
                <Flex direction={'row'} align={'center'} width={{base:'100%',md:'95%'}} justifyContent={'space-between'} mb={2} ml={{base:0,lg:3}}>
                    <Flex direction={'row'} align={'center'}>
                        <MaterialCommunityIcons name="calendar-clock" size={32} color="#1F2357" />
                        <Text fontSize={"lg"} fontWeight="bold" ml={1}>{t('Conferences_soon')}</Text>
                    </Flex>
                </Flex>
                <Slider
                    data={soonConferences?.slice(0,MAX_ITEMS - 1)}
                    sliderMobileWidth={sliderMobileWidth*(deviceType == 1 ? 0.675 : 1)}
                    sliderDesktopWidth={sliderDesktopWidth}
                    itemContent={itemContent}
                    btnHeight={deviceType == 1 ? 100 : 120}
                    videos
                />
            </View>
        );
    } else if (isLoading) {
        return (

            <View my={2} width={'100%'} mx={{base:0,md:4}} mt={6}>
                <Flex direction={'row'} align={'center'} width={{base:'100%',md:'95%'}} justifyContent={'space-between'} mb={2} ml={{base:0,lg:3}}>
                    <Flex direction={'row'} align={'center'}>
                        <MaterialCommunityIcons name="calendar-clock" size={32} color="#1F2357" />
                        <Text fontSize={"lg"} fontWeight="bold" ml={1}>{t('Conferences_soon')}</Text>
                    </Flex>
                </Flex>
                {renderPlaceholderSlider()}
            </View>
        );
    } else return <></>;
}

export default ConferencesLive

ConferencesLive.defaultProps = {
    sliderMobileWidth: 240,
    sliderDesktopWidth: 240,
}




