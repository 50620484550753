import * as React from "react";
import {
    Platform,
} from "react-native";
import {useEffect, useState} from "react";
import HttpService from "../services/HttpService";
import Exhibitors from "./Exhibitors";
import MasonryList from '@react-native-seoul/masonry-list';
import {Center, Divider, Flex, ScrollView, Spinner, Text, useTheme, View} from "native-base";
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "react-responsive";
import {FontAwesome, MaterialIcons} from '@expo/vector-icons';

import ViewAll from "./ViewAll";

function HomeBooth() {
    const [companies, setCompanies] = useState([]);
    const [filteredCompanies, setFilteredCompanies] = useState(companies);
    const [isLoading, setIsLoading] = useState(false);
    const [t, i18n] = useTranslation();
    const {colors} = useTheme();
    const isMobile = useMediaQuery({query: '(max-width: 760px)'});

    /*
     * Return an integer between min and max both included
     */
    function getRandomIntInclusive(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    /*
     * Insert ads on array of data
     * insertIndex = index where ad is inserted
     * spaceIndex = number of items between ads
     */
    const dataWithAds = (arrayOfData, arrayOfAds, firstAdIndex = 2, secondAdIndex = 4, initialAdIndexMin = 6, initialAdIndexMax = 8, adSpaceMin = 2, adSpaceMax = 4) => {

        let adIndex = 1;
        arrayOfAds = [];
        for (let add of arrayOfAds) {
            // if (add.sorting_order == 1 && add.type != "live_exhibition") {
            //     arrayOfData.splice(firstAdIndex, 0, add);
            // } else if (add.sorting_order == 2 && add.type != "live_exhibition") {
            //     arrayOfData.splice(secondAdIndex, 0, add);
            // } else {
                arrayOfData.splice(adIndex, 0, add);
                adIndex = adIndex + 3;
            // }

        }
        return arrayOfData;
    }

    const getCompanies = () => {
        setIsLoading(true);
        HttpService
            .getData('exhibitor/get/home')
            .then(response => {
                let arr = response.data.data;

                HttpService
                    .getData('exhibitor/get/featured')
                    .then(response => {
                        const featured = response.data.data;
                        if(featured?.length > 0) {
                            arr = featured.concat(arr);
                        }
                        setIsLoading(false);

                        HttpService
                            .getData('ads/get/exhibition')
                            .then(response => {
                                let ads = response.data.data;
                                if (Platform.OS == 'web' || !isMobile) {
                                    arr = dataWithAds(arr, ads, 1, 3, 1, 15, 2, 2);
                                } else {
                                    arr = dataWithAds(arr, ads, 1, 3 , 1, 15, 2, 2);
                                }
                                setFilteredCompanies(arr);
                                setCompanies(arr);
                            })
                            .catch(error => {
                                console.log('Error: ' + error)
                            })
                    })
                    .catch(error => {

                    });
            })
            .catch(error => {
                setIsLoading(false);
            })
    }

    useEffect(
        () => {
            getCompanies();
        }, []
    );

    const renderExhibitors = () => {
        return (
            Platform.OS === 'web' || !isMobile ?
                (
                    // for desktop
                    <View my={2} mx={{base:0,md:4}} width={'100%'} mt={4}>
                        <Flex direction={'row'} align={'center'} width={{base:'100%',md:'95%'}} justifyContent={'space-between'} mb={2}>
                            <Flex direction={'row'} align={'center'}>
                                <FontAwesome name="handshake-o" size={30} color="#1F2357" />
                                <Text fontSize={"lg"} fontWeight="bold" ml={2}>{t('Exhibitors')}</Text>
                            </Flex>
                            <ViewAll to={'Exhibition'}></ViewAll>
                        </Flex>
                        <Exhibitors
                            companies={companies}
                            filteredCompanies={filteredCompanies}
                            companiesWidth={150}
                            companiesHeight={225}
                            adsWidth={150}
                            adsHeight={150}
                        />
                    </View>
                )
                :
                (
                    // for mobile
                    <View my={2} mx={{base:0,md:4}} width={'100%'} mt={4}>
                        <Flex direction={'row'} align={'center'} width={{base:'100%',md:'95%'}} justifyContent={'space-between'} mb={2}>
                            <Flex direction={'row'} align={'center'}>
                                <FontAwesome name="handshake-o" size={30} color="#1F2357" />
                                <Text fontSize={"lg"} fontWeight="bold" ml={2}>{t('Exhibitors')}</Text>
                            </Flex>
                            <ViewAll to={'Exhibition'}></ViewAll>
                        </Flex>
                        <Exhibitors
                            companies={companies}
                            filteredCompanies={filteredCompanies}
                            companiesRatio={2 / 3}
                            adsRatio={2.2 / 2}
                        />
                    </View>
                )
        )

    }

    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };

    const renderPlaceholder = () => {
        return (
            <ScrollView
                my={2}
                mt={4}
                mx={{base:0,md:4}}
            >
                <Flex direction={'row'} align={'center'} width={{base:'100%',md:'95%'}} justifyContent={'space-between'} mb={2}>
                    <Flex direction={'row'} align={'center'}>
                        <FontAwesome name="address-book" size={30} color="#1F2357" />
                        <Text fontSize={"lg"} fontWeight="bold" ml={2}>{t('Exhibitors')}</Text>
                    </Flex>
                    <ViewAll to={'Exhibition'}></ViewAll>
                </Flex>
                    <MasonryList
                        style={{alignSelf: 'stretch',
                            maxWidth:900,
                            marginLeft : 'auto',
                            marginRight : 'auto',
                        }}
                        contentContainerStyle={{
                            paddingHorizontal: 2,

                        }}
                        numColumns={isMobile ? 2 : 5}
                        data={new Array(isMobile ? 6 : 10).fill({})}
                        keyExtractor={(item) => uniqueId()}
                        renderItem={(item) => {
                            return (
                                <View
                                    bg={'#dedede'}
                                    height={225}
                                    width={150}
                                    m={2}
                                    borderRadius={5}
                                >

                                </View>
                            );
                        }
                        }
                    />

            </ScrollView>
        );
    }

    if (isLoading) {
        return renderPlaceholder();
    } else if (companies?.length > 0) {
        return (
            <>
                {renderExhibitors()}
            </>
        );
    } else {
        return null
    }
    ;

}

export default HomeBooth;


