import * as React from "react";
import {NativeBaseProvider, extendTheme, Spinner, StatusBar} from "native-base";
import {
    useFonts,
    Poppins_100Thin,
    Poppins_100Thin_Italic,
    Poppins_200ExtraLight,
    Poppins_200ExtraLight_Italic,
    Poppins_300Light,
    Poppins_300Light_Italic,
    Poppins_400Regular,
    Poppins_400Regular_Italic,
    Poppins_500Medium,
    Poppins_500Medium_Italic,
    Poppins_600SemiBold,
    Poppins_600SemiBold_Italic,
    Poppins_700Bold,
    Poppins_700Bold_Italic,
    Poppins_800ExtraBold,
    Poppins_800ExtraBold_Italic,
    Poppins_900Black,
    Poppins_900Black_Italic
} from '@expo-google-fonts/poppins'

export default function NativeBaseProviderWithCustomTheme(props) {
    let [fontsLoaded] = useFonts({
        Poppins_300Light,
        Poppins_300Light_Italic,
        Poppins_400Regular,
        Poppins_400Regular_Italic,
        Poppins_500Medium,
        Poppins_500Medium_Italic,
        Poppins_600SemiBold,
        Poppins_600SemiBold_Italic,
        Poppins_700Bold,
        Poppins_700Bold_Italic,
    });

    const theme = extendTheme({
        /*
        ** COLORS
         */
        colors: {
            background:
                {
                    50: '#fff',
                    100: '#f2f2f2',
                    200: '#bfbfbf',
                    300: '#a6a6a6',
                    400: '#8c8c8c',
                    500: '#737373',
                    600: '#595959',
                    700: '#404040',
                    800: '#262626',
                    900: '#0d0d0d',
                },
            backgroundLight:
                {
                    50: '#e9ebff',
                    100: '#c1c7f5',
                    200: '#98a2e8',
                    300: '#6f7cdd',
                    400: '#4757d2',
                    500: '#2d3db8',
                    600: '#223090',
                    700: '#172268',
                    800: '#0c1441',
                    900: '#02061b',
                },
            action: {
                50: '#5766D6',
                100: '#5766D6',
                200: '#5766D6',
                300: '#5766D6',
                400: '#5766D6',
                500: '#5766D6',
                600: '#5766D6',
                700: '#5766D6',
                800: '#5766D6',
                900: '#5766D6',
            },
            actionDark: {
                50: '#4754AD',
                100: '#4754AD',
                200: '#4754AD',
                300: '#4754AD',
                400: '#4754AD',
                500: '#4754AD',
                600: '#4754AD',
                700: '#4754AD',
                800: '#4754AD',
                900: '#4754AD',
            },
            primary: {
                50: '#e9ebff',
                100: '#c1c7f5',
                200: '#98a2e8',
                300: '#6f7cdd',
                400: '#5766D6',
                500: '#5766D6',
                600: '#4754AD',
                700: '#4754AD',
                800: '#4754AD',
                900: '#4754AD',
            }
        },
        config: {
            initialColorMode: 'light'
        },

        /*
        ** FONTS
         */
        fontConfig: {
            Poppins: {
                100: {
                    normal: 'Poppins_100Thin',
                    italic: 'Poppins_100Thin_Italic',
                },
                200: {
                    normal: 'Poppins_200ExtraLight',
                    italic: 'Poppins_200ExtraLight_Italic',
                },
                300: {
                    normal: 'Poppins_300Light',
                    italic: 'Poppins_300Light_Italic',
                },
                400: {
                    normal: 'Poppins_400Regular',
                    italic: 'Poppins_400Regular_Italic',
                },
                500: {
                    normal: 'Poppins_500Medium',
                    italic: 'Poppins_500Medium_Italic',
                },
                600: {
                    normal: 'Poppins_600SemiBold',
                    italic: 'Poppins_600SemiBold_Italic',
                },
                700: {
                    normal: 'Poppins_700Bold',
                    italic: 'Poppins_700Bold_Italic',
                },
                800: {
                    normal: 'Poppins_800ExtraBold',
                    italic: 'Poppins_800ExtraBold_Italic',
                },
                900: {
                    normal: 'Poppins_900Black',
                    italic: 'Poppins_900Black_Italic',
                },
            },
        },
        fonts: {
            heading: 'Poppins',
            body: 'Poppins',
            mono: 'Poppins',
        },
        shadows: {
            0: {
                shadowColor: '#5766D6',
                shadowOffset: {
                    width: 0,
                    height: 1,
                },
                shadowOpacity: 0.18,
                shadowRadius: 1.0,
                elevation: 1,
            },
            1: {
                shadowColor: '#5766D6',
                shadowOffset: {
                    width: 0,
                    height: 1,
                },
                shadowOpacity: 0.2,
                shadowRadius: 1.41,
                elevation: 2,
            },
            2: {
                shadowColor: '#5766D6',
                shadowOffset: {
                    width: 0,
                    height: 1,
                },
                shadowOpacity: 0.22,
                shadowRadius: 2.22,
                elevation: 3,
            },
            3: {
                shadowColor: '#5766D6',
                shadowOffset: {
                    width: 0,
                    height: 2,
                },
                shadowOpacity: 0.23,
                shadowRadius: 2.62,
                elevation: 4,
            },
            4: {
                shadowColor: '#5766D6',
                shadowOffset: {
                    width: 0,
                    height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 3.84,
                elevation: 5,
            },
            5: {
                shadowColor: '#5766D6',
                shadowOffset: {
                    width: 0,
                    height: 3,
                },
                shadowOpacity: 0.27,
                shadowRadius: 4.65,
                elevation: 6,
            },
            6: {
                shadowColor: '#5766D6',
                shadowOffset: {
                    width: 0,
                    height: 3,
                },
                shadowOpacity: 0.29,
                shadowRadius: 4.65,
                elevation: 7,
            },
            7: {
                shadowColor: '#5766D6',
                shadowOffset: {
                    width: 0,
                    height: 4,
                },
                shadowOpacity: 0.3,
                shadowRadius: 4.65,
                elevation: 8,
            },
            8: {
                shadowColor: '#5766D6',
                shadowOffset: {
                    width: 0,
                    height: 4,
                },
                shadowOpacity: 0.32,
                shadowRadius: 5.46,
                elevation: 9,
            },
            9: {
                shadowColor: '#5766D6',
                shadowOffset: {
                    width: 0,
                    height: 5,
                },
                shadowOpacity: 0.34,
                shadowRadius: 20,
                elevation: 10,
            },
        },

        /*
        ** COMPONENTS
         */
        components: {
            Select:{
                baseStyle:{
                    _actionSheetContent: {
                        bg:'#6f7cdd',
                    },
                }
            },
            Text :{
                baseStyle:{
                    color : '#1F2357'
                }
            },
            Heading:{
                baseStyle:{
                    color : '#1F2357'
                }
            },
            Button: {
                defaultProps: {
                    colorScheme: 'action',
                    _text: {
                        color: 'white'
                    },
                }
            },
            Checkbox: {
                baseStyle: {
                    _checked: {
                        borderColor: '#5766D6',
                        bg: '#5766D6'
                    },
                    _text : {
                        color : '#fff'
                    }
                },
            }
        }
    });

    if (!fontsLoaded) {
        return (
            <NativeBaseProvider theme={theme} config={{suppressColorAccessibilityWarning: true}}>
                <Spinner color="#5766D6" />
            </NativeBaseProvider>
        );
    } else return (
        <NativeBaseProvider theme={theme} config={{suppressColorAccessibilityWarning: true}}>
            <StatusBar barStyle='dark-content' backgroundColor='#fff'/>
            {props.children}
        </NativeBaseProvider>
    );
}
