import {
    Text, View, useTheme, Flex, Avatar, Button,
} from "native-base";
import * as React from "react";
import {Animated, Dimensions, Linking, Platform} from "react-native";
import Slider from "./Slider";
import {useTranslation} from "react-i18next";
import {formatDistance, parseISO} from "date-fns";
import {Link} from "@react-navigation/native";
import Config from "../config.json";
import * as Device from "expo-device";
import { Entypo } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import {useEffect, useState} from "react";

import * as ScreenOrientation from "expo-screen-orientation";



function News(props) {
    const {t, i18n} = useTranslation();
    const {colors} = useTheme();
    const [deviceType,setDeviceType] = React.useState();
    const [landscape, setLandscape] = useState(null);

    const {itemMobileWidth, itemMobileHeight, itemDesktopWidth, itemDesktopHeight, title, data, screen} = props;
    const type = Config.type.toUpperCase();

    const ITEM_MOBILE_WIDTH = itemMobileWidth;
    const ITEM_MOBILE_HEIGHT = itemMobileHeight;
    const ITEM_DESKTOP_WIDTH = itemDesktopWidth;
    const ITEM_DESKTOP_HEIGHT = itemDesktopHeight;


    useEffect(() => {
        async function setLayout() {
            const type = await Device.getDeviceTypeAsync();
            setDeviceType(type);
        }
        setLayout();
    }, []);

    const convertNativeBase = (value) => {
        return parseInt(String(value).split('px')[0]);
    }

    const innerItemContent = (item,index) => {
        return (
                <Flex
                    direction={'row'}
                    bg={colors['background'][100]}
                    w={deviceType != 3 ?   ITEM_MOBILE_WIDTH * 0.98 : ITEM_DESKTOP_WIDTH*0.98}
                    h={deviceType != 3 ?  ITEM_MOBILE_HEIGHT : ITEM_DESKTOP_HEIGHT}
                    borderRadius={25}
                    align={'center'}
                >
                    <Flex flex={6} px={3}>
                        <Flex direction={'column'} justifyContent={'space-between'} align={'flex-start'}>
                            <Text fontSize={'sm'} ml={.5} bold noOfLines={1} isTruncated >{item.exhibitor ? item.exhibitor.company_name : type}</Text>
                            <Text fontSize={'xs'} opacity={0.5} mt={-1  }
                                  italic> {item.published_at ? formatDistance(new Date(), parseISO(item.published_at)) + ' ago' : ''}</Text>
                        </Flex>


                        <View width={'97.5%'} mt={1} opacity={0.75}>
                            <Text fontSize={'xs'} numberOfLines={{base: 4, md:3}} >{item.content.replace(/\s+/g, ' ').trim()}</Text>
                        </View>
                    </Flex>
                </Flex>

           );


    }

    const itemContent = (item, index) => {
        if(item.redirection_link){
            return (
                <Button onPress={() => {
                        Linking.openURL(item.redirection_link)

                }}

                        pr={2}
                >
                    {innerItemContent(item,index)}
                </Button>
            )
        }
        else if(item.session_id && item.session_id != 0 ){
            return (
                <Link
                    to={{
                        screen: 'Session',
                        params: {id: item.session_id}
                }}
                    style={{

                        paddingRight : 8,
                    }}
                >
                    {innerItemContent(item,index)}
                </Link>
            )
        }
        else if(item.exhibitor_id){
            return (
                <Link
                    to={{
                        screen: 'CompanyProfile',
                        params: {id: item.exhibitor_id}
                    }}
                    style={{

                        paddingRight : 8,
                        marginHorizontal: 'auto'
                    }}
                >
                    {innerItemContent(item,index)}
                </Link>
            )
        }
        else{
            return (
                <View

                    pr={2}
                >
                    {innerItemContent(item,index)}
                </View>
            )
        }
    }

    return (
        <Slider
            data={data}
            sliderTitle={title}
            sliderMobileWidth={ITEM_MOBILE_WIDTH}
            sliderMobileHeight={ITEM_MOBILE_HEIGHT}
            sliderDesktopWidth={ITEM_DESKTOP_WIDTH}
            sliderDesktopHeight={ITEM_DESKTOP_HEIGHT}
            itemMobileSpace={ 0}
            itemDesktopSpace={0}
            itemContent={itemContent}
            btnSize={24}
            btnHeight={110}
        />
    );

}

export default News


