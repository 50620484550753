import React, {useEffect, useState} from 'react';
import {Flex, ScrollView, Text, View} from "native-base";
import Layout from "../layouts/Layout";
import UserCard from "../components/UserCard";
import {useSelector} from "react-redux";
import UserSession from "../components/UserSession";
import HttpService from "../services/HttpService";
import {useIsFocused} from "@react-navigation/native";
import AccountService from "../services/AccountService";

const UserProfileScreen = (props) => {
    const {list} = useSelector(state => state.auth);
    const {id} = props.route.params;
    const [user, setUser] = useState({});
    const [lectures, setLectures] = useState([]);
    const [sessions, setSessions] = useState([]);
    const isFocused = useIsFocused();


    const getUser = () => {
        HttpService
            .getData(`user/get/one/${id}`)
            .then(response => {
                setUser(response.data.data);
            })
            .catch(error => {
                console.log('Error: ' + error)
            })
    }

    const getLecturesByUser = () => {
        HttpService
            .getData(`lecture/get/all/user/${id}`)
            .then(response => {
                setLectures(response.data.data);
                getSessionsFromLectures(response.data.data);
            })
            .catch(error => {
                console.log('Error: ' + error)
            })
    }

    useEffect(
        () => {
            let interval;
            if(isFocused) {
                getUser();
                getLecturesByUser();
                interval = AccountService.startPing(props.navigation, {page: 'user-profile', pageId: id});
            }

            return () => clearInterval(interval);
        }, [isFocused]
    );

    const getSessionsFromLectures = (lecturesToCheck) => {
        if (lecturesToCheck?.length > 0) {
            let arr = lecturesToCheck.map(lecture => lecture.session);
            // remove duplicate session
            arr = arr.reduce((acc, current) => {
                const x = acc.find(item => item.id === current.id);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);
            setSessions(arr);
        }
    }

    let isChecked = false;
    if (list.find(listItem => listItem.entity_id === id)) isChecked = true;
    return (
        <Layout withBackButton>
            <ScrollView width={'100%'} maxW={900} margin={'auto'}>
                <UserCard speakerId={id} isChecked={isChecked} user={user}/>
                {
                    sessions.length > 0 &&
                    <View p={3}>
                        <UserSession id={id} userSessions={sessions}/>
                    </View>
                }
            </ScrollView>
        </Layout>
    );
};

export default UserProfileScreen;
