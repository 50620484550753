import {
    LOGIN_SUCCESS,
    REFRESH_DATA_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
} from "./types";

import {
    GetDataUserService,
    LoginUserService,
    LogOutUserService
} from "../services/AuthService";
import {refreshNotifications} from "./notifications";

export const login = (credentials, deviceType) => (dispatch) => {
    return LoginUserService(credentials, deviceType).then(
        (data) => {
            if (data.success) {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: {
                        user: data.data,
                        congressUser: data.dataCongressUser,
                        list: data.user_list,
                    },
                });
                return Promise.resolve();
            } else {
                let message = '';
                if (typeof data.message === 'string') message = data.message;
                else {
                    for (const [key, value] of Object.entries(data.message)) {
                        if (typeof value[0] !== 'undefined') message = message.concat(value[0]);
                    }
                }
                dispatch({
                    type: LOGIN_FAIL,
                });
                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });
                return Promise.reject();
            }
        },
        (error) => {
            const message = data.message;
            dispatch({
                type: LOGIN_FAIL,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const refreshData = () => (dispatch) => {
    return GetDataUserService().then(
        (data) => {
            if (data.success) {
                dispatch({
                    type: REFRESH_DATA_SUCCESS,
                    payload: {
                        user: data.data,
                        congressUser: data.dataCongressUser,
                        list: data.user_list,
                    },
                });

                dispatch(refreshNotifications());

                return Promise.resolve();
            } else {
                dispatch(logout());
                return Promise.reject();
            }
        },
        (error) => {
            dispatch(logout());
            return Promise.reject();
        }
    );
};

export const logout = () => (dispatch) => {
    LogOutUserService().then(
        dispatch({
            type: LOGOUT,
        })
    );
};
