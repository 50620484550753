import * as React from "react";
import {
    Text,
    Modal,
    Flex,
    Center,
    Avatar, Heading, View, Pressable
} from "native-base";
import {useTranslation} from "react-i18next";
import {useNavigation} from "@react-navigation/native";
import DisclosureModal from "./DisclosureModal";
import {Platform} from "react-native";
import {useState} from "react";
import {EvilIcons} from '@expo/vector-icons';

function AbstractModal({lecture, showModal, setShowModal}) {
    const {t, i18n} = useTranslation();
    const [truncate, setTruncate] = useState(true);
    const navigation = useNavigation();

    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };

    function toggleTruncate() {
        return setTruncate(!truncate);
    }

    function hideModal() {
        return setShowModal(false);
    }

    const renderSpeaker = () => {
        if (lecture?.user) {
            return (
                <Pressable width={'100%'} onPress={() => navigation.navigate('UserProfile', {id: lecture?.user.id})}
                           style={{cursor: 'pointer'}}>
                    <Flex alignItems='center'>
                        <Avatar
                            key={uniqueId()}
                            source={{uri: lecture?.user?.picture}}
                            size={{base: '48px', md: '100px'}}
                        />
                        <Text bold fontSize={{base: 'sm', md: 'lg'}}>{lecture?.user?.fullname}</Text>
                        <Flex direction={'row'} alignItems={'center'} >
                            <EvilIcons name="external-link" size={20} color="white"/><Text>{t('View_profile')}</Text>
                        </Flex>
                    </Flex>
                </Pressable>
            );
        }
    }

    const renderAbstract = () => {
        if (Platform.OS === 'web') {
            return (
                <View marginBottom={5} width={'100%'}>
                    <Text textAlign={'justify'} isTruncated={truncate} width={'100%'}>{lecture?.abstract}</Text>
                    <Text
                        fontSize={{base: 'xs', md: 'sm'}}
                        onPress={toggleTruncate}
                        color={'primary.500'}>
                        {truncate ? t('Read_more') : t('Read_less')}
                    </Text>
                </View>
            );
        } else {
            return (
                <View marginBottom={5} width={'100%'}>
                    <Text textAlign={'justify'} isTruncated={truncate}
                          fontSize={'sm'} width={'100%'}>{lecture?.abstract}</Text>
                    <Text
                        fontSize={{base: 'xs', md: 'sm'}}
                        onPress={toggleTruncate}
                        color={'primary.500'}>
                        {truncate ? t('Read_more') : t('Read_less')}
                    </Text>
                </View>
            );
        }
    }

    return (
        <>
            <Modal isOpen={showModal} onClose={hideModal}>
                <Modal.Content maxWidth={'600px'}>
                    <Modal.CloseButton/>
                    <Modal.Body pt={8}>
                        <Center marginBottom={2}>
                            {renderSpeaker()}
                        </Center>
                        {lecture?.abstract ?
                            <>
                                <Heading size={{base: 'sm', md: 'md'}}>{t('Abstract')}</Heading>
                                {renderAbstract()}
                            </> : <Text textAlign={'center'}>{t('No_abstract')}</Text>}
                        <DisclosureModal lecture={lecture}/>
                    </Modal.Body>
                </Modal.Content>
            </Modal>
        </>
    );
}

export default AbstractModal
