import {Avatar, Flex, Spacer, Text, View} from "native-base";
import * as React from "react";
import {flexWrap} from "styled-system";

// Renders a rounded picture with text.
// Text can be underneath or on the right side of the picture.
// There can be one or several lines of text under the picture. Each line will be underneath the other lines.
// Expected props :
// text (string or array of strings)
// size (string or object)
// picture (string)
// direction (string, optional)
export default function AvatarWithText(props) {

    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };
    const wrapping = props.wrap ? props.wrap : 'wrap';

    function renderText() {
        if(Array.isArray(props.text)) {
            let texts = [];
            props.text.forEach((text) => {
                texts.push(
                    <Text
                        key={uniqueId()}
                        fontSize={props.size}
                    >{text}</Text>);
            });
            return <Flex align={props.textAlign}>{texts}</Flex>;
        }
        else return <Text fontSize={props.size}>{props.text}</Text>;
    }

    return(
        <Flex
            direction={props.direction}
            align={props.align}
            flex={props.flex}
            wrap={props.wrap}
            width={'100%'}
        >
            <Avatar
                size={props.avatarSize}
                source={{
                    uri: props.picture ? props.picture : null
                }}
                m={2}
            />
            <View width={'80%'}>
                {renderText()}
            </View>
        </Flex>
    );
}

AvatarWithText.defaultProps = {
    direction: 'column',
    flex: 1,
    align: 'center',
    textAlign: 'center',
    flexWrap: 'wrap'
}
