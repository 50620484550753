import React from 'react';
import {FormControl, Input, Text, VStack} from "native-base";
import {Controller} from "react-hook-form";


const InputForm = ({name, control, defaultValue, placeholder, width, required, validation, errors, apiErrors, type, label, ...props}) => {

    return (
        <>
            <VStack mb={4}>
                <Controller
                    control={control}
                    name={name}
                    rules={{
                        ...validation,
                        required: required,
                    }}
                    defaultValue={defaultValue}
                    render={({field: {onChange, ref, onBlur, value}}) => (
                        <FormControl isInvalid={errors[name] || apiErrors[name]}>
                            <FormControl.Label>{label}</FormControl.Label>
                            <Input
                                onBlur={onBlur}
                                onChangeText={onChange}
                                value={value}
                                width={width}
                                placeholder={placeholder}
                                onKeyPress={props.onKeyPress}
                                fontSize={props.fontSize}
                                type={type}
                                bg={'#fff'}
                            />

                            {errors && errors[name] &&
                            <FormControl.ErrorMessage>This field is required </FormControl.ErrorMessage>}
                            {apiErrors && apiErrors[name] &&
                            <FormControl.ErrorMessage>{apiErrors[name]}</FormControl.ErrorMessage>}

                        </FormControl>
                    )}

                />
            </VStack>
        </>
    );

}
InputForm.defaultProps = {
    fontSize: 'sm',
    errors: {},
    apiErrors: {},
    type: 'text'
}
export default InputForm;
