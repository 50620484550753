import * as React from "react";
import {Text, Input, Select, CheckIcon, VStack, HStack, Button, Modal, Flex, Spacer, useTheme} from "native-base";
import {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import FilterButton from "./FilterButton";

function PartnerFilters({partners, setFilteredPartners}) {
    const {t, i18n} = useTranslation();
    const {colors} = useTheme();
    const toggleFilters = () => {
        setShowFilters(!showFilters);
    }
    const [showFilters, setShowFilters] = useState(false);
    const [partnerAcronym, setPartnerAcronym] = useState("");
    const [partnerName, setPartnerName] = useState("");
    const [activeFilterCount, setActiveFilterCount] = useState(0);

    /*
    * Filter partners according to the name or the acronym or both
    */
    const applyFilter = () => {
        let filterCount = 0;

        let partnersFilteredByName = partners;
        if (partnerName !== "") {
            partnersFilteredByName = partners.filter(partner =>
                partner?.name?.toLowerCase().includes(partnerName?.toLowerCase())
            );
            filterCount++;
        }

        let partnersFilteredByAcronym = partnersFilteredByName;
        if (partnerAcronym !== "") {
            partnersFilteredByAcronym = partnersFilteredByName.filter(partner =>
                partner?.acronym?.toLowerCase().includes(partnerAcronym?.toLowerCase())
            );
            filterCount++;
        }
        setFilteredPartners(partnersFilteredByAcronym);
        setActiveFilterCount(filterCount);
    }

    /*
     * remove all filters applied
     */
    const clearFilters = () => {
        setPartnerAcronym('');
        setPartnerName('');
    }

    function applyAndToggleFilters() {
        applyFilter();
        toggleFilters();
    }

    return (
        <>
            <FilterButton toggleFilters={toggleFilters} count={activeFilterCount}/>
            <Modal isOpen={showFilters} onClose={toggleFilters}>
                <Modal.Content maxWidth={'600px'}>
                    <Modal.CloseButton/>
                    <Modal.Body pt={8}>
                        <Input
                            value={partnerName}
                            p={2}
                            my={2}
                            onChangeText={name => setPartnerName(name)}
                            placeholder={t('Search_by_name')}
                            autoFocus={false}
                        />
                        <Input
                            value={partnerAcronym}
                            p={2}
                            my={2}
                            onChangeText={name => setPartnerAcronym(name)}
                            placeholder={t('Search_by_acronym')}
                            autoFocus={false}
                        />
                        <Flex direction={'row'} mt={4}>
                            <Button
                                flex={10}
                                onPress={clearFilters}
                                variant={'outline'}
                                _text={{
                                    color: colors['action'][50]
                                }}
                            >{t('Reset_filters')}</Button>
                            <Spacer flex={1}/>
                            <Button
                                flex={10}
                                onPress={applyAndToggleFilters}>{t('Apply_filters')}</Button>
                        </Flex>
                    </Modal.Body>
                </Modal.Content>
            </Modal>
        </>
    );
}

export default PartnerFilters
