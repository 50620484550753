import {Flex, Text, useTheme, View} from "native-base";
import * as React from "react";
import { Ionicons } from '@expo/vector-icons';

export default function StatsItemBlock(props) {
    const {colors} = useTheme();

    function renderIcon() {
        if(!props.noIcon) {
            return (
                <>
                    <View mx={1} />
                    <Ionicons name="people" size={24} />
                </>
            )
        }
        else return null;
    }

    return (
        <Flex maxW={400} py={4} backgroundColor={colors['background'][100]} borderRadius="lg" m={4}>
            <Text px={4}>
                <Text bold bg={colors['background'][100]} borderRadius={'md'} fontSize={'2xl'} mr={2} py={1} px={2}>
                    {props.score}
                    {renderIcon()}
                </Text>
                {props.text}
            </Text>
        </Flex>
    );
}
