import * as React from "react";
import {Input, Select, CheckIcon, Button, Modal, Flex, Spacer, useTheme, VStack} from "native-base";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import FilterButton from "./FilterButton";
import Select2Form from "./Select2Form";
import {FlatList, Platform} from "react-native";
import {CheckBox} from "react-native-web";
import {MaterialIcons} from "@expo/vector-icons";
import {backgroundColor} from "styled-system";

function CompanyFilters({companies, setFilteredCompanies}) {
    const {t, i18n} = useTranslation();
    const {colors} = useTheme();
    const toggleFilters = () => {
        setShowFilters(!showFilters);
    }
    const [showFilters, setShowFilters] = useState(false);
    const [companyName, setCompanyName] = useState("");
    const [countrySelected, setCountrySelected] = useState(""); // filter companies by country
    const [categorySelected, setCategorySelected] = useState(""); // filter companies by category
    const [activeFilterCount, setActiveFilterCount] = useState(0);

    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };

    const sortArray = (arr) => {
        arr.sort(function (a, b) {
            if (a > b) {
                return 1;
            }
            if (a < b) {
                return -1;
            }
            return 0;
        });
    }

    /*
     * Return an array of countries for all companies
     */
    const arrayOfCountries = () => {
        if (companies.length > 0) {
            let countries = companies.map(company => company?.country[0]?.name);
            countries = countries.filter((country, index) => countries.indexOf(country) === index);
            sortArray(countries);
            return countries.map(elt => {return {country : elt}});
        }
    }

    /*
     * Return an array of categories for all companies
     */
    const arrayOfCategories = () => {
        if (companies.length > 0) {
            let categories = companies.map(company => company.categories[0]?.name);
            categories = categories.filter((category, index) => categories.indexOf(category) === index);
            sortArray(categories);
            categories = categories.filter(category => category && category.length >0);
            return categories.map(elt => {return {category : elt}});
        }
    }

    /*
  * Filter companies according to the name or the sponsorship_level or both
  */
    const applyFilter = () => {
        let filterCount = 0;

        let companiesFilteredByName = companies;
        if (companyName !== "") {
            companiesFilteredByName = companies.filter(company =>
                company?.company_name?.toLowerCase().includes(companyName?.toLowerCase())
                ||
                company?.sponsor_company_name?.toLowerCase().includes(companyName?.toLowerCase())
            );
            filterCount++;
        }

        let companiesFilteredByCountry = companiesFilteredByName;
        if (countrySelected !== "") {
            companiesFilteredByCountry = companiesFilteredByName.filter(company =>
                company?.country[0]?.name.toLowerCase().includes(countrySelected.toLowerCase())
            );
            filterCount++;
        }

        let arr = [];
        let companiesFilteredByCategory = companiesFilteredByCountry;
        if (categorySelected !== "") {
            for (let company of companiesFilteredByCategory) {
                if (company?.categories?.length > 0) {
                    for (let c of company.categories) {
                        if (c?.name?.toLowerCase() === categorySelected?.toLowerCase()) {
                            arr = [...arr, company]
                        }
                    }
                }
            }
            setFilteredCompanies(arr);
            filterCount++;
        } else {
            setFilteredCompanies(companiesFilteredByCategory);
        }

        setActiveFilterCount(filterCount);
    }

    /*
     * remove all filters applied
     */
    const clearFilters = () => {
        setCompanyName('');
        setCountrySelected('');
        setCategorySelected('');
    }

    function applyAndToggleFilters() {
        applyFilter();
        toggleFilters();
    }

    return (
        <>
            <FilterButton toggleFilters={toggleFilters} count={activeFilterCount}/>
            <Modal isOpen={showFilters} onClose={toggleFilters}>
                <Modal.Content maxWidth={'600px'}>
                    <Modal.CloseButton/>
                    <Modal.Body pt={8}>
                        <Input
                            value={companyName}
                            p={2}
                            my={2}
                            onChangeText={name => setCompanyName(name)}
                            placeholder={t('Search_by_company_name')}
                            autoFocus={false}
                        />
                        {
                            arrayOfCountries().length > 0 && (
                                Platform.OS === "web" ?
                                <Select
                                    selectedValue={countrySelected}
                                    placeholder={countrySelected === "" ? t("Filter_by_country") : countrySelected}
                                    _selectedItem={{
                                        bg: "teal.600",
                                        endIcon: <CheckIcon size="5"/>,
                                    }}
                                    p={2}
                                    my={2}
                                    onValueChange={(itemValue) => setCountrySelected(itemValue)}
                                >
                                    <Select.Item label="" value=""/>
                                    {arrayOfCountries().map(c => (
                                        <Select.Item index={c.country} label={c.country} value={c.country} key={uniqueId()}/>))}
                                </Select> :
                                <Select
                                    selectedValue={countrySelected}
                                    placeholder={countrySelected === "" ? t("Filter_by_country") : countrySelected}
                                    _selectedItem={{
                                        bg: colors.white,
                                        paddingRight:2,
                                        _icon: {
                                            color: '#fff'
                                        },
                                        _text: {
                                            overflow: "hidden"
                                        },
                                        startIcon: <MaterialIcons name={"radio-button-checked"} size={20} color={colors['action'][50]}></MaterialIcons>,
                                    }}
                                    _item={{
                                        bg:colors.white,
                                        paddingRight:10,
                                        _text: {
                                            overflow: "hidden"
                                        },
                                        startIcon:<MaterialIcons name={"radio-button-unchecked"} size={20} color={colors}></MaterialIcons>
                                    }}
                                    _customDropdownIconProps={{
                                        size: 5
                                    }}
                                    _actionSheetContent={{
                                        bg: colors.white
                                    }}
                                    p={2}
                                    my={2}
                                    onValueChange={(itemValue) => setCountrySelected(itemValue)}
                                >
                                    <Select.Item label="" value=""/>
                                    {arrayOfCountries().map(c => (
                                        <Select.Item index={c.country} label={c.country} value={c.country} key={uniqueId()}/>))}
                                </Select>

                            )
                        }
                        {
                            arrayOfCategories().length > 0 && (
                                Platform.OS === "web" ?

                                    <Select
                                    selectedValue={categorySelected}
                                    placeholder={categorySelected === "" ? t('Filter_by_category') : categorySelected}
                                    _selectedItem={{
                                        bg: "teal.600",
                                        endIcon: <CheckIcon size="5"/>,
                                    }}
                                    my={2}
                                    p={2}
                                    onValueChange={(itemValue) => setCategorySelected(itemValue)}
                                >
                                    <Select.Item label="" value=""/>
                                    {arrayOfCategories().map(c => (
                                        <Select.Item index={c.category} label={c.category} value={c.category} key={uniqueId()}/>))}
                                </Select>
                                    :
                                    <Select
                                        _customDropdownIconProps={{
                                            size: 5
                                        }}
                                        selectedValue={categorySelected}
                                        placeholder={categorySelected === "" ? t('Filter_by_category') : categorySelected}
                                        _selectedItem={{
                                            bg: colors.white,
                                            paddingRight:2,
                                            _icon: {
                                                color: '#fff'
                                            },
                                            _text: {
                                                overflow: "hidden"
                                            },
                                            startIcon: <MaterialIcons name={"radio-button-checked"} size={20} color={colors['action'][50]}></MaterialIcons>,
                                        }}
                                        _item={{
                                            bg:colors.white,
                                            paddingRight:10,
                                            _text: {
                                                overflow: "hidden"
                                            },
                                            startIcon:<MaterialIcons name={"radio-button-unchecked"} size={20} color={colors}></MaterialIcons>
                                        }}
                                        _actionSheetContent={{
                                            bg: colors.white
                                        }}
                                        p={2}
                                        my={2}
                                        onValueChange={(itemValue) => setCategorySelected(itemValue)}
                                    >
                                        <Select.Item label="" value=""/>
                                        {arrayOfCategories().map(c => (
                                            <Select.Item index={c.category} label={c.category} value={c.category} key={uniqueId()}/>))}
                                    </Select>
                            )
                        }
                        <Flex direction={'row'} mt={4}>
                            <Button
                                flex={10}
                                onPress={clearFilters}
                                variant={'outline'}
                                _text={{
                                    color: colors['action'][50]
                                }}
                            >{t('Reset_filters')}</Button>
                            <Spacer flex={1}/>
                            <Button
                                flex={10}
                                onPress={applyAndToggleFilters}>{t('Apply_filters')}</Button>
                        </Flex>
                    </Modal.Body>
                </Modal.Content>
            </Modal>
        </>
    );
}

export default CompanyFilters
