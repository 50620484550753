import * as React from 'react';
import {
    Button,
    Center,
    Divider,
    Flex,
    Heading,
    HStack,
    Modal,
    ScrollView,
    Spacer,
    Text,
    useToast,
    View
} from "native-base";
import Layout from "../layouts/Layout";
import {useEffect, useState} from "react";
import HttpService from "../services/HttpService";
import PhotoBoothPhoto from "../components/PhotoBoothPhoto";
import * as FileSystem from "expo-file-system";
import * as MediaLibrary from "expo-media-library";
import * as Sharing from "expo-sharing";
import PhotoBoothModal from "../components/PhotoBoothModal";
import PhotoBoothForm from "../components/PhotoBoothForm";
import {useTranslation} from "react-i18next";
import * as ImagePicker from "expo-image-picker";
import Constants from "expo-constants";
import {Platform} from "react-native";
import AccountService from "../services/AccountService";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import {formatDistance, parseISO} from "date-fns";
import Config from "../config.json";
import pusher from "../pusher";
import {useIsFocused} from "@react-navigation/native";

export default function PhotoBoothScreen(props) {
    const [user,setUser] = useState()
    const [photos, setPhotos] = useState([]);
    const [serverError, setServerError] = useState({});
    const [reload, setReload] = useState(false);
    const [showFocusedPicture, setShowFocusedPicture] = useState(false);
    const [focusedPicture, setFocusedPicture] = useState('');
    const [t, i18n] = useTranslation();
    const toast = useToast();
    const isFocused = useIsFocused();


    // Ping
    useEffect(() => {
        let interval;
        if(isFocused) {
            interval = AccountService.startPing(props.navigation, {page: 'photobooth' });
        }
        return () => clearInterval(interval);
    }, [isFocused]);

    useEffect(() => {
        getPhotos();
    }, [reload]);

    useEffect(() => {
        const channelPhotobooth = pusher.subscribe('live-photobooth');
        channelPhotobooth.bind('new', function (data) {
            setReload(prev => !prev);
        });
    }, []);

    useEffect(() => {
        if(focusedPicture){
            HttpService.getData('user/get/one/'+focusedPicture.user_id)
                .then((r) => {
                    setUser(r.data.data.first_name + " "+ r.data.data.last_name);
                })
                .catch((err) => {
                    setServerError(err);
                });
        }
    },[focusedPicture])

    function getPhotos() {
        HttpService.getData('photo-booth/get/all')
            .then((response) => {
                setPhotos(response.data.data);
            })
            .catch((err) => setServerError(err));
    }

    function renderPhotos() {
        let list = [];
        if (photos) {
            photos.forEach(photo => {
                list.push(
                    <PhotoBoothPhoto photo={photo} focus={renderFocusedPicture} key={photo.id}/>
                );
            });
        }
        if (list.length > 0)
            return <Flex direction={'row'} wrap={'wrap'} mt={2}>{list}</Flex>;
        else
            return (
                <Flex direction={'row'} wrap={'wrap'} mt={2}>
                    <Text italic>{t('Photobooth_empty')}</Text>
                </Flex>
            );
    }

    function renderFocusedPicture(url) {
        setShowFocusedPicture(true);
        setFocusedPicture(url);
    }

    async function saveOrShare(type) {
        let directory = await FileSystem.getInfoAsync(FileSystem.cacheDirectory + 'imcas-photobooth/');
        let newDirectory;

        if (!directory.exists) {
            newDirectory = await FileSystem.makeDirectoryAsync(FileSystem.cacheDirectory + 'imcas-photobooth/', {intermediates: true});
        } else {
            newDirectory = directory;
        }

        const file = await FileSystem.downloadAsync(focusedPicture.picture, newDirectory.uri + '/' + focusedPicture.id + '.jpeg');

        if (type === 'share') {
            await share(file.uri);
        } else if (type === 'save') {
            let {status} = await ImagePicker.requestMediaLibraryPermissionsAsync();
            if (status !== 'granted') {
                alert('Sorry, we need media library permissions to make this work!');
            } else await save(file.uri);
        }
    }

    async function save(uri) {
        try {
            const asset = await MediaLibrary.createAssetAsync(uri);
            await MediaLibrary.createAlbumAsync("IMCAS", asset, false);
            toast.show({
                title: "Success",
                placement: "top",
                status: "success",
                bg: "emerald.500",
                description: "The photo has been saved to your device",
            })
        } catch (error) {
            toast.show({
                title: 'Error',
                placement: "top",
                status: "error",
                description: "The photo has not been saved to your device",
            });
        }

    }

    async function share(uri) {
        await Sharing.shareAsync(uri, {dialogTitle: 'test', UTI: '.jpg', mimeType: 'image/jpeg'});
    }

    const renderHeader = () => {
        if (Platform.OS === 'web') {
            return (
                <Flex align={'center'}>
                    <Heading size={'2xl'}>{t('Photo_booth')}</Heading>
                </Flex>
            );
        } else {
            return (
                <Flex>
                    <Heading>{t('Photo_booth')}</Heading>
                </Flex>
            );
        }
    }

    return (
        <Layout withBackButton activePage={props.navigation.isFocused ? 'Explore' : ''}>
            <PhotoBoothForm/>
            <ScrollView flex={1} px={4} maxW={{md: 'auto'}} margin={{md: 'auto'}}>
                {renderHeader()}
                {renderPhotos()}
            </ScrollView>

            <PhotoBoothModal
                showFocusedPicture={showFocusedPicture}
                setShowFocusedPicture={setShowFocusedPicture}
                focusedPicture={focusedPicture}
                user={user}
                // date={formatDistance(new Date(), parseISO(focusedPicture.created_at))}
                action={saveOrShare}
            />

        </Layout>
    );
}
