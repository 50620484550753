import {Image, Platform} from "react-native";
import {Button, Center, Flex, Modal, Text, View} from "native-base";
import {AntDesign} from "@expo/vector-icons";
import * as React from "react";
import {useEffect, useState} from "react";
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import {parseISO} from "date-fns";

export default function PhotoBoothModal(props) {
    const [date,setDate] = useState();

    useEffect(() => {
        if(props.focusedPicture)
        setDate(  formatDistanceToNow(parseISO(props.focusedPicture.created_at)));
    });

    function hideFocusedPicture() {
        return props.setShowFocusedPicture(false);
    }

    function save() {
        return props.action('save');
    }

    function share() {
        return props.action('share');
    }

    if(Platform.OS === 'web') {
        return (
            <Modal isOpen={props.showFocusedPicture} onClose={hideFocusedPicture}>
                <Modal.Content maxWidth={'800px'}>
                    <Modal.CloseButton/>
                    <Modal.Body>
                        <Flex direction={'row'} justify={'flex-end'} pr={12} mb={8}>
                            <a href={props.focusedPicture.dl_link}
                               style={{textDecoration: 'none', paddingLeft: '6px', paddingRight: '6px'}}
                            >
                                <AntDesign name="download" size={24} color="white" />
                            </a>
                        </Flex>
                        <Center>
                            <Image source={{uri: props.focusedPicture.picture}} alt={'Photo HomeBooth Picture'}
                                   resizeMode={'cover'}
                                   style={{width: '600px', height: '600px'}} />
                        </Center>
                        <View px={20}>
                            {
                                props.focusedPicture.comment ?
                                    <Text my={3} fontWeight={"light"} fontStyle={"italic"}>"{props.focusedPicture.comment}"</Text>
                                    : null
                            }
                            <Text mt={1} fontWeight={"light"} fontSize={{base :"sm", lg:"md"}} opacity={0.75} textAlign={"right"}>{props.user}</Text>
                            <Text fontWeight={"light"} fontSize={{base :"xs", lg:"sm"}} opacity={0.75} textAlign={"right"} fontWeight={"light"} mt={-1}>{date} ago</Text>

                        </View>
                    </Modal.Body>
                </Modal.Content>
            </Modal>
        );
    }
    else return (
        <Modal isOpen={props.showFocusedPicture} onClose={hideFocusedPicture}>
            <Modal.Content maxWidth={'500px'}>
                <Modal.CloseButton />
                <Modal.Body>
                    <Flex direction={'row'} justify={'flex-end'} pr={12} mb={4}>
                        <Button variant={'ghost'} p={0} mr={6} onPress={save}>
                            <AntDesign name="download" size={24} color="white" />
                        </Button>
                        <Button variant={'ghost'} p={0} onPress={share}>
                            <AntDesign name="sharealt" size={24} color="white" />
                        </Button>
                    </Flex>
                    <Center>
                        <Image source={{uri: props.focusedPicture.picture}} alt={'Photo HomeBooth Picture'}
                               resizeMode={'cover'}
                               style={{width: 300, height: 300}}/>
                    </Center>
                    {
                        props.focusedPicture.comment ?
                            <Text my={3} fontWeight={"light"} fontStyle={"italic"}>"{props.focusedPicture.comment}"</Text>
                            : null
                    }
                    <Text my={1} fontWeight={"light"} fontSize={{base :"sm", lg:"md"}} opacity={0.75} textAlign={"right"}>{props.user}</Text>
                    <Text fontWeight={"light"} fontSize={{base :"xs", lg:"sm"}} opacity={0.75} textAlign={"right"} fontWeight={"light"} mt={-1}>{date} ago</Text>

                </Modal.Body>
            </Modal.Content>
        </Modal>
    );
}
