export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REFRESH_DATA_SUCCESS = "REFRESH_DATA_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const REFRESH_NOTIFICATIONS = "REFRESH_NOTIFICATIONS";
export const INIT_CONGRESS = "INIT_CONGRESS";
export const CLEAR_CONGRESS = "CLEAR_CONGRESS";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
