import * as React from "react";
import {
    Text,
    Input,
    Select,
    CheckIcon,
    VStack,
    HStack,
    Button,
    Modal,
    Flex,
    Spacer,
    Icon,
    Box,
    useTheme
} from "native-base";
import {useState, useEffect, useMemo, useCallback} from "react";
import {useTranslation} from "react-i18next";
import FilterButton from "./FilterButton";
import SelectForm from "./SelectForm";
import {useForm} from "react-hook-form";
import Select2Form from "./Select2Form";
import {Platform} from "react-native";
import {MaterialIcons} from "@expo/vector-icons";

function SpeakerFilters({speakers, setFilteredSpeakers}) {
    const {t, i18n} = useTranslation();
    const [showFilters, setShowFilters] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [lastNameValue, setLastNameValue] = useState(""); // filter value by last name
    const [countrySelected, setCountrySelected] = useState(""); // filter value by last name
    const [activeFilterCount, setActiveFilterCount] = useState(0);
    const {colors} = useTheme();
    const toggleFilters = () => {
        setShowFilters(!showFilters);
        if (showFilters) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        applyFilters();
    },[lastNameValue,countrySelected])

    /*
     * Return an array of countries include in Speakers List
     */
    const getCountries = useMemo(() => {
        let countries = speakers.map(speaker => speaker.country);
        countries = countries
            .filter((country, index) => countries.indexOf(country) === index)
            .sort((current,next) => current.localeCompare(next))
            .map((country) => {return {singleCountry : country}})
        ;
        return countries;
    },[speakers])

    /*
     * Filter speakers according to the lastname or the country or both
     */
    const filter = async () => {
        setIsLoading(true);
        toggleFilters();
        applyFilters();
    }

    const applyFilters = () => {
        let speakersFilteredByLastname = speakers;
        let filterCount = 0;

        if (lastNameValue !== "") {
            speakersFilteredByLastname = speakers.filter(speaker =>
                speaker.last_name.toLowerCase().includes(lastNameValue.toLowerCase())
            );
            filterCount++;
        }

        let speakersFilteredByCountry = speakersFilteredByLastname;
        if (countrySelected !== "") {
            speakersFilteredByCountry = speakersFilteredByLastname.filter(speaker =>
                speaker.country.toLowerCase().includes(countrySelected.toLowerCase())
            );
            filterCount++;
        }
        setFilteredSpeakers(speakersFilteredByCountry);
        setActiveFilterCount(filterCount);
    }

    const selectFormCountries = () => {

        if (getCountries.length > 0){
            if(Platform.OS !== "web"){
                return (
                        <Select
                            styles={{
                                marginTop : 8,
                                opacity:0.4,
                            }}
                            selectedValue={countrySelected}
                            placeholder={countrySelected === "" ? t("Filter_by_country") : countrySelected}
                            _selectedItem={{
                                bg: colors.white,
                                paddingRight:2,
                                _icon: {
                                    color: '#fff'
                                },
                                _text: {
                                    overflow: "hidden",
                                },
                                startIcon: <MaterialIcons name={"radio-button-checked"} size={20} color={colors['action'][50]}></MaterialIcons>,
                            }}
                            _item={{
                                bg:colors.white,
                                paddingRight:10,
                                _text: {
                                    overflow: "hidden",
                                },
                                startIcon:<MaterialIcons name={"radio-button-unchecked"} size={20} color={colors}></MaterialIcons>
                            }}
                            _customDropdownIconProps={{
                                size: 5
                            }}
                            _actionSheetContent={{
                                bg: colors.white
                            }}
                            p={2}
                            my={2}
                            onValueChange={(itemValue) => setCountrySelected(itemValue)}
                        >
                            <Select.Item label="" value=""/>
                            {getCountries.map(c => (
                                <Select.Item index={c.singleCountry} label={c.singleCountry} value={c.singleCountry} key={c.singleCountry}/>))}
                        </Select>
                  )
            }

            else{
                return (
                    <Select width={"100%"}
                        selectedValue={ countrySelected === "" ? t("Filter_by_country") : countrySelected.singleCountry}
                        accessibilityLabel={t("Filter_by_country")}
                        placeholder={countrySelected == "" ? t("Filter_by_country") : countrySelected}
                        _selectedItem={{
                            bg: "teal.600",
                            endIcon: <CheckIcon size="5"/>,
                        }}
                        mt={1}
                        p={2}
                        minWidth={{base: "100%", md: "60%",}}
                        onValueChange={(itemValue) => setCountrySelected(itemValue)}
                    >
                        <Select.Item label="" value=""/>
                        {getCountries.map(c => (<Select.Item index={c.singleCountry} label={c.singleCountry} value={c.singleCountry} key={c.singleCountry}/>))}
                    </Select>
                );
            }
        }
    }

    /*
     * Remove all filters applied
     */
    const clearFilters = () => {
        setLastNameValue('');
        setCountrySelected('');
    }

    return (
        <>
            {/*<FilterButton toggleFilters={toggleFilters} count={activeFilterCount}/>*/}
            <Flex direction={'column'}  w={Platform.OS === "web"?'50%':'100%'} justify={'center'} align={'center'} my={'2%'} alignSelf={'center'}>
                <Input
                    value={lastNameValue}
                    minWidth={{base: "100%", md: "60%",}}
                    onChangeText={name => setLastNameValue(name)}
                    placeholder={t('Search_a_speaker')}
                    autoFocus={false}
                    returnKeyType="search"
                    w={'100%'}
                />
                <Flex direction={"row"}  w={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                        <Box width={"60%"} mt={2}>
                            {selectFormCountries()}
                        </Box>
                        <Button
                            mt={2}
                            onPress={() => {clearFilters(); clearFilters()}}
                            variant={'outline'}
                            _text={{
                                color: colors['action'][50]
                            }}
                        >
                            {t('Reset_filters')}
                        </Button>

                        {isLoading ?
                            (<Button flex={10}> Wait...</Button>) : ""}
                </Flex>
            </Flex>
                    {/*</Modal.Body>
                </Modal.Content>
            </Modal>*/}
        </>
    );
}

export default SpeakerFilters
