import {
    Button,
    Center, Checkbox,
    Divider,
    Flex,
    Heading,
    Radio,
    ScrollView,
    Spinner,
    Text,
    useTheme,
    useToast,
    View
} from "native-base";
import {Link, useNavigation} from "@react-navigation/native";
import InputForm from "./InputForm";
import {AntDesign, Entypo} from "@expo/vector-icons";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import HttpService from "../services/HttpService";
import {useForm} from "react-hook-form";
import Constants from "expo-constants";
import {Platform, KeyboardAvoidingView} from "react-native";
import PinnedContent from "./PinnedContent";
import {useSelector} from "react-redux";
import Config from "../config.json";
import pusher from "../pusher";

export default function Chat(props) {
    const chat = useRef(null);
    const {t, i18n} = useTranslation();
    const [postError, setPostError] = useState({});
    const {control, handleSubmit, setError, reset, formState: {errors}} = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [isContentLoading, setIsContentLoading] = useState(false);
    const [chatContent, setChatContent] = useState([]);
    const [sortedContent, setSortedContent] = useState([]);
    const [getError, setGetError] = useState({});
    const [pinnedMessage, setPinnedMessage] = useState();
    const [inputFocus,setInputFocus] = useState(false);
    const {congressUser} = useSelector(state => state.auth);
    const {colors} = useTheme();
    const toast = useToast();
    const navigation = useNavigation();

    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };

    useEffect(() => {
        getMessages().then((r) => {
            scrollToEnd();
        });

        pusher.subscribe('live-session-' + props.sessionId);

        pusher.bind('comment-new', function(data) {
            getMessages().then((r) => scrollToEnd());
        });
        pusher.bind('comment-update', function(data) {
            getMessages().then((r) => scrollToEnd());
        });
        pusher.bind('comment-delete', function(data) {
            getMessages().then((r) => scrollToEnd());
        });
    }, []);

    useEffect(() => {
        if(props.isSessionLoading === false) {
            getMessages().then((r) => {
                scrollToEnd();
            });
        }
    }, [props.isSessionLoading]);

    useEffect(() => {
        sortMessages();
    }, [chatContent]);

    function getMessages() {
        setIsContentLoading(true);
        return HttpService.getData('session/' + props.sessionId + '/chat')
            .then((response) => {
                setChatContent(response.data.data);
                setIsContentLoading(false);
            })
            .catch((error) => {
                setGetError(error);
                setIsContentLoading(false);
            })
    }

    function sortMessages() {
        // Purpose of this function is have pinned messages some place else
        let messages = [];
        if(chatContent.length > 0) {
            chatContent.forEach((msg) => {
               if(msg.pinned) setPinnedMessage(msg);
               else messages.push(msg);
            });
            setSortedContent(messages);
        }
    }

    function onSubmit(data) {
        setIsLoading(true);
        if(typeof data.content === "string" && data.content.trim() !== ""){
         reset({content: ''});
         return HttpService.postData('session/' + props.sessionId + '/chat', data)
             .then((response) => {
                 if(response.data.error && response.data.error === 1) {
                     toast.show({
                         title: "Message not sent",
                         placement: "top",
                         status: "error",
                         description: "Sorry, you have been banned from the chat.",
                     });
                     setIsLoading(false);
                 }
                 else setIsLoading(false);
             })
             .catch((error) => {
                 setPostError(error);
             })
        }

        setIsLoading(false);

    }

    function scrollToEnd() {
        chat.current?.scrollToEnd({ animated: false });
    }

    function goToLiveStageForMobile() {
        return navigation.navigate('LiveStageScreenForMobile', {id: props.roomId});
    }

    function goToLiveStageForDesktop() {
        return navigation.navigate('LiveStageScreenForDesktop', {id: props.roomId});
    }

    function renderLink() {
        if(Platform.OS !== 'web') {
            return (
                <Button px={2} py={1} colorScheme={'red'} ml={2} mb={6}
                        onPress={goToLiveStageForMobile}>
                    <Text color={'white'}>See live video of the session</Text>
                </Button>
            );
        }
        else {
            return (
                <Button px={2} py={1} colorScheme={'red'} ml={2} mb={6}
                        onPress={goToLiveStageForDesktop}>
                    <Text color={'white'}>See live video of the session</Text>
                </Button>
            );
        }
    }

    return (
        <>
            {congressUser.physical_attendance && props.physical && props.room && props.room.stream_url ?
                renderLink()
                :
                <></>
            }
            <KeyboardAvoidingView
                behavior={"position"}
                style={{flex: 1, width: '100%'}}
            >
                <Flex height={'100%'}
                      bg={props.background ?? ''} pt={2} px={2} borderRadius={5}>
                    <View maxH={'50%'} zIndex={20}>
                            <Heading size={props.size}>{t('Chat_here')}</Heading>
                        <Divider />
                        <PinnedContent sessionId={props.sessionId} pinnedMessage={pinnedMessage} />
                    </View>
                    <View flex={1} zIndex={1}>

                        <ScrollView ref={chat} onLayout={scrollToEnd} nestedScrollEnabled={true}>

                            {sortedContent?.map((msg) => {
                                if(msg.user) {
                                    return (
                                        <Text key={uniqueId()}>
                                            <Link to={{screen: 'UserProfile', params: {id: msg.user.id}}}>
                                                <Text underline fontSize={props.size}>{msg.user.first_name + ' ' + msg.user.last_name} :</Text>
                                            </Link>
                                            <Text fontSize={props.size}> {msg.content}</Text>
                                        </Text>);
                                }
                                else return (
                                    <View key={uniqueId()}>
                                        <Text fontSize={props.size}><Text fontSize={props.size}>Anonymous :</Text> {msg.content}</Text>
                                    </View>);
                            })}
                        </ScrollView>

                    </View>
                    <Flex direction={'row'} align={'center'} p={1}>
                        <View flex={props.size === 'xs' ? 3 : 5} p={props.size === 'xs' ? 0 : 1} pt={props.size === 'xs' ? 0 : 3}>
                            <InputForm
                                name="content"
                                placeholder={'Your message'}
                                width="100%"
                                errors={errors}
                                control={control}
                                apiErrors={postError}
                                fontSize={props.size}
                                onKeyPress={(e) => {
                                    if(e.nativeEvent.key === "Enter"){
                                        handleSubmit(onSubmit)();
                                    }
                                }}
                                validation={{minLength: 2}}
                            />
                        </View>
                        <Center flex={1} py={props.size === 'xs' ? 3 : 5}>
                            {isLoading ? (<Spinner color="#5766D6"  size={'sm'}/>) :
                                <Button
                                    p={0}
                                    flex={1}
                                    w={'100%'}
                                    style={{flexBasis: 'auto'}}
                                    height={Platform.OS === 'web' ? '40px' : 'auto'}
                                    onPress={handleSubmit(onSubmit)}
                                >
                                    <AntDesign name="arrowright" size={20} color={'white'}/>
                                </Button>
                            }
                        </Center>
                    </Flex>
                </Flex>
            </KeyboardAvoidingView>
        </>
    );
}

Chat.defaultProps = {
    size: 'sm'
}
