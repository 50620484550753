import {useSelector} from "react-redux";
import {useState} from "react";
import {Avatar, Badge, Center, Flex, Text, useTheme, View, VStack} from "native-base";
import {Link} from "@react-navigation/native";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {FontAwesome, FontAwesome5, MaterialIcons} from "@expo/vector-icons";

export default function MenuLink(props) {
    const {t, i18n} = useTranslation();
    const {user} = useSelector(state => state.auth);
    const {unreadCount} = useSelector(state => state.notifications);
    const [isHovered, setIsHovered] = useState(false);
    const {colors} = useTheme();

    function renderText() {
        return (
            <Text
                ml={5}
                mt={0}
                fontSize={'sm'}
                color={props.activePage?.toLowerCase() === props.text?.toLowerCase() ? colors['actionDark'][50] : 'default'}
            >
                {props.text}
            </Text>
        );
    }

    function renderIcon() {
        if (props.destination === 'Profile') {
            if (unreadCount > 0) {
                return (
                    <VStack>
                        <Badge
                            bg={colors['action'][50]}
                            rounded="999px"
                            mb={-4}
                            mr={-1}
                            zIndex={1}
                            variant="solid"
                            alignSelf="flex-end"
                            w={4}
                            h={4}
                        >
                            <Center flex={1}>
                                <Text style={{fontSize: 8}}>{unreadCount}</Text>
                            </Center>
                        </Badge>
                        <Avatar
                            source={{uri: user?.picture}}
                            size={7}
                        >
                            ME
                        </Avatar>
                    </VStack>
                );
            } else {
                return (
                    <Avatar
                        source={{uri: user?.picture}}
                        size={6}
                    >
                        ME
                    </Avatar>
                );
            }
        } else if (props.text === t('Program')) {
            return (
                <FontAwesome5
                    name="calendar-alt"
                    size={26}
                    style={{color: props.activePage?.toLowerCase() === props.text?.toLowerCase() ? '#4754AD' : '#1F2357'}}
                />)
        } else if(props.text === t('Exhibition')) {
            return (
                <FontAwesome
                    name="handshake-o"
                    size={20}
                    style={{color: props.activePage?.toLowerCase() === props.text?.toLowerCase() ? '#4754AD' : '#1F2357'}}
                />)

        } else return (
            <MaterialIcons
                name={props.icon}
                size={24}
                style={{color: props.activePage?.toLowerCase() === props.text?.toLowerCase() ? '#4754AD' : '#1F2357'}}
            />
        );
    }

    function hoverOn() {
        return setIsHovered(true);
    }

    function hoverOff() {
        return setIsHovered(false);
    }

    return (
        <View
            onMouseEnter={hoverOn}
            onMouseLeave={hoverOff}
            bg={isHovered || props.activePage?.toLowerCase() === props.text?.toLowerCase() ? colors['backgroundLight'][50] : ''}
            my={props.isProfile ? 1 : 2}
            px={2}
            pr={{md:10}}
            borderTopLeftRadius={'xl'}
            borderBottomLeftRadius={'xl'}
        >
            <Link to={{screen: props.destination}}
                  style={{textAlign: 'left'}}
            >
                <View>
                    <Flex
                        direction={'row'}
                        align={'center'}
                        justify={'flex-start'}
                        my={4}
                        pl={4}
                    >
                        {renderIcon()}
                        {renderText()}
                    </Flex>
                </View>
            </Link>
        </View>

    );
}
