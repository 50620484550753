import {
    Button,
    Center, Circle,
    Divider,
    Flex,
    Heading,
    NativeBaseProvider, Pressable,
    ScrollView, Spacer, Spinner,
    Text,
    useTheme,
    View,
    VStack
} from "native-base";
import { Linking } from 'react-native';
import * as React from "react";
import Layout from "../layouts/Layout";
import {Link, useIsFocused} from "@react-navigation/native";
import {useTranslation} from "react-i18next";
import AvatarWithText from "../components/AvatarWithText";
import {useDispatch, useSelector} from "react-redux";
import {Platform} from "react-native";
import {useEffect, useState} from "react";
import HttpService from "../services/HttpService";
import {format, formatDistance, parseISO} from "date-fns";
import {TouchableOpacity, TouchableWithoutFeedback} from "react-native";
import {refreshNotifications} from "../actions/notifications";
import {AntDesign, MaterialCommunityIcons} from '@expo/vector-icons';
import {logout} from "../actions/auth";
import { MaterialIcons } from '@expo/vector-icons';
import AccountService from "../services/AccountService";
import Config from "../config.json";
import httpService from "../services/HttpService";

export default function ProfileScreen({navigation}) {
    const [serverError, setServerError] = useState({});
    const [isSpeaker, setIsSpeaker] = useState({});
    const [reduxError, setReduxError] = useState({});
    const {t, i18n} = useTranslation();
    const {user} = useSelector(state => state.auth);
    const {congress} = useSelector(state => state.global);
    const {all} = useSelector(state => state.notifications);
    const {colors} = useTheme();
    const dispatch = useDispatch();
    const isFocused = useIsFocused();

    // Ping
    useEffect(() => {
        let interval;
        if(isFocused) {
            interval = AccountService.startPing(navigation, {page: 'profile' });
        }
        return () => clearInterval(interval);
    }, [isFocused]);

    function getIsSpeaker() {
        return HttpService
            .getData(`user/is/speaker/${user.id}`).then(response => {
                setIsSpeaker(response.data.is_speaker);
            })
            .catch(error => {
                console.log(error.data);
                setLoading(false);
            });
    }

    useEffect(() => {
        getIsSpeaker();
    }, []);

    function markAsRead(notification) {

        if (notification.id) {
            HttpService.postData('account/notification-read', {id: notification.id})
                .then((response) => {
                    dispatch(refreshNotifications()).then(() => console.log(response)).catch((err) => setReduxError(err));
                })
                .catch((error) => setServerError(error));
        } else return 'Notification ID not found.';
    }

    function handlePressLogout() {
        return dispatch(logout());
    }

    function renderNotification(n) {
        if (n.notification.url && Platform.OS === 'web') {
            return (
                    <TouchableWithoutFeedback onPress={() => markAsRead(n.notification)}>
                        <View>
                            <Flex px={4} py={2} backgroundColor={colors['background'][100]}>
                                <Text bold={!n.read_at} fontSize={{base: 'xs', md: 'md'}}>
                                    {!n.read_at ?
                                        <View h={2} w={2} bg={colors['action'][50]} rounded={'999px'} mr={2} mb={.5}/> : <></>}
                                    {n.notification.text}
                                </Text>
                                <Text mt={1} fontSize={{base: '2xs', md: 'xs'}}
                                      italic>{n.created_at ? formatDistance(new Date(), parseISO(n.created_at)) + ' ago' : 'Unknown date'}</Text>
                            </Flex>
                            <Link to={{screen: 'Session', params: {id: n.notification.url.id}}}
                                  style={{position: 'absolute', width: "100%", height: "100%"}}
                            />
                        </View>
                    </TouchableWithoutFeedback>
            );
        } else if (!n.read_at) {
            return (
                <TouchableWithoutFeedback onPress={() => markAsRead(n.notification)}>
                    <View>
                        <Flex px={4} py={2} backgroundColor={colors['background'][100]}>
                            <Text bold fontSize={{base: 'xs', md: 'md'}}>
                                <View h={2} w={2} bg={colors['action'][50]} rounded={'999px'}/>
                                {'  ' + n.notification.text}
                            </Text>
                            <Text mt={1} fontSize={{base: '2xs', md: 'xs'}}
                                  italic>{n.created_at ? formatDistance(new Date(), parseISO(n.created_at)) + ' ago' : 'Unknown date'}</Text>
                        </Flex>
                        <Link to={{screen: 'Session', params: {id: n.notification.url.id}}}
                              style={{position: 'absolute', width: "100%", height: "100%"}}
                        />
                    </View>
                </TouchableWithoutFeedback>
            );
        } else {
            return (
                <TouchableWithoutFeedback onPress={() => markAsRead(n.notification)}>
                    <View>
                        <Flex px={4} py={2} backgroundColor={colors['background'][100]}>
                            <Text fontSize={{base: 'xs', md: 'md'}}>
                                {n.notification.text}
                            </Text>
                            <Text mt={1} fontSize={{base: '2xs', md: 'xs'}}
                                  italic>{n.created_at ? formatDistance(new Date(), parseISO(n.created_at)) + ' ago' : 'Unknown date'}</Text>
                        </Flex>
                        <Link to={{screen: 'Session', params: {id: n.notification.url.id}}}
                              style={{position: 'absolute', width: "100%", height: "100%"}}
                        />
                    </View>
                </TouchableWithoutFeedback>
            );
        }
    }

    function renderNotifications() {
        let list = [];

        for (let i in all) {
            if (parseInt(i) >= (Object.keys(all).length - 1)) {
                list.push(renderNotification(all[i]));
            } else {
                list.push(renderNotification(all[i]));
                list.push(<Divider/>);
            }
        }

        if (list.length > 0) return list;
        else return (
            <Flex direction={'row'}>
                <Text italic p={2} fontSize={{base: 'xs', md: 'md'}} style={{flex: 1, flexWrap: 'wrap'}} bg={colors['background'][100]}>{t('No_notifications')}</Text>
            </Flex>
        );
    }

    function renderCallToActions() {
        return (
            <Flex
                direction={{base: 'column', md: 'row'}}
                wrap={'wrap'}
                justify={'space-between'}
                flex={1}
                my={2}
            >
                <Flex
                    direction={'row'}
                    align={'center'}
                    justify={'flex-start'}
                    py={4}
                    px={6}
                    borderWidth={{base: 0, md: 1}}
                    borderColor={colors['action'][50]}
                    borderRadius={5}
                >
                    <MaterialIcons name="list" size={24} color={"#5766D6"}/>
                    <View ml={2}>
                        <Text color={colors['action'][50]}>{t('My_list')}</Text>
                    </View>
                    <Link to={{screen: 'UserList'}}
                          style={{position: 'absolute', width: '100%', height: '100%'}}
                    />
                </Flex>
                <View flex={1} width={{base: '100%'}} borderTopWidth={{base: 1, md: 0}} borderColor={colors['action'][50]} />
                <Flex
                    direction={'row'}
                    align={'center'}
                    justify={'flex-start'}
                    py={4}
                    px={6}
                    borderWidth={{base: 0, md: 1}}
                    borderColor={colors['action'][50]}
                    borderRadius={5}
                >
                    <MaterialIcons name="settings" size={24} color={"#5766D6"} />
                    <View ml={2}>
                        <Text color={colors['action'][50]}>{t('My_info')}</Text>
                    </View>
                    <Link to={{screen: 'PersonalInformations'}}
                          style={{position: 'absolute', width: '100%', height: '100%'}}
                    />
                </Flex>
                <View flex={1} width={{base: '100%'}} borderTopWidth={{base: 1, md: 0}} borderColor={colors['action'][50]} />
                <Flex
                    justify={'flex-start'}
                    py={4}
                    px={6}
                    borderWidth={{base: 0, md: 1}}
                    borderColor={colors['action'][50]}
                    borderRadius={5}
                    direction={'row'}
                    align={'center'}>
                    <MaterialIcons name="badge" size={24} color={"#5766D6"} />
                    <View ml={2}>
                        <Text color={colors['action'][50]}>{t('my_registration')}</Text>
                        <Link to={{screen: 'MyBadge'}}
                              style={{position: 'absolute', width: '100%', height: '100%'}}
                        />
                    </View>
                </Flex>
                {
                    isSpeaker ?
                        <>
                            <View flex={1} width={{base: '100%'}} borderTopWidth={{base: 1, md: 0}} borderColor={colors['action'][50]}/>
                            <Flex
                                justify={'flex-start'}
                                py={4}
                                px={6}
                                borderWidth={{base: 0, md: 1}}
                                borderColor={colors['action'][50]}
                                borderRadius={5}
                                direction={'row'}
                                align={'center'}>
                                <MaterialCommunityIcons name="human-male-board" size={24} color={"#5766D6"} />
                                <View ml={2}>
                                    <Text color={colors['action'][50]}>{t('my_lectures')}</Text>
                                    <Link to={{screen: 'Lectures'}}
                                          style={{position: 'absolute', width: '100%', height: '100%'}}
                                    />
                                </View>
                            </Flex>
                        </>
                    :
                    <></>
                }

                <View flex={1} width={{base: '100%'}} borderTopWidth={{base: 1, md: 0}} borderColor={'red.500'} />
                <Pressable
                    variant={'unstyled'}
                    bg={colors['background'][50]}
                    onPress={handlePressLogout}
                    borderWidth={{base: 0, md: 1}}
                    borderColor={'red.500'}
                    borderRadius={5}
                    style={{cursor: 'pointer'}}
                >
                    <Flex
                        direction={'row'}
                        align={'center'}
                        justify={'flex-start'}
                        py={4} px={6}
                    >
                        <AntDesign name="logout" size={24} color={'red'} />
                        <View ml={2}>
                            <Text color={'red.600'}>{t('Logout')}</Text>
                        </View>
                    </Flex>
                </Pressable>
                <View flex={1} width={{base: '100%'}} borderTopWidth={{base: 1, md: 0}} borderColor={colors['action'][50]} opacity={0}
                      display={{md:"none"}}/>

            </Flex>
        );
    }

    return (
        <Layout activePage={navigation.isFocused ? t('My_profile') : ''}>
            <Center px={4}>
                <Flex w={'100%'} maxW={900} align={{base: 'flex-start', md: 'center'}}>
                    <Heading size={'2xl'}>{t('My_profile')}</Heading>
                </Flex>
            </Center>

            <View my={2}/>

            <ScrollView px={4}>
                <Center>
                    <Flex w={'100%'} maxW={900}>
                        <Flex align={'center'} h={{base: '150px', md: '250px'}}>
                            <AvatarWithText
                                text={user.first_name + ' ' + user.last_name}
                                size={{base: 'sm', md: 'xl'}}
                                avatarSize={{base: 24, md: 40}}
                                picture={user.picture}
                            />
                        </Flex>

                        <View mb={{base: '0', md: '60px'}} />

                        {renderCallToActions()}

                        <View mb={{base: '0', md: '90px'}} />

                        <Heading size={'lg'}>{t('Notifications')}</Heading>
                        <Flex wrap={'wrap'}
                              bg={colors['backgroundLight'][50]}
                              borderRadius={'md'}
                        >
                            {renderNotifications()}
                        </Flex>

                        <View mt={8}>
                            <Text>{(Config.type == 'imcas'?t('Account_deletion_help_message'):t('Account_deletion_help_message_aop'))}</Text>
                        </View>
                    </Flex>
                </Center>
            </ScrollView>
        </Layout>
    );
}
