import React from 'react';
import PropTypes from 'prop-types';
import {connectHighlight} from 'react-instantsearch-native';
import {Flex, Text} from "native-base";

const Highlight = ({attribute, hit, highlight}) => {
    const highlights = highlight({
        highlightProperty: '_highlightResult',
        attribute,
        hit,
    });

    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };

    return (
        <Text>
            {highlights.map(({value, isHighlighted}, index) => {
                const style = {
                    backgroundColor: isHighlighted ? 'yellow' : 'transparent',
                    color: 'black',
                    width:'100%',
                };

                return (
                    <Text key={uniqueId()} style={style} fontSize={{base: 'md', md: 'xl'}} textAlign={'left'}>
                        {value}
                    </Text>
                );
            })}
        </Text>
    );
};

Highlight.propTypes = {
    attribute: PropTypes.string.isRequired,
    hit: PropTypes.object.isRequired,
    highlight: PropTypes.func.isRequired,
};

export default connectHighlight(Highlight);
