import {createStore, applyMiddleware, compose} from "redux";
import {persistStore, persistReducer} from 'redux-persist';
import AsyncStorage from "@react-native-async-storage/async-storage";
import rootReducer from "./reducers";
import thunk from "redux-thunk";

const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
}

const middleware = [thunk];
const persistedReducer = persistReducer(persistConfig, rootReducer)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


let store = createStore(persistedReducer, composeEnhancers(
    applyMiddleware(...middleware)
));
let persistor = persistStore(store)


export {persistor, store};
