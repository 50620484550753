import React, {useState} from 'react';
import {Checkbox, Text} from "native-base";
import {Controller} from "react-hook-form";


const CheckboxForm = ({
                          name,
                          control,
                          defaultValue,
                          placeholder,
                          width,
                          required,
                          validation,
                          errors,
                          text,
                          checkboxValue,
                          liveExhibitStatus
                      }) => {
    const [groupValues, setGroupValues] = useState([])

    return (
        <>
            <Controller
                control={control}
                name={name}
                rules={{
                    ...validation,
                    required: required,
                }}
                defaultValue={checkboxValue}
                render={({field: {onChange, ref, onBlur, value}}) => (
                    <Checkbox colorScheme="green" my={2} value={value} onChange={onChange} defaultIsChecked={checkboxValue}>
                        <Text fontSize="xs" mx={2}>{text}</Text>
                    </Checkbox>
                )}

            />
            {errors && errors[name] && <Text>This field is required</Text>}
        </>
    );

}
export default CheckboxForm;
