import React from 'react';
import {StyleSheet, View, FlatList} from 'react-native';
import PropTypes from 'prop-types';
import {connectInfiniteHits} from 'react-instantsearch-native';
import Highlight from "./Highlight";
import {Avatar, Badge, Flex, Text} from "native-base";
import {Link} from "@react-navigation/native";


const styles = StyleSheet.create({
    separator: {
        borderBottomWidth: 1,
        borderColor: '#043033',
    },
    item: {
        padding: 10,
        flexDirection: 'column',
        maxWidth: '100%'
    },
    titleText: {
        fontWeight: 'bold',
    },
});

const InfiniteHits = ({hits, hasMore, refineNext, attribute, haveAvatar, entity}) => {

    function renderLink(item) {
        if(entity === 'speaker') {
            return (
                <Link
                    style={{position: 'absolute', width: '100%', height: '100%'}}
                    to={{screen: 'UserProfile', params: {id: item.user_id}}}
                />
            );
        }
        else if(entity === 'company') {
            return (
                <Link
                    style={{position: 'absolute', width: '100%', height: '100%'}}
                    to={{screen: 'CompanyProfile', params: {id: item.objectID}}}
                />
            );
        }
        else if(entity === 'session') {
            return (
                <Link
                    style={{position: 'absolute', width: '100%', height: '100%'}}
                    to={{screen: 'Session', params: {id: item.objectID}}}
                />
            );
        }
        else return <></>;
    }

    function renderItemSeparator() {
        return <View style={styles?.separator}/>;
    }

    return (
        <FlatList
            w={'100%'}
            data={hits}
            keyExtractor={item => item.objectID}
            ItemSeparatorComponent={renderItemSeparator}
            /**
             * TO DO - Implement infinite scroll with handler below and scroll tracking API
             */
            // onEndReached={() => {
            //     hasMore && refineNext()
            // }}
            renderItem={({item}) => (
                <Flex flexDirection={'row'}
                      alignItems='center'
                      p={2} width={'100%'}
                      bg={'#fff'}
                >
                    {
                        haveAvatar &&
                        <View m={2} flex={1}>
                            <Avatar size={{base: 'md', md: 'xl'}} source={{uri: item.picture}}/>
                        </View>
                    }
                    <Flex height={'100%'} px={5} wrap={'wrap'} flex={6}>
                        <View>
                            <Highlight attribute={attribute} hit={item}/>
                        </View>
                        {entity === 'company' &&
                        <Badge borderRadius={5} colorScheme='primary.600' m={2} alignSelf={'flex-start'}>
                            <Text>{entity}</Text>
                        </Badge>}
                        {entity === 'speaker' &&
                        <Badge borderRadius={5} colorScheme='success' m={2} alignSelf={'flex-start'}>
                            <Text>{entity}</Text>
                        </Badge>}
                        {entity === 'session' &&
                        <Badge borderRadius={5} m={2} alignSelf={'flex-start'}>
                            <Text>{entity}</Text>
                        </Badge>}
                    </Flex>
                    {renderLink(item)}
                </Flex>
            )}
        />
    );
};

InfiniteHits.propTypes = {
    hits: PropTypes.arrayOf(PropTypes.object).isRequired,
    hasMore: PropTypes.bool.isRequired,
    refineNext: PropTypes.func.isRequired,
};

export default connectInfiniteHits(InfiniteHits);
