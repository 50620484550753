import HttpService from './HttpService';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {Platform} from "react-native";

const startPing = (navigation, data, callBackReload = () => {
}) => {
    const interval = setInterval(() => {
        HttpService.postData('account/ping', data).then((response) => {
            if (response.data.success) {
                callBackReload();
                //console.log('Success ping: ');
            } else {
                if (response.data.action == 'redirect') {
                    if (response.data.to == 'login') {
                        navigation.navigate('/');
                    }
                } else if (response.data.action == 'redirect-room-change') {
                    if (Platform.OS === 'web') {
                        navigation.navigate('LiveChangeRoom', {id: response.data.id, id2: response.data.id2});
                    }
                } else if (response.data.action == 'reload') {
                    callBackReload();
                }
            }
            return response.data;
        }).catch(() => {
            console.log('Error ping http');
        })
    }, 30000);
    return interval;
}

export default {
    startPing,
};
