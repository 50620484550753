import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Center, Flex, Heading, Image, Pressable, Spinner, Text, useTheme, View} from "native-base";
import * as ScreenOrientation from "expo-screen-orientation";
import Layout from "../layouts/Layout";
import HttpService from "../services/HttpService";
import {AntDesign, Entypo} from "@expo/vector-icons";
import LinkButton from "../components/LinkButton";
import VideoPlayer from "../components/VideoPlayer";
import LiveChatMobileScreen from "./LiveChatMobileScreen";
import LiveInfoMobileScreen from "./LiveInfoMobileScreen";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {Platform} from "react-native";
import Constants from "expo-constants";
import AccountService from "../services/AccountService";
import {useIsFocused} from "@react-navigation/native";
import {useTranslation} from "react-i18next";
import Config from "../config.json";
import pusher from "../pusher";

const Stack = createNativeStackNavigator();

export default function LiveStageScreen({navigation, ...props}) {
    const [orientationReady, setOrientationReady] = useState(false);
    const [dataReady, setDataReady] = useState(false);
    const [ready, setReady] = useState(false);
    const [room, setRoom] = useState()
    const [currentLecture, setCurrentLecture] = useState();
    const [currentSession, setCurrentSession] = useState();
    const [isLoadingCurrentSession, setIsLoadingCurrentSession] = useState(false);
    const [isLoadingCurrentLecture, setIsLoadingCurrentLecture] = useState(false);
    const [fetchError, setFetchError] = useState();
    const [mobileSidebarIsShown, setMobileSidebarIsShown] = useState(true);
    const [emptyRoom, setEmptyRoom] = useState(false);
    const isFocused = useIsFocused();
    const {colors} = useTheme();
    const {t, i18n} = useTranslation();

    // Ping
    useEffect(() => {
        let interval;
        if(isFocused) {
            interval = AccountService.startPing(navigation, {
                page: 'conference-room',
                sessionRoomId: props.route.params.id,
                sessionId: (currentSession ? currentSession.id : null)
            });
        }
        return () => clearInterval(interval);
    }, [currentSession, isFocused])

    // Setup device orientation and API data
    useEffect( () => {
        if (props.route.params.id) {
            getRoom(props.route.params.id).then((room) => {
                if (room.live_current_session) {
                    setCurrentSession(room.live_current_session);
                    setEmptyRoom(false);
                }
                else setEmptyRoom(true);
                if (room.live_current_lecture) setCurrentLecture(room.live_current_lecture);
                setDataReady(true);
            });
        }
        else {
            setFetchError({message: 'No room ID.'});
        }
    }, []);

    // Change screen orientation at screen focus
    useEffect(() => {
        setReady(false);
        ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE)
            .then(r => setOrientationReady(true));
        return () => ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
    }, [isFocused]);

    useEffect(() => {
        if(dataReady && orientationReady) {
            setReady(true);
        }
    }, [dataReady, orientationReady]);

    useEffect(() => {
        pusher.subscribe('onsite-room');

        pusher.bind('launch-lecture', function (data) {
            setTimeout(function () {
                if (data.roomId?.toString() === props.route.params?.id?.toString()) {
                    setIsLoadingCurrentLecture(true);
                    getRoom(props.route.params?.id).then((room) => {
                        setCurrentLecture(room.live_current_lecture);
                        setIsLoadingCurrentLecture(false);
                    });
                }
            }, 2000);
        });

        pusher.bind('update', function (data) {
            setTimeout(function () {
                if (data.roomId?.toString() === props.route.params?.id?.toString()) {
                    setIsLoadingCurrentSession(true);
                    getRoom(props.route.params?.id).then((room) => {
                        setCurrentSession(room.live_current_session);
                        setCurrentLecture(room.live_current_lecture);
                        setIsLoadingCurrentSession(false);
                    });
                }
            }, 2000);
        });

        return () => {
            pusher.unsubscribe('onsite-room');
        };
    }, [isFocused]);

    function getRoom(id) {
        return HttpService.getData('room/get/one-inside/' + id)
            .then((response) => {
                setRoom(response.data.data);
                return response.data.data;
            })
            .catch((error) => {
                setFetchError(error);
                return error;
            });
    }

    function toggleMobileSidebar() {
        setMobileSidebarIsShown(!mobileSidebarIsShown);
    }

    function changeStreamLanguage(language) {
        setIsLoadingCurrentSession(true);
        return HttpService.postData('account/change-stream-language', {language: language})
            .then((response) => {
                getRoom(props.route.params?.id).then((room) => {
                    setCurrentSession(room.live_current_session);
                    setCurrentLecture(room.live_current_lecture);
                    setIsLoadingCurrentSession(false);
                });
            })
            .catch((error) => {
                setIsLoadingCurrentSession(false);
                setFetchError(error);
                return error;
            });
    }

    function goToPreviousPage() {
        return navigation.goBack();
    }

    function renderTranslations() {
        if (room.stream_available_languages && room.stream_available_languages.length > 1) {
            return (
                <Flex direction={'row'}>

                    {room.stream_available_languages.map((language, index) => {
                        if (room.stream_language !== language.code) {
                            return (
                                <Pressable key={index} onPress={() => changeStreamLanguage(language.code)}
                                           zIndex={10}
                                >
                                    <Flex justify={'center'} height={'100%'} pr={4}>
                                        <Image
                                            source={{uri: language.flag_url}}
                                            style={{
                                                width: 20,
                                                height: 15,
                                                opacity: 0.5
                                            }}/>
                                    </Flex>
                                </Pressable>
                            );
                        } else {
                            return (
                                <Flex justify={'center'} height={'100%'} pr={4}>
                                    <Image
                                        source={{uri: language.flag_url}}
                                        style={{
                                            width: 20,
                                            height: 15,
                                        }}/>
                                </Flex>
                            );
                        }
                    })}

                </Flex>
            );
        }
    }

    if (fetchError) {
        return (
            <Layout orientationFree>
                <Center flex={1}>
                    <Text>{t("There_was_a_problem_loading_your_data")}</Text>
                    <Text>{fetchError.message}</Text>
                </Center>
            </Layout>
        );
    } else if (room && currentSession && !currentSession.hasAccess) {
        return (
            <Layout orientationFree>
                <Flex direction={'row'}>
                    <Button
                        variant={'unstyled'}
                        onPress={goToPreviousPage}>
                        <AntDesign name="arrowleft" size={40} color="black"/>
                    </Button>
                    <Heading size={'2xl'}>{t('Live')}</Heading>
                </Flex>
                <Center flex={1}>
                    <Flex
                        justify={'center'}
                        align={'center'}
                        bg={colors['backgroundLight'][50]}
                        py={10}
                        px={16}
                        borderRadius='lg'
                        maxW={700}
                    >
                        <Text>{t('You_dont_have_access_to_this_session')}</Text>
                        <View h={8}/>
                        <LinkButton destination={'Program'} text={t('Program')}/>
                    </Flex>
                </Center>
            </Layout>
        );
    } else if (room && emptyRoom) {
        return (
            <Layout orientationFree>
                <Flex direction={'row'}>
                    <Button
                        variant={'unstyled'}
                        onPress={goToPreviousPage}>
                        <AntDesign name="arrowleft" size={40} color="black"/>
                    </Button>
                    <Heading size={'2xl'}>{t('Live')}</Heading>
                </Flex>
                <Center flex={1}>
                    <Flex
                        justify={'center'}
                        align={'center'}
                        bg={colors['backgroundLight'][50]}
                        py={10}
                        px={16}
                        borderRadius='lg'
                        maxW={700}
                    >
                        <Text>{t('Break_message_browse')}</Text>
                        <View h={8}/>
                        <LinkButton destination={'Exhibition'} text={t('Exhibition')}/>
                    </Flex>
                </Center>
            </Layout>
        );
    } else if (room && currentSession && ready) {
        return (
            <Flex flex={1} direction={'row'}>
                <View flex={10} bg={colors['background'][50]} borderTopWidth={1} borderColor={"white"}>
                    <Flex flex={1} width={'100%'} direction={'row'} justify={'space-between'}>
                        <Button
                            zIndex={10}
                            width={'15%'}
                            variant={'unstyled'}
                            p={0}
                            onPress={goToPreviousPage}>
                            <AntDesign name="arrowleft" size={20} color="black"/>
                        </Button>
                        {renderTranslations()}
                    </Flex>
                    <View flex={10}>
                        <VideoPlayer
                            file={room.stream_url}
                        />
                    </View>
                </View>
                <Flex flex={1} bg={colors['background'][50]}>
                    <Button
                        onPress={toggleMobileSidebar}
                        variant={'unstyled'}
                        h={'100%'}
                        w={'100%'}
                        border={1}
                        borderColor={colors['action'][50]}
                        p={0}
                    >
                        <Entypo name={mobileSidebarIsShown ? "chevron-right" : "chevron-left"} size={32}
                                color='#5766D6'/>
                    </Button>
                </Flex>
                <Flex flex={5}
                      style={{display: mobileSidebarIsShown ? 'flex' : 'none'}}
                      bg={colors['background'][50]}
                      px={2}
                >
                    <Stack.Navigator
                        screenOptions={{headerShown: false}}
                    >
                        <Stack.Screen name={'LiveChatMobileScreen'}>
                            {(props) =>
                                <LiveChatMobileScreen
                                    {...props}
                                    session={currentSession}
                                    room={room}
                                    isSessionLoading={isLoadingCurrentSession}
                                />}
                        </Stack.Screen>
                        <Stack.Screen name={'LiveInfoMobileScreen'}>
                            {(props) =>
                                <LiveInfoMobileScreen {...props}
                                                      session={currentSession}
                                                      lecture={currentLecture}
                                                      isLoadingCurrentLecture={isLoadingCurrentLecture}
                                />}
                        </Stack.Screen>
                    </Stack.Navigator>
                </Flex>
            </Flex>
        );
    } else {
        return (
            <Layout emptyLayout orientationFree>
                <Center flex={1}><Spinner color="#5766D6" /></Center>
            </Layout>
        );
    }
}
