import {Button, Center, Image, Text, useTheme, View} from "native-base";
import * as React from "react";
import {Platform, TouchableOpacity} from "react-native";
import {FontAwesome} from "@expo/vector-icons";

export default function PhotoBoothPhoto(props) {

    const {colors} = useTheme();

    function focus() {
        return props.focus(props.photo);
    }

    if(Platform.OS === 'web') {
        return (
            <View
                m={0.5}
            >
                {props.photo.comment ?
                    <Text  color={"white"} position={"absolute"} top={"0px"} left={"0px"} zIndex={2} bg={colors['action'][50]} p={1} opacity={0.75}>
                        <FontAwesome name="commenting" size={12} />
                    </Text>
                    : null
                }
                <TouchableOpacity
                    onPress={focus}
                    activeOpacity={0.5}
                >
                    <Image source={{uri: props.photo.picture}} alt={'Photo HomeBooth Picture'}
                           resizeMode={'cover'}
                           style={pictureDesktopStyle} />
                </TouchableOpacity>
            </View>
        );
    }
    else return (
        <View
            width={'32%'}
            maxWidth={'150px'}
            aspectRatio={1}
            m={0.5}
        >
            {props.photo.comment ?
                <Text  color={"white"} position={"absolute"} top={"0px"} left={"0px"} zIndex={2} bg={colors['action'][50]} p={1} opacity={0.75}>
                    <FontAwesome name="commenting" size={12} />
                </Text>
                : null
            }
            <TouchableOpacity
                onPress={focus}
                activeOpacity={0.5}
            >
                <Image source={{uri: props.photo.picture}} alt={'Photo HomeBooth Picture'}
                       resizeMode={'cover'}
                       style={pictureMobileStyle} />
            </TouchableOpacity>
        </View>
    );
}

const pictureMobileStyle = {
    width: '100%',
    height: '100%'
}

const pictureDesktopStyle = {
    width: '200px',
    height: '200px'
}
