import {Flex, Text, useTheme, View, Image, Center, Pressable} from "native-base";
import {Platform} from "react-native";
import SpeakerCard from "./SpeakerCard";
import * as React from "react";
import {useTranslation} from "react-i18next";
import LinkBtn from "./LinkBtn";
import {EvilIcons} from '@expo/vector-icons';
import PartnerModal from "./PartnerModal";
import {useState} from "react";

export default function SessionFeatures(props) {
    const {t, i18n} = useTranslation();
    const {colors} = useTheme();
    const [showModal, setShowModal] = useState(false);

    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };

    function handlePressShowModal() {
        return setShowModal(true);
    }

    function renderSponsor() {
        let sponsors = [];
        if(props.session.sponsor) sponsors.push(props.session.sponsor);
        if(props.session.sponsor1) sponsors.push(props.session.sponsor1);
        if(props.session.sponsor2) sponsors.push(props.session.sponsor2);
        if(props.session.sponsor3) sponsors.push(props.session.sponsor3);
        if(props.session.sponsor4) sponsors.push(props.session.sponsor4);
        if(props.session.sponsor5) sponsors.push(props.session.sponsor5);
        if(props.session.sponsor6) sponsors.push(props.session.sponsor6);
        if(props.session.sponsor7) sponsors.push(props.session.sponsor7);
        if(props.session.sponsor8) sponsors.push(props.session.sponsor8);

        if (props.session.isSponsored && sponsors.length > 0) {
            let sponsorsRendered = 0;
            return (
                <Center>
                    <Flex direction='row' align='center' wrap={'wrap'}>
                        <Text>{t('Sponsored_by')} </Text>
                        {sponsors.map(sponsor => {
                            sponsorsRendered++;
                            return (<View>
                                <LinkBtn
                                    to={{
                                        screen: 'CompanyProfile',
                                        params: {id: sponsor.id}
                                    }}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                        zIndex: 3,
                                        elevation: 3,
                                    }}
                                ></LinkBtn>
                                <Text bold>{sponsor.company_name}{sponsorsRendered > 0 && sponsorsRendered <= sponsors.length - 1 ? ', ' : null}</Text>
                            </View>);
                        })}
                    </Flex>
                </Center>);
        }
    }

    function renderPartners() {
        if (props.session.partners) {
            let items = [];
            props.session.partners.forEach((partner) => {
                items.push(
                    <Pressable onPress={handlePressShowModal} style={{cursor: 'pointer'}}>
                        <Flex
                            key={uniqueId()}
                            direction={'column'}
                            alignItems={'center'}
                            m={2}
                        >
                            <Image
                                source={{uri: partner.picture ? partner.picture : null}}
                                size={props.verySmall ? 'md' : Platform.OS === 'web' ? 'lg' : 'md'}
                                resizeMode={'contain'}
                                width={'100%'}
                                m={2}
                                alt={'partner logo'}
                            />
                            <Text
                                fontSize={props.verySmall ? '2xs' : {base: '2xs', md: 'sm'}}
                                px={2}
                            >{partner.name}</Text>
                            <PartnerModal partner={partner} showModal={showModal} setShowModal={setShowModal}/>
                        </Flex>
                    </Pressable>
                );
            });

            if (items.length > 0) {
                return (
                    <View my={2} borderTopWidth={1} borderColor={colors['background'][100]} pt={2}>
                        <Text fontSize={props.verySmall ? 'xs' : {base: 'md', md: 'xl'}}
                              textAlign={props.verySmall ? 'left' : {base: 'left', md: 'center'}}
                              color={'darkblue.800'}
                              mb={2}
                              bold
                        >{t('Scientific_partner')}</Text>
                        <Flex direction={'row'} justifyContent={'center'} wrap={'wrap'}>
                            {items}
                        </Flex>
                    </View>
                );
            }
        }
    }

    function renderSpecialMessage() {
        if(props.session.id === 54170) {
            return (
                <Text fontSize={'sm'} textAlign={'center'} mt={2}>This session is intended for healthcare professionals only.</Text>
            );
        }
    }

    function renderChairs() {
        const isMobile = Platform.OS !== 'web';
        if (props.session.chairs) {
            let items = [];
            for (const chair in props.session.chairs) {
                items.push(
                    <SpeakerCard
                        uri={props.session.chairs[chair].picture}
                        avatarSize={props.verySmall ? 'xs' : Platform.OS === 'web' ? '2xl': 'lg'}
                        fullname={props.session.chairs[chair].fullname}
                        specialty={props.session.chairs[chair].specialty}
                        country={props.session.chairs[chair].country}
                        id={props.session.chairs[chair].id}
                        isHorizontal={isMobile}
                        key={uniqueId()}
                    />
                );
            }

            if (items.length > 0) {
                return (
                    <View my={2} borderTopWidth={1} borderColor={colors['background'][100]} pt={2}>
                        <Text fontSize={props.verySmall ? 'xs' : {base: 'md', md: 'xl'}}
                              textAlign={props.verySmall ? 'left' : {base: 'left', md: 'center'}}
                              color={'darkblue.800'}
                              mb={2}
                        >
                            {t('Chairs')}
                        </Text>
                        <Flex
                            direction={isMobile ? 'column' : 'row'}
                            justifyContent={'center'}
                            wrap={'wrap'}
                        >
                            {items}
                        </Flex>
                    </View>
                );
            }
        }
    }

    return (
        <Flex>
            {renderSponsor()}
            {renderPartners()}
            {renderSpecialMessage()}
            {renderChairs()}
            {/*<PartnerModal partner={partner} showModal={showModal} setShowModal={setShowModal}/>*/}
        </Flex>
    );
}
