import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
    Center,
    Heading,
    HStack,
    Spinner,
    Text,
    Flex, ScrollView, View, useTheme, Avatar
} from "native-base";
import Layout from "../layouts/Layout";
import HttpService from "../services/HttpService";
import {Platform, FlatList, Dimensions} from "react-native";
import SpeakerCard from "../components/SpeakerCard";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import SpeakerFilters from "../components/SpeakerFilters";
import CountResult from "../components/CountResult";
import AccountService from "../services/AccountService";
import ScrollToTopButton from "../components/ScrollToTopButton";
import * as Device from "expo-device";
import {Link, useIsFocused} from "@react-navigation/native";

function SpeakersListScreen({navigation}) {

    const {t, i18n} = useTranslation();
    const {colors} = useTheme();
    const [speakers, setSpeakers] = useState([]);
    const [filteredSpeakers, setFilteredSpeakers] = useState([]);
    const {list} = useSelector(state => state.auth);
    const listRef = useRef(null);
    const CONTENT_OFFSET_THRESHOLD = 100;
    const [contentVerticalOffset, setContentVerticalOffset] = useState(0);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [deviceType,setDeviceType] = useState()
    const isFocused = useIsFocused();

    // Ping
    useEffect(() => {
        let interval;
        if(isFocused) {
            interval = AccountService.startPing(navigation, {page: 'speakers-list'});
        }
        return () => clearInterval(interval);
    }, [isFocused]);

    const uniqueId = useCallback(() => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    },[]);


    const viewabilityConfig = useMemo(() => {return {
        waitForInteraction: false,
        itemVisiblePercentThreshold: 0,
    }},[])

    /*
     * Fetch the list of Speakers
     */
    const getSpeakers = useCallback(() => {
        if (loading) {
            return null;
        }
        setLoading(true);
        HttpService
            .getData('speaker/get/all?page=' + page)
            .then(response => {
                //setTotalSpeakers(response.data.total);
                setSpeakers(speakers.concat(response.data.data));
                setFilteredSpeakers(filteredSpeakers.concat(response.data.data));
                setPage(page + 1);
                setLoading(false);
            })
            .catch(error => {
                console.log('Error: ' + error);
                setLoading(false);
            })
    },[])

    useEffect(
        () => {
            setSpeakers([]);
            setFilteredSpeakers([]);
            getSpeakers();
            async function setLayout() {
                const type = await getDeviceType();
                setDeviceType(type);
            }
            setLayout();
        }, []);

    async function getDeviceType() {
        return await Device.getDeviceTypeAsync();
    }

    /*
     * return a different layout according to the device (desktop or mobile)
     */
    const speakersListLayout = useMemo(() => {
        if (filteredSpeakers.length > 0) {
            if (Platform.OS === 'web' || (deviceType == 2 && Dimensions.get('window').width > Dimensions.get('window').height)) {
                //for desktop, speakers list is displayed in multiple rows
                return (
                    <>
                        <Center
                            mx={"auto"}
                            flex={1}
                            justifyContent={"center"}
                        >
                            <FlatList
                                data={filteredSpeakers}
                                renderItem={({item})  =>
                                    <SpeakerCard
                                        uri={item.picture}
                                        avatarSize="100px"
                                        fullname={item.fullname}
                                        specialty={item.specialty}
                                        country={item.country}
                                        id={item.user_id}
                                        key={item.id}
                                        isChecked={((list.find(listItem => listItem.entity_id === item.user_id)) ? true : false)}
                                    />
                                }
                                // viewabilityConfig={viewabilityConfig}
                                initialNumToRender={15}
                                keyExtractor={item => item.id.toString()}
                                numColumns={3}
                                columnWrapperStyle={ {
                                    flex: 1,
                                    justifyContent: 'center',
                                }}
                            />
                        </Center>
                        </>

                );
            } else {
                //for mobile, speakers list is displayed in single column
                return (
                    <>
                        <FlatList
                            data={filteredSpeakers}
                            renderItem={({item})  =>
                                <SpeakerCard
                                    uri={item.picture}
                                    avatarSize="48px"
                                    fullname={item.fullname}
                                    specialty={item.specialty}
                                    country={item.country}
                                    id={item.user_id}
                                    key={item.id}
                                    isHorizontal
                                    isChecked={((list.find(listItem => listItem.entity_id === item.user_id)) ? true : false)}
                                />
                            }
                            // viewabilityConfig={viewabilityConfig}
                            initialNumToRender={4}
                            keyExtractor={item => item.id.toString()}
                        />
                    </>
                );
            }
        }  else if(loading){
            if (Platform.OS === 'web' || (deviceType == 2 && Dimensions.get('window').width > Dimensions.get('window').height)) {
                //for desktop, speakers list is displayed in multiple rows
                return (
                    <>
                        <Center
                            mx={"auto"}
                            flex={1}
                            justifyContent={"center"}
                        >
                            <FlatList
                                data={new Array(deviceType === 1 ? 6 : 25).fill({})}
                                renderItem={({item})  =>
                                    <Flex
                                        justifyContent="center" alignItems="center"
                                        w={'25%'}
                                        m={3}
                                        minW={"250px"}
                                        borderColor={colors['background'][100]}
                                        borderWidth={1}
                                        borderRadius='md'
                                        bg={colors['background'][100]}

                                    >
                                        <Flex justifyContent="center" alignItems="center" w={'100%'} h={{base:"100%",lg:'275px'}} p={5} >
                                            <View borderRadius={500} w={100} height={100} bg={"#e0e0e0"}></View>
                                            <Flex justifyContent="center" alignItems="center" width={"80%"} mt={5}>
                                                <View
                                                    style={{
                                                        width: '100%',
                                                        height: 20,
                                                        backgroundColor: '#e8e8e8',
                                                    }}
                                                >
                                                </View>
                                                <View
                                                    style={{
                                                        marginTop : 20,
                                                        width: '100%',
                                                        height: 20,
                                                        backgroundColor: '#e8e8e8',
                                                    }}
                                                >
                                                </View>
                                                <View
                                                    style={{
                                                        marginTop : 2,
                                                        width: '80%',
                                                        height: 20,
                                                        backgroundColor: '#e8e8e8',
                                                    }}
                                                >
                                                </View>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                }
                                initialNumToRender={15}
                                keyExtractor={(item) => uniqueId()}
                                numColumns={3}
                                columnWrapperStyle={ {
                                    flex: 1,
                                    justifyContent: 'center',
                                }}
                            />
                        </Center>
                    </>

                );
            } else {
                //for mobile, speakers list is displayed in single column
                return (
                    <>
                        <FlatList
                            data={new Array(deviceType === 1 ? 10 : 25).fill({})}
                            renderItem={({item}) => (
                            <Flex w={"100%"} px="2" py="2">
                                    <HStack alignItems="center" w="100%">
                                        <View borderRadius={500} w={'48px'} height={'48px'} bg={"#e0e0e0"}></View>
                                        <Flex w={'100%'} ml={3}>
                                            <View
                                                style={{
                                                    width: '70%',
                                                    height: 15,
                                                    backgroundColor: '#e8e8e8',
                                                }}
                                            >
                                            </View>
                                            <View
                                                style={{
                                                    marginTop : 2,
                                                    width: '50%',
                                                    height: 15,
                                                    backgroundColor: '#e8e8e8',
                                                }}
                                            >
                                            </View>
                                            <View
                                                style={{
                                                    marginTop : 2,
                                                    width: '30%',
                                                    height: 15,
                                                    backgroundColor: '#e8e8e8',
                                                }}
                                            >
                                            </View>
                                        </Flex>
                                    </HStack>
                            </Flex>)}
                            keyExtractor={(item) => uniqueId()}
                        />

                    </>
                );
            }
        }
        else {
            return <Center flex={1}><Text>{t('No_results')}</Text></Center>
        }
    },[filteredSpeakers])

    const renderSpeakerFilters = useMemo(() => {
        return (<SpeakerFilters
            speakers={speakers}
            setFilteredSpeakers={setFilteredSpeakers}
        />);
    },[speakers])

    const renderHeader = useMemo(() => {
        if (Platform.OS === 'web' || (deviceType == 2 && Dimensions.get('window').width > Dimensions.get('window').height)) {
            return (<Flex align={'center'}>
                <Heading size={'2xl'}>{t('All_speakers')}</Heading>
                <Flex direction={'row'} w={'100%'} justify={'center'} align={'flex-end'}>
                    <View>
                        <CountResult result={filteredSpeakers?.length}/>
                    </View>
                </Flex>
            </Flex>);
        }else {
            return (<Flex>
                <Heading>{t('All_speakers')}</Heading>
                <Center paddingBottom={2}>
                    <CountResult result={filteredSpeakers?.length}/>
                </Center>
            </Flex>);
        }
    },[filteredSpeakers])

    if (speakers.length > 0) {
        if (Platform.OS !== 'web' || (deviceType == 2 && Dimensions.get('window').width > Dimensions.get('window').height)) {
            return (<Layout withBackButton activePage={navigation.isFocused ? 'Explore' : ''}>
                {renderHeader}
                <ScrollView
                    ref={listRef}
                    onScroll={event => {
                        setContentVerticalOffset(event.nativeEvent.contentOffset.y)
                    }}
                    scrollEventThrottle={16}
                >
                    {renderSpeakerFilters}
                    {speakersListLayout}
                </ScrollView>
                {contentVerticalOffset > CONTENT_OFFSET_THRESHOLD &&
                    <ScrollToTopButton eltRef={listRef} scroll={(i) => listRef.current.scrollTo({y: 0})}/>}
            </Layout>);
        } else {
            return(<Layout withBackButton activePage={navigation.isFocused ? 'Explore' : ''}>
                {renderHeader}
                {renderSpeakerFilters}
                {speakersListLayout}
            {contentVerticalOffset > CONTENT_OFFSET_THRESHOLD &&
            <ScrollToTopButton eltRef={listRef} scroll={(i) => listRef.current.scrollTo({y: 0})}/>}
        </Layout>);
        }
    } else return (<Layout withBackButton activePage={navigation.isFocused ? 'Explore' : ''}>
        {renderHeader}
        {speakersListLayout}

    </Layout>);
}

export default SpeakersListScreen;


